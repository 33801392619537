import { useBannersContext } from '@contexts/banners-provider'
import { TeammatesControls } from '@modules/teammates-controls'
import { TeammatesLayout } from '@modules/teammates-layout'
import { getContainerHeight } from '@utils/get-container-height'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Heading, RoundedContainer } from 'ui'

export const Teammates: FC = () => {
  const { t } = useTranslation(['teammates'])

  const [isTileLayout, setIsTileLayout] = useState(true)
  const [searchString, setSearchString] = useState<string>('')

  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()

  return (
    <PageContainer
      internetConnectionBannerShown={internetConnectionBannerShown}
      notificationsBannerShown={notificationsBannerShown}
    >
      <RoundedContainer p="2rem 2rem 3.6rem" flex="0 1 100%" overflow="hidden">
        <Heading mb="1.6rem">{t('pageTitle')}</Heading>
        <TeammatesControls
          isTileLayout={isTileLayout}
          setIsTileLayout={setIsTileLayout}
          searchValue={searchString}
          setSearchValue={setSearchString}
          mb="1.6rem"
        />
        <TeammatesLayout isTile={isTileLayout} searchValue={searchString} />
      </RoundedContainer>
    </PageContainer>
  )
}

const PageContainer = styled(Container)<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  display: flex;
  flex-direction: column;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
  padding: 2rem;
`
