import React, { FC } from 'react'
import { SpaceProps, PositionProps } from 'styled-system'
import { Text, Container, TypingAnimation } from 'ui'

interface TypingIndicatorProps extends SpaceProps, PositionProps {
  text: string
}

export const TypingIndicator: FC<TypingIndicatorProps> = ({ text, ...stylesProp }) => {
  return (
    <Container display="flex" alignItems="center" width="100%" {...stylesProp}>
      <Text variant="smallTextRegular" color="greyDark" mr="0.8rem">
        {text}
      </Text>
      <Container as="span" flex="0 0 auto">
        <TypingAnimation width={22} />
      </Container>
    </Container>
  )
}
