import React, { FC, SVGProps } from 'react'

export const HashTagIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 4a1 1 0 0 0-1 1v3H5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h3v4H5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h3v3a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-3h4v3a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-3v-4h3a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-3V5a1 1 0 0 0-1-1 1 1 0 0 0-1 1v3h-4V5a1 1 0 0 0-1-1zm1 6h4v4h-4v-4z" />
  </svg>
)
