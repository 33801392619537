import { CheckIcon } from '@components/icons'
import { useFilters } from '@contexts/filters-provider'
import { FiltersSection } from '@modules/filters/filters-section'
import { SidepageHeader } from '@modules/side-page/header'
import { Form, Formik } from 'formik'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Container,
  ResetIcon,
  ScrollContainer,
  SearchIcon,
  Text,
  theme,
} from 'ui'

interface FiltersProps {
  results: number
}

export const Filters: FC<FiltersProps> = ({ results }) => {
  const { t } = useTranslation(['filters'])

  const { filters, setFilters } = useFilters()

  const [showAll, setShowAll] = useState(false)

  return (
    <>
      <SidepageHeader title={t('filters')} mb="1.6rem" />
      <Formik
        initialValues={filters}
        onSubmit={(values) => {
          setFilters(values)
        }}
      >
        {({ values, resetForm, handleSubmit }) => (
          <Form>
            <Container
              display="flex"
              flexDirection="column"
              height="85vh"
              backgroundColor={theme.colors.white}
            >
              <ScrollContainer display="flex" flexDirection="column" hideScrollbar height="100%">
                <Container display="flex" flexDirection="column" flexGap="3.2rem">
                  {Object.keys(values)
                    .map((key) => (
                      <FiltersSection key={key} status={key} options={Object.keys(values[key])} />
                    ))
                    .slice(0, showAll ? undefined : 2)}
                </Container>
                <Button
                  onClick={() => setShowAll(!showAll)}
                  postFix={showAll ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  size="sm"
                  plain
                  variant="secondary"
                  mt="1.2rem"
                >
                  {t(showAll ? 'hide' : 'showAll')}
                </Button>
              </ScrollContainer>
              <Container display="flex" flexDirection="column">
                <Container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginBottom="2.4rem"
                >
                  <SearchIcon fill={theme.colors.greyDark} />
                  <Text variant="smallTextRegular" color={theme.colors.greyDark}>
                    {results} {t('results')}
                  </Text>
                </Container>
                <Container display="flex" justifyContent="space-between" flexGap="0.8rem">
                  <Button
                    variant="secondary"
                    preFix={<ResetIcon />}
                    size="sm"
                    onClick={() => {
                      resetForm()
                      handleSubmit()
                    }}
                  >
                    {t('clear')}
                  </Button>
                  <Button type="submit" preFix={<CheckIcon />} size="sm">
                    {t('apply')}
                  </Button>
                </Container>
              </Container>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  )
}
