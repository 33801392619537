import { ThemeTypeEnum, useTheme } from '@contexts/theme-provider'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Heading, Text } from 'ui'

export const Forbidden = () => {
  const { setCurrentTheme } = useTheme()
  const { t } = useTranslation('forbidden')

  useEffect(() => {
    setCurrentTheme(ThemeTypeEnum.DARK)
  }, [])

  //TODO: Connect user email and button
  return (
    <StyledContainer>
      <Heading variant="h0" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text>
        {t('noInvitation')} <Email>example@mail.com</Email>.
      </Text>
      <Text mb="3.2rem">{t('invitedOnly')}</Text>
      <Button onClick={() => null} minWidth="20rem">
        {t('anotherEmail')}
      </Button>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/img/forbidden.svg') no-repeat;
  background-size: cover;
  background-position: center -6rem;
  height: 100%;
  text-align: center;
  padding-top: 14.5vh;
`
const Email = styled.span`
  font-weight: 500;
`
