import { messagesApi } from './instance'
import { Message } from './types/message'

export interface AddMessageReactionArgs {
  messageId: string
  reactionCode: string
}

export const addMessageReaction = async ({ messageId, reactionCode }: AddMessageReactionArgs) => {
  const { data } = await messagesApi.post<Message>(`/${messageId}/reactions/${reactionCode}`)
  return data
}
