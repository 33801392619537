import { SkeletonVariantProps } from '@modules/skeleton'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'ui'
import { SkeletonContainerList } from '../style'

export const ChannelSkeleton: FC<SkeletonVariantProps> = ({ arrayToMap }) => (
  <SkeletonContainerList>
    {arrayToMap.map((num) => (
      <Container key={num} display="flex" alignItems="center" flexGap="1.2rem" py="1.2rem">
        <Skeleton width={36} height={36} borderRadius={6} />
        <Container>
          <Skeleton width={160} height={12} style={{ marginBottom: '1.2rem' }} />
          <Skeleton width={240} height={12} />
        </Container>
      </Container>
    ))}
  </SkeletonContainerList>
)
