import { Invitation } from '@modules/workspace-invitations/invitation-item/types'

export const sortInvitations = (invitations: Invitation[]) => {
  const sortedActiveInvitations = invitations
    .filter((invitation) => invitation.expiresIn.slice(0, 1) !== '-')
    .sort((a, b) => {
      const [aDaysAndHours] = a.expiresIn.split(':').map(Number)
      const [bDaysAndHours] = b.expiresIn.split(':').map(Number)
      return bDaysAndHours - aDaysAndHours
    })
  const sortedExpiredInvitations = invitations
    .filter((invitation) => invitation.expiresIn.slice(0, 1) === '-')
    .sort((a, b) => {
      const [aDaysAndHours] = a.expiresIn.split(':').map(Number)
      const [bDaysAndHours] = b.expiresIn.split(':').map(Number)
      return bDaysAndHours - aDaysAndHours
    })

  return [...sortedActiveInvitations, ...sortedExpiredInvitations]
}
