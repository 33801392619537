import { useMutation } from '@tanstack/react-query'
import { editMessage, EditMessageParams } from '../edit-message'
import { MessageDetailDto } from '@api/chats/generated'

export interface EditData extends EditMessageParams {
  clientMessageId: string
  newText: string
  oldText: string
  chatId: string
}

interface UseEditMessageArgs {
  onMutate?: (data: EditData) => void
  onSuccess?: VoidFunction
  onError?: (error: unknown, data: EditData) => void
}

export const useEditMessage = ({ onMutate, onSuccess, onError }: UseEditMessageArgs) => {
  const { mutate, isSuccess, isLoading } = useMutation<MessageDetailDto, Error, EditData>({
    mutationFn: ({ messageId, newText }) => editMessage({ messageId, newText }),
    onMutate,
    onSuccess,
    onError,
  })

  return { mutate, isSuccess, isLoading }
}
