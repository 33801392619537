import { useGetMe } from '@api/account/hooks/use-get-me'
import { Chat } from '@api/chats/types/chat'
import { getChatName } from '@utils/get-chat-name'
import { CHANNEL_TYPES, isChat } from '@utils/get-chat-type'
import { TFunction } from 'next-i18next'
import { useMemo } from 'react'

export const useChatName = (chat: Chat, t: TFunction) => {
  const shouldSkip = useMemo(() => {
    return chat.chatUsers?.length > 2 || CHANNEL_TYPES.includes(chat.conversationType)
  }, [chat])

  //TODO find method how to get data from cache if it is there
  const { meData } = useGetMe(shouldSkip)

  if (isChat(chat)) {
    if (chat.chatUsers.length === 1) {
      return `${meData?.firstName} ${meData?.lastName}`
    }

    const chatUsersList = chat.chatUsers
      .filter((user) => user.user.userId !== meData?.userId)
      .map((user) => `${user.user.firstName} ${user.user.lastName}`)

    const chatListLength = chatUsersList.length
    if (chatListLength === 1) {
      return chatUsersList[0]
    } else {
      return `${chatUsersList.join(', ')}`
    }
  }

  return getChatName(chat, t)
}
