import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, RadioButton, Text, ToggleButton } from 'ui'
import { NotificationsProps } from './types'

export const Notifications: React.FC<NotificationsProps> = ({ title, ...stylesProps }) => {
  const [notifications, setNotifications] = useState<boolean>(true)
  const [selectedRadioBtn, setSelectedRadioBtn] = useState('all')
  const { t } = useTranslation(['personal'])
  const isRadioSelected = (value: string): boolean => selectedRadioBtn === value

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedRadioBtn(e.currentTarget.value)
  }
  return (
    <Container {...stylesProps}>
      <Text as="h2" mb="1.2rem" fontSize="1.8rem" fontWeight={500}>
        {title}
      </Text>
      <ToggleButton
        label={t('notifyToggle')}
        id="notifications"
        checked={notifications}
        mb="1.2rem"
        onClick={() => setNotifications(!notifications)}
      />
      {notifications && (
        <>
          <RadioButton
            name="notifications"
            label={t('allActivity')}
            value="all"
            mb="2rem"
            checked={isRadioSelected('all')}
            onChange={handleOnChange}
          />
          <RadioButton
            name="notifications"
            label={t('onlyDM')}
            value="direct"
            checked={isRadioSelected('direct')}
            onChange={handleOnChange}
          />
        </>
      )}
    </Container>
  )
}
