import { theme } from '@common/theme'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, DownloadIcon, HiddenLabel, Text } from 'ui'
import { FileImageProps, FilePreview, FileTypeVariant, FileVideoProps } from './file-preview'
import { FilesItem, InnerContainer } from './styled'

export interface FileProps {
  type: FileTypeVariant
  name: string
  user: string
  date: Date
  img?: FileImageProps
  href: string
  video?: FileVideoProps
}

export const FileItem: React.FC<FileProps> = ({ type, name, user, date, img, href, video }) => {
  const { t } = useTranslation('files')
  return (
    <FilesItem key={name}>
      <InnerContainer href={href} download>
        <HiddenLabel>{t('download')}</HiddenLabel>
        <FilePreview type={type} img={img} video={video} />
        <Container textAlign="left" ml="1.6rem">
          <Text color="black">{name}</Text>
          <Text variant="smallTextRegular" color={theme.colors.greySuperDark}>
            {user} {t('on')} {format(date, 'MMM d')}
          </Text>
        </Container>
        <DownloadIcon />
      </InnerContainer>
    </FilesItem>
  )
}
