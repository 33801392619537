import { useGetMe } from '@api/account/hooks/use-get-me'
import { usePatchMe } from '@api/account/hooks/use-patch-me'
import { PatchMePath } from '@api/account/patch-me'
import { useGetTimezones } from '@api/timezones/hooks/use-get-timezones'
import { Form, Formik, FormikHelpers } from 'formik'
import { isEmpty } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { Button, Container, OptionType, Select, TextField } from 'ui'
import { personalValidationSchema } from './data'
import { PersonalValues } from './types'

export const GeneralSettingsForm: FC = () => {
  const { t } = useTranslation(['personal'])
  const { meData } = useGetMe()
  const { mutate, isLoading: isSendingData } = usePatchMe()
  const { timezones } = useGetTimezones()

  const profileSettings: PersonalValues = useMemo(
    () => ({
      [PatchMePath.FirstName]: meData?.firstName || '',
      [PatchMePath.LastName]: meData?.lastName || '',
      [PatchMePath.PhoneNumber]: meData?.phoneNumber || '',
      [PatchMePath.Email]: meData?.email || '',
      [PatchMePath.JobTitle]: meData?.jobTitle || '',
      [PatchMePath.Timezone]: meData?.timezone || '',
    }),
    [meData]
  )

  const handleSubmit = (values: PersonalValues, actions: FormikHelpers<PersonalValues>) => {
    const timezoneId = values.timezone?.id
    const valuesToMutate = { ...values, [PatchMePath.Timezone]: timezoneId }
    mutate(valuesToMutate)
    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={profileSettings}
      validationSchema={personalValidationSchema(t)}
      onSubmit={handleSubmit}
      validateOnBlur
    >
      {({ values, errors, handleChange, setFieldValue }) => (
        <Form>
          <Container display="flex" mb="2.4rem" flexDirection={{ _: 'column', desktop: 'row' }}>
            <TextField
              id="name"
              name="firstName"
              label={t('name')}
              value={values.firstName}
              placeholder={t('placeholder.name')}
              onChange={handleChange}
              warning={Boolean(errors?.firstName)}
              helperText={errors?.firstName}
              width="100%"
            />
            <TextField
              id="surmame"
              name="lastName"
              label={t('surname')}
              value={values.lastName}
              placeholder={t('placeholder.surname')}
              onChange={handleChange}
              warning={Boolean(errors?.lastName)}
              helperText={errors?.lastName}
              width="100%"
              m={{ _: '2.4rem 0 0', desktop: '0 0 0 1.6rem' }}
            />
          </Container>
          <Container display="flex" mb="2.4rem" flexDirection={{ _: 'column', desktop: 'row' }}>
            <TextField
              id="email"
              name="email"
              label={t('email')}
              type="email"
              value={values.email}
              onChange={handleChange}
              warning={Boolean(errors?.email)}
              helperText={errors?.email}
              description={t('emailDesc')}
              disabled
              width="100%"
            />
            {/*Phone number not allowed yet on backend*/}
            <Container width="100%" display="flex">
              <TextField
                id="phone"
                name="phoneNumber"
                label={t('phone')}
                type="text"
                placeholder={t('placeholder.phone')}
                value={values.phoneNumber}
                onChange={handleChange}
                warning={Boolean(errors?.phoneNumber)}
                helperText={errors?.phoneNumber}
                width="100%"
                m={{ _: '2.4rem 0 0', desktop: '0 0 0 1.6rem' }}
              />
            </Container>
            {/*Phone number not allowed yet on backend*/}
          </Container>
          <Select
            label={t('timezone')}
            options={timezones || []}
            value={{
              value: values.timezone?.id || '',
              label: values.timezone?.displayName || '',
            }}
            onChange={(timezone) =>
              setFieldValue('timezone', {
                id: (timezone as SingleValue<OptionType>)?.value,
                displayName: (timezone as SingleValue<OptionType>)?.label,
              })
            }
            width="100%"
            maxMenuHeight={240}
            mb="2.4rem"
          />
          <TextField
            width="100%"
            id="jobTitle"
            name="jobTitle"
            label={t('jobTitle')}
            placeholder={t('placeholder.jobTitle')}
            type="text"
            value={values.jobTitle}
            onChange={handleChange}
            warning={Boolean(errors?.jobTitle)}
            helperText={errors?.jobTitle}
            mb="2.4rem"
          />
          <Button type="submit" minWidth="16rem" disabled={!isEmpty(errors) || isSendingData}>
            {t('saveButton')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
