import { WORKSPACE_INVITATIONS } from '@api/workspaces/tags'
import { useQuery } from '@tanstack/react-query'
import {
  getWorkspaceInvitations,
  GetWorkspaceInvitationsParams,
} from '../get-workspace-invitations'

export const useGetWorkspaceInvitations = ({ id }: GetWorkspaceInvitationsParams) => {
  const { data, isLoading, isError } = useQuery(
    [WORKSPACE_INVITATIONS, id],
    () => getWorkspaceInvitations({ id }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  )
  return { data, isLoading, isError }
}
