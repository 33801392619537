import { AvatarResponse } from '@api/workspaces/generated'
import { postWorkspaceAvatar } from '@api/workspaces/post-workspace-avatar'
import { useMutation } from '@tanstack/react-query'

const WORKSPACE_AVATAR = 'workspace-avatar'

export const usePostWorkspaceAvatar = () => {
  return useMutation<AvatarResponse, Error, string>([WORKSPACE_AVATAR], {
    mutationFn: (workspaceId: string) => postWorkspaceAvatar(workspaceId),
  })
}
