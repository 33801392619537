import { useGetUsers } from '@api/account/hooks/use-get-users'
import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { useAppSelector } from '@app/hooks'
import { useBannersContext } from '@contexts/banners-provider'
import { useSkeleton } from '@hooks/use-skeleton'
import { useTabTitleWithCounter } from '@hooks/use-tab-title-with-counter'
import { useWorkspace } from '@hooks/use-workspace'
import { WorkspaceGeneralWithSkeleton } from '@modules/workspace-general'
import { WorkspaceInvitations } from '@modules/workspace-invitations'
import { WorkspaceMembers } from '@modules/workspace-members'
import { getContainerHeight } from '@utils/get-container-height'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { Container, Heading, RoundedContainer, Tab, Tabs, TabsVariant, ScrollableTab } from 'ui'

export const Workspace = () => {
  const { sortBy, sortDesc } = useAppSelector((state) => state.userSorting)
  const { users, isUsersFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useGetUsers({
    sortBy,
    sortDesc,
  })
  const [isInView, setIsInView] = useState(false)
  const { t } = useTranslation(['workspace'])
  const tabTitle = useTabTitleWithCounter(users ?? [], 'members')

  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()

  const { showSkeleton, hideSkeleton } = useSkeleton()
  const { workspaceId } = useWorkspace()
  const { isLoading: isGeneralDataLoading } = useGetWorkspace({ id: workspaceId })

  const canFetch = hasNextPage && isInView && !isFetchingNextPage && !isUsersFetching

  useEffect(() => {
    if (canFetch) {
      fetchNextPage()
    }
  }, [canFetch])

  useEffect(() => {
    isGeneralDataLoading
      ? showSkeleton(SkeletonComponentNames.SETTINGS_WORKSPACE_GENERAL_1)
      : hideSkeleton(SkeletonComponentNames.SETTINGS_WORKSPACE_GENERAL_1)
  }, [isGeneralDataLoading, showSkeleton, hideSkeleton])

  return (
    <Container
      display="flex"
      flexDirection="column"
      height={getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)}
      p="2rem"
    >
      <RoundedContainer p="2rem 2rem 6rem" flex="0 1 100%" overflow="hidden">
        <Heading mb="1.6rem">{t('pageTitle')}</Heading>
        <Tabs variant={TabsVariant.UNDERLINE}>
          <ScrollableTab title={t('general')}>
            <WorkspaceGeneralWithSkeleton
              componentName={SkeletonComponentNames.SETTINGS_WORKSPACE_GENERAL_1}
            />
          </ScrollableTab>
          <Tab title={tabTitle}>
            <WorkspaceMembers
              users={users}
              setIsInView={setIsInView}
              isLoading={isFetchingNextPage || isUsersFetching}
            />
          </Tab>
          <Tab title={t('invites')}>
            <WorkspaceInvitations />
          </Tab>
        </Tabs>
      </RoundedContainer>
    </Container>
  )
}

export const SettingsListItem = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
  }
`
