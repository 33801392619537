import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { EditAvatar } from '@modules/edit-avatar'
import { GeneralSettingsForm } from '@modules/forms/general-settings'
import { AvatarType } from '@modules/modals/edit-avatar/types'
import React, { FC } from 'react'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { Container } from 'ui'
import { PersonalGeneralSkeleton } from './skeleton'

const PersonalGeneral: FC = () => {
  const { meData } = useGetMe()

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: meData?.userId,
    avatarId: meData?.avatar?.mediaId,
  })

  return (
    <Container maxWidth={{ _: '40rem', desktop: '60rem' }}>
      <EditAvatar
        mb="2.4rem"
        imgURL={usersAvatarUrl}
        avatarBgColor={meData?.color}
        avatarType={AvatarType.User}
        name={`${meData?.firstName ?? ''} ${meData?.lastName ?? ''}`}
        label={`${meData?.firstName?.charAt(0) ?? ''}`}
        isAvatarExists={enabled}
      />
      <GeneralSettingsForm />
    </Container>
  )
}

export const PersonalGeneralWithSkeleton = withSkeleton(
  PersonalGeneral,
  <PersonalGeneralSkeleton />
)
