import { useGetUsers } from '@api/account/hooks/use-get-users'
import { User } from '@api/account/types/user'
import { ChipInput } from '@components/chip-input'
import { ChipInputItemProps } from '@components/chip-input/chip-input-item'
import { DirectMessagesContent } from '@modules/direct-messages-content'
import { routes } from '@routes/routes'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Container, Heading, RoundedContainer } from 'ui'

export const DirectMessages: FC = () => {
  // search sting is not used now, but it will be used in future for the list search in direct messages page
  const [searchString] = useState('')
  const navigate = useNavigate()
  const [searchUser, setSearchUser] = useState<User[]>([])
  const { users, hasNextPage, isUsersFetching, isFetchingNextPage, fetchNextPage } = useGetUsers()
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (searchUser.length > 0) {
      navigate(`../${routes.chats}/${routes.createNew}?userId=${searchUser[0].userId}`)
    }
  }, [searchUser, navigate])

  const canFetch = hasNextPage && !isUsersFetching && !isFetchingNextPage

  useEffect(() => {
    if (canFetch) fetchNextPage()
  }, [canFetch])

  const chipInputOptions = useMemo(
    () =>
      users.map(
        (user) =>
          ({
            ...user,
            value: user.userId,
            label: `${user.firstName} ${user.lastName}`,
            avatar: user.avatar,
            color: user.color,
            online: user.online,
          } as ChipInputItemProps)
      ),
    [users]
  )

  return (
    <Container display="flex" flexDirection="column" height="100vh" p="2rem">
      <RoundedContainer p="2rem 2rem 3.6rem" flex="0 1 100%" overflow="hidden">
        <Heading mb="0.8rem">{t('directMessages')}</Heading>
        <ChipInput
          options={chipInputOptions}
          isMulti
          setOptionsList={setSearchUser}
          defaultValue={null}
        />
        <DirectMessagesContent searchString={searchString} />
      </RoundedContainer>
    </Container>
  )
}
