import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsers } from '@api/account/hooks/use-get-users'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { ChatUser } from '@api/chats/types/chat-user'
import { NetworkMode } from '@api/enums'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { formatChatHeaderTitle } from '@utils/format-chat-header-title'
import { getChatName } from '@utils/get-chat-name'
import { CHANNEL_TYPES, isChat } from '@utils/get-chat-type'
import { getFullName } from '@utils/helpers'
import { getRecipientOnlineStatus } from '@utils/utils'
import React, { FC, useMemo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { SpaceProps } from 'styled-system'
import { Avatar, AvatarsIcon, ChevronDownIcon, Container, StatusVariant, theme } from 'ui'
import { ChatHeaderButton, ChatName, IconContainer } from './styled'

export const ChatHeader: FC<SpaceProps> = ({ ...spacing }) => {
  const { setSidePageId, setOpen, setType } = useSidePage()
  const { t } = useTranslation(['chat'])
  const { meData } = useGetMe()
  const { chatId, channelId } = useParams<{ chatId?: string; channelId?: string }>()
  const { data: chatDetails } = useGetChatDetails(chatId || channelId)
  const { users } = useGetUsers({ networkMode: NetworkMode.OFFLINE_FIRST })
  const [chatUserId, setChatUserID] = useState('')
  const [avatarId, setAvatarId] = useState<string>()
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({ userId: chatUserId, avatarId })

  // Get chat users amount
  const chatUsersNumber = useMemo(() => {
    return chatDetails?.chatUsers.length || 0
  }, [chatDetails])

  // Filter all chat users excluding ME
  const usersSendingToMe = useMemo(() => {
    if (!meData || !chatDetails) {
      return []
    }

    return chatDetails.chatUsers.filter((chatUser) => chatUser.user.userId !== meData.userId)
  }, [chatDetails, meData])

  const isMyPrivateChat = useMemo(() => {
    if (!meData || !chatDetails) {
      return false
    }

    return chatUsersNumber === 1 && usersSendingToMe.length === 0 && isChat(chatDetails)
  }, [chatDetails, chatUsersNumber, meData, usersSendingToMe.length])

  useEffect(() => {
    if (isMyPrivateChat && meData) {
      setChatUserID(meData.userId)
      setAvatarId(meData.avatar?.mediaId)
    }
    if (usersSendingToMe.length === 1) {
      setChatUserID(usersSendingToMe[0].user.userId)
      //@ts-ignore
      setAvatarId(usersSendingToMe[0].user.avatar?.id)
    }
  }, [usersSendingToMe, meData])

  // Generate chat header title
  const generateChatHeader = (usersSendingToMe: ChatUser[]) => {
    if (isMyPrivateChat && meData) {
      const { firstName, lastName, online, color } = meData
      const fullName = getFullName(firstName, lastName)
      return (
        <>
          <Avatar
            imgUrl={usersAvatarUrl}
            name={fullName}
            status={online.status === 0 ? StatusVariant.OFFLINE : StatusVariant.ONLINE}
            label={firstName.charAt(0).toUpperCase()}
            borderRadius="0.6rem"
            size="3.6rem"
            fontSize="2.4rem"
            bgColor={`#${color}`}
            isAvatarExists={enabled}
          />
          <ChatName>{fullName}</ChatName>
        </>
      )
    }

    if (chatUsersNumber === 2) {
      const { firstName, lastName, color } = usersSendingToMe[0].user
      const fullName = getFullName(firstName, lastName)
      const recipientOnlineStatus = getRecipientOnlineStatus(
        usersSendingToMe,
        users,
        meData || null
      )

      return (
        <>
          <Avatar
            imgUrl={usersAvatarUrl}
            name={fullName}
            label={firstName}
            status={recipientOnlineStatus === 0 ? StatusVariant.OFFLINE : StatusVariant.ONLINE}
            borderRadius="0.6rem"
            size="3.6rem"
            fontSize="2.4rem"
            bgColor={`#${color}`}
          />
          <ChatName>{fullName}</ChatName>
        </>
      )
    }
    if (chatUsersNumber > 2) {
      return (
        <>
          <Avatar
            size="3.6rem"
            borderRadius="0.6rem"
            bgColor={`#${chatDetails?.color}`}
            icon={<AvatarsIcon />}
          />
          <ChatName>{formatChatHeaderTitle(usersSendingToMe)}</ChatName>
        </>
      )
    }
    return null
  }

  const handleClick = useCallback(() => {
    let sidePageId
    let sidePageType

    if (!chatDetails) {
      return
    }

    if (CHANNEL_TYPES.includes(chatDetails.conversationType) || usersSendingToMe.length > 1) {
      sidePageId = chatDetails.id
      sidePageType = SidePageTypes.DETAILS
    } else {
      sidePageId = isMyPrivateChat && meData ? meData.userId : usersSendingToMe[0]?.user.userId
      sidePageType = SidePageTypes.USER
    }

    setSidePageId(sidePageId)
    setType(sidePageType)
    setOpen(true)
  }, [chatDetails, isMyPrivateChat, meData, usersSendingToMe, setSidePageId, setType, setOpen])

  if (!chatDetails) {
    return null
  }

  return (
    <Container as="header" {...spacing}>
      <ChatHeaderButton
        type="button"
        onClick={handleClick}
        aria-label={t('chatHeader.ariaLabelViewDetails')}
        aria-hidden={true}
      >
        {isChat(chatDetails) ? (
          generateChatHeader(usersSendingToMe)
        ) : (
          <ChatName as="p">{getChatName(chatDetails, t)}</ChatName>
        )}
        <IconContainer>
          <ChevronDownIcon />
        </IconContainer>
      </ChatHeaderButton>
    </Container>
  )
}

export const ChatHeaderWithSkeleton = withSkeleton(
  ChatHeader,
  <Skeleton width={262} height={24} baseColor={theme.colors.beigeDark} />
)
