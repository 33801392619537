import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { theme } from '@common/theme'
import { TilesIcon } from '@components/icons'
import { AddChannelMembersModal } from '@modules/modals/add-channel-members'
import { isChat, isGeneralChannel } from '@utils/get-chat-type'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  BasicSearch,
  Button,
  Container,
  HiddenLabel,
  ListIcon,
  PlusIcon,
  ScrollContainer,
  useModal,
} from 'ui'
import { ListItem } from './list-item'
import { MembersList, MembersTiles, SwitchButton } from './styled'
import { TileItem } from './tile-item'

export const Members: FC = () => {
  const [searchString, setSearchString] = useState<string>('')
  const [tilesView, setTilesView] = useState<boolean>(true)
  const { t } = useTranslation(['details', 'common'])
  const { openModal } = useModal()

  const { chatId, channelId } = useParams<{ chatId: string; channelId: string }>()
  const { data: chatDetails } = useGetChatDetails(chatId || channelId)

  const members = useMemo(() => {
    if (chatDetails) {
      return chatDetails.chatUsers.filter((item) => !item.leftChat)
    }
    return []
  }, [chatDetails])

  // Filter members by starting characters of firstName or lastName
  const filterMembers = useMemo(() => {
    return members.filter((member) => {
      return (
        member.user.firstName.toLowerCase().startsWith(searchString.toLowerCase()) ||
        member.user.lastName.toLowerCase().startsWith(searchString.toLowerCase())
      )
    })
  }, [searchString, members])

  if (!chatDetails) {
    return null
  }

  return (
    <>
      <Container mb="1.6rem" display="flex" alignItems="center">
        <BasicSearch
          id="search_member"
          name="search_member"
          hiddenLabel={t('searchMember')}
          placeholder={t('searchMember')}
          setSearchString={setSearchString}
          searchString={searchString}
          flexGrow={1}
        />
        <SwitchButton type="button" onClick={() => setTilesView(!tilesView)} ml="0.5rem">
          <HiddenLabel>
            {tilesView ? t('listView', { ns: 'common' }) : t('tilesView', { ns: 'common' })}
          </HiddenLabel>
          {tilesView ? (
            <ListIcon fill={theme.colors.black} />
          ) : (
            <TilesIcon fill={theme.colors.black} />
          )}
        </SwitchButton>
      </Container>
      <ScrollContainer hideScrollbar mx="-2rem">
        {!isChat(chatDetails) && !isGeneralChannel(chatDetails) && (
          <Container m="0.3rem 2rem 1.9rem">
            <Button
              variant="secondary"
              plain
              width="100%"
              preFix={<PlusIcon />}
              onClick={() =>
                openModal({
                  content: (
                    <AddChannelMembersModal
                      channelName={chatDetails.name}
                      channelId={chatDetails.id}
                    />
                  ),
                  maxWidth: '62rem',
                })
              }
            >
              {t('addMembers')}
            </Button>
          </Container>
        )}
        {!tilesView ? (
          <MembersList>
            {filterMembers.map((user) => (
              <ListItem {...user} />
            ))}
          </MembersList>
        ) : (
          <MembersTiles>
            {filterMembers.map((user) => (
              <TileItem {...user} />
            ))}
          </MembersTiles>
        )}
      </ScrollContainer>
    </>
  )
}
