import { EntityState } from '@reduxjs/toolkit'
import { ConversationMessaging } from '../../features/conversation/models'

export const getEntities = (state: EntityState<ConversationMessaging>, chatId: string) => {
  const chatEntity = state.entities[chatId]

  const messageEntities = chatEntity?.messages.entities

  return { chatEntity, messageEntities }
}
