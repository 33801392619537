import { getWorkspaceTokenFromStorage } from '@api/interceptors'
import { GetWorkspaceTokenParams } from '@api/workspaces/types'
import { workspaceApi } from './instance'

export const getWorkspaceToken = async ({ id }: GetWorkspaceTokenParams) => {
  const tokenObj = getWorkspaceTokenFromStorage()
  const { data } = await workspaceApi.post<{ accessToken: string }>(`workspaces/${id}/token`, {
    id_token: JSON.parse(tokenObj!).id_token,
    device_id: 'web_client',
  })

  sessionStorage.setItem('workspace.token', data.accessToken)
  return data
}
