import React, { FC } from 'react'
import { MouseEvent } from 'react'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { space } from 'styled-system'

export interface IconButtonProps extends SpaceProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  type?: 'button' | 'submit' | 'reset'
  children?: JSX.Element
}

export const IconButton: FC<IconButtonProps> = ({
  type = 'button',
  onClick,
  children,
  ...spacing
}) => {
  return (
    <ButtonContainer type={type} onClick={onClick} {...spacing}>
      {children}
    </ButtonContainer>
  )
}

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.greyDark};
  transition: color ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${space};
`
