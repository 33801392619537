import { faker } from '@faker-js/faker'
import { FileProps } from '@modules/files/file-item'
import { FileTypeVariant } from '@modules/files/file-preview'

type TypeVariantStrings = keyof typeof FileTypeVariant

const generateDummyFiles = (number: number) => {
  const dummyFiles: FileProps[] = new Array(number).fill(0).map(() => {
    const fileType =
      FileTypeVariant[
        faker.helpers.shuffle(['IMAGE', 'AUDIO', 'VIDEO', 'OTHER'])[0] as TypeVariantStrings
      ]

    const name = faker.random.word()
    const user = faker.name.fullName()
    const date = faker.date.past()

    const video = {
      thumbnailSrc: `https://picsum.photos/id/${faker.datatype.number({
        min: 15,
        max: 60,
      })}/72/72`,
      alt: 'alt',
      duration: '3:10',
    }

    const img = {
      src: `https://picsum.photos/id/${faker.datatype.number({
        min: 1,
        max: 15,
      })}/72/72`,
      alt: 'alt',
    }

    return {
      type: fileType,
      name: name,
      user: user,
      date: date,
      video: video,
      img: img,
      href: '/images/doc-preview.jpg',
    }
  })

  return dummyFiles
}

export { generateDummyFiles }
