import { OnSuccessCallback } from '@hooks/types'
import { useMutation } from '@tanstack/react-query'
import { DeleteChannelProps, deleteChannel } from '../delete-channel'

interface UseDeleteChannelParams<D, V> {
  onSuccess: OnSuccessCallback<D, V>
}

export const useDeleteChannel = <D, V extends DeleteChannelProps>(
  options: UseDeleteChannelParams<D, V>
) => {
  return useMutation({
    mutationFn: ({ channelId }: V) => deleteChannel({ channelId }),
    ...options,
  })
}
