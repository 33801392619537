import styled from 'styled-components'
import { ImgContainer, Text } from 'ui'

export const PopperButton = styled.span`
  display: inline-flex;
  align-items: center;
  ${Text} {
    flex-grow: 1;
    max-width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Logo = styled(ImgContainer)`
  flex: 0 0 auto;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.8rem;
  overflow: hidden;
`
