import React from 'react'

export const TextIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 4.5A2.511 2.511 0 0 0 4.5 7v7.373c0 1.372 1.128 2.5 2.5 2.5h8.166l1.666 2.068 1.334 1.655a.75.75 0 0 0 1.334-.471V7c0-1.372-1.128-2.5-2.5-2.5ZM7 6h10c.561 0 1 .439 1 1v11l-1.818-2.254a1.002 1.002 0 0 0-.778-.373H7c-.561 0-1-.439-1-1V7c0-.561.439-1 1-1Z" />
      <path d="M8 8.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h8a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75ZM8 11.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h5a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75Z" />
    </svg>
  )
}
