import { AvatarField } from '@components/skeleton-items/avatar-field'
import { Button } from '@components/skeleton-items/button'
import { InputField } from '@components/skeleton-items/input-field'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { useTheme } from 'styled-components'
import { Container, Divider } from 'ui'

export const WorkspaceGeneralSkeleton: FC = () => {
  const theme = useTheme()

  return (
    <Container width={600}>
      <Container display="flex" flexDirection="column" flexGap="2.4rem">
        <AvatarField />
        <InputField width={400} />
        <Button />
      </Container>
      <Divider color={theme.colors.greyLight} my="3.2rem" />
      <Container display="flex" flexDirection="column" flexGap="2.4rem">
        <NestedContainer>
          <Skeleton width={200} height={12} />
          <Skeleton width={320} height={12} />
        </NestedContainer>
        <Button />
      </Container>
    </Container>
  )
}

const NestedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  height: 5.8rem;
`
