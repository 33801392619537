import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import React from 'react'
import { Avatar, StatusVariant } from 'ui'
import { OptionType } from '../types'

interface MembersAvatarProps {
  option: OptionType
  status: StatusVariant
}

const MembersAvatar = ({ option, status }: MembersAvatarProps) => {
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: option?.id,
    avatarId: option?.avatar?.id,
  })

  return (
    <Avatar
      label={option.label}
      bgColor={`#${option.color}`}
      imgUrl={usersAvatarUrl}
      status={status}
      size="2.4rem"
      borderRadius="0.4rem"
      isAvatarExists={enabled}
    />
  )
}

export default MembersAvatar
