import { CreateChannelForm } from '@modules/forms/create-channel'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'ui'

export const CreateChannelModal: FC = () => {
  const { t } = useTranslation('modal-create-channel')
  return (
    <>
      <Heading as="h2" mb="1.6rem">
        {t('title')}
      </Heading>
      <CreateChannelForm />
    </>
  )
}
