import React, { createContext, FC, useContext, useState } from 'react'

export interface TeammatesFiltersValues {
  [key: string]: {
    [key: string]: boolean
  }
}

export const teammatesInitialFilters: TeammatesFiltersValues = {
  status: {
    available: false,
    busy: false,
    away: false,
  },
  jobTitle: {
    copywriter: false,
    designer: false,
    designDirector: false,
    hrManager: false,
    manager: false,
  },
}

interface FiltersContextValues {
  filters: TeammatesFiltersValues
  setFilters: (filters: TeammatesFiltersValues) => void
}

const FiltersContext = createContext<FiltersContextValues>({
  filters: {},
  setFilters: () => {},
})

export const useFilters = () => useContext(FiltersContext)

interface FiltersProviderProps {
  children: React.ReactNode
  initialValues: TeammatesFiltersValues
}

export const FiltersProvider: FC<FiltersProviderProps> = ({ children, initialValues }) => {
  const [filters, setFilters] = useState(initialValues)

  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>
  )
}
