import { GeneralMessageProps } from '@components/message'
import { formatISO9075 } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { MessageDate } from './types'

export const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'do MMMM',
}

interface GroupedDays {
  [date: string]: GeneralMessageProps[]
}

export const locale = {
  ...enUS,
  formatRelative: (token: string) => (formatRelativeLocale as any)[token],
}

export function groupedDays(messages: GeneralMessageProps[]) {
  return messages.reduce((acc: GroupedDays, el: GeneralMessageProps) => {
    const messageDay = formatISO9075(el.createdAt, { representation: 'date' })
    if (acc[messageDay as keyof typeof acc]) {
      return { ...acc, [messageDay]: acc[messageDay as keyof typeof acc].concat([el]) }
    }
    return { ...acc, [messageDay]: [el] }
  }, {})
}

export function generateMessagesWithDate(messages: GeneralMessageProps[]) {
  const days = groupedDays(messages)
  const sortedDays = Object.keys(days).sort(
    (x: string, y: string) => new Date(y).valueOf() - new Date(x).valueOf()
  )
  const items = sortedDays.reduce((acc: (GeneralMessageProps | MessageDate)[], date: string) => {
    const sortedMessages: GeneralMessageProps[] = days[date as keyof typeof days].sort(
      (a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()
    )
    return acc.concat([...sortedMessages, { type: 'day', date, id: date }])
  }, [])
  return items
}
