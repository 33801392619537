import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsers } from '@api/account/hooks/use-get-users'
import { useCreateChat } from '@api/chats/hooks/use-create-chat'
import { useGetChatList } from '@api/chats/hooks/use-get-chat-list'
import { useAppSelector } from '@app/hooks'
import { useSidePage } from '@hooks/use-side-page'
import { useWorkspace } from '@hooks/use-workspace'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { EmptyResults } from '@modules/empty-results'
import { ListLayout } from '@modules/teammates-layout/list-layout'
import { TileLayout } from '@modules/teammates-layout/tile-layout'
import { routes } from '@routes/routes'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface TeammatesLayoutProps {
  isTile: boolean
  searchValue: string
}

export const TeammatesLayout: FC<TeammatesLayoutProps> = ({ isTile, searchValue }) => {
  const navigate = useNavigate()
  const { workspaceId } = useWorkspace()
  const { data, createChat: createChatMutation, isCreateChatSuccess } = useCreateChat()
  const { setSidePageId, setType, setOpen } = useSidePage()
  const { sortBy, sortDesc } = useAppSelector((state) => state.userSorting)
  const { users, isUsersFetching, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetUsers({
    sortBy,
    sortDesc,
  })
  const { t } = useTranslation(['teammates'])
  const [isTriggerInView, setIsTriggerInView] = useState(false)
  const { chats } = useGetChatList()
  const { meData } = useGetMe()

  const canFetchNextPage = isTriggerInView && hasNextPage && !isUsersFetching && !isFetchingNextPage

  const redirectToChat = (chatId: string) =>
    navigate(`${routes.workspaces}/${workspaceId}/${routes.chats}/${chatId}`, { replace: true })

  const findExistingChat = (userId: string, isPrivate: boolean) => {
    if (isPrivate) {
      return chats.find(
        ({ chatUsers }) => chatUsers.length === 1 && chatUsers[0]?.user.userId === userId
      )
    }

    return chats
      .filter(({ chatUsers }) => chatUsers.length === 2)
      .find(({ chatUsers }) => {
        return chatUsers.some(({ user }) => user.userId === userId)
      })
  }

  useEffect(() => {
    if (isCreateChatSuccess && data) {
      redirectToChat(data.id)
    }
  }, [data, isCreateChatSuccess])

  const handleSendClick = (userId: string) => {
    const existingChat = findExistingChat(userId, userId === meData?.userId)

    if (existingChat) {
      redirectToChat(existingChat.id)
    } else {
      createChatMutation({ userIds: [userId] })
    }
  }

  useEffect(() => {
    if (canFetchNextPage) {
      fetchNextPage()
    }
  }, [isTriggerInView, isUsersFetching, isFetchingNextPage, hasNextPage])

  const handleUserClick = useCallback((userId: string) => {
    setOpen(true)
    setType(SidePageTypes.USER)
    setSidePageId(userId)
  }, [])

  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`
        return fullName.toLowerCase().includes(searchValue.toLowerCase())
      }),
    [searchValue, users]
  )

  if (filteredUsers.length === 0 && !isUsersFetching) {
    return <EmptyResults title={t('emptyTeammatesHeading')} text={t('emptyTeammatesDescription')} />
  }

  if (isTile) {
    return (
      <>
        <TileLayout
          setIsTriggerInView={setIsTriggerInView}
          isUsersFetching={isUsersFetching || isFetchingNextPage}
          handleSendClick={handleSendClick}
          handleUserClick={handleUserClick}
          users={filteredUsers}
        />
      </>
    )
  }
  return (
    <ListLayout
      handleSendClick={handleSendClick}
      isUsersFetching={isUsersFetching || isFetchingNextPage}
      setIsTriggerInView={setIsTriggerInView}
      handleUserClick={handleUserClick}
      users={filteredUsers}
    />
  )
}
