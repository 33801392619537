import { usePatchMe } from '@api/account/hooks/use-patch-me'
import { usePatchWorkspace } from '@api/workspaces/hooks/use-patch-workspace'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Heading, Text, useModal } from 'ui'

interface DeleteAvatarProps {
  workspaceId?: string
}

export const DeleteAvatarModal: FC<DeleteAvatarProps> = ({ workspaceId }) => {
  const { t } = useTranslation(['common', 'edit-avatar'])

  const { closeModal } = useModal()

  const { mutate: mutateMe, isLoading } = usePatchMe({
    onSuccessCallback: closeModal,
  })

  const { mutate: mutateWorkspace, isLoading: isLoadingWorkspace } = usePatchWorkspace({
    onSuccessCallback: closeModal,
  })

  const deleteAvatar = () => {
    if (workspaceId) {
      mutateWorkspace({
        id: workspaceId,
        avatarId: null,
      })
    } else {
      mutateMe({
        avatarId: null,
      })
    }
  }

  return (
    <Container>
      <Heading as="h2" mb="0.8rem">
        {t('deleteAvatar', { ns: 'edit-avatar' })}
      </Heading>
      <Text>{t('confirmDeleteAvatar', { ns: 'edit-avatar' })}</Text>
      <Container display="flex" mt="4rem" justifyContent="space-between" flexGap="0.8rem">
        <Button
          onClick={closeModal}
          width="100%"
          variant="secondary"
          disabled={isLoading || isLoadingWorkspace}
        >
          {t('cancel')}
        </Button>
        <Button width="100%" onClick={deleteAvatar} disabled={isLoading || isLoadingWorkspace}>
          {t('delete')}
        </Button>
      </Container>
    </Container>
  )
}
