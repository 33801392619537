import { Chat } from '@api/chats/types/chat'

// from GET `/list`
export enum ConversationType {
  'CHAT' = 'Chat',
  'PRIVATE_CHANNEL' = 'PrivateChannel',
  'PUBLIC_CHANNEL' = 'PublicChannel',
  'GENERAL_CHANNEL' = 'GeneralChannel',
}

export const CHANNEL_TYPES = [
  ConversationType.PRIVATE_CHANNEL,
  ConversationType.PUBLIC_CHANNEL,
  ConversationType.GENERAL_CHANNEL,
]

// from GET `/channels`
export enum ChatType {
  CHAT = 0,
  CHANNEL = 1,
  PUBLIC_CHANNEL = 2,
  GENERAL_CHANNEL = 3,
}

export const PUBLIC_CHANNEL_TYPES = [ChatType.PUBLIC_CHANNEL, ChatType.GENERAL_CHANNEL]

// ConversationType helpers
export const isChat = (chat: Chat): boolean => chat.conversationType === ConversationType.CHAT

//TODO: Remove the check by name, currently only newly created workspaces have general channel with type GeneralChannel
export const isGeneralChannel = (chat: Chat): boolean =>
  chat.conversationType === ConversationType.GENERAL_CHANNEL || chat.name === '#general'

// ChatType helpers
export const isGeneralChannelChatType = (type: ChatType) => type === ChatType.GENERAL_CHANNEL
