import { getChatDetails } from '@api/chats/get-chat-details'
import { useAppDispatch, useAppSelector } from '@app/hooks'
import { useQuery } from '@tanstack/react-query'
import { selectChat, setChat } from '../../../features/chats/slice'
import { CHAT_LIST } from '../query-keys'

export const useGetChatDetails = (chatId?: string) => {
  const chatDetails = useAppSelector(({ chats }) => selectChat(chats, chatId!))

  const dispatch = useAppDispatch()

  const { isLoading, isError, isFetching, refetch } = useQuery(
    [CHAT_LIST, chatId],
    () => getChatDetails({ chatId }),
    {
      onSuccess: (response) => {
        dispatch(setChat(response))
      },
      enabled: !!chatId,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: 3
    }
  )

  return { data: chatDetails, isLoading, isError, isFetching, refetch }
}
