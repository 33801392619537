import { generateDummyFiles } from '@utils/dummy-data/generate-dummy-files'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, ImgContainer, ScrollContainer, Text } from 'ui'
import { FileItem, FileProps } from './file-item'
import { FilesList } from './styled'

export const Files: FC = () => {
  const { t } = useTranslation(['files'])
  const files = generateDummyFiles(30)

  return (
    <ScrollContainer hideScrollbar m="-0.8rem -2.4rem 0">
      <FilesList>
        {files.length > 0 ? (
          files.map((item: FileProps) => {
            return <FileItem key={item.name} {...item} />
          })
        ) : (
          <Container textAlign="center" mt="6.4rem" px="2rem">
            <ImgContainer mb="4.4rem">
              <img src="/img/files-empty.svg" alt="files-empty" width={272} height={293} />
            </ImgContainer>
            <Text mb="0.8rem" fontSize="2.4rem" fontWeight={500}>
              {t('noFilesTitle')}
            </Text>
            <Text>{t('noFilesText')}</Text>
          </Container>
        )}
      </FilesList>
    </ScrollContainer>
  )
}
