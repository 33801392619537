import { useJoinChannel } from '@api/chats/hooks/use-join-channel'
import { Chat } from '@api/chats/types/chat'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Container, Heading, Text } from 'ui'

interface JoinCtaProps {
  channel: Chat
  setIsMeJoined: Dispatch<SetStateAction<boolean>>
}

export const JoinChannelCta: FC<JoinCtaProps> = ({ channel, setIsMeJoined }) => {
  const { name, description, id } = channel
  const { t } = useTranslation('chat')
  const navigate = useNavigate()
  const handleOnGoBack = () => navigate(-1)

  const { mutate: joinChannel, isLoading: isJoinChannelLoading } = useJoinChannel({
    conversationId: id,
    onSuccessCallback: () => setIsMeJoined(true),
    onErrorCallback: () => toast.error(`${t('errors.somethingWentWrong')}`),
  })

  return (
    <JoinCtaContainer>
      <Container mb="1.2rem">
        <Heading variant="h2">{name}</Heading>
        {description && <Text>{description}</Text>}
      </Container>
      <Container display="flex" justifyContent="center" flexGap="1.2rem">
        <Button
          variant="secondary"
          size="sm"
          minWidth="12rem"
          disabled={isJoinChannelLoading}
          onClick={handleOnGoBack}
        >
          {t('back')}
        </Button>
        <Button
          size="sm"
          minWidth="12rem"
          disabled={isJoinChannelLoading}
          onClick={() => joinChannel(id)}
        >
          {t('join')}
        </Button>
      </Container>
    </JoinCtaContainer>
  )
}

const JoinCtaContainer = styled.div`
  padding: 1.6rem;
  text-align: center;
  border-radius: 1.6rem 1.6rem 1.6rem 0;
  outline: 0.1rem solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
`
