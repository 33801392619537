import { messagesApi } from './instance'
import { MessageDetail } from './types/message-detail'

export interface UpdateTranslationLanguageInput {
  messageId: string
  languageCode: string
}

export const updateTranslationLanguage = async ({
  messageId,
  languageCode,
}: UpdateTranslationLanguageInput) => {
  const { data } = await messagesApi.post<MessageDetail>(`${messageId}/translate`, {
    secondaryLanguageCode: languageCode,
  })
  return data
}
