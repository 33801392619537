import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { Workspace } from '@api/workspaces/types'
import { AddUserIcon, GearIcon, TeammatesIcon } from '@components/icons'
import { NameWithState } from '@components/name-with-state'
import { useAuth } from '@hooks/use-auth'
import { InviteWsMembersModal } from '@modules/modals/invite-ws-members'
import { routes } from '@routes/routes'
import { getFeatureFlag } from '@utils/flags-manager'
import { getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
  Container,
  StatusVariant,
  Text,
  UserIcon,
  HelpIcon,
  useModal,
  LogoutIcon,
  PopperButton,
  PopperAction,
  ClipboardIcon,
  ShieldIcon,
} from 'ui'
import { AvatarWithLogo } from '../avatar-with-logo'
import { FreeTrialNotice } from './free-trial-notice'
import { HeaderContainer, ListItem, PopperContainer, WorkspaceName } from './styled'

const KNOWLEDGE_BASE_LINK = 'https://help.native.tech/community'
const TERMS_LINK = 'https://native.tech/terms/'
const PRIVACY_POLICY_LINK = 'https://native.tech/privacy/'

export interface HeaderPopperProps {
  workspace: Workspace
  onClose: VoidFunction
}

interface HeaderPopperAction extends PopperAction {
  group?: string
}

export interface ListItemsProps {
  isFirstInGroup?: boolean
  isLastInGroup?: boolean
}

export const HeaderPopper: FC<HeaderPopperProps> = ({ workspace, onClose }) => {
  const { logout } = useAuth()
  const { meData: me, isLoading: isMeLoading } = useGetMe()
  const navigate = useNavigate()
  const { openModal } = useModal()
  const theme = useTheme()
  const { t } = useTranslation('sidebar')
  const { usersAvatarUrl } = useGetUsersAvatar({
    userId: me?.userId,
    avatarId: me?.avatar?.mediaId,
  })

  const handleNavigate = (route: string) => {
    navigate(route)
    onClose()
  }

  const handleRedirect = (link: string) => location.replace(link)

  //this will be determined later by auth server
  const isAdmin = true
  const popperOptions: HeaderPopperAction[] = [
    {
      name: t('teammates'),
      icon: <TeammatesIcon />,
      onClick: () => {
        handleNavigate(routes.teammates)
      },
      group: 'group2',
    },
    {
      name: t('inviteYourTeam'),
      icon: <AddUserIcon />,
      onClick: () => {
        openModal({ content: <InviteWsMembersModal />, maxWidth: '62rem' })
      },
      group: 'group2',
    },
    {
      name: t('personalSettings'),
      icon: <UserIcon />,
      onClick: () => {
        handleNavigate(`${routes.users}/${me?.userId}/${routes.settings}`)
      },
      group: 'group3',
    },
    {
      name: t('knowledgeBase'),
      icon: <HelpIcon />,
      onClick: () => handleRedirect(KNOWLEDGE_BASE_LINK),
      group: 'group4',
    },
    {
      name: t('logoutOfNative'),
      icon: <LogoutIcon />,
      onClick: logout,
      group: 'group5',
    },
  ]

  const adminPopperOptions: HeaderPopperAction[] = [
    {
      name: t('teammates'),
      icon: <TeammatesIcon />,
      onClick: () => {
        handleNavigate(routes.teammates)
      },
      group: 'group2',
    },
    {
      name: t('inviteYourTeam'),
      icon: <AddUserIcon />,
      onClick: () => {
        openModal({ content: <InviteWsMembersModal />, maxWidth: '62rem' })
      },
      group: 'group2',
    },
    {
      name: t('personalSettings'),
      icon: <UserIcon />,
      onClick: () => {
        handleNavigate(`${routes.users}/${me?.userId}/${routes.settings}`)
      },
      group: 'group3',
    },
    {
      name: t('workspaceSettings'),
      icon: <GearIcon />,
      onClick: () => {
        handleNavigate(routes.settings)
      },
      group: 'group3',
    },
    {
      name: t('knowledgeBase'),
      icon: <HelpIcon />,
      onClick: () => handleRedirect(KNOWLEDGE_BASE_LINK),
      group: 'group4',
    },
    {
      name: t('termsOfService'),
      icon: <ClipboardIcon />,
      onClick: () => handleRedirect(TERMS_LINK),
      group: 'group4',
    },
    {
      name: t('privacyPolicy'),
      icon: <ShieldIcon />,
      onClick: () => handleRedirect(PRIVACY_POLICY_LINK),
      group: 'group4',
    },
    {
      name: t('logoutOfNative'),
      icon: <LogoutIcon />,
      onClick: logout,
      group: 'group5',
    },
  ]

  // should replace with actual count when the backend support is available
  const freeTrialLeftDays = 86

  return (
    <PopperContainer>
      <HeaderContainer>
        <Container display="flex" width="100%">
          {isMeLoading && <Text>Loading...</Text>}
          {me && !isMeLoading && (
            <AvatarWithLogo
              avatarUrl={usersAvatarUrl}
              firstName={me.firstName || ''}
              lastName={me.lastName || ''}
              color={me.color || theme.colors.green}
              workspace={workspace}
            />
          )}
          <Container
            display="flex"
            flexDirection="column"
            flex="1 1 auto"
            ml="1.2rem"
            overflow="hidden"
          >
            {isMeLoading && <Text>Loading...</Text>}
            {me && !isMeLoading && (
              <NameWithState
                isPopup
                name={getFullName(me.firstName || '', me.lastName || 'unknown')}
                status={StatusVariant.ONLINE}
              />
            )}
            <WorkspaceName variant="smallTextRegular">
              {workspace?.name} {t('ws')}
            </WorkspaceName>
          </Container>
        </Container>
      </HeaderContainer>
      {getFeatureFlag('showOnProd') && isAdmin && (
        <FreeTrialNotice freeTrialLeftDays={freeTrialLeftDays} />
      )}
      <Container>
        <ul>
          {(isAdmin ? adminPopperOptions : popperOptions).map((option, index, array) => {
            const isFirstInGroup = index === 0 || option.group !== array[index - 1].group
            const isLastInGroup =
              index === array.length - 1 || option.group !== array[index + 1].group
            return (
              <ListItem isFirstInGroup={isFirstInGroup} isLastInGroup={isLastInGroup}>
                <PopperButton icon={option.icon} onClick={option.onClick} name={option.name} />
              </ListItem>
            )
          })}
        </ul>
      </Container>
    </PopperContainer>
  )
}
