import { DeleteChannelProps } from '@api/chats/delete-channel'
import { useDeleteChannel } from '@api/chats/hooks/use-delete-channel'
import { useGetChannelList } from '@api/chats/hooks/use-get-channel-list'
import { useAppDispatch } from '@app/hooks'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/routes'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Container, Heading, Text, useModal } from 'ui'
import { deleteChat } from '../../../features/chats/slice'

interface DeleteChannelModalProps {
  id: string
  name: string
  membersNumber: number
}

export const DeleteChannelModal: FC<DeleteChannelModalProps> = ({ id, name, membersNumber }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-delete-channel')
  const { workspaceId } = useWorkspace()
  const navigate = useNavigate()
  const { channels } = useGetChannelList()

  const dispatch = useAppDispatch()

  const {
    mutate: deleteChannel,
    isSuccess: isDeleteChannelSuccess,
    isError: isDeleteChannelError,
  } = useDeleteChannel<null, DeleteChannelProps>({
    onSuccess: (_, variables) => {
      dispatch(deleteChat({ chatId: variables.channelId }))
    },
  })

  const handleOnSubmit = () => {
    deleteChannel({ channelId: id })
  }

  useEffect(() => {
    if (isDeleteChannelSuccess) {
      toast.success(`${t('toastMessages.yourChangesWereSaved')}`)
      navigate(`${routes.workspaces}/${workspaceId}/${routes.channels}/${channels[0].id}`)
      closeModal()
    }
  }, [channels, closeModal, isDeleteChannelSuccess, navigate, t, workspaceId])

  useEffect(() => {
    if (isDeleteChannelError) {
      toast.error(`${t('toastMessages.yourChangesWerentSaved')}`)
    }
  }, [isDeleteChannelError, t])

  return (
    <Container>
      <Heading as="h2" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text>{t('confirmation')}</Text>
      <Text>{t('warning')}</Text>
      <InfoBox m="2.8rem 0 0">
        <Text mr="1rem" variant="textMedium">
          {name}
        </Text>
        <Text color="greyDark" variant="smallTextRegular">
          {membersNumber} {membersNumber === 1 ? t('member') : t('members')}
        </Text>
      </InfoBox>
      <Container display="flex" flexGap="1.2rem" mt="4.8rem">
        <Button variant="secondary" width="100%" onClick={closeModal}>
          {t('cancelButton')}
        </Button>
        <Button type="submit" width="100%" onClick={handleOnSubmit}>
          {t('submitButton')}
        </Button>
      </Container>
    </Container>
  )
}

const InfoBox = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 1.6rem 1.6rem 1.6rem 0;
  border: 1px solid ${({ theme }) => theme.colors.grey};
`
