import { capitalizeFirstLetter } from '@utils/helpers'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusBadge, Text, CalendarIcon, Container, StatusVariant, theme, Caption } from 'ui'

interface TeammateInfoProps {
  firstName: string
  lastName: string
  position: string
  joined: Date
  status: StatusVariant
}

export const TeammateInfo: FC<TeammateInfoProps> = ({
  firstName,
  lastName,
  position,
  joined,
  status,
}) => {
  const { t } = useTranslation(['common'])

  return (
    <Container display="flex" flexDirection="column" justifyContent="center">
      <Container display="flex" alignItems="center" mb="0.4rem">
        <Text variant="textMedium">
          {firstName} {lastName}
        </Text>
        <StatusBadge status={status} ml="0.8rem" />
      </Container>
      <Text variant="smallTextRegular" mb="0.4rem" textAlign="left">
        {capitalizeFirstLetter(position)}
      </Text>
      <Container display="flex" alignItems="center" flexGap="0.4rem">
        <CalendarIcon fill={theme.colors.greyDark} />
        <Caption variant="captionRegular" color={theme.colors.greySuperDark}>
          {t('joined')} {format(joined, 'PP')}
        </Caption>
      </Container>
    </Container>
  )
}
