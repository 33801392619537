import React from 'react'

export const ChevronLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m13.693 8.492-4.8 4.801a1 1 0 0 0 0 1.414l4.8 4.8a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.413L11.014 14l4.093-4.092a1 1 0 0 0 0-1.416 1 1 0 0 0-1.414 0z" />
    </svg>
  )
}
