import { useMessageBulb } from '@hooks/use-message-bulb'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BlockIcon, Text } from 'ui'
import { GeneralMessageProps, MessageVariant } from '.'
import { DeleteIndicator, MessageBody, MessageHeader, MessageText, Spacer } from './styled'

export const DeletedMessage: React.FC<GeneralMessageProps> = (props) => {
  const { isSender, createdByFullName } = props
  const { t } = useTranslation('chat')
  const { spacerWidth, getMessageFooter } = useMessageBulb(props, MessageVariant.DELETED)

  return (
    <MessageBody isSender={isSender} variant={MessageVariant.DELETED}>
      {isSender && (
        <MessageHeader>
          <Text variant="textMedium">{createdByFullName}</Text>
        </MessageHeader>
      )}
      <MessageText display="flex" alignItems="center">
        <DeleteIndicator>
          <BlockIcon />
        </DeleteIndicator>
        <Text>
          {t('message.deleted')} <Spacer width={spacerWidth} />
        </Text>
      </MessageText>
      <>{getMessageFooter()}</>
    </MessageBody>
  )
}
