import { useGetMyWorkspaces } from '@api/workspaces/hooks/use-get-my-workspaces'
import { useWorkspaceToken } from '@api/workspaces/hooks/use-workspace-token'
import { MyWorkspace, MyWorkspaceStatus } from '@api/workspaces/types'
import { OnboardingType } from '@contexts/workspace-provider'
import { useAuth } from '@hooks/use-auth'
import { useSkeleton } from '@hooks/use-skeleton'
import { useWorkspace } from '@hooks/use-workspace'
import { InvitationValidWithSkeleton } from '@modules/invitation-valid'
import { routes } from '@routes/routes'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import { Button, ButtonLink, Container, Text } from 'ui'

export const Invitation: FC = () => {
  const { logout, login, user } = useAuth()
  const { data: myWorkspacesData, isLoading: myWorkspacesDataIsLoading } = useGetMyWorkspaces({
    enabled: true,
  })
  const navigate = useNavigate()
  const { workspaceId: workspaceIdParam } = useParams()
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const { t } = useTranslation('invitation')
  const { setOnboardingType, setWorkspaceId } = useWorkspace()
  const { refetch, isSuccess, isWorkspaceFetching } = useWorkspaceToken({
    id: workspaceIdParam || '',
    enabled: false,
  })
  const { workspaces, invite, invalidInvite, teammates, onboarding } = routes

  const workspacePath = `${workspaces}/${workspaceIdParam}`
  const workspaceUrl = `${workspacePath}/${teammates}`
  const onboardingUrl = `${workspacePath}/${onboarding}`
  const invalidInvitationUrl = `${workspacePath}/${invite}/${invalidInvite}`

  const hasOneWorkspace = myWorkspacesData?.length === 1

  // Get the workspace based on URL parameter
  const workspace = useMemo(
    () =>
      myWorkspacesData?.filter((workspace: MyWorkspace) => workspace.id === workspaceIdParam)[0],
    [myWorkspacesData, workspaceIdParam]
  )

  const handleOnJoin = () => {
    if (workspaceIdParam) {
      setWorkspaceId(workspaceIdParam)
      setOnboardingType(OnboardingType.USER)
      navigate(onboardingUrl)
    }
  }

  useEffect(() => {
    if (myWorkspacesDataIsLoading) {
      return
    }
    switch (workspace?.myStatus) {
      case MyWorkspaceStatus.INVITED:
        break
      case MyWorkspaceStatus.ACTIVATED:
        refetch()
        if (isSuccess && workspaceIdParam) {
          setWorkspaceId(workspaceIdParam)
          navigate(workspaceUrl)
        }
        break
      case MyWorkspaceStatus.ALLOWED:
        navigate(onboardingUrl)
        break
      default:
        navigate(invalidInvitationUrl)
    }
  }, [myWorkspacesDataIsLoading, workspaceIdParam, isSuccess])

  useEffect(() => {
    if (!user) {
      const redirectUrl = new URL(window.location.href)
      login({ redirectUrl: redirectUrl.pathname })
    }
  }, [user])

  useEffect(() => {
    myWorkspacesDataIsLoading || isWorkspaceFetching
      ? showSkeleton(SkeletonComponentNames.INVITATION_VALID_1)
      : hideSkeleton(SkeletonComponentNames.INVITATION_VALID_1)
  }, [myWorkspacesDataIsLoading, isWorkspaceFetching])

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      <Container width="100%" maxWidth="70rem" mt="4.8rem">
        <InvitationValidWithSkeleton
          workspace={workspace}
          onJoin={handleOnJoin}
          componentName={SkeletonComponentNames.INVITATION_VALID_1}
        />
      </Container>
      {!myWorkspacesDataIsLoading && (
        <Container textAlign="center" mt="auto" pt="2.2rem">
          {user && (
            <Text variant="textMedium" mb="0.8rem">
              {t('loggedInAs')} {user.profile.email}
            </Text>
          )}
          {hasOneWorkspace ? (
            <Button plain variant="secondary" onClick={logout}>
              {t('change')}
            </Button>
          ) : (
            <ButtonLink plain variant="secondary" href={routes.workspaces}>
              {t('showOtherWs')}
            </ButtonLink>
          )}
        </Container>
      )}
    </Container>
  )
}
