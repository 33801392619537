import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Container } from 'ui'
import { AttachmentsContainerProps } from '.'

type GridStylesMap = {
  default: FlattenSimpleInterpolation
  [key: number]: FlattenSimpleInterpolation
}

export const AttachmentContainer = styled(Container)`
  position: relative;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.greyLight};
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const gridStylesMap: GridStylesMap = {
  1: css`
    grid-template-columns: repeat(1, 20rem);
    grid-template-rows: 20rem;
  `,
  2: css`
    grid-template-columns: repeat(2, 20rem);
    grid-template-rows: 20rem;
  `,
  3: css`
    grid-template-columns: repeat(3, 20rem);
    grid-template-rows: 20rem;
  `,
  4: css`
    grid-template-columns: repeat(2, 20rem);
    grid-template-rows: repeat(2, 20rem);
  `,
  default: css`
    grid-template-columns: repeat(6, 6.65rem);
    grid-template-rows: 13.3rem 20rem;

    ${AttachmentContainer} {
      grid-column: span 2;
    }
    ${AttachmentContainer}:nth-child(4) {
      grid-column: span 3;
    }
    ${AttachmentContainer}:nth-child(5) {
      grid-column: span 3;
    }
  `,
}

export const getGridStyles = (attachmentsCount: number) => {
  const gridStyle = gridStylesMap[attachmentsCount] || gridStylesMap.default

  return css`
    ${gridStyle}
  `
}

const getBorderStyles = (hasIndicator: boolean, isIncoming: boolean) => {
  if (hasIndicator) {
    if (isIncoming) {
      return '0 0 1.2rem 1.2rem'
    }
    return '1.2rem 1.2rem 0 1.2rem'
  } else {
    if (isIncoming) {
      return '0'
    }
    return '1.2rem 1.2rem 0 0'
  }
}

export const GridContainer = styled.div<{ attachmentsCount: number }>`
  display: grid;
  gap: 0.4rem;
  ${({ attachmentsCount }) => getGridStyles(attachmentsCount)}
`

export const Indicator = styled.div`
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  display: inline-flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  border-radius: 10rem;
  background-color: rgba(100, 105, 95, 0.7);
  height: 2.2rem;
`

export const AttachmentsContainer = styled(Container)<AttachmentsContainerProps>`
  overflow: hidden;
  position: relative;
  border-radius: ${({ hasIndicator, isIncoming }) => getBorderStyles(hasIndicator, isIncoming)};
`

export const AttachmentOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
`
