import { chatsApi } from './instance'
import { Chat } from './types/chat'

export interface LeaveChannelProps {
  channelId: string
}

export const leaveChannel = async ({ channelId }: LeaveChannelProps) => {
  const { data } = await chatsApi.post<Chat>(`${channelId}/leave`)
  return data
}
