import { PersonalProfile } from '@api/account/types/personal-profile'
import { Workspace } from '@api/workspaces/types'
import { CreateNewChatButton } from '@components/create-new-chat-btn'
import { NameWithState } from '@components/name-with-state'
import { Popover } from '@headlessui/react'
import { routes } from '@routes/routes'
import { getFeatureFlag } from '@utils/flags-manager'
import { getFullName } from '@utils/helpers'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { Text, Container, StatusVariant, Avatar, BasicSearch, ArrowIndicator } from 'ui'
import { HeaderPopper } from './header-popper'
import { SidebarHeaderSkeleton } from './skeleton'
import { PopperButton } from './styled'

export interface SidebarHeaderProps {
  workspace?: Workspace
  me?: PersonalProfile
}

export interface ArrowIndicatorStylesProps {
  isCardOpen: boolean
}

export const SidebarHeader: FC<SidebarHeaderProps> = ({ workspace, me }) => {
  const [searchString, setSearchString] = useState<string>('')
  const navigate = useNavigate()
  const { t } = useTranslation(['sidebar', 'common'])
  const userName = getFullName((me && me.firstName) || '', me?.lastName || '')

  if (!workspace) {
    return null
  }

  return (
    <Container display="flex" flexDirection="column" p="0 1.6rem 0.4rem">
      <Container display="flex" mb="1.6rem">
        <Avatar
          size="4.4rem"
          imgUrl={workspace.avatar?.mediaUri || ''}
          bgColor={`#${workspace.color}`}
          label={workspace.name}
          flex="0 0 auto"
        />
        <Container mx="1rem" flex="1 1 auto" maxWidth="15rem">
          <Container position="relative" zIndex={1}>
            <Popover>
              {({ open }) => (
                <>
                  <Container>
                    <Popover.Button style={{ outline: 'none' }}>
                      <PopperButton>
                        <Text as="span" fontWeight={600} fontSize={18}>
                          {workspace.name}
                        </Text>
                        <ArrowIndicator
                          open={open}
                          hiddenLabel={t('hiddenLabel.mainMenu', { ns: 'common' })}
                        />
                      </PopperButton>
                    </Popover.Button>
                  </Container>
                  <Container position="absolute">
                    <Popover.Panel>
                      {({ close }) => <HeaderPopper workspace={workspace} onClose={close} />}
                    </Popover.Panel>
                  </Container>
                </>
              )}
            </Popover>
          </Container>
          <NameWithState isPopup={false} name={userName} status={StatusVariant.ONLINE} />
        </Container>
        <CreateNewChatButton onClick={() => navigate(`${routes.chats}/${routes.createNew}`)} />
      </Container>
      {getFeatureFlag('showOnProd') && (
        <BasicSearch
          id="search_sidebar"
          name="search_sidebar"
          hiddenLabel={t('searchPlaceholder')}
          placeholder={t('searchPlaceholder')}
          setSearchString={setSearchString}
          searchString={searchString}
        />
      )}
    </Container>
  )
}

export const SidebarHeaderWithSkeleton = withSkeleton(SidebarHeader, <SidebarHeaderSkeleton />)
