import { MyWorkspace, MyWorkspaceStatus } from '@api/workspaces/types'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { withSkeleton } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { Container, ScrollContainer, Text } from 'ui'
import { DashboardItem, DashboardItemVariant } from '../dashboard-item'
import { DashboardSkeleton } from './skeleton'

interface DashboardProps {
  data?: MyWorkspace[]
}

export const Dashboard: FC<DashboardProps> = ({ data }) => {
  const { t } = useTranslation('home')

  const workspaces = useMemo(
    () =>
      data?.filter(
        (item) =>
          item.myStatus === MyWorkspaceStatus.ALLOWED ||
          item.myStatus === MyWorkspaceStatus.ACTIVATED
      ) ?? [],
    [data]
  )
  const invitations = useMemo(
    () => data?.filter((item) => item.myStatus === MyWorkspaceStatus.INVITED) ?? [],
    [data]
  )

  return (
    <ScrollContainer maxHeight="33.6rem">
      {workspaces.length > 0 && (
        <DashboardSection>
          <Text as="h2" textAlign="left" fontWeight={500}>
            {t('yourWorkspaces')}
          </Text>
          <Container>
            {workspaces.map((item) => (
              <DashboardItem variant={DashboardItemVariant.WORKSPACE} item={item} key={item.id} />
            ))}
          </Container>
        </DashboardSection>
      )}
      {invitations.length > 0 && (
        <DashboardSection>
          <Text as="h2" textAlign="left" fontWeight={500}>
            {t('invitations')}
          </Text>
          <Container>
            {invitations.map((item) => (
              <DashboardItem variant={DashboardItemVariant.INVITATION} item={item} key={item.id} />
            ))}
          </Container>
        </DashboardSection>
      )}
    </ScrollContainer>
  )
}

export const DashboardWithSkeleton = withSkeleton(Dashboard, <DashboardSkeleton />)

const DashboardSection = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }
`
