import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { usePatchWorkspace } from '@api/workspaces/hooks/use-patch-workspace'
import { useWorkspace } from '@hooks/use-workspace'
import { Formik } from 'formik'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, TextField } from 'ui'
import * as yup from 'yup'

const workspaceValidationSchema = () =>
  yup.object().shape({
    name: yup.string().required().max(200),
  })

const WorkspaceSettingsForm: React.FC = () => {
  const { workspaceId } = useWorkspace()
  const { data } = useGetWorkspace({ id: workspaceId })
  const { t } = useTranslation(['workspace'])

  const { mutate: setWorkspaceName, isLoading: isSendingData } = usePatchWorkspace()

  const workspaceInitialValues = useMemo(
    () => ({
      name: data?.name ?? '', //TODO add skeleton protection
    }),
    [data]
  )

  return (
    <Formik
      initialValues={workspaceInitialValues}
      onSubmit={(values) => {
        setWorkspaceName({ id: workspaceId, name: values.name })
      }}
      validationSchema={workspaceValidationSchema}
      validateOnBlur
    >
      {({ values, handleSubmit, handleChange, errors }) => {
        return (
          <Container>
            <Container maxWidth="40rem" my="2.4rem">
              <TextField
                id="name"
                name="name"
                label={t('workspaceName')}
                value={values.name}
                warning={Boolean(errors?.name)}
                helperText={errors?.name}
                onChange={handleChange('name')}
              />
            </Container>
            <Button
              onClick={() => {
                handleSubmit()
              }}
              minWidth="16rem"
              disabled={isSendingData}
              type="submit"
              variant="primary"
            >
              {t('saveChanges')}
            </Button>
          </Container>
        )
      }}
    </Formik>
  )
}

export default WorkspaceSettingsForm
