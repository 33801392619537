import React, { FC } from 'react'
import { ImageType } from 'react-images-uploading'
import { CloseIcon, Container, Text } from 'ui'
import styled from 'styled-components'
import { InputButton } from '@components/message-input/styled'
import { bytesToSize } from '@utils/bytes-to-size'
import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'
import { useTranslation } from 'react-i18next'

interface AttachmentProps {
  image: ImageType
  index: number
}

export const Attachment: FC<AttachmentProps> = ({ image, index }) => {
  const { onImageRemove } = useAttachmentsUploadingContext()
  const { t } = useTranslation('attachments')

  return (
    <AttachmentContainer>
      <ImgContainer>
        <img src={image.dataURL} alt={image.file?.name} />
      </ImgContainer>
      <Container ml="0.8rem" width="100%">
        <Container display="flex" justifyContent="space-between" alignItems="center">
          <Name variant="textMedium">{image.file?.name}</Name>
          <InputButton
            onClick={() => {
              onImageRemove(index)
            }}
            aria-label={t('removeAttachment')}
          >
            <CloseIcon />
          </InputButton>
        </Container>
        <Text variant="smallTextRegular" color="icon">
          {image.file?.size && bytesToSize(image.file?.size)}
        </Text>
      </Container>
    </AttachmentContainer>
  )
}

const AttachmentContainer = styled(Container)`
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  border-radius: 0.8rem;
  padding: 0.8rem;
  width: 20.8rem;
  display: flex;
`

const ImgContainer = styled(Container)`
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.6rem;
  overflow: hidden;
  flex: 0 0 auto;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Name = styled(Text)`
  white-space: nowrap;
  max-width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
`
