import { useGetMe } from '@api/account/hooks/use-get-me'
import { User } from '@api/account/types/user'
import { getFullName } from '@utils/helpers'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FlexProps } from 'styled-system'
import { Container, Text } from 'ui'
import { UserPreviewAvatar } from './user-preview-avatar'

interface EmptyChatMessagesProps extends FlexProps {
  users: User[]
}

export const EmptyChatMessages: FC<EmptyChatMessagesProps> = ({ users }) => {
  const { t } = useTranslation(['create-new'])
  const { meData } = useGetMe()

  const firstUser = users[0]
  const isDM = users.length === 1
  const isMessageToMyself = isDM && firstUser.userId === meData?.userId

  // chatUsers excl. ME
  const chatUsers = useMemo(() => users.filter(({ userId }) => userId !== meData?.userId), [users])

  // chatUsers fullnames  excl. my name
  const chatUsersNames = useMemo(() => {
    return chatUsers.map(({ firstName, lastName }) => getFullName(firstName, lastName))
  }, [chatUsers])

  // amount of the chatUsers incl. ME
  const chatUsersNumber = useMemo(() => {
    return chatUsers.length + 1
  }, [chatUsers])

  if (isMessageToMyself) {
    return (
      <div>
        <Container display="flex" flexGap="1.2rem" alignItems="center">
          <UserPreviewAvatar user={firstUser} />
          <Container>
            <Text variant="textMedium">
              {`${firstUser.firstName}`}&nbsp;{`(${t('you')})`}
            </Text>
            <Text variant="smallTextRegular">{firstUser.jobTitle}</Text>
          </Container>
        </Container>
        <EmptyText>
          <Text variant="textMedium" as="span">
            {t('privateChatPart1')}
          </Text>
          {t('privateChatPart2')}
        </EmptyText>
      </div>
    )
  }
  if (isDM) {
    const userName = getFullName(firstUser.firstName, firstUser.lastName)
    return (
      <div>
        <Container display="flex" flexDirection="row">
          <Container display="flex" flexGap="1.2rem">
            {chatUsers.map((user) => (
              <UserPreviewAvatar user={user} />
            ))}
          </Container>
          <Container display="flex" flexDirection="column" ml="1.2rem" justifyContent="center">
            <Text variant="textMedium">{userName}</Text>
            {firstUser.jobTitle && <Text>{firstUser.jobTitle}</Text>}
          </Container>
        </Container>
        <EmptyText>
          {t('usersInfo', {
            userNames: userName,
          })}{' '}
          {t('usersCount', {
            usersCount: chatUsersNumber.toString(),
          })}
        </EmptyText>
      </div>
    )
  }
  if (!isDM) {
    return (
      <div>
        <Container display="flex" flexGap="1.2rem">
          {chatUsers.map((user) => (
            <UserPreviewAvatar user={user} />
          ))}
        </Container>
        <EmptyText>
          {t('usersInfo', {
            userNames: chatUsersNames.join(', '),
          })}{' '}
          {t('usersCount', {
            usersCount: chatUsersNumber.toString(),
          })}
        </EmptyText>
      </div>
    )
  }
  return null
}

const EmptyText = styled(Text)`
  margin-top: 1.6rem;
`
