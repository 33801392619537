import { useGetMyWorkspaces } from '@api/workspaces/hooks/use-get-my-workspaces'
import { routes } from '@routes/routes'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutProps, SpaceProps } from 'styled-system'
import { Button, ButtonLink, Container, Heading, ImgContainer, PlusIcon, Text } from 'ui'

interface InvitationInvalidProps extends SpaceProps, LayoutProps {
  onCreateWs: VoidFunction
}

export const InvitationInvalid: FC<InvitationInvalidProps> = ({ onCreateWs, ...stylesProps }) => {
  const { data: myWorkspacesData } = useGetMyWorkspaces({
    enabled: true,
  })
  const { t } = useTranslation('invitation')

  return (
    <Container textAlign="center" {...stylesProps}>
      <Heading variant="h0" mb="1.2rem">
        {t('invalidInvitation')}
      </Heading>
      <Text mb="2.4rem">
        {t('invitationExpired')}
        <br />
        {t('checkEmailRequest')}
      </Text>
      {myWorkspacesData?.length === 0 ? (
        <Button minWidth="25.2rem" onClick={onCreateWs} preFix={<PlusIcon />}>
          {t('createNewWs')}
        </Button>
      ) : (
        <Container display="flex" flexGap="1.2rem" maxWidth="52rem" m="0 auto">
          <Button variant="secondary" width="100%" onClick={onCreateWs}>
            {t('createNewWs')}
          </Button>
          <ButtonLink width="100%" href={routes.workspaces}>
            {t('showOtherWs')}
          </ButtonLink>
        </Container>
      )}
      <ImgContainer textAlign="center" maxWidth="28rem" m="6rem auto 0">
        <img src="/img/thinking.svg" alt={t('imgAlt')} width={276} height={246} />
      </ImgContainer>
    </Container>
  )
}
