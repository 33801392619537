import { AttachmentResponse } from '@api/chats/generated'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { SpinnerLightAnimation, Text } from 'ui'
import { AttachmentFetcher } from './attachment-fetcher'
import {
  AttachmentContainer,
  AttachmentOverlay,
  AttachmentsContainer,
  GridContainer,
  Indicator,
} from './styled'

export interface AttachmentsContainerProps {
  isIncoming: boolean
  hasIndicator: boolean
}

export interface MessageAttachmentsProps extends AttachmentsContainerProps, SpaceProps {
  attachments: AttachmentResponse[]
  createdAt: Date
  isLoading?: boolean
}

export const MessageAttachments: FC<MessageAttachmentsProps> = ({
  attachments,
  hasIndicator,
  isIncoming,
  isLoading = false,
  createdAt,
  ...spacing
}) => {
  const { t } = useTranslation(['chat', 'attachments'])
  const maxAttachments = 5

  const totalAttachments = attachments?.length
  //   const { openModal } = useModal()

  const shouldShowOverlay = (index: number) => {
    return totalAttachments > maxAttachments && index + 1 === maxAttachments
  }

  // TODO: Open gallery modal on click
  const handleOnClick = () => {
    // openModal({ content: <></>, variant: ModalVariant.FULL, closeOnClickOutside: false })
  }

  return (
    <AttachmentsContainer isIncoming={isIncoming} hasIndicator={hasIndicator} {...spacing}>
      {totalAttachments === 1 ? (
        <AttachmentFetcher {...attachments[0]}>
          {({ src, name }) => {
            return (
              <AttachmentContainer
                width="32rem"
                height="32rem"
                role="button"
                onClick={handleOnClick}
              >
                <img src={src} alt={name ?? t('image')} />
              </AttachmentContainer>
            )
          }}
        </AttachmentFetcher>
      ) : (
        <GridContainer attachmentsCount={totalAttachments}>
          {attachments.slice(0, maxAttachments).map((attachment, index) => (
            <AttachmentFetcher {...attachment}>
              {({ src, id, name }) => {
                return (
                  <AttachmentContainer key={id} role="button" onClick={handleOnClick}>
                    <img src={src} alt={name ?? t('image')} />
                    {shouldShowOverlay(index) && (
                      <AttachmentOverlay>
                        {
                          <Text color="white">
                            +{totalAttachments - maxAttachments} {t('more')}
                          </Text>
                        }
                      </AttachmentOverlay>
                    )}
                  </AttachmentContainer>
                )
              }}
            </AttachmentFetcher>
          ))}
        </GridContainer>
      )}
      {hasIndicator && (
        <Indicator>
          {isLoading ? (
            <SpinnerLightAnimation size={16} />
          ) : (
            <Text color="white" variant="smallTextRegular">
              {format(createdAt, 'p')}
            </Text>
          )}
        </Indicator>
      )}
    </AttachmentsContainer>
  )
}
