import { ChatUser } from '@api/chats/types/chat-user'

// TODO:  Fix Typescript > Align `ChatUserResponse` type (API generated) and `ChatUser` type (our client type)
export const getUsersLanguageCodes = (users: ChatUser[]) => {
  return users.reduce((languageCodesList: string[], currentUser) => {
    const userLanguages = currentUser.user.languages

    if (!userLanguages || userLanguages.length === 0) {
      return ['']
    }

    const languageCodes = userLanguages.map((language) => language.code)
    const usersLanguageCodes = languageCodesList.concat(languageCodes)

    return usersLanguageCodes
  }, [])
}
