import styled from 'styled-components'
import { Heading } from 'ui'

export const ChatHeaderButton = styled.button`
  display: inline-flex;
  align-items: center;
  & > * + * {
    margin-left: 1rem;
  }
`

export const ChatName = styled(Heading)`
  text-align: left;
`

export const IconContainer = styled.span`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
`
