import { DeleteAvatarModal } from '@modules/modals/delete-avatar'
import { EditAvatarModal } from '@modules/modals/edit-avatar'
import { AvatarType } from '@modules/modals/edit-avatar/types'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Avatar, AvatarVariant, Button, Container, EditIcon, TrashcanIcon, useModal } from 'ui'

export interface EditAvatarProps extends SpaceProps {
  imgURL?: string
  avatarBgColor?: string | null
  name?: string
  label?: string
  isAvatarExists: boolean
  avatarType: AvatarType
  workspaceId?: string
}

export const EditAvatar: FC<EditAvatarProps> = ({
  imgURL,
  avatarBgColor,
  label,
  name,
  isAvatarExists,
  avatarType,
  workspaceId,
  ...spacing
}) => {
  const { t } = useTranslation(['common'])
  const theme = useTheme()

  const { openModal } = useModal()

  const handleChangeImageClick = useCallback(() => {
    openModal({
      content: <EditAvatarModal avatarType={avatarType} workspaceId={workspaceId} />,
      closeOnClickOutside: false,
    })
  }, [openModal])

  const handleDeleteClick = useCallback(() => {
    openModal({
      content: <DeleteAvatarModal workspaceId={workspaceId} />,
      closeOnClickOutside: true,
    })
  }, [])

  return (
    <Container display="flex" flexDirection="row" alignItems="center" {...spacing}>
      <Avatar
        imgUrl={imgURL}
        bgColor={avatarBgColor ? `#${avatarBgColor}` : theme.colors.primary}
        size="9rem"
        borderRadius="1.2rem"
        fontSize="4.8rem"
        name={name}
        label={label}
        variant={AvatarVariant.SQUARE}
        isAvatarExists={isAvatarExists}
      />
      <Button
        onClick={handleChangeImageClick}
        ml="2.4rem"
        preFix={<EditIcon />}
        variant="secondary"
        plain
      >
        {t('changeImage')}
      </Button>
      {imgURL && (
        <Button
          onClick={handleDeleteClick}
          ml="2.4rem"
          preFix={<TrashcanIcon />}
          variant="warning"
          plain
        >
          {t('deleteImage')}
        </Button>
      )}
    </Container>
  )
}
