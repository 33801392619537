import { defaultImport } from 'default-import'
import React, { FC } from 'react'
import defaultStyled from 'styled-components'
import { Text } from '../../typography'
import { StyledToastMessageProps, ToastMessageProps } from './types'

const styled = defaultImport(defaultStyled)

export const ToastMessage: FC<ToastMessageProps> = ({ message, isVisible }) => (
  <StyledToastMessage variant="smallTextRegular" isVisible={isVisible}>
    {message}
  </StyledToastMessage>
)

const StyledToastMessage = styled(Text)<StyledToastMessageProps>`
  display: inline-block;
  pointer-events: none;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0.05rem;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity ${(props) => props.theme.transitionTimes.short},
    transform ${(props) => props.theme.transitionTimes.short};
  position: absolute;
  transform: ${(props) => (props.isVisible ? 'translateY(-30px)' : 'translateY(-30%)')};
  top: 0;
  right: 0;
  padding: 0.6rem 0.8rem;
  border-radius: 0.5rem;
`
