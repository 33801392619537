import React from 'react'
import { css, DefaultTheme } from 'styled-components'
import styled from 'styled-components'
import { position, space } from 'styled-system'
import { generalSettings } from '../container'
import { Text } from '../typography'
import {
  GeneralStatusProps,
  StatusParsedProperties,
  StatusLabelProps,
  StatusColorVariant,
} from './types'

function parseStatusStyles(
  theme: DefaultTheme,
  variant?: StatusColorVariant
): StatusParsedProperties {
  switch (variant) {
    case StatusColorVariant.SUCCESS:
      return {
        background: '#EAFFF6',
        color: theme.colors.green,
      }
    case StatusColorVariant.ATTENTION:
      return {
        background: theme.colors.yellowLighter,
        color: '#CA7A20',
      }
    case StatusColorVariant.NEUTRAL:
      return {
        background: '#D9E6FB',
        color: theme.colors.primary,
      }
    case StatusColorVariant.WARNING:
      return {
        background: '#FFF3EF',
        color: theme.colors.red,
      }
    default:
      return {
        background: '#D9E6FB',
        color: theme.colors.primary,
      }
  }
}

export const Status: React.FC<GeneralStatusProps> = ({ label, variant, ...spacing }) => (
  <StatusLabel as="span" variant={variant} {...spacing}>
    {label}
  </StatusLabel>
)

export const StatusLabel = styled(Text).withConfig(generalSettings)<StatusLabelProps>`
  ${(props) => {
    const { background, color } = parseStatusStyles(props.theme, props.variant)

    return css`
      background-color: ${background};
      color: ${color};
    `
  }};
  display: inline-block;
  font-weight: 500;
  padding: 0.2rem 1.2rem;
  border-radius: 0.4rem;
  ${space};
  ${position};
`
