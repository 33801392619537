import { useWorkspace } from '@hooks/use-workspace'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { createWorkspace as createWorkspaceFn } from '../create-workspace'
import { CreateWorkspaceRequest } from '../generated'
import { WORKSPACE } from '../tags'
import { Workspace } from '../types'

interface useCreateWorkspaceProps {
  disableToast?: boolean
  successCallbackFn?: VoidFunction
}

export const useCreateWorkspace = ({
  disableToast = false,
  successCallbackFn,
}: useCreateWorkspaceProps = {}) => {
  const { setWorkspaceId } = useWorkspace()
  const queryClient = useQueryClient()
  const { t } = useTranslation('common')
  const {
    data: createWorkspaceData,
    isLoading: isCreateWorkspaceLoading,
    isError: isCreateWorkspaceError,
    isSuccess: isCreateWorkspaceSuccess,
    mutateAsync: createWorkspaceAsync,
    mutate: createWorkspace,
  } = useMutation<Workspace, Error, CreateWorkspaceRequest>(
    [WORKSPACE],
    (newWorkspace) => createWorkspaceFn(newWorkspace),
    {
      onError: () => {
        if (!disableToast) {
          toast.error(`${t('somethingWentWrong')}.`)
        }
      },
      onSuccess: (data, variables, context) => {
        setWorkspaceId(data.id)
        if (!disableToast) {
          toast.success(`${t('workspaceCreated')}.`)
        }
        successCallbackFn?.()
      },
      onSettled: () => {
        queryClient.invalidateQueries([WORKSPACE])
      },
    }
  )

  return {
    createWorkspaceData,
    createWorkspace,
    createWorkspaceAsync,
    isCreateWorkspaceLoading,
    isCreateWorkspaceSuccess,
    isCreateWorkspaceError,
  }
}
