import { ProviderTranslation } from '@api/chats/types/provider-translation'
import { TranslationText } from '@components/translation-text'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { withSkeleton } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { Container } from 'ui'
import { TranslationHeading } from '.'
import { TranslationsSkeleton } from './skeleton'

interface TranslationsProps {
  translationTexts: ProviderTranslation[]
  onClick: (provider: number) => void
}

export const Translations: FC<TranslationsProps> = ({ translationTexts, onClick }) => {
  const { t } = useTranslation('translation-picker')

  // Our pick is the first translation in the array (provider: 1)
  const isOurPick = translationTexts[0]
  const alternatives = translationTexts.slice(1)

  return (
    <Container>
      {isOurPick && (
        <Section mb="2.4rem">
          <TranslationHeading>{t('ourPick')}</TranslationHeading>
          <TranslationText
            key={isOurPick.provider}
            text={isOurPick.translation}
            isSelected={isOurPick.isPreferred}
            onClick={() => onClick(isOurPick.provider)}
          />
        </Section>
      )}
      {alternatives.length > 0 && (
        <Section>
          <TranslationHeading>{t('alternatives')}</TranslationHeading>
          {alternatives.map(({ provider, translation, isPreferred }) => (
            <TranslationText
              key={provider}
              text={translation}
              isSelected={isPreferred}
              onClick={() => onClick(provider)}
            />
          ))}
        </Section>
      )}
    </Container>
  )
}

export const TranslationsWithSkeleton = withSkeleton(Translations, <TranslationsSkeleton />)

const Section = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`
