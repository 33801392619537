import React, { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTheme } from 'styled-components'
import { Container, Text } from 'ui'

export const InvitationValidSkeleton: FC = () => {
  const theme = useTheme()

  return (
    // overwrite skeleton color
    <SkeletonTheme baseColor={theme.colors.beige} enableAnimation={false} borderRadius={8}>
      <Container textAlign="center">
        <Text mb="3.2rem">
          <Skeleton width={80} height={80} />
        </Text>
        <Text mb="1.4rem">
          <Skeleton height={60} borderRadius={100} />
        </Text>
        <Text mb="4rem">
          <Skeleton width={240} height={22} borderRadius={100} />
        </Text>
        <Skeleton width={336} height={48} />
      </Container>
    </SkeletonTheme>
  )
}
