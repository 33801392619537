import { useAppDispatch } from '@app/hooks'
import { useMutation } from '@tanstack/react-query'
import { reportIncorrectTranslation } from 'src/features/conversation/slice'
import { ReportTranslationInput, reportTranslation } from '../report-translation'
import { Message } from '../types/message'

export type ExtendedReportTranslationInput = ReportTranslationInput & {
  clientMessageId: string
  chatId: string
}

export const useReportTranslation = () => {
  const dispatch = useAppDispatch()

  return useMutation<Message, Error, ExtendedReportTranslationInput>(
    ({ messageId, languageCode }) => reportTranslation({ messageId, languageCode }),
    {
      onMutate: async ({ clientMessageId, messageId, chatId, languageCode }) => {
        dispatch(
          reportIncorrectTranslation({
            clientMessageId,
            messageId,
            chatId,
            languageCode,
          })
        )
      },
    }
  )
}
