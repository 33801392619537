import { getMyWorkspaces } from '@api/workspaces/get-my-workspaces'
import { useQuery } from '@tanstack/react-query'
import { WORKSPACE } from '../tags'

interface GetMyWorkspacesParams {
  enabled?: boolean
}

export const useGetMyWorkspaces = ({ enabled = true }: GetMyWorkspacesParams) => {
  const { data, isLoading, isError, isSuccess } = useQuery([WORKSPACE], () => getMyWorkspaces(), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled,
  })
  return { data, isLoading, isError, isSuccess }
}
