import styled from 'styled-components'
import { ImgContainer } from 'ui'

export const LogoWrapper = styled(ImgContainer)`
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  right: 0;
  bottom: 0;
  overflow: hidden;
`
