/**
 * Creates selection range from beginning to target position.
 * @constructor
 * @param {HTMLDivElement} node - The element withing which we need to add range.
 * @param {number} targetPosition - The end position for range.
 * @param {boolean} setCursorToEnd - Should caret be moved to the very end of the element's content.
 * @returns {Range}
 */
const createRange = (
  node: HTMLDivElement,
  targetPosition: number,
  setCursorToEnd: boolean = false
) => {
  let range = document.createRange()
  range.selectNode(node)
  range.setStart(node, 0)

  let pos = 0
  const stack = [node]
  while (stack.length > 0) {
    const current = stack.pop()

    if (!setCursorToEnd && current?.nodeType === Node.TEXT_NODE) {
      const len = current.textContent?.length
      if (len) {
        if (pos + len >= targetPosition) {
          if (pos === 0 && targetPosition === 0 && len !== 0) {
            range.setEnd(current, len)
            return range
          }
          range.setEnd(current, targetPosition - pos)
          return range
        }
        pos += len
      }
    } else if (current?.childNodes?.length && current?.childNodes?.length > 0) {
      for (let i = current.childNodes.length - 1; i >= 0; i--) {
        stack.push(current.childNodes[i] as HTMLDivElement)
      }
    }
  }

  // The target position is greater than the
  // length of the contenteditable element.
  range.setEnd(node, node.childNodes.length)
  return range
}

export const setCursorPosition = (
  targetPosition: number,
  contentEle: HTMLDivElement,
  setCursorToEnd: boolean = false
) => {
  const range = createRange(contentEle, targetPosition, setCursorToEnd)
  const selection = window.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)
  try {
    selection?.collapseToEnd()
  } catch (e) {
    console.error(e)
  }
  setTimeout(() => {
    contentEle?.focus()
  }, 0)
}
