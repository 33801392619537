import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { User } from '@api/account/types/user'
import { MemberRole } from '@modules/members/types'
import { DeactivateMemberModal } from '@modules/modals/deactivate-member'
import { DemoteMemberModal } from '@modules/modals/demote-member'
import { RevokeAccountModal } from '@modules/modals/revoke-account'
import { SetAdminModal } from '@modules/modals/set-admin'
import { UpdateEmailModal } from '@modules/modals/update-email'
import { getFeatureFlag } from '@utils/flags-manager'
import { getFullName } from '@utils/helpers'
import format from 'date-fns/format'
import isEqual from 'date-fns/isEqual'
import subDays from 'date-fns/subDays'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  AvatarVariant,
  BlockIcon,
  Container,
  EmailIcon,
  Popper,
  PopperActionVariant,
  StatusVariant,
  Text,
  useModal,
  UserIcon,
} from 'ui'
import { StyledItem } from './styled'

export interface MemberItemStylesProps {
  isDeleted: boolean
}

export const MemberItem: React.FC<User> = ({
  avatar,
  firstName,
  lastName,
  email,
  userId,
  online,
  isDeleted,
  joiningDate,
  color,
}) => {
  const { t } = useTranslation('workspace')

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: userId,
    //@ts-ignore
    avatarId: avatar?.id,
  })

  const { openModal } = useModal()
  const status = online?.status === 1 ? StatusVariant.ONLINE : StatusVariant.OFFLINE

  // Helpers
  const fullName = getFullName(firstName, lastName)

  // now we do not have implemented roles and lower it checks if it is false
  const isGlobalAdmin = false

  const getRoleStr = (role: MemberRole) => {
    switch (role) {
      case MemberRole.GLOBAL_ADMIN:
        return t('globalAdmin')
      case MemberRole.ADMIN:
        return t('admin')
      case MemberRole.USER:
        return t('member')
      default:
        return null
    }
  }

  const getFormatedDate = (date: Date) => {
    const today = new Date()
    const yesterday = subDays(today, 1)
    const givenDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    const yesterdayDate = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate()
    )
    if (isEqual(givenDate, todayDate)) {
      return t('today')
    }
    if (isEqual(givenDate, yesterdayDate)) {
      return t('yesterday')
    }
    return format(date, 'MMM d, yyyy')
  }

  const getWsMembersActions = useMemo(() => {
    if (!isDeleted) {
      return [
        {
          name: t('demoteToMember'),
          onClick: () =>
            openModal({
              content: <DemoteMemberModal id={userId} fullName={fullName} />,
            }),
          icon: <UserIcon />,
        },
        {
          name: t('deactivate'),
          onClick: () =>
            openModal({
              content: <DeactivateMemberModal id={userId} fullName={fullName} />,
            }),
          icon: <BlockIcon />,
        },
      ]
    }
    if (!isDeleted) {
      return [
        {
          name: t('setAdmin'),
          onClick: () =>
            openModal({
              content: <SetAdminModal id={userId} fullName={fullName} />,
            }),
          icon: <UserIcon />,
        },
        {
          name: t('updateEmail'),
          onClick: () =>
            openModal({
              content: <UpdateEmailModal id={userId} email={email} firstName={firstName} />,
            }),
          icon: <EmailIcon />,
        },
        {
          name: t('deactivate'),
          onClick: () =>
            openModal({
              content: <DeactivateMemberModal id={userId} fullName={fullName} />,
            }),
          icon: <BlockIcon />,
          variant: PopperActionVariant.WARNING,
        },
      ]
    }
    if (isDeleted) {
      return [
        {
          name: t('restoreAccount'),
          onClick: () =>
            openModal({
              content: <RevokeAccountModal id={userId} fullName={fullName} />,
            }),
          icon: <BlockIcon />,
          variant: PopperActionVariant.WARNING,
        },
      ]
    }
    return []
  }, [isDeleted, t, userId, fullName, email, firstName, openModal])

  return (
    <StyledItem isDeleted={Boolean(isDeleted)}>
      <Container>
        <Container display="flex" alignItems="center">
          <Avatar
            imgUrl={usersAvatarUrl}
            size="3.6rem"
            borderRadius="0.6rem"
            fontSize="2rem"
            label={firstName}
            name={fullName}
            status={isDeleted ? undefined : status}
            variant={AvatarVariant.SQUARE}
            bgColor={`#${color}`}
            isAvatarExists={enabled}
          />
          <Container ml="1.2rem">
            <Text>{fullName}</Text>
            <Text variant="smallTextRegular" color="greyDark">
              {email}
            </Text>
          </Container>
        </Container>
      </Container>
      <Container>
        <Text>{isDeleted ? t('deactivated') : getRoleStr(MemberRole.ADMIN)}</Text>
      </Container>
      <Container>
        <Text>{getFormatedDate(new Date(joiningDate))}</Text>
      </Container>
      {getFeatureFlag('showOnProd') && (
        <Container position="relative">
          {!isGlobalAdmin && <Popper actions={getWsMembersActions} hiddenLabel={t('userMenu')} />}
        </Container>
      )}
    </StyledItem>
  )
}
