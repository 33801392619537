import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Container, ChevronDownIcon, Text } from 'ui'

interface Props {
  onClick: () => void
  newMessagesCount?: number
}

export const ScrollDown = ({ onClick, newMessagesCount }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('chat')

  if (!newMessagesCount) return <></>

  return (
    <Container position="absolute" right="0" top="-2rem">
      <ScrollDownCircle onClick={onClick} aria-label={t('bottomScroll')}>
        <Text as="span" variant="smallTextRegular" color="primary">
          {newMessagesCount}
        </Text>
        <ChevronDownIcon fill={theme.colors.primary} />
      </ScrollDownCircle>
    </Container>
  )
}

const ScrollDownCircle = styled.button`
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.primaryLight};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Text} {
    margin-top: 0.4rem;
  }

  > svg {
    position: absolute;
    top: 1.5rem;
  }
`
