import React, { FC } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { ChevronUpIcon } from '../icons'
import { HiddenLabel } from '../typography'
import { ArrowContainerProps, ArrowContainerStylesProps } from './types'

export const ArrowIndicator: FC<ArrowContainerProps> = ({ open, hiddenLabel, ...stylesProps }) => {
  return (
    <ArrowContainer isOpen={open} {...stylesProps}>
      {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
      <ChevronUpIcon />
    </ArrowContainer>
  )
}

const ArrowContainer = styled.span<ArrowContainerStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  transform-origin: center;
  transition: transform ${({ theme }) => theme.transitionTimes.short};
  transform: rotate(${({ isOpen }) => (isOpen ? '0' : '180deg')});
  ${space}
`
