import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetChannelList } from '@api/chats/hooks/use-get-channel-list'
import { useGetChatList } from '@api/chats/hooks/use-get-chat-list'
import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { CollapsibleList, PrimaryAction } from '@components/collapsible-list'
import { DirectMessageIcon, HashTagIcon, TeammatesIcon, UnreadIcon } from '@components/icons'
import { useLeftSideBar } from '@hooks/use-left-side-bar'
import { useWorkspace } from '@hooks/use-workspace'
import { CreateChannelModal } from '@modules/modals/create-channel'
import { routes } from '@routes/routes'
import { notificationsManager } from '@utils/notifications'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Container,
  ScrollContainer,
  useModal,
  PlusIcon,
  VerticalMenuIcon,
  PopperAction,
  ListIcon,
} from 'ui'
import { SidebarHeader } from '../sidebar-header'
import { SidebarHeaderSkeleton } from '../sidebar-header/skeleton'
import { SidebarContentSkeleton } from './skeleton'

export const SidebarContent: FC = () => {
  const { setSelectedOption } = useLeftSideBar()
  const navigate = useNavigate()
  const { workspaceId } = useWorkspace()
  const { openModal } = useModal()
  const { channelId, chatId } = useParams()
  const { chats, isLoading: isChatsLoading } = useGetChatList(500)
  const { channels, isLoading: isChannelsLoading } = useGetChannelList(500)
  const { t } = useTranslation('sidebar')
  const { meData } = useGetMe()
  const { data: workspaceData } = useGetWorkspace({
    id: workspaceId,
  })

  const isSidebarLoading = isChatsLoading || isChannelsLoading

  //TODO: add pagination when design will be available
  const myActiveChannels = useMemo(() => {
    if (!meData) {
      return []
    }

    return channels.filter(
      ({ chatUsers }) => !chatUsers.find((user) => user.user.userId === meData.userId)?.leftChat
    )
  }, [channels, meData])

  useEffect(() => {
    setSelectedOption(channelId ?? chatId ?? null)
  }, [channelId, chatId, setSelectedOption])

  const onCreateChannelClick = useCallback(() => {
    openModal({ content: <CreateChannelModal /> })
  }, [openModal])

  const onCreateChatClick = useCallback(() => {
    navigate(`${routes.chats}/${routes.createNew}`)
  }, [navigate])

  const onChannelClick = useCallback(
    (id: string) => {
      navigate(`${routes.channels}/${id}`)
    },
    [navigate]
  )

  const onChatClick = useCallback(
    (id: string) => {
      navigate(`${routes.chats}/${id}`)
    },
    [navigate]
  )

  const onTeammatesClick = useCallback(() => {
    navigate(routes.teammates)
  }, [navigate])

  const onDirectMessageClick = useCallback(() => {
    navigate(routes.directMessages)
  }, [navigate])

  const unreadMessages = useMemo(() => {
    notificationsManager
      .setMappingDataForNotifications(myActiveChannels.concat(chats))
      .setNavigate(navigate)
    return myActiveChannels
      ?.concat(chats)
      .filter(({ unreadMessagesCount }) => unreadMessagesCount > 0)
  }, [myActiveChannels, chats, navigate])

  const chatPrimaryAction: PrimaryAction = useMemo(
    () => ({
      name: t('chatsMenu'),
      icon: <VerticalMenuIcon />,
      popperOptions: [
        {
          name: t('openDirectMessages'),
          icon: <PlusIcon />,
          onClick: onDirectMessageClick,
        },
        {
          name: t('seeTeammates'),
          icon: <TeammatesIcon />,
          onClick: onTeammatesClick,
        },
      ],
    }),
    []
  )

  const chatSecondaryAction: PopperAction = useMemo(
    () => ({
      name: t('newMessage'),
      icon: <PlusIcon />,
      onClick: onCreateChatClick,
    }),
    [onCreateChatClick, t]
  )

  const channelSecondaryAction: PopperAction = useMemo(
    () => ({
      name: t('createChannel'),
      icon: <PlusIcon />,
      onClick: onCreateChannelClick,
    }),
    [onCreateChannelClick, t]
  )

  const channelPrimaryAction: PrimaryAction = useMemo(
    () => ({
      name: t('channelsMenu'),
      icon: <VerticalMenuIcon />,
      popperOptions: [
        {
          name: t('browseChannels'),
          icon: <ListIcon />,
          onClick: () => navigate(routes.channels),
        },
      ],
    }),
    [navigate, t]
  )

  return (
    <>
      {isSidebarLoading ? (
        <>
          <SidebarHeaderSkeleton />
          <SidebarContentSkeleton />
        </>
      ) : (
        <>
          <SidebarHeader workspace={workspaceData} me={meData} />
          <CollapsibleListContainer>
            <ScrollContainer hideScrollbar>
              <CollapsibleList
                itemList={unreadMessages}
                title={t('unread')}
                icon={<UnreadIcon isUnread={unreadMessages.length > 0} />}
                onItemClick={onChatClick}
                isOpenByDefault={false}
              />
              <CollapsibleList
                itemList={myActiveChannels}
                title={t('channels')}
                icon={<HashTagIcon />}
                onItemClick={onChannelClick}
                isOpenByDefault={true}
                primaryAction={channelPrimaryAction}
                secondaryAction={channelSecondaryAction}
              />
              <CollapsibleList
                itemList={chats}
                title={t('directMessages')}
                icon={<DirectMessageIcon />}
                onItemClick={onChatClick}
                isOpenByDefault={true}
                primaryAction={chatPrimaryAction}
                secondaryAction={chatSecondaryAction}
              />
            </ScrollContainer>
          </CollapsibleListContainer>
        </>
      )}
    </>
  )
}

export const CollapsibleListContainer = styled(Container)`
  height: calc(100vh - 14rem);
`
