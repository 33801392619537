import { User } from '@api/account/types/user'
import { EmptyChannelMembers } from '@modules/empty-channel-members'
import { EmptyChannelMessages } from '@modules/empty-channel-messages'
import { EmptyChatMessages } from '@modules/empty-chat-messages'
import { ConversationType, CHANNEL_TYPES } from '@utils/get-chat-type'
import React from 'react'
import { FC } from 'react'
import { Container } from 'ui'

interface EmptyConversationBodyProps {
  users: User[]
  type: ConversationType
}

export const EmptyConversationBody: FC<EmptyConversationBodyProps> = ({ type, users }) => {
  const renderEmptyContent = (users: User[], type: ConversationType) => {
    // Chat with members and 0 messages
    if (type === ConversationType.CHAT) {
      if (users.length > 0) {
        return (
          <Container height="100%" display="flex" alignItems="end">
            <EmptyChatMessages users={users} />
          </Container>
        )
      }
    }
    // Channels with members and 0 messages
    if (CHANNEL_TYPES.includes(type)) {
      if (users.length > 1) {
        return (
          <Container height="100%" display="flex" alignItems="end">
            <EmptyChannelMessages />
          </Container>
        )
      }
      // Channels with no members (except me) and 0 messages
      return (
        <Container height="100%" display="flex" justifyContent="center" alignItems="center">
          <EmptyChannelMembers />
        </Container>
      )
    }

    return null
  }

  return renderEmptyContent(users, type)
}
