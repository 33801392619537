import { CheckIcon } from '@components/icons'
import React, { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'ui'
import { TranslationBox, SelectedIndicator } from './styled'

export interface TranslationTextProps {
  text: string
  isSelected?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export const TranslationText: FC<TranslationTextProps> = ({ text, isSelected, onClick }) => {
  const { t } = useTranslation('translation-picker')

  return (
    <TranslationBox isSelected={isSelected} text={text} onClick={onClick}>
      {isSelected && (
        <SelectedIndicator>
          <CheckIcon aria-label={t('selectedTranslation')} />
        </SelectedIndicator>
      )}
      <Text maxWidth="22.8rem" display="inline-block">
        {text}
      </Text>
    </TranslationBox>
  )
}
