import styled from 'styled-components'
import { space } from 'styled-system'
import { Container } from 'ui'

export const CustomEmojiPickerWrapper = styled(Container)`
  * {
    font-family: ${({ theme }) => theme.fonts.default};
    --epr-search-input-border-radius: 0.8rem;
    --epr-search-input-bg-color: ${({ theme }) => theme.colors.greyLight};
    --epr-text-color: ${({ theme }) => theme.colors.greySuperDark};
    --epr-picker-border-radius: 1.6rem;
    --epr-search-input-padding: 0 1.6rem;
    --epr-horizontal-padding: 0.8rem;
    --epr-header-padding: 0.8rem;
    --epr-category-icon-active-color: ${({ theme }) => theme.colors.primary};
    --epr-highlight-color: ${({ theme }) => theme.colors.primary};
    --epr-emoji-size: 2.4rem;
    --epr-category-navigation-button-size: 2.5rem;
  }
  .epr-cat-btn:focus:before {
    border: none !important;
  }
  .epr-icn-search {
    display: none;
  }
  .epr-body {
    font-size: 1.3rem;
    font-weight: 400;
  }
  .epr-preview {
    display: none;
  }
  .epr-search {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .epr-search-container {
    border-radius: 0.8rem;
  }
  .epr-category-nav {
    padding: 0.8rem 0 !important;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
  }
  ${space}
`
