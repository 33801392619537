import { useMutation } from '@tanstack/react-query'
import { CreateAttachmentResponse } from '@api/chats/generated'
import { createAttachment } from '@api/messages/create-attachment'
import { uploadBlob } from '@modules/modals/edit-avatar/utils/crop-utils'

interface SendAttachmentRequest {
  conversationId: string
  files: File[]
}

const sendAttachment = async ({
  conversationId,
  files,
}: {
  conversationId: string
  files: File[]
}) => {
  const requestBody = files.map(({ name }) => ({ name }))
  const data = await createAttachment(conversationId, requestBody)
  const attachmentsArray = files.map((file, index) => ({
    file,
    meta: data[index],
  }))
  await Promise.all(
    attachmentsArray.map((attachment) => {
      if (!attachment.meta.uri) {
        throw Error('wrong attachment format')
      }
      return uploadBlob(attachment.meta.uri, attachment.file)
    })
  )
  //@ts-ignore - wrong type in swagger
  return data as CreateAttachmentResponse[]
}

export const useCreateAttachment = () => {
  return useMutation<CreateAttachmentResponse[], Error, SendAttachmentRequest>({
    mutationFn: ({ conversationId, files }) => sendAttachment({ conversationId, files }),
  })
}
