import { TeammatesFiltersValues } from '@contexts/filters-provider'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Checkbox, Container, Text } from 'ui'

interface FiltersSectionProps {
  status: string
  options: string[]
}

export const FiltersSection: FC<FiltersSectionProps> = ({ status, options }) => {
  const { t } = useTranslation(['filters'])

  const { setFieldValue, values } = useFormikContext<TeammatesFiltersValues>()

  return (
    <FiltersSectionContainer>
      <Status variant="smallTextRegular">{t(`options.${status}.title`)}</Status>
      <Container display="flex" flexDirection="column" alignItems="flex-start" flexGap="1.2rem">
        {options.map((option) => (
          <Checkbox
            name={option}
            label={t(`options.${status}.options.${option}`)}
            value={option}
            checked={values[status][option]}
            onChange={(e) => {
              setFieldValue(`${status}.${option}`, e.target.checked)
            }}
          />
        ))}
      </Container>
    </FiltersSectionContainer>
  )
}

const FiltersSectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  :not(:last-child) {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
  }
`

const Status = styled(Text)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.greyDark};
  margin-bottom: 1.2rem;
`
