import { useAppDispatch } from '@app/hooks'
import { ScrollDown } from '@modules/conversation-body/scroll-down'
import { LimitModal } from '@modules/modals/limit'
import { getCursorPosition } from '@utils/get-cursor-position'
import { localiseNumber } from '@utils/localise-number'
import { charLimit, isMessageOverLimit } from '@utils/message-limit'
import { EmojiClickData } from 'emoji-picker-react'
import { addDraftMessage, removeDraftMessage } from 'features/messages/draft-message-slice'
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, useModal } from 'ui'
import { EditGrid } from './edit-grid'
import { SendGrid } from './send-grid'
import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'

export enum MessageInputMode {
  SEND = 'send',
  EDIT = 'edit',
}

export interface ExpandingInputProps extends SpaceProps {
  messageInputMode: MessageInputMode
}

export interface InputWrapperProps {
  isFocused: boolean
}

export interface MessageInputProps extends SpaceProps {
  isDisabled: boolean
  inputText: string
  contentEditableInputText: string
  originalText?: string
  messageInputMode: MessageInputMode
  setInputText: Dispatch<SetStateAction<string>>
  setContentEditableInputText: Dispatch<SetStateAction<string>>
  onClickSend: VoidFunction
  isMessageInputOpen?: boolean
  setMessageInputMode?: Dispatch<SetStateAction<MessageInputMode>>
  setIsMessageInputOpen?: Dispatch<SetStateAction<boolean>>
  conversationId?: string
  inView: boolean
  scrollDownHandle: () => void
  isEditLoading: boolean
}

export const MessageInput: FC<MessageInputProps> = ({
  isDisabled,
  inputText,
  contentEditableInputText,
  messageInputMode,
  setInputText,
  setContentEditableInputText,
  onClickSend,
  isMessageInputOpen,
  originalText,
  setMessageInputMode,
  setIsMessageInputOpen,
  conversationId,
  inView,
  scrollDownHandle,
  isEditLoading,
  ...spacing
}) => {
  const dispatch = useAppDispatch()
  const { openModal } = useModal()
  const { t } = useTranslation('chat')
  const expandingInputRef = useRef<null | HTMLDivElement>(null)
  const hasReachedLimit = isMessageOverLimit(inputText)
  const [cursorPosition, setCursorPosition] = useState<number>(0)
  const isSpaceInString = !inputText.trim() || inputText === '' || inputText === '<br>'
  const isSubmitDisabled = isDisabled || isSpaceInString || hasReachedLimit || isEditLoading

  const { imageList } = useAttachmentsUploadingContext()

  const hasAttachment = imageList.length > 0

  const isSendGridDisabled = (!hasAttachment && isSpaceInString) || hasReachedLimit

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter' && !e.shiftKey) {
      if (hasReachedLimit) {
        openModal({
          content: (
            <LimitModal
              title={t('modalMaxCharacters.title')}
              text={t('modalMaxCharacters.subtitle')}
              buttonLabel={t('modalMaxCharacters.submitButton')}
            />
          ),
        })
        e.preventDefault()
      } else {
        onClickSend()
        e.preventDefault()
      }
    }
  }

  const handleOnClose = () => {
    if (setIsMessageInputOpen) {
      setInputText(originalText || '')
      setIsMessageInputOpen(false)
    }
  }

  const handleTextChange = (newText: string) => {
    if (conversationId && messageInputMode === MessageInputMode.SEND) {
      dispatch(addDraftMessage({ conversationId, messageText: newText }))
    } else if (newText.length === 0 && conversationId) {
      dispatch(removeDraftMessage({ conversationId }))
    }
  }

  const handleOnChangeText = (target: HTMLDivElement) => {
    const newText = target.textContent || ''
    setInputText(newText)
    handleTextChange(newText)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown)
    return () => {
      document.removeEventListener('keydown', handleOnKeyDown)
    }
  })

  const getCharsLeftText = useMemo(() => {
    const charactersDifference = charLimit - inputText.length

    if (charactersDifference < -1) {
      return t('charactersOverLimit', {
        charactersDifference: localiseNumber(Math.abs(charactersDifference)),
      })
    }

    if (charactersDifference === -1) {
      return t('characterOverLimit', { charactersDifference: 1 })
    }

    if (charactersDifference <= 100) {
      if (charactersDifference === 1) {
        return `${localiseNumber(charactersDifference)} ${t('characterLeft')}`
      }
      return `${localiseNumber(charactersDifference)} ${t('charactersLeft')}`
    }

    return ''
  }, [inputText, t])

  // Update message text with emoji
  const onEmojiClick = ({ emoji }: EmojiClickData, _: MouseEvent, onClose?: VoidFunction) => {
    const currentCursorPosition = getCursorPosition(expandingInputRef)
    setInputText((prevText) => {
      // Split the prevText into two parts: before and after the cursor position
      const textBeforeCursor = prevText.slice(0, currentCursorPosition)
      const textAfterCursor = prevText.slice(currentCursorPosition)

      // Insert the emoji between the two parts and update the input text
      const updatedText = textBeforeCursor + emoji + textAfterCursor

      setCursorPosition((textBeforeCursor + emoji).length)
      setContentEditableInputText(updatedText)
      handleTextChange(updatedText)
      return updatedText
    })

    onClose?.()
  }

  return (
    <Container {...spacing}>
      {/*TODO: ScrollDown component should start to get new messages, currently working on it*/}
      {!inView && <ScrollDown onClick={scrollDownHandle} />}
      {messageInputMode === MessageInputMode.EDIT ? (
        <EditGrid
          handleOnChangeText={handleOnChangeText}
          contentEditableInputText={contentEditableInputText}
          setContentEditableInputText={setContentEditableInputText}
          cursorPosition={cursorPosition}
          charsLeftText={getCharsLeftText}
          hasReachedLimit={hasReachedLimit}
          onClickSend={onClickSend}
          onClickClose={handleOnClose}
          isDisabled={isSubmitDisabled}
          ref={expandingInputRef}
          onEmojiClick={onEmojiClick}
          isEditLoading={isEditLoading}
        />
      ) : (
        <SendGrid
          handleOnChangeText={handleOnChangeText}
          cursorPosition={cursorPosition}
          charsLeftText={getCharsLeftText}
          hasReachedLimit={hasReachedLimit}
          isDisabled={isDisabled || isSendGridDisabled}
          onClickSend={onClickSend}
          ref={expandingInputRef}
          contentEditableInputText={contentEditableInputText}
          onEmojiClick={onEmojiClick}
        />
      )}
    </Container>
  )
}

const MessageInputSkeleton = () => {
  const theme = useTheme()
  return (
    <Skeleton
      width="100%"
      height="7.4rem"
      baseColor={theme.colors.beigeDark}
      style={{ borderRadius: 16, borderBottomRightRadius: 0 }}
    />
  )
}

export const MessageInputWithSkeleton = withSkeleton(MessageInput, <MessageInputSkeleton />)
