import { Translation } from '@api/chats/types/translation'

export const updatePreferredTranslation = (
  languageCode: string,
  provider: number,
  translations: Translation[]
) => {
  return translations.map((translation) => {
    if (translation.languageCode === languageCode) {
      const updatedProviderTranslations = translation.translations.map((providerTranslation) => ({
        ...providerTranslation,
        isPreferred: providerTranslation.provider === provider,
      }))

      return {
        ...translation,
        translations: updatedProviderTranslations,
        hasBeenReportedAsIncorrect: false,
      }
    }
    return translation
  })
}
