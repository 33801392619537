export const workspaceBaseUrl = `https://api.${process.env.API_ENV}.${process.env.API}/workspaces/${process.env.WORKSPACE_API_VERSION}`

export const protectedBaseUrl = `https://api.${process.env.API_ENV}.${process.env.API}`

export const accountAdditionalUrl = `/accounts/${process.env.ACCOUNT_API_VERSION}`

export const chatsAdditionalUrl = `/chats/${process.env.CHAT_API_VERSION}/chats`

export const messagesAdditionalUrl = `/chats/${process.env.CHAT_API_VERSION}/messages`

export const mediaAdditionalUrl = `media/${process.env.MEDIA_API_VERSION}`
