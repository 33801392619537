import { useWorkspaceToken } from '@api/workspaces/hooks/use-workspace-token'
import { MyWorkspace, MyWorkspaceStatus } from '@api/workspaces/types'
import { OnboardingType } from '@contexts/workspace-provider'
import { useSignalR } from '@hooks/use-signalr'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/routes'
import React, { FC, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Avatar, Button, Container, Text } from 'ui'

export enum DashboardItemVariant {
  WORKSPACE = 'workspace',
  INVITATION = 'invitation',
}

interface DashboardItemProps extends SpaceProps {
  variant?: DashboardItemVariant
  item: MyWorkspace
}

export const DashboardItem: FC<DashboardItemProps> = ({
  variant = DashboardItemVariant.WORKSPACE,
  item,
}) => {
  const { t } = useTranslation(['home', 'common'])
  const navigate = useNavigate()
  const { workspaceId, setWorkspaceId, setOnboardingType, setIsPendingOnboarding } = useWorkspace()
  const { startConnection } = useSignalR()
  const [isClicked, setIsClicked] = useState(false)

  const invitationRedirectPath: string = `${routes.workspaces}/${workspaceId}/${routes.onboarding}`
  const workspaceRedirectPath: string = `${routes.workspaces}/${workspaceId}/${routes.teammates}`

  const { isSuccess, isError } = useWorkspaceToken({
    id: workspaceId,
    enabled: isClicked,
  })

  const handleOnClick = (id: string) => {
    setWorkspaceId(id)
    setIsClicked(true)
  }

  // Set button label based on variant and myStatus
  const getButtonLabel = useMemo(() => {
    if (variant === DashboardItemVariant.WORKSPACE) {
      if (item.myStatus === MyWorkspaceStatus.ACTIVATED) {
        return t('open')
      } else if (item.myStatus === MyWorkspaceStatus.ALLOWED) {
        return t('proceed')
      }
    }
    return t('join')
  }, [variant, t, item.myStatus])

  // Handle redirect based on variant and myStatus
  useEffect(() => {
    const handleSuccess = () => {
      if (variant === DashboardItemVariant.INVITATION) {
        setOnboardingType(OnboardingType.USER)
        setIsPendingOnboarding(true)
        navigate(invitationRedirectPath, {
          replace: true,
        })
      } else if (variant === DashboardItemVariant.WORKSPACE) {
        if (item.myStatus === MyWorkspaceStatus.ALLOWED) {
          setOnboardingType(OnboardingType.USER)
          setIsPendingOnboarding(true)
          navigate(invitationRedirectPath, { replace: true })
        } else if (item.myStatus === MyWorkspaceStatus.ACTIVATED) {
          startConnection()
          navigate(workspaceRedirectPath, { replace: true })
        }
      }
    }

    if (isSuccess && isClicked) {
      handleSuccess()
    }
    if (isError) {
      toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`)
    }
  }, [isSuccess, isError, isClicked, variant, item])

  return (
    <DashboardItemContainer key={item.id}>
      <Container>
        <Avatar
          size="6rem"
          borderRadius="1.2rem"
          imgUrl={item?.avatar?.mediaUri}
          name={item.name}
          label={item.name}
          bgColor={`#${item.color}`}
          fontSize="3.6rem"
        />
      </Container>
      <Container flex="1 1 100%" mx="1.2rem" textAlign="left">
        <WorkspaceName>{item.name}</WorkspaceName>
      </Container>
      <Button
        variant="secondary"
        minWidth="12rem"
        onClick={() => {
          handleOnClick(item.id)
        }}
      >
        {getButtonLabel}
      </Button>
    </DashboardItemContainer>
  )
}

const DashboardItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  }
`

const WorkspaceName = styled(Text)`
  font-size: 1.8rem;
  font-weight: 500;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`
