import React from 'react'
import {
  FileAudioPreview,
  FileGeneralPreview,
  FileImagePreview,
  FileVideoPreview,
  VideoDuration,
} from './styled'

export enum FileTypeVariant {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  OTHER = 'other',
}

export interface FileVideoProps {
  thumbnailSrc: string
  duration: string
  alt: string
}
export interface FileImageProps {
  src: string
  alt: string
}
export interface FilePreviewProps {
  type: FileTypeVariant
  img?: FileImageProps
  video?: FileVideoProps
}

const getFilePreview = (type: FileTypeVariant, img?: FileImageProps, video?: FileVideoProps) => {
  switch (type) {
    case FileTypeVariant.IMAGE:
      return (
        <FileImagePreview>
          <img src={img?.src} alt={img?.alt} width={72} height={72} />
        </FileImagePreview>
      )
    case FileTypeVariant.AUDIO:
      return <FileAudioPreview />
    case FileTypeVariant.VIDEO:
      return (
        <FileVideoPreview>
          <img src={video?.thumbnailSrc} alt={video?.alt} width={72} height={72} />
          <VideoDuration>{video?.duration}</VideoDuration>
        </FileVideoPreview>
      )
    default:
      return <FileGeneralPreview />
  }
}

export const FilePreview: React.FC<FilePreviewProps> = ({ type, img, video }) => (
  <>{getFilePreview(type, img, video)}</>
)
