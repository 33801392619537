import { ConfirmationModal } from '@modules/modals/confirmation'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, useModal } from 'ui'

export interface DeactivateMemberModalProps {
  id: string
  fullName: string
}

export const DeactivateMemberModal: FC<DeactivateMemberModalProps> = ({ id, fullName }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-deactivate-member')

  //Temporary
  const handleOnSubmit = () => {
    const alertString = `${fullName}, id: ${id}`
    alert(alertString)
    closeModal()
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      onSubmit={handleOnSubmit}
      width="40rem"
    >
      <Text mb="4rem">{t('confirmation', { fullName })}</Text>
    </ConfirmationModal>
  )
}
