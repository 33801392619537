import { SkeletonVariantProps } from '@modules/skeleton'
import { SkeletonContainerList } from '@modules/skeleton/style'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { Container } from 'ui'

export const TeammatesSkeletonList: FC<SkeletonVariantProps> = ({ arrayToMap }) => {
  return (
    <SkeletonContainerList>
      {arrayToMap.map((key) => (
        <Row key={key}>
          <Skeleton width="6.8rem" height="6.8rem" />
          <InfoRows>
            <Skeleton height="1.2rem" width="14.6rem" borderRadius={6} />
            <br />
            <Skeleton height={12} width={110} borderRadius={6} />
            <br />
            <Skeleton height="1.2rem" width="11rem" borderRadius={6} />
          </InfoRows>
        </Row>
      ))}
    </SkeletonContainerList>
  )
}

const InfoRows = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 1.2rem;
`

const Row = styled(Container)`
  position: relative;
  display: flex;
  padding: 1.2rem 2rem;
`
