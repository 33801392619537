import { useSignalR } from '@hooks/use-signalr'
import { useSignalREvents } from '@hooks/use-signalr-events'
import { InitialLayout } from '@layouts/initial-layout'
import { MainLayout } from '@layouts/main-layout'
import { AuthCallBack } from '@pages/auth-call-back'
import { ChannelBrowser } from '@pages/channel-browser'
import { Conversation } from '@pages/conversation'
import { CreateNew } from '@pages/create-new'
import { Dashboard } from '@pages/dashboard'
import { DirectMessages } from '@pages/direct-messages'
import { Forbidden } from '@pages/forbidden'
import { InvalidInvitation } from '@pages/invalid-invitation'
import { Invitation } from '@pages/invitation'
import { NewUser } from '@pages/new-user'
import { NotFound } from '@pages/not-found'
import { Onboarding } from '@pages/onboarding'
import { Personal } from '@pages/personal'
import { Teammates } from '@pages/teammates'
import { Workspace } from '@pages/workspace'
import { ProtectedRoutes } from '@routes/protected-routes'
import { routes } from '@routes/routes'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

export const App: React.FC = () => {
  const { connection, startConnection } = useSignalR()
  const { startEvents, stopEvents } = useSignalREvents()

  useEffect(() => {
    startConnection()
  }, [])

  useEffect(() => {
    startEvents()
    return () => {
      stopEvents()
    }
  }, [connection])

  return (
    <Routes>
      <Route
        path={`${routes.workspaces}/:workspaceId/${routes.invite}`}
        element={<InitialLayout />}
      >
        <Route index element={<Invitation />} />
        <Route path={routes.invalidInvite} element={<InvalidInvitation />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route element={<MainLayout />}>
          <Route path={`${routes.workspaces}/${routes.createNew}`} element={<Onboarding />} />
        </Route>
        <Route element={<InitialLayout />}>
          <Route path={routes.newUser} element={<NewUser />} />
          <Route path={routes.workspaces} element={<Dashboard />} />
        </Route>
        <Route path={`${routes.workspaces}/:workspaceId`} element={<MainLayout />}>
          <Route path={routes.settings} element={<Workspace />} />
          <Route path={routes.teammates} element={<Teammates />} />
          <Route path={routes.directMessages} element={<DirectMessages />} />
          <Route path={routes.chats}>
            <Route path=":chatId" element={<Conversation />} />
            <Route path={routes.createNew} element={<CreateNew />} />
          </Route>
          <Route path={routes.channels}>
            <Route index element={<ChannelBrowser />} />
            <Route path=":channelId" element={<Conversation />} />
          </Route>
          <Route path={routes.onboarding} element={<Onboarding />} />
          <Route path={routes.users}>
            <Route path=":userId">
              <Route path={routes.settings} element={<Personal />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={routes.authCallBack} element={<AuthCallBack />} />
      <Route element={<InitialLayout />}>
        <Route path="/*" element={<NotFound />} />
        <Route path={routes.forbidden} element={<Forbidden />} />
      </Route>
      <Route path={routes.home} element={<Navigate to={routes.workspaces} />} />
    </Routes>
  )
}
