import React, { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Text, CheckIcon } from '../../index'

const CheckboxContainer = styled.label<SpaceProps>`
  width: fit-content;
  display: flex;
  align-items: center;
  ${space}

  :hover {
    cursor: pointer;
  }
`

const Icon = styled(CheckIcon)``

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  padding: 0.4rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.greyDark}`};
  transition: ${({ theme }) => `all ${theme.transitionTimes.short}`};

  ${HiddenCheckbox}:focus + & {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  }

  ${HiddenCheckbox}:disabled + & {
    opacity: 0.2;
  }

  ${Icon} {
    transition: opacity ${({ theme }) => theme.transitionTimes.short};
    opacity: 0;
  }

  ${HiddenCheckbox}:checked + & {
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
    ${Icon} {
      opacity: 1;
    }
  }
`

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  spaceProps?: SpaceProps
}

export const Checkbox: FC<CheckboxProps> = ({ label, name, spaceProps, ...props }) => (
  <CheckboxContainer htmlFor={name} {...spaceProps}>
    <HiddenCheckbox type="checkbox" id={name} {...props} />
    <StyledCheckbox>
      <Icon />
    </StyledCheckbox>
    {label && <Text ml="1.2rem">{label}</Text>}
  </CheckboxContainer>
)
