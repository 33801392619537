import { LostConnectionBanner } from '@components/lost-connection-banner'
import { NotificationsBanner } from '@components/notifications-banner'
import { FiltersProvider, teammatesInitialFilters } from '@contexts/filters-provider'
import { LeftSideBarProvider } from '@contexts/left-side-bar-provider'
import { BannersProvider } from '@contexts/banners-provider'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'ui'
import { SidePage } from './side-page'
import { Sidebar } from './sidebar'
import { MainGrid, MainScreen } from './styled'

export const MainLayout = () => {
  return (
    <BannersProvider>
      <Container>
        <LostConnectionBanner />
        <NotificationsBanner />
        <MainGrid>
          <LeftSideBarProvider>
            <Sidebar />
          </LeftSideBarProvider>
          <FiltersProvider initialValues={teammatesInitialFilters}>
            <MainScreen>
              <Outlet />
            </MainScreen>
            <SidePage />
          </FiltersProvider>
        </MainGrid>
      </Container>
    </BannersProvider>
  )
}
