import { useMutation, useQueryClient } from '@tanstack/react-query'
import { InviteUsersResponse } from '../generated'
import { inviteWorkspaceUsers as inviteWorkspaceUsersFn } from '../invite-workspace-users'
import { WORKSPACE, WORKSPACE_INVITATIONS } from '../tags'
import { InviteWorkspaceUsersParams } from '../types'

export const useInviteWorkspaceUsers = () => {
  const queryClient = useQueryClient()

  const { data, isLoading, isError, isSuccess, mutateAsync, mutate } = useMutation<
    InviteUsersResponse,
    Error,
    InviteWorkspaceUsersParams
  >(
    [WORKSPACE],
    ({ workspaceId, requestBody }) => inviteWorkspaceUsersFn(workspaceId, requestBody),
    {
      onSettled: () => {
        queryClient.invalidateQueries([WORKSPACE])
        queryClient.invalidateQueries([WORKSPACE_INVITATIONS])
      },
    }
  )

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    mutateAsync,
    mutate,
  }
}
