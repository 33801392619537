import { SkeletonVariantProps } from '@modules/skeleton'
import React, { FC } from 'react'
import { Container } from 'ui'
import { MessageSkeleton } from './message'

export const ConversationBodySkeleton: FC<SkeletonVariantProps> = () => (
  <Container display="flex" flexDirection="column" justifyContent="flex-end" flexGap="0.8rem">
    <MessageSkeleton messageWidth={402} isIncoming />
    <MessageSkeleton messageWidth={241} isIncoming />
    <MessageSkeleton messageWidth={433} />
    <MessageSkeleton messageWidth={296} isIncoming />
  </Container>
)
