import { getUser } from '@api/account/get-user'
import { useQuery } from '@tanstack/react-query'

export const USER = 'user'

export const useGetUser = (id: string | null) => {
  const { data, error } = useQuery([USER, id], () => getUser(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    staleTime: 10000,
  })
  return { user: data, error }
}
