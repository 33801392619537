import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { Button, CheckmarkIcon, Container, Text } from 'ui'

interface TranslationsFooterProps {
  onClick: VoidFunction
  isIncorrect: boolean
}

export const TranslationsFooter: FC<TranslationsFooterProps> = ({ onClick, isIncorrect }) => {
  const { t } = useTranslation(['translation-picker'])

  return (
    <>
      {isIncorrect ? (
        <Container display="flex" alignItems="center" color="greyDark">
          <CheckmarkIcon />
          <Text color="greyDark" ml="0.8rem">
            {t('thankYouForHelpingUs')}
          </Text>
        </Container>
      ) : (
        <Button onClick={onClick} plain variant="warning">
          {t('iDontUnderstand')}
        </Button>
      )}
    </>
  )
}

export const TranslationsFooterWithSkeleton = withSkeleton(
  TranslationsFooter,
  <Skeleton width={160} height={12} borderRadius={10} />
)
