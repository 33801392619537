import { darkTheme, theme } from '@common/theme'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

export interface ThemeProviderProps {
  children: JSX.Element | JSX.Element[]
}

export enum ThemeTypeEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ThemeProviderDefinition {
  currentTheme: ThemeTypeEnum
  setCurrentTheme: Dispatch<SetStateAction<ThemeTypeEnum>>
}

const initData: ThemeProviderDefinition = {
  currentTheme: ThemeTypeEnum.LIGHT,
  setCurrentTheme: () => {},
}

const ThemeContext = createContext<ThemeProviderDefinition>(initData)

const useTheme = () => useContext(ThemeContext)

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeTypeEnum>(ThemeTypeEnum.LIGHT)

  const themeMapping = {
    [ThemeTypeEnum.LIGHT]: theme,
    [ThemeTypeEnum.DARK]: darkTheme,
  }

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        setCurrentTheme,
      }}
    >
      <StyledThemeProvider theme={themeMapping[currentTheme]}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeProvider, useTheme }
