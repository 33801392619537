import { capitalizeFirstLetter } from '@utils/helpers'
import { useTranslation } from 'react-i18next'

export const useTabTitleWithCounter = (
  valuesArray: unknown[],
  translationKey: string,
  ns: string = 'common'
) => {
  const { t } = useTranslation(ns)
  return `${capitalizeFirstLetter(t(translationKey))} (${valuesArray.length})`
}
