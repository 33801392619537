import { StatusVariant } from 'ui'

export enum MemberRole {
  GLOBAL_ADMIN,
  ADMIN,
  USER,
}

export interface Member {
  id: string
  isReceiver?: boolean
  firstName: string
  lastName: string
  url?: string
  imgUrl?: string
  status?: StatusVariant
  email: string
  phone?: string
  localTime: Date
  languages?: string
  position?: string
  role: MemberRole
  joined: Date
  isDeleted?: boolean
  color?: string
}
