import { User } from '@api/account/types/user'
import { Skeleton } from '@modules/skeleton'
import { ListCard } from '@modules/teammate-card/list-card'
import React, { FC } from 'react'
import { InViewTrigger } from 'src/components/in-view-trigger'
import styled from 'styled-components'
import { ScrollContainer } from 'ui'

interface ListLayoutProps {
  users: User[]
  handleSendClick: (userId: string) => void
  handleUserClick: (userId: string) => void
  isUsersFetching: boolean
  setIsTriggerInView: (c: boolean) => void
}

export const ListLayout: FC<ListLayoutProps> = ({
  handleSendClick,
  handleUserClick,
  users,
  isUsersFetching,
  setIsTriggerInView,
}) => (
  <ListContainer hideScrollbar mx="-2rem">
    {users.map((teammate) => (
      <ListCardItem key={teammate.userId}>
        <ListCard
          handleSendClick={handleSendClick}
          handleUserClick={handleUserClick}
          {...teammate}
        />
      </ListCardItem>
    ))}
    {isUsersFetching && <Skeleton type="teammatesList" length={3} />}
    <InViewTrigger onChange={setIsTriggerInView} bottom={0} position="absolute" />
  </ListContainer>
)

const ListCardItem = styled.div`
  padding: 1.2rem 2rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`

const ListContainer = styled(ScrollContainer)`
  height: calc(100% - 12rem);
  padding-bottom: 2rem;
  & > ${ListCardItem}:not(:last-of-type) {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      height: 0.1rem;
      background: ${({ theme }) => theme.colors.greyLight};
    }
  }
`
