import { messagesApi } from './instance'
import { Message } from './types/message'

export interface ReportTranslationInput {
  messageId: string
  languageCode: string
}

export const reportTranslation = async ({ messageId, languageCode }: ReportTranslationInput) => {
  const { data } = await messagesApi.post<Message>(`${messageId}/translation-report`, {
    languageCode: languageCode,
  })
  return data
}
