import { useAppDispatch, useAppSelector } from '@app/hooks'
import { PickerDropdown } from '@components/picker-dropdown'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { SortingArrowsIcon } from 'ui'
import { setSortingType, SortingType } from '../../features/user-sorting/slice'

type SortingOption = { label: string; value: SortingType }

export const SortTeammates: FC = () => {
  const { t } = useTranslation(['sorting'])
  const theme = useTheme()
  const { sortBy, sortDesc } = useAppSelector((state) => state.userSorting)
  const dispatch = useAppDispatch()

  const setSortBy = (sortBy: SortingType) => {
    dispatch(setSortingType(sortBy))
  }

  const option = useMemo(() => {
    return sortBy.concat(sortDesc ? '-reversed' : '')
  }, [sortBy, sortDesc])

  const sortingOptions: SortingOption[] = useMemo(
    () => [
      {
        label: t('az'),
        value: SortingType.NAME,
      },
      {
        label: t('za'),
        value: SortingType.NAME_REVERSED,
      },
      {
        label: `${t('joiningDate')}: ${t('recent')}`,
        value: SortingType.JOINED_REVERSED,
      },
      {
        label: `${t('joiningDate')}: ${t('oldest')}`,
        value: SortingType.JOINED,
      },
    ],
    [t]
  )

  return (
    <PickerDropdown
      onChange={setSortBy}
      value={option}
      preFix={<SortingArrowsIcon fill={theme.colors.greyDark} />}
      options={sortingOptions}
    />
  )
}
