import DeleteWorkspace from '@modules/workspace-general/delete-workspace'
import Info from '@modules/workspace-general/info'
import React from 'react'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { useTheme } from 'styled-components'
import { Container, Divider } from 'ui'
import { WorkspaceGeneralSkeleton } from './skeleton'

const WorkspaceGeneral: React.FC = () => {
  const theme = useTheme()
  return (
    <Container maxWidth="84rem">
      <Info />
      <Divider my="3.2rem" color={theme.colors.greyLight} />
      <DeleteWorkspace />
    </Container>
  )
}

export const WorkspaceGeneralWithSkeleton = withSkeleton(
  WorkspaceGeneral,
  <WorkspaceGeneralSkeleton />
)
