import styled from 'styled-components'

export const MainGrid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: 'sidebar mainscreen sidepage';
  grid-template-columns: 28rem 1fr auto;
  overflow: hidden;
`

export const MainScreen = styled.div`
  grid-area: mainscreen;
`
