import { WorkspaceUserRole } from '@api/workspaces/generated'

export enum InvitationStatus {
  PENDING = 'pending',
  EXPIRED = 'expired',
}

export interface Invitation {
  id: string
  email: string
  expiresIn: string
  invitedAt: Date
  roles: WorkspaceUserRole[]
}

export interface InvitationItemStylesProps {
  status: InvitationStatus
}
