import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Heading, ImgContainer } from 'ui'

export const ChannelDenied = () => {
  const { t } = useTranslation('chat')
  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      height="100%"
    >
      <ImgContainer mb="3.2rem">
        <img src="/img/access-denied.svg" alt={t('accessDenied.imgAlt')} width={240} height={380} />
      </ImgContainer>
      <Heading variant="h2" maxWidth="35rem">
        {t('accessDenied.title')}
      </Heading>
    </Container>
  )
}
