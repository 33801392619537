import { Float } from '@headlessui-float/react'
import { Popover } from '@headlessui/react'
import React, { FC, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import styled, { css } from 'styled-components'
import {
  ArrowIndicator,
  CheckmarkIcon,
  Container,
  HiddenLabel,
  PopperButton,
  Text,
  TranslationIcon,
} from 'ui'
import { Language, LanguageSelectProps, PopperItemProps } from './types'

//TODO: uncomment when the translations will be available
const languages: Language[] = [
  {
    code: 'en',
    name: 'English',
  },
  //   {
  //     code: 'zh',
  //     name: '官话',
  //   },
  //   {
  //     code: 'hi',
  //     name: 'हिन्दी',
  //   },
  //   {
  //     code: 'es',
  //     name: 'Español',
  //   },
  //   {
  //     code: 'fr',
  //     name: 'Français',
  //   },
  //   {
  //     code: 'ar',
  //     name: 'العربية',
  //   },
]

export const LanguageSelect: FC<LanguageSelectProps> = ({
  interfaceLanguage,
  setInterfaceLanguage,
  hiddenLabel,
  hiddenLabelSelected,
  ...spacing
}) => {
  const theme = useTheme()
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0])

  const handleClick = (lang: Language) => {
    setSelectedLanguage(lang)
    setInterfaceLanguage(lang)
  }

  useEffect(() => {
    const getLanguageByCode = (langCode: string) => {
      const language = languages.find((lang) => lang.code === langCode)
      if (language) {
        // Check if the language has changed before updating the state
        if (selectedLanguage.code !== language.code) {
          setSelectedLanguage({ code: language.code, name: language.name })
          setInterfaceLanguage({ code: language.code, name: language.name })
        }
      }
    }
    const browserLang =
      languages.find((lang) => lang.code === navigator.language.split('-')[0])?.code ?? 'en'
    getLanguageByCode(interfaceLanguage?.code ?? browserLang)
  }, [interfaceLanguage, selectedLanguage.code])

  return (
    <Container {...spacing}>
      {/* TODO: unable the lang select when translations are available */}
      <StyledPopover>
        {({ open, close }) => (
          <Float show={open} flip offset={{ mainAxis: 10 }}>
            <StyledPopoverButton>
              <Container as="span" display="flex" alignItems="center">
                <TranslationIcon fill={theme.colors.icon} />
                <Text as="span" m="0 0.8rem 0 0.4rem">
                  {selectedLanguage.name}
                </Text>
                <ArrowIndicator open={open} hiddenLabel={hiddenLabel} />
              </Container>
            </StyledPopoverButton>
            <Popover.Panel>
              <PopperList>
                {languages.map((lang) => (
                  <PopperItem key={lang.code}>
                    <PopperButton
                      onClick={() => {
                        handleClick(lang)
                        close()
                      }}
                      name={lang.name}
                    />
                    {selectedLanguage.code === lang.code && (
                      <SelectedIndicator>
                        <CheckmarkIcon />
                        <HiddenLabel>{hiddenLabelSelected}</HiddenLabel>
                      </SelectedIndicator>
                    )}
                  </PopperItem>
                ))}
              </PopperList>
            </Popover.Panel>
          </Float>
        )}
      </StyledPopover>
    </Container>
  )
}

const StyledPopover = styled(Popover)`
  position: relative;
`

const StyledPopoverButton = styled(Popover.Button)`
  outline: none;
  pointer-events: none;
`

const PopperItem = styled.li<PopperItemProps>`
  ${(props) => {
    const selectedStyles = css`
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    `
    return css`
      position: relative;
      ${props.isSelected && selectedStyles}
    `
  }}
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
`

const PopperList = styled.ul`
  overflow: hidden;
  width: 20rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
`

const SelectedIndicator = styled.span`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 2.4rem;
  height: 2.4rem;
`
