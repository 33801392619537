import React, { FC, SVGProps } from 'react'
import { theme } from 'ui'

export const UnreadIcon: FC<SVGProps<SVGSVGElement> & { isUnread?: boolean }> = ({
  isUnread,
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M9.5 7A4.5 4.5 0 0 0 5 11.5V17h9.5a4.5 4.5 0 0 0 4.5-4.5V12a1 1 0 1 1 2 0V12.5A6.5 6.5 0 0 1 14.5 19H3v-7.5A6.5 6.5 0 0 1 9.5 5H13.5a1 1 0 1 1 0 2H9.5Z"
      clipRule="evenodd"
    />
    <circle
      cx="19.5"
      cy="6.5"
      r="2.5"
      fill={isUnread ? theme.colors.primary : theme.colors.greyDark}
    />
  </svg>
)
