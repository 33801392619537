import { ME } from '@api/account/tags'
import { routes } from '@routes/routes'
import { useQueryClient } from '@tanstack/react-query'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Text, useModal } from 'ui'
import { ConfirmationModal } from '../confirmation'

interface LeaveOnboardingModalProps {
  workspaceName?: string
}

export const LeaveOnboardingModal: FC<LeaveOnboardingModalProps> = ({ workspaceName }) => {
  const { closeModal } = useModal()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation('modal-leave-onboarding')

  const handleOnClick = () => {
    queryClient.setQueryData([ME], {
      firstName: '',
      lastName: '',
    })
    closeModal()
    navigate(routes.workspaces)
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      onSubmit={handleOnClick}
    >
      <Text mb="4rem">
        {t('confirmation')} <br />
        {workspaceName
          ? t('wsSaved', {
              wsName: workspaceName,
            })
          : t('progressNotSaved')}
      </Text>
    </ConfirmationModal>
  )
}
