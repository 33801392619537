import { ChatUser } from '@api/chats/types/chat-user'
import { Dispatch, SetStateAction } from 'react'

interface TypingUser {
  userId: string
  chatId: string
}

interface HandleUserIsTypingProps {
  usersArray: ChatUser[]
  typeNewUsers: ChatUser[]
  setTypeNewUsers: Dispatch<SetStateAction<ChatUser[]>>
  setTypingChat: Dispatch<SetStateAction<string>>
}

export const handleUserIsTyping =
  ({ usersArray, setTypeNewUsers, typeNewUsers, setTypingChat }: HandleUserIsTypingProps) =>
  (message: TypingUser) => {
    setTypingChat(message.chatId)
    const typingUser = usersArray.find(({ user }) => user.userId === message.userId)
    if (typingUser && !typeNewUsers.some(({ user }) => user.userId === typingUser.user.userId)) {
      setTypeNewUsers([...typeNewUsers, typingUser])
    }
  }
