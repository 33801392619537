import { JsonPatchDocument } from '@api/workspaces/generated'
import { workspaceApi } from '@api/workspaces/instance'
import { Workspace } from '@api/workspaces/types'

export interface PatchWorkspaceParams {
  id: string
  requestBody?: JsonPatchDocument
}

export enum PatchWorkspacePath {
  Name = 'name',
  AvatarId = 'avatarId',
}

export const patchWorkspace = async ({ id, requestBody }: PatchWorkspaceParams) => {
  return await workspaceApi.patch<Workspace>(`workspaces/${id}`, requestBody, {
    headers: { 'Content-Type': 'application/json-patch+json' },
  })
}
