import { useAppDispatch } from '@app/hooks'
import { useMutation } from '@tanstack/react-query'
import { updateTranslationPreferences } from 'src/features/conversation/slice'
import { Message } from '../types/message'
import {
  UpdateTranslationPreferenceInput,
  updateTranslationPreference,
} from '../update-translation-preference'

export type ExtendedUpdateTranslationPreferenceInput = UpdateTranslationPreferenceInput & {
  clientMessageId: string
  chatId: string
}

export const useUpdateTranslationPreference = () => {
  const dispatch = useAppDispatch()

  return useMutation<Message, Error, ExtendedUpdateTranslationPreferenceInput>(
    ({ languageCode, provider, messageId }) =>
      updateTranslationPreference({ messageId, languageCode, provider }),

    {
      onMutate: async ({ clientMessageId, chatId, languageCode, provider }) => {
        dispatch(
          updateTranslationPreferences({
            clientMessageId,
            chatId,
            languageCode,
            provider,
          })
        )
      },
    }
  )
}
