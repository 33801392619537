import {
  LanguageDto,
  MeService,
  Operation,
  Operation_1,
  PersonalProfileResponse,
} from './generated'
import { UserLanguage } from './types/language'
import { PersonalProfile } from './types/personal-profile'

export enum PatchMePath {
  FirstName = 'firstName',
  LastName = 'lastName',
  JobTitle = 'jobTitle',
  Timezone = 'timezone',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  AvatarId = 'avatarId',
  IsOnboarded = 'isOnboarded',
}

const parsePersonalProfileResponse = (response: PersonalProfileResponse): PersonalProfile => {
  const user: PersonalProfile = {
    firstName: response.firstName || '',
    lastName: response.lastName || '',
    phoneNumber: response.phoneNumber || '',
    email: response.email || '',
    color: response.color || null,
    avatar: response.avatar
      ? {
          mediaId: response.avatar.id || '',
          mediaUri: response.avatar.uri || '',
        }
      : undefined,
    isOnboarded: response.isOnboarded,
    activeLanguages: [],
    userId: response.userId || '',
    jobTitle: response.jobTitle || '',
    timezone: response.timezone || '',
    online: {
      status: 0,
    },
  }

  if (response.activeLanguages) {
    user.activeLanguages = response.activeLanguages.map((languageDto: LanguageDto) => {
      const language: UserLanguage = {
        code: languageDto.code,
        name: languageDto.name || '',
        translatedName: languageDto.translatedName || '',
        isPrimary: languageDto.isPrimary || false,
      }
      return language
    })
  }

  if (response.doNotDisturbFrom) {
    user.online.status = 2
    user.online.lastOnline = new Date(response.doNotDisturbFrom)
  } else {
    user.online.status = 1
  }

  return user
}

export async function patchMe(params: Array<Operation | Operation_1>) {
  const response = await MeService.patchV2Me(params)
  return parsePersonalProfileResponse(response)
}
