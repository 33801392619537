import { WorkspaceUsers } from '@api/account/hooks/use-get-users'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { TeammateInfo } from '@modules/teammate-card/teammate-info'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Avatar, AvatarVariant, Button, Container, SendIcon, UserIcon } from 'ui'

export const ListCard: FC<WorkspaceUsers> = ({
  userId,
  firstName,
  lastName,
  position,
  joined,
  status,
  color,
  handleSendClick,
  handleUserClick,
  avatar,
}) => {
  const { t } = useTranslation(['common'])

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({ userId, avatarId: avatar?.id })

  const [quickActionsShown, setQuickActionsShown] = useState(false)
  const onMouseEnter = useCallback(() => {
    setQuickActionsShown(true)
  }, [setQuickActionsShown])
  const onMouseLeave = useCallback(() => {
    setQuickActionsShown(false)
  }, [setQuickActionsShown])

  return (
    <CardContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ContentContainer flexGap="1.2rem" onClick={() => handleUserClick(userId)}>
        <Avatar
          label={firstName}
          bgColor={`#${color}`}
          name={firstName}
          imgUrl={usersAvatarUrl}
          size="6.8rem"
          fontSize="3.6rem"
          variant={AvatarVariant.SQUARE}
          isAvatarExists={enabled}
        />
        <TeammateInfo
          firstName={firstName}
          lastName={lastName}
          position={position}
          joined={joined}
          status={status}
        />
      </ContentContainer>
      {quickActionsShown && (
        <QuickActions flexGap="1.2rem">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handleSendClick(userId)}
            preFix={<SendIcon />}
            minWidth="15.4rem"
          >
            {t('message')}
          </Button>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handleUserClick(userId)}
            preFix={<UserIcon />}
            minWidth="15.4rem"
          >
            {t('viewProfile')}
          </Button>
        </QuickActions>
      )}
    </CardContainer>
  )
}

const CardContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const ContentContainer = styled(Container)`
  display: flex;
  flex: 1;
`

const QuickActions = styled(Container)`
  display: flex;
`
