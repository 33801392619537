import { DefaultTheme } from 'styled-components'
import { StatusVariant } from '../components/avatar/types'

export type Status = number | StatusVariant

export const getStatusIconStyles = (status: Status, theme: DefaultTheme) => {
  switch (status) {
    case 'busy':
      return { fill: theme.colors.yellow }
    case 'online':
      return { fill: theme.colors.green }
    case 'offline':
    default:
      return { fill: theme.colors.greyDark, transform: 'rotate(180)' }
  }
}
