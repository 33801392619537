import { useSignalR } from '@hooks/use-signalr'
import { throttle } from 'lodash'
import { useEffect, useMemo } from 'react'
import { USER_IS_TYPING_IN_CHAT } from '../signalr/events'
import { useConversation } from './use-conversation'

export const useConversationAndTrackTyping = () => {
  const conversation = useConversation()
  const { conversationId, connection, inputText } = conversation.typingTrackingData
  const { invokeMethod } = useSignalR()

  const throttledInvokingUserIsTypingInChat = useMemo(
    () =>
      throttle(
        (_) => {
          invokeMethod(USER_IS_TYPING_IN_CHAT, conversationId)
        },
        900,
        { leading: true, trailing: false }
      ),
    [conversationId]
  )

  useEffect(() => {
    if (conversationId && connection && inputText) {
      throttledInvokingUserIsTypingInChat(inputText)
    }
  }, [inputText])

  return conversation
}
