import { useSpacer } from '@hooks/use-spacer'
import React, { RefObject, useRef, FC } from 'react'
import { SpinnerLightAnimation, Text } from 'ui'
import { GeneralMessageProps, MessageVariant } from '.'
import { MessageBody, MessageFooter, MessageText, Spacer } from './styled'

export const SendingMessage: FC<GeneralMessageProps> = ({ text }) => {
  const messageFooterRef: RefObject<HTMLDivElement> = useRef(null)
  const { spacerWidth } = useSpacer({ refComponent: messageFooterRef, offset: 15 })

  return (
    <MessageBody isSender={false} variant={MessageVariant.IS_SENDING}>
      {/* TODO: Add MessageAttachments grid here after passing necessary props */}
      {/* <MessageAttachments
        media={[]}
        hasText={true}
        isSender={true}
        createdAt={new Date()}
        pt="0.8rem"
        pb={shownText.length > 0 ? '0.8rem' : '0'}
      /> */}
      <div>
        <MessageText display="inline-block">
          <Text>{text}</Text>
        </MessageText>
        <Spacer width={spacerWidth} />
      </div>
      <MessageFooter display="flex" ref={messageFooterRef}>
        <SpinnerLightAnimation size={12} />
      </MessageFooter>
    </MessageBody>
  )
}
