import React from 'react'

export const LockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 4.25A3.761 3.761 0 0 0 8.25 8v1H8c-1.095 0-2 .905-2 2v6c0 1.095.905 2 2 2h8c1.095 0 2-.905 2-2v-6c0-1.095-.905-2-2-2h-.25V8A3.761 3.761 0 0 0 12 4.25zm0 1.5c1.252 0 2.25.998 2.25 2.25v1h-4.5V8c0-1.252.998-2.25 2.25-2.25zM8 10.5h8c.29 0 .5.21.5.5v6c0 .29-.21.5-.5.5H8c-.29 0-.5-.21-.5-.5v-6c0-.29.21-.5.5-.5z" />
      <path d="M12 12.25a.75.75 0 0 0-.75.75v2a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-2a.75.75 0 0 0-.75-.75Z" />
    </svg>
  )
}
