import { PersonalProfile } from '@api/account/types/personal-profile'
import { Reaction } from '@api/messages/types/message-detail'
import { useCallback } from 'react'

export const useHasUserReacted = (user?: PersonalProfile) => {
  return useCallback(
    (reaction: Reaction) => {
      return user ? reaction.userReactionTimes.some(({ userId }) => userId === user?.userId) : false
    },
    [user]
  )
}
