export const routes = {
  home: '/',
  login: '/login',
  workspaces: '/workspaces',
  chats: 'chats',
  channels: 'channels',
  users: 'users',
  settings: 'settings',
  createNew: 'create-new',
  teammates: 'teammates',
  directMessages: 'direct-messages',
  onboarding: 'onboarding',
  newUser: '/new-user',
  authCallBack: '/auth-callback',
  invite: 'invite',
  invalidInvite: 'invalid',
  forbidden: 'forbidden',
}
