import React from 'react'
import { SVGProps } from 'react'

export const PhoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.607 3a2.62 2.62 0 0 0-1.923.848A2.61 2.61 0 0 0 3.01 5.84a1 1 0 0 0 .002.015 16.87 16.87 0 0 0 2.62 7.393 16.673 16.673 0 0 0 5.128 5.12h.002a16.946 16.946 0 0 0 7.377 2.62 1 1 0 0 0 .017.002 2.616 2.616 0 0 0 2.625-1.55c.146-.333.22-.694.219-1.057v-2.38a2.606 2.606 0 0 0-2.244-2.644 1 1 0 0 0-.01-.002 9.336 9.336 0 0 1-2.041-.507l.002.002a2.613 2.613 0 0 0-1.46-.11 1 1 0 0 0-.003 0 2.608 2.608 0 0 0-1.289.695 1 1 0 0 0-.004.004l-.41.41a11.773 11.773 0 0 1-3.412-3.404l.408-.408a1 1 0 0 0 .004-.004 2.605 2.605 0 0 0 .588-2.748v-.002a9.295 9.295 0 0 1-.508-2.035 1 1 0 0 0-.002-.01A2.606 2.606 0 0 0 8.012 3Zm0 2h2.414a1 1 0 0 0 .01 0 .606.606 0 0 1 .61.521c.112.843.317 1.67.615 2.465a1 1 0 0 0 .002.004.6.6 0 0 1-.137.637L8.104 9.643a1 1 0 0 0-.165 1.203 13.861 13.861 0 0 0 5.204 5.191 1 1 0 0 0 1.199-.16l1.017-1.018a.613.613 0 0 1 .645-.136 1 1 0 0 0 .002.002c.8.298 1.633.503 2.48.615h-.01a.607.607 0 0 1 .524.615 1 1 0 0 0 0 .024v2.41a1 1 0 0 0 0 .004.602.602 0 0 1-.414.576.608.608 0 0 1-.244.03 14.944 14.944 0 0 1-6.496-2.31 1 1 0 0 0-.008-.005 14.667 14.667 0 0 1-4.518-4.51 1 1 0 0 0-.006-.008 14.865 14.865 0 0 1-2.312-6.51.607.607 0 0 1 .156-.459.612.612 0 0 1 .45-.197Z" />
    </svg>
  )
}
