import { getWorkspaceAvatar } from '@api/workspaces/get-workspace-avatar'
import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'

export const WORKSPACE_AVATAR = 'workspace_avatar'

interface UseGetWorkspaceAvatar {
  workspaceId: string
  avatarId?: string
}

export const useGetWorkspaceAvatar = ({ workspaceId, avatarId }: UseGetWorkspaceAvatar) => {
  const enabled = Boolean(workspaceId && avatarId)

  const { data, isError, isLoading, error } = useQuery(
    [WORKSPACE_AVATAR, workspaceId, avatarId],
    () => getWorkspaceAvatar(workspaceId!),
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: AVATAR_STALE_TIME,
    }
  )
  return { workspaceAvatarUrl: data, isError, isLoading, error, enabled }
}
