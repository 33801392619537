import { RefObject, useLayoutEffect, useState } from 'react'

interface useSpacerProps {
  refComponent: RefObject<HTMLElement>
  offset?: number
  hostContent?: string
}

// Calculate width of the spacer and add offset if needed

export const useSpacer = ({ refComponent, offset = 0, hostContent }: useSpacerProps) => {
  const [spacerWidth, setSpacerWidth] = useState<number>(0)
  useLayoutEffect(() => {
    if (refComponent.current) {
      const messageFooterWidth = Math.ceil(refComponent.current.offsetWidth * 10) / 10
      setSpacerWidth(messageFooterWidth + offset)
    }
  }, [offset, refComponent, hostContent])
  return { spacerWidth }
}
