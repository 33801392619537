import { uploadBlob } from '@modules/modals/edit-avatar/utils/crop-utils'
import { UploadAvatarArgs } from '../types'

export const uploadUserAvatar = async ({ blob, uri }: UploadAvatarArgs) => {
  if (!blob) {
    throw Error
  }
  const file = new File([blob], 'avatar.png', { type: 'image/png' })
  await uploadBlob(uri, file)
}
