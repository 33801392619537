import { useGetChatList } from '@api/chats/hooks/use-get-chat-list'
import { routes } from '@routes/routes'
import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ScrollContainer } from 'ui'
import { MessageItem } from './message-item'

export interface DirectMessagesContentProps {
  searchString?: string
}

export const DirectMessagesContent: FC<DirectMessagesContentProps> = ({ searchString }) => {
  const { chats } = useGetChatList()
  const navigate = useNavigate()

  const filteredChats = useMemo(() => chats.filter((chat) => chat.lastMessage), [chats])

  const searchResults = useMemo(() => {
    if (!searchString) return filteredChats
    return filteredChats.filter(
      (chat) =>
        chat.chatUsers[0].user.firstName.toLowerCase().includes(searchString) ||
        chat.chatUsers[0].user.lastName.toLowerCase().includes(searchString)
    )
  }, [filteredChats, searchString])

  const onListCardItemClick = useCallback(
    (id: string) => {
      navigate(`../${routes.chats}/${id}`)
    },
    [navigate]
  )

  return (
    <ListContainer hideScrollbar>
      {searchResults.map((chat) => (
        <ListCardItem key={chat.id} onClick={() => onListCardItemClick(chat.id)}>
          <MessageItem chat={chat} />
        </ListCardItem>
      ))}
    </ListContainer>
  )
}

export const ListContainer = styled(ScrollContainer)`
  height: calc(100% - 12rem);
  padding-bottom: 2rem;
  margin: 1.6rem -2rem 0;
`
const ListCardItem = styled.div`
  padding: 1.2rem 2rem;
  cursor: pointer;
  &:not(:last-of-type) {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      height: 0.1rem;
      background: ${({ theme }) => theme.colors.background};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`
