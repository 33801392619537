import { useGetUser } from '@api/account/hooks/use-get-user'
import { useSidePage } from '@hooks/use-side-page'
import { Files } from '@modules/files'
import { SidepageHeader } from '@modules/side-page/header'
import { UserDetail } from '@modules/user-detail'
import { getFeatureFlag } from '@utils/flags-manager'
import { getStatusVariant } from '@utils/get-status'
import { capitalizeFirstLetter, getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusBadge, Tab, Tabs, TabsVariant } from 'ui'

export const User: FC = () => {
  const { t } = useTranslation('common')
  const { sidePageId } = useSidePage()
  const { user } = useGetUser(sidePageId)

  const userFullName = getFullName(user?.firstName, user?.lastName)

  if (!user) {
    return null
  }

  const tabs = [
    <Tab title={capitalizeFirstLetter(t('about'))}>
      <UserDetail user={user} />
    </Tab>,
    <Tab title={capitalizeFirstLetter(t('files'))}>
      <Files />
    </Tab>,
  ]

  return (
    <>
      {user.isDeleted ? (
        <SidepageHeader title={userFullName} mb="1.2rem" />
      ) : (
        <SidepageHeader
          title={userFullName}
          mb="1.4rem"
          postFix={<StatusBadge status={getStatusVariant(user.online)} sizeLg />}
        />
      )}
      {getFeatureFlag('showOnProd') ? (
        <Tabs variant={TabsVariant.UNDERLINE} listMarginBottom="1.6rem">
          {tabs}
        </Tabs>
      ) : (
        <UserDetail user={user} />
      )}
    </>
  )
}
