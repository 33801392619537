import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { Container } from 'ui'

export const Item: FC = () => (
  <ItemContainer display="flex" alignItems="center" flexGap="0.8rem">
    <Skeleton width={24} height={24} borderRadius={4} />
    <Skeleton width={150} height={12} />
  </ItemContainer>
)

const ItemContainer = styled(Container)`
  &:not(:last-of-type) {
    padding-bottom: 0.8rem;
  }
`
