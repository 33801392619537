import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteMessage, DeleteMessageInput } from '../delete-message'
import { Message } from '../types/message'
import { MESSAGE_LIST } from './use-get-message-list'

interface UseDeleteMessageInput {
  onSuccessCallback?: VoidFunction
  onErrorCallback?: VoidFunction
}

export const useDeleteMessage = ({ onSuccessCallback, onErrorCallback }: UseDeleteMessageInput) => {
  const queryClient = useQueryClient()
  return useMutation<Message, Error, DeleteMessageInput>(
    ({ messageId }) => deleteMessage({ messageId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MESSAGE_LIST])
        onSuccessCallback?.()
      },
      onError: () => {
        onErrorCallback?.()
      },
    }
  )
}
