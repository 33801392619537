import React, { FC } from 'react'
import { LayoutProps } from 'styled-system'
import { Button, Container, Heading, useModal } from 'ui'

export interface ConfirmationModalProps extends LayoutProps {
  title: string
  children: JSX.Element
  cancelButton?: string
  submitButton?: string
  onSubmit?: VoidFunction
  isDisabled?: boolean
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  children,
  cancelButton,
  submitButton,
  onSubmit,
  isDisabled,
  ...stylesProps
}) => {
  const { closeModal } = useModal()

  return (
    <Container {...stylesProps}>
      <Heading as="h2" mb="0.8rem">
        {title}
      </Heading>
      {children}
      <Container display="flex" flexGap="1.3rem">
        {closeModal && cancelButton && (
          <Button variant="secondary" width="100%" disabled={isDisabled} onClick={closeModal}>
            {cancelButton}
          </Button>
        )}
        {onSubmit && submitButton && (
          <Button type="submit" width="100%" disabled={isDisabled} onClick={onSubmit}>
            {submitButton}
          </Button>
        )}
      </Container>
    </Container>
  )
}
