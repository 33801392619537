import { messagesApi } from './instance'
import { Message } from './types/message'

export interface DeleteMessageReactionArgs {
  messageId: string
  reactionCode: string
}

export const deleteMessageReaction = async ({
  messageId,
  reactionCode,
}: DeleteMessageReactionArgs) => {
  const { data } = await messagesApi.delete<Message>(`/${messageId}/reactions/${reactionCode}`)
  return data
}
