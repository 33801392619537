import { PositionProps, SpaceProps } from 'styled-system'

export enum StatusColorVariant {
  ATTENTION = 'attention',
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface StatusStylesProps {
  variant?: StatusColorVariant
}

export interface StatusProps {
  label: string
}

export type GeneralStatusProps = StatusStylesProps & StatusProps & SpaceProps & PositionProps

export type StatusLabelProps = StatusStylesProps & SpaceProps

export interface StatusParsedProperties {
  background: string
  color: string
}
