import { useGetChannels } from '@api/chats/hooks/use-get-channels'
import { useBannersContext } from '@contexts/banners-provider'
import { useSkeleton } from '@hooks/use-skeleton'
import { ChannelListWithSkeleton } from '@modules/channel-list'
import { EmptyResults } from '@modules/empty-results'
import { CreateChannelModal } from '@modules/modals/create-channel'
import { getContainerHeight } from '@utils/get-container-height'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { BasicSearch, Button, Container, Heading, PlusIcon, RoundedContainer, useModal } from 'ui'

export const ChannelBrowser: FC = () => {
  const { t } = useTranslation('channel-browser')
  const [searchString, setSearchString] = useState<string>('')
  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()
  const {
    data: channels,
    isLoading: isChannelsLoading,
    isFetching: isChannelsFetching,
  } = useGetChannels({ search: searchString })
  const { openModal } = useModal()
  const { hideSkeleton, showSkeleton } = useSkeleton()

  const isLoading = isChannelsLoading || isChannelsFetching

  const onCreateChannelClick = useCallback(() => {
    openModal({ content: <CreateChannelModal /> })
  }, [openModal])

  useEffect(() => {
    if (isLoading) {
      showSkeleton(SkeletonComponentNames.CHANNEL_LIST_1)
    } else {
      hideSkeleton(SkeletonComponentNames.CHANNEL_LIST_1)
    }
  }, [isLoading])

  if (!channels) {
    return null
  }

  return (
    <PageContainer
      internetConnectionBannerShown={internetConnectionBannerShown}
      notificationsBannerShown={notificationsBannerShown}
    >
      <RoundedContainer
        display="flex"
        flexDirection="column"
        p="2rem 2rem 0"
        height="100%"
        overflow="hidden"
      >
        <Container display="flex" justifyContent="space-between" mb="1.6rem">
          <Heading> {t('channelBrowser')}</Heading>
          <Button
            variant="secondary"
            preFix={<PlusIcon />}
            size="sm"
            onClick={onCreateChannelClick}
          >
            {t('createChannel')}
          </Button>
        </Container>
        <BasicSearch
          name="searchChannel"
          id="searchChannel"
          placeholder={t('searchByChannelName')}
          hiddenLabel={t('searchByChannelName')}
          searchString={searchString}
          setSearchString={setSearchString}
          mb="1.6rem"
        />
        {!isLoading && channels.length === 0 ? (
          <EmptyResults title={t('emptyResults.title')} text={t('emptyResults.text')} />
        ) : (
          <ChannelListWithSkeleton
            channels={channels}
            componentName={SkeletonComponentNames.CHANNEL_LIST_1}
          />
        )}
      </RoundedContainer>
    </PageContainer>
  )
}

const PageContainer = styled(Container)<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  display: flex;
  flex-direction: column;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
  padding: 2rem;
`
