import { User } from '@api/account/types/user'
import { InViewTrigger } from '@components/in-view-trigger'
import { EmptyResults } from '@modules/empty-results'
import { InviteWsMembersModal } from '@modules/modals/invite-ws-members'
import { Skeleton } from '@modules/skeleton'
import { SettingsListItem } from '@pages/workspace'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicSearch, Button, Container, PlusIcon, ScrollContainer, useModal } from 'ui'
import { MemberItem } from './member-item'
import { SortingHeader } from './sorting-header'

interface Props {
  setIsInView: (c: boolean) => void
  isLoading: boolean
  users: User[]
}

export const WorkspaceMembers: FC<Props> = ({ setIsInView, isLoading, users }) => {
  const [searchString, setSearchString] = useState<string>('')
  const { openModal } = useModal()
  const { t } = useTranslation('workspace')

  // Filter members by starting characters of firstName or lastName
  const filterMembers = useMemo(() => {
    return users.filter((user) => {
      return (
        user.firstName.toLowerCase().startsWith(searchString) ||
        user.lastName.toLowerCase().startsWith(searchString)
      )
    })
  }, [searchString, users])

  const isEmptyUsersArray = filterMembers.length === 0 && !isLoading

  return (
    <Container position="relative" height="100%">
      <Container position="absolute" top="-5.6rem" right="0">
        <Button
          size="sm"
          preFix={<PlusIcon />}
          minWidth="17.7rem"
          onClick={() =>
            openModal({
              content: <InviteWsMembersModal />,
              maxWidth: '62rem',
            })
          }
        >
          {t('inviteMembers')}
        </Button>
      </Container>
      <BasicSearch
        id="search_ws_member"
        name="search_ws_member"
        hiddenLabel={t('searchByNameAndSurname')}
        placeholder={t('searchByNameAndSurname')}
        setSearchString={setSearchString}
        searchString={searchString}
      />
      {!isEmptyUsersArray ? (
        <>
          <SortingHeader pt="1.6rem" />
          <ScrollContainer hideScrollbar>
            <Container pb={isLoading ? 0 : '8rem'}>
              {filterMembers.map((member) => (
                <SettingsListItem key={member.userId}>
                  <MemberItem {...member} />
                </SettingsListItem>
              ))}
            </Container>
            {isLoading && <Skeleton type="workspaceMembers" length={3} />}
            <InViewTrigger onChange={setIsInView} />
          </ScrollContainer>
        </>
      ) : (
        <EmptyResults title={t('emptyMembersTitle')} />
      )}
    </Container>
  )
}
