import React from 'react'

export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.771 2c-.752 0-1.47.322-1.982.87A2.946 2.946 0 0 0 3 4.888V18.11c0 .75.276 1.473.79 2.02.512.547 1.23.869 1.981.869h3.542a1 1 0 0 0 1-1 1 1 0 0 0-1-1H5.77a.708.708 0 0 1-.523-.236A.965.965 0 0 1 5 18.11V4.89c0-.253.097-.491.248-.653A.708.708 0 0 1 5.771 4h3.542a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm10.272 4.543a1 1 0 0 0 0 1.414l2.543 2.543H9.313a1 1 0 0 0-1 1 1 1 0 0 0 1 1h9.273l-2.543 2.543a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l4.25-4.25a1 1 0 0 0 .26-.627A1 1 0 0 0 22 11.5a1 1 0 0 0-.033-.166 1 1 0 0 0-.028-.143 1 1 0 0 0-.232-.398l-4.25-4.25a1 1 0 0 0-1.414 0z" />
  </svg>
)
