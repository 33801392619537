import { MyWorkspace } from '@api/workspaces/types'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { LayoutProps, SpaceProps } from 'styled-system'
import { Avatar, Button, Container, Heading, Text } from 'ui'
import { InvitationValidSkeleton } from './skeleton'

interface InvitationValidProps extends SpaceProps, LayoutProps {
  workspace?: MyWorkspace
  onJoin: VoidFunction
}

export const InvitationValid: FC<InvitationValidProps> = ({
  workspace,
  onJoin,
  ...stylesProps
}) => {
  const { t } = useTranslation('invitation')

  if (!workspace) {
    return null
  }

  return (
    <Container textAlign="center" {...stylesProps}>
      <Avatar
        bgColor={`#${workspace.color}`}
        imgUrl={workspace.avatar?.mediaUri}
        label={workspace.name}
        size="8rem"
        borderRadius="1.2rem"
        fontSize="4rem"
        m="0 auto 3.2rem"
      />
      <Heading variant="h0" mb="1.2rem">
        {t('joinWorkspace', {
          workspaceName: workspace.name,
        })}
      </Heading>
      <Text mb="4rem"> {t('teammatesWaiting')}</Text>
      <Button width="33.6rem" onClick={onJoin}>
        {t('join')}
      </Button>
    </Container>
  )
}

export const InvitationValidWithSkeleton = withSkeleton(
  InvitationValid,
  <InvitationValidSkeleton />
)
