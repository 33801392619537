import { ME } from '@api/account/tags'
import { PersonalProfile } from '@api/account/types/personal-profile'
import { useQueryClient } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, InputVariant, LimitInputContainer, LimitTextField, SendIcon } from 'ui'
import { WsInitialValues } from '../data'
import { UserInitialValues } from '../data'
import { StyledContainer, SubmitButton } from '../styled'

interface OnboardingPersonalProps extends SpaceProps {
  moveToNextStep: VoidFunction
}

export const OnboardingPersonal: FC<OnboardingPersonalProps> = ({ moveToNextStep, ...spacing }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('onboarding')
  const { values, errors, handleChange, handleBlur, setTouched, touched } = useFormikContext<
    WsInitialValues | UserInitialValues
  >()

  /**
Handler function to submit the form on pressing enter key
@param {KeyboardEvent} event - Keyboard event object
@returns {void}
*/
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setTouched({ firstName: true, lastName: true })
      if (!errors.firstName && !errors.lastName) {
        handleOnClick(values.firstName, values.lastName)
      }
    }
  }

  /**
Handler function to update user's first and last name in the app state and move to the next onboarding step
@param {string} firstName - User's first name
@param {string} lastName - User's last name
@returns {void}
*/
  const handleOnClick = (firstName: string, lastName: string) => {
    const previousMeData = queryClient.getQueryData<PersonalProfile>([ME])
    queryClient.setQueryData([ME], {
      ...previousMeData,
      firstName,
      lastName,
    })
    moveToNextStep()
  }

  // Add keydown event listener to submit form on pressing enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <Container {...spacing}>
      <StyledContainer>
        <LayoutContainer>
          <LimitTextField
            id="firstName"
            name="firstName"
            placeholder={t('name')}
            hiddenLabel={t('name')}
            maxCharacters={100}
            variant={InputVariant.GHOST}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
            helperText={errors.firstName && touched.firstName ? errors.firstName : undefined}
            width="100%"
          />
          <LimitTextField
            id="lastName"
            name="lastName"
            placeholder={t('surname')}
            hiddenLabel={t('surname')}
            maxCharacters={100}
            variant={InputVariant.GHOST}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.lastName && touched.lastName ? errors.lastName : undefined}
            width="100%"
          />
        </LayoutContainer>
        <SubmitButton
          variant="primary"
          hiddenLabel={t('submit')}
          circle
          disabled={errors.firstName || errors.lastName ? true : false}
          onClick={() => handleOnClick(values.firstName, values.lastName)}
          icon={<SendIcon />}
        />
      </StyledContainer>
    </Container>
  )
}

const LayoutContainer = styled(Container)`
  display: flex;

  & > * + * {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      display: block;
      width: 0.1rem;
      height: 3.6rem;
      background: ${({ theme }) => theme.colors.grey};
    }
  }
`
