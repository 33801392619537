import { PersonalProfile } from '@api/account/types/personal-profile'
import { User } from '@api/account/types/user'
import { ChatUser } from '@api/chats/types/chat-user'

const getRecipientOnlineStatus = (
  chatUsers: ChatUser[],
  workspaceUsers: User[],
  me: PersonalProfile | null
) => {
  if (!chatUsers || !workspaceUsers || !me) return 0

  const recipientId = chatUsers?.find((user) => user.user.userId)?.user.userId
  const recipientUser = workspaceUsers?.find((user) => user.userId === recipientId)

  return recipientUser?.online.status || 0
}

export { getRecipientOnlineStatus }
