import { WORKSPACE_TOKEN } from '@api/workspaces/tags'
import { GetWorkspaceTokenParams } from '@api/workspaces/types'
import { useQuery } from '@tanstack/react-query'
import { getWorkspaceToken } from '../get-workspace-token'

export const useWorkspaceToken = ({ id, enabled }: GetWorkspaceTokenParams) => {
  const {
    data: workspaceData,
    isLoading: isWorkspaceLoading,
    isSuccess,
    isError,
    error: workspaceError,
    isFetching: isWorkspaceFetching,
    refetch,
  } = useQuery<{ accessToken: string }>([WORKSPACE_TOKEN, id], () => getWorkspaceToken({ id }), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled,
  })

  return {
    workspaceData,
    isWorkspaceLoading,
    workspaceError,
    refetch,
    isWorkspaceFetching,
    isSuccess,
    isError,
  }
}
