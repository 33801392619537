import { locale } from '@modules/conversation-body/utils'
import { formatRelative } from 'date-fns'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Container, Text } from 'ui'

interface MessageInfoProps {
  name: string
  lastModifiedAt: Date
  lastMessage?: string
}

export const MessageInfo: FC<MessageInfoProps> = ({ name, lastModifiedAt, lastMessage }) => {
  const getLastModifiedDate = () => {
    const date = new Date(lastModifiedAt)
    const now = new Date()
    return formatRelative(date, now, { locale })
  }

  return (
    <Container display="flex" justifyContent="space-between" width="100%">
      <Container display="flex" flexDirection="column" ml="1.2rem">
        <Text variant="textMedium">{name}</Text>
        <LastMessage variant="smallTextRegular" mt="0.4rem">
          {lastMessage}
        </LastMessage>
      </Container>
      <Text variant="smallTextRegular" color="greyDark">
        {getLastModifiedDate()}
      </Text>
    </Container>
  )
}

const LastMessage = styled(Text)`
  white-space: nowrap;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
`
