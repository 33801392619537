/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkspaceUserStatus {
    ALLOWED = 'Allowed',
    INVITED = 'Invited',
    ACTIVATED = 'Activated',
    DEACTIVATED = 'Deactivated',
}
