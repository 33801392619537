import { messagesApi } from './instance'
import { MessageDetail } from './types/message-detail'

export interface GetMessageListProps {
  chatId?: string
  pageNumber?: number
  pageSize?: number
}

export interface GetMessageListResponse {
  chatId?: string
  messages: MessageDetail[]
  pages: number
  messagesCount: number
  currentPage: number
}

export const getMessageList = async ({
  chatId,
  pageNumber = 1,
  pageSize = 50,
}: GetMessageListProps) => {
  const { data } = await messagesApi.get<GetMessageListResponse>(`/list`, {
    params: { ChatId: chatId, PageNumber: pageNumber, PageSize: pageSize },
  })
  return data
}
