import { useCreateWorkspace } from '@api/workspaces/hooks/use-create-workspace'
import { useWorkspaceToken } from '@api/workspaces/hooks/use-workspace-token'
import { useWorkspace } from '@hooks/use-workspace'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container } from 'ui'

interface DecisionBtnsProps {
  moveToNextStep: VoidFunction
  domainName: string
}

export const DecisionButtons: FC<DecisionBtnsProps> = ({ moveToNextStep, domainName }) => {
  const { t } = useTranslation('common')
  const { workspaceName, workspaceId, setAllowJoinByDomain } = useWorkspace()
  const { createWorkspace } = useCreateWorkspace({
    disableToast: true,
    successCallbackFn: moveToNextStep,
  })

  useWorkspaceToken({
    id: workspaceId,
    enabled: workspaceId.length > 0,
  })

  /**
@description A function that creates a workspace with the given name and sets the workspace ID
@param {boolean} allowJoinByDomain - A boolean indicating whether the workspace can be joined by domain
@returns {void}
*/
  const handleOnClick = (joinByDomain: boolean) => {
    setAllowJoinByDomain(joinByDomain)
    const newWorkspaceData = {
      name: workspaceName,
      domains: joinByDomain ? [domainName] : undefined,
    }
    createWorkspace(newWorkspaceData)
  }

  return (
    <Container display="flex" flexGap="0.8rem" maxWidth="40rem" mx="auto">
      <Button variant="secondary" width="100%" onClick={() => handleOnClick(false)}>
        {t('no')}
      </Button>
      <Button variant="secondary" width="100%" onClick={() => handleOnClick(true)}>
        {t('yes')}
      </Button>
    </Container>
  )
}
