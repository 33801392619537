import React from 'react'

export default function ArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="current_color"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893C9.90237 0.683418 9.90237 1.31658 10.2929 1.70711L12.5858 4L1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6L12.5858 6L10.2929 8.29289C9.90237 8.68342 9.90237 9.31658 10.2929 9.70711C10.6834 10.0976 11.3166 10.0976 11.7071 9.70711L15.7065 5.70774C15.7069 5.70735 15.7073 5.70696 15.7076 5.70658L16.4142 5L15.7071 4.29289L11.7071 0.292893Z"
        fill="#A9A49C"
      />
    </svg>
  )
}
