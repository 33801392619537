import React, { FC, useMemo, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { Container } from '../container'
import { StatusBadge } from '../status-badge'
import { Text } from '../typography'
import { DeletedAvatar } from './deleted'
import {
  AvatarStatusBadgeProps,
  AvatarImageProps,
  AvatarVariant,
  GeneralAvatarProps,
  ImagePlaceholderProps,
} from './types'

const ImagePlaceholder: FC<ImagePlaceholderProps> = ({ label, firstName, fontSize }) => {
  return (
    <Text as="span" fontSize={fontSize || '1.6rem'}>
      {label || firstName?.charAt(0).toUpperCase()}
    </Text>
  )
}

function parseAvatarStyles(variant?: AvatarVariant, borderRadius?: string) {
  switch (variant) {
    case AvatarVariant.SQUARE:
      return css`
        border-radius: ${borderRadius || '0.8rem'};
      `
    case AvatarVariant.CIRCLE:
    default:
      return css`
        border-radius: 50%;
      `
  }
}

export const DEFAULT_SIZE = '3.6rem'

export const Avatar: FC<GeneralAvatarProps> = ({
  variant,
  label,
  name,
  bgColor,
  imgUrl,
  size,
  fontSize,
  iconSize,
  flag,
  status,
  borderRadius,
  isDeleted,
  firstName,
  lastName,
  icon,
  isAvatarExists,
  ...spacing
}) => {
  const theme = useTheme()

  const imgRef = React.useRef<HTMLImageElement>(null)

  const [isError, setIsError] = useState(false) //fallback to initials if image fails to load
  const [isLoaded, setIsLoaded] = useState(false)

  const getBgColor = useMemo(() => {
    if (!isLoaded && isAvatarExists) {
      return theme.colors.greyLight
    }

    if (bgColor && bgColor.slice(0, 2) === '##') {
      return `#${bgColor.substring(2)}`
    } else {
      return bgColor
    }
  }, [bgColor, isLoaded, isAvatarExists])

  const getLabelLetter = useMemo(() => {
    if (!isLoaded && isAvatarExists) {
      return ''
    }

    if (!label) return ''
    return label.slice(0, 1).toUpperCase()
  }, [label, isLoaded, isAvatarExists])

  return (
    <Container
      minHeight={size || DEFAULT_SIZE}
      minWidth={size || DEFAULT_SIZE}
      width={size || DEFAULT_SIZE}
      height={size || DEFAULT_SIZE}
      color="white"
      {...spacing}
      position="relative"
    >
      <AvatarImageContainer
        backgroundColor={isDeleted ? theme.colors.grey : getBgColor}
        variant={variant || AvatarVariant.SQUARE}
        borderRadius={borderRadius}
      >
        {isDeleted ? (
          <DeletedAvatar iconSize={iconSize} flag={flag} />
        ) : (
          <>
            {(!imgUrl || isError || !isLoaded) && (
              <ImagePlaceholder label={getLabelLetter} firstName={firstName} fontSize={fontSize} />
            )}
            <img
              ref={imgRef}
              src={imgUrl}
              alt={name || `${firstName} ${lastName}`}
              width="100%"
              height="100%"
              style={{
                display: isError || !imgUrl || !isLoaded ? 'none' : 'block',
                objectFit: 'cover',
              }}
              onError={() => {
                setIsError(true)
              }}
              onLoad={() => {
                setIsLoaded(true)
              }}
            />
          </>
        )}
        {icon && icon}
      </AvatarImageContainer>
      {status && !isDeleted && (
        <StatusBadgeContainer>
          <StatusBadge status={status} />
        </StatusBadgeContainer>
      )}
    </Container>
  )
}

const AvatarImageContainer = styled(Container)<AvatarImageProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => parseAvatarStyles(props.variant, props.borderRadius)};
`

const StatusBadgeContainer = styled.span<AvatarStatusBadgeProps>`
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  height: 1.2rem;
  width: 1.2rem;
`
