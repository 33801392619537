import { setPersonalLanguages } from '@api/account/set-personal-languages'
import { ME } from '@api/account/tags'
import { UserLanguage } from '@api/account/types/language'
import { User } from '@api/account/types/user'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface useSetPersonalLanguagesProps {
  disableToast?: boolean
}

export const useSetPersonalLanguages = ({
  disableToast = false,
}: useSetPersonalLanguagesProps = {}) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (languageData: UserLanguage[]) =>
      setPersonalLanguages(languageData.map((l) => ({ code: l.code, isPrimary: l.isPrimary }))),
    onMutate: async (languageData) => {
      await queryClient.cancelQueries([ME])
      const previousMeData = queryClient.getQueryData<User>([ME])
      queryClient.setQueryData([ME], {
        ...previousMeData,
        activeLanguages: languageData,
      })
      return { previousMeData }
    },
    onError: (e, v, context) => {
      if (!disableToast) {
        toast.error(`${t('changesNotSaved')}.`)
      }
      queryClient.setQueryData([ME], context?.previousMeData)
    },
    onSuccess: () => {
      if (!disableToast) {
        toast.success(`${t('changesSaved')}.`)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([ME])
    },
  })

  return { mutate, isLoading, isSuccess, isError }
}
