import { useWorkspaceToken } from '@api/workspaces/hooks/use-workspace-token'
import { DateDivider } from '@components/date-divider'
import { DotsLoader } from '@components/dots-loader'
import { LogoHeader } from '@components/logo-header'
import { OnboardingType } from '@contexts/workspace-provider'
import { useWorkspace } from '@hooks/use-workspace'
import { ChatBodyContainer } from '@modules/conversation-body/styled'
import { OnboardingForm } from '@modules/forms/onboarding'
import { OnboardingChatUser } from '@modules/onboarding-chat/user'
import { OnboardingChatWs } from '@modules/onboarding-chat/workspace'
import React, { useState, useEffect, FC, ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container } from 'ui'

export const Onboarding: FC = () => {
  const { workspaceId, onboardingType } = useWorkspace()
  const {} = useWorkspaceToken({
    id: workspaceId,
    enabled: onboardingType === OnboardingType.USER,
  })
  const [bottomComponent, setBottomComponent] = useState<ReactNode>(null)
  const { t } = useTranslation(['onboarding', 'common'])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [calculatedHeight, setCalculatedHeight] = useState('')
  const formRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (formRef.current) {
      const containerHeight = '100%' // Assuming this is the desired height of the container
      const formHeight = formRef.current.offsetHeight // Get the height of the form element

      // Update the state with the calculated height
      setCalculatedHeight(`calc(${containerHeight} - ${formHeight}px)`)
    }
  }, [bottomComponent])

  return (
    <Container display="flex" flexDirection="column" justifyContent="center" height="100%">
      {isLoading ? (
        <DotsLoader text={t('warmingEngines', { ns: 'common' })} />
      ) : (
        <>
          <LogoHeader />
          <FakeChatBodyContainer p="2.4rem 2rem" hideScrollbar flex={`1 1 ${calculatedHeight}`}>
            <DateDivider date={t('today')} m="0 auto 0.4rem" />
            {onboardingType === OnboardingType.USER ? (
              <OnboardingChatUser
                setBottomComponent={setBottomComponent}
                height={calculatedHeight}
              />
            ) : (
              <OnboardingChatWs setBottomComponent={setBottomComponent} height={calculatedHeight} />
            )}
          </FakeChatBodyContainer>
        </>
      )}
      <Container flex="0 1 auto" p="2rem" ref={formRef}>
        <OnboardingForm
          bottomComponent={bottomComponent}
          onboardingType={onboardingType}
          setIsLoading={setIsLoading}
        />
      </Container>
    </Container>
  )
}

export const FakeChatBodyContainer = styled(ChatBodyContainer)`
  flex-direction: column;
`
