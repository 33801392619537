import { TFunction } from '@utils/i18n/types'
import timezones from 'timezones.json'
import * as Yup from 'yup'

export const timezoneOptions = timezones.map((timezone) => ({
  value: timezone.value,
  label: timezone.text,
}))

// Yup validation

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

const emptyNameError = 'validation.emptyNameError'
const emptySurnameError = 'validation.emptySurnameError'
const emptyEmailError = 'validation.emptyEmailError'
const invalidEmailError = 'validation.invalidEmailError'
const invalidPhoneError = 'validation.invalidPhoneError'
const minDigitsPhoneError = 'validation.minDigitsPhoneError'
const maxDigitsPhoneError = 'validation.maxDigitsPhoneError'

export const personalValidationSchema = (t: TFunction) => () =>
  Yup.object().shape({
    firstName: Yup.string().required(t(emptyNameError)),
    lastName: Yup.string().required(t(emptySurnameError)),
    phoneNumber: Yup.string()
      .min(7, t(minDigitsPhoneError))
      .max(15, t(maxDigitsPhoneError))
      .matches(phoneRegExp, t(invalidPhoneError)),
    email: Yup.string().required(t(emptyEmailError)).email(t(invalidEmailError)),
    jobTitle: Yup.string(), //.required(),
    timezone: Yup.object().shape({
      id: Yup.string(),
      displayName: Yup.string(),
    }),
  })
