import { RefObject, useEffect } from 'react'

export const useAutoScroll = (scrollRef: RefObject<HTMLElement>, dependencies: any[]) => {
  useEffect(() => {
    const scroll = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    scroll()
  }, [scrollRef, ...dependencies])
}
