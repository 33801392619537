import { useAppDispatch, useAppSelector } from '@app/hooks'
import { SortingIcon } from '@components/icons'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, SortingButton, SortingDownIcon, SortingUpIcon, Text } from 'ui'
import { setSortingType, SortingType } from '../../../features/user-sorting/slice'

const SORTING_ICONS = {
  default: <SortingIcon />,
  up: <SortingUpIcon />,
  down: <SortingDownIcon />,
}

export const SortingHeader: FC<SpaceProps> = ({ ...spaceProps }) => {
  const { t } = useTranslation('workspace')
  const { sortingIcons } = useAppSelector((state) => state.userSorting)
  const dispatch = useAppDispatch()

  const toggleSortBy = (sortBy: SortingType) => {
    dispatch(setSortingType(sortBy))
  }

  return (
    <SortingHeaderContainer {...spaceProps}>
      <Container>
        <SortingButton
          onClick={() =>
            toggleSortBy(
              sortingIcons.name === 'down' ? SortingType.NAME_REVERSED : SortingType.NAME
            )
          }
          icon={SORTING_ICONS[sortingIcons.name]}
        >
          {t('member')}
        </SortingButton>
      </Container>
      <Container>
        <Text variant="smallTextMedium" color="greyDark">
          {t('role')}
        </Text>
      </Container>
      <Container>
        <SortingButton
          onClick={() =>
            toggleSortBy(
              sortingIcons.joiningDate === 'down' ? SortingType.JOINED_REVERSED : SortingType.JOINED
            )
          }
          icon={SORTING_ICONS[sortingIcons.joiningDate]}
        >
          {t('joiningDate')}
        </SortingButton>
      </Container>
    </SortingHeaderContainer>
  )
}

const SortingHeaderContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1.25fr 1fr 1fr 2.5rem;
  gap: 2.4rem;
  width: 100%;
  align-items: center;
`
