import React, { createContext, useState, ReactNode } from 'react'

type SkeletonsCurrentVisibility = {
  [componentName: string]: boolean
}

type SkeletonContextType = {
  skeletonStates: SkeletonsCurrentVisibility
  registerComponent: (componentName: string) => void
  isSkeletonVisible: (componentName: string) => boolean
  showSkeleton: (componentName: string) => void
  hideSkeleton: (componentName: string) => void
}

type SkeletonProviderProps = {
  children: ReactNode
}

const initData: SkeletonContextType = {
  skeletonStates: {},
  registerComponent: () => {},
  isSkeletonVisible: () => false,
  showSkeleton: () => {},
  hideSkeleton: () => {},
}

export const SkeletonContext = createContext<SkeletonContextType>(initData)

export const SkeletonProvider = ({ children }: SkeletonProviderProps) => {
  const [skeletonStates, setSkeletonStates] = useState<SkeletonsCurrentVisibility>({})

  const registerComponent = (componentName: string) => {
    setSkeletonStates((prevState) => {
      return { ...prevState, [componentName]: false }
    })
  }

  const isSkeletonVisible = (componentName: string) => skeletonStates[componentName]

  const showSkeleton = (componentName: string) => {
    setSkeletonStates((prevState) => {
      if (!prevState[componentName]) {
        return {
          ...prevState,
          [componentName]: true,
        }
      }
      return prevState
    })
  }

  const hideSkeleton = (componentName: string) => {
    setSkeletonStates((prevState) => {
      if (prevState[componentName]) {
        return {
          ...prevState,
          [componentName]: false,
        }
      }
      return prevState
    })
  }

  return (
    <SkeletonContext.Provider
      value={{ skeletonStates, isSkeletonVisible, registerComponent, showSkeleton, hideSkeleton }}
    >
      {children}
    </SkeletonContext.Provider>
  )
}
