import { chatsApi } from './instance'

export interface DeleteChannelProps {
  channelId: string
}

export const deleteChannel = async ({ channelId }: DeleteChannelProps) => {
  const { data } = await chatsApi.delete(`/${channelId}`)
  return data
}
