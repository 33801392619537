import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, ImgContainer, Heading, Text, useModal } from 'ui'

export const SentInvitationModal: FC = () => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-sent-invitation')

  return (
    <Container textAlign="center">
      <ImgContainer maxWidth="26.1rem" m="0 auto 2rem">
        <img src="/img/sent.svg" alt="Sending" width={261} height={156} />
      </ImgContainer>
      <Heading as="h2" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text mb="4rem">{t('subtitle')}</Text>
      <Button type="submit" width="100%" onClick={closeModal}>
        {t('submitButton')}
      </Button>
    </Container>
  )
}
