import { getChatDetails } from '@api/chats/get-chat-details'
import { CHAT_LIST } from '@api/chats/query-keys'
import { ConversationList } from '@api/chats/types/chat-list'
import { QueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'

interface CacheData {
  pages: ConversationList[]
}

interface SignalRChat {
  chatId: string
  createdAt: string
  createdBy: string
  lastModifiedAt: string
  chatType: number
  chatName: string
  chatAvatarUrl: string | null
  isGroupChat: boolean
  joiningUsers: string[]
}

export const handleNewUserJoinedChat = (queryClient: QueryClient) => async (chat: SignalRChat) => {
  try {
    const newChat = await getChatDetails({ chatId: chat.chatId })
    queryClient.setQueryData([CHAT_LIST], (oldData: CacheData | undefined | null) => {
      if (!oldData) return null

      const newData = cloneDeep(oldData)
      if (newChat) {
        newData.pages[newData.pages.length - 1].chats.push(newChat)
        newData.pages[newData.pages.length - 1].totalChatsCount =
          newData.pages[newData.pages.length - 1].totalChatsCount + 1
      }
      return newData
    })
  } catch (error) {
    // todo: handle error in sentry
    console.error(error)
  }
}
