import styled from 'styled-components'
import { ListItemButtonProps } from '.'
import { Container } from '../container'
import { Text } from '../typography'

export const ListItemText = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 19.6rem;
  overflow: hidden;
`

export const ListItemTextContainer = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.8rem;
`
export const ListItemButton = styled(Container)<ListItemButtonProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  min-height: 3.2rem;
  padding: 0.4rem 1.6rem;

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.greyLight : 'transparent'};
  width: 100%;
  transition: background-color ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }
`
