import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Heading, ImgContainer, Text } from 'ui'

interface EmptyResultsProps {
  title: string
  text?: string
}

export const EmptyResults: FC<EmptyResultsProps> = ({ title, text }) => {
  const { t } = useTranslation('common')
  return (
    <MainContainer>
      <ImgContainer mb="3.2rem">
        <img src="/img/empty-results.svg" width={200} height={215} alt={t('imgAlt.emptyResults')} />
      </ImgContainer>
      <Heading variant="h2">{title}</Heading>
      {text && <Text mt="0.8rem">{text}</Text>}
    </MainContainer>
  )
}

const MainContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
`
