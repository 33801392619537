import { addUsersToChannel } from '@api/chats/add-users-to-chat'
import { CHAT_LIST } from '@api/chats/query-keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const useAddUsersToChannel = (chatId: string) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { data, mutate, isLoading, isSuccess } = useMutation({
    mutationFn: async (userIds: string[]) => addUsersToChannel({ chatId, userIds }),
    onSuccess: () => {
      toast.success(t('changesSaved') as string) //TODO custom toast
      return queryClient.invalidateQueries([CHAT_LIST, chatId])
    },
    onError: () => {
      toast.error(t('changesNotSaved') as string) //TODO custom toast
    },
  })

  return { data, mutate, isLoading, isSuccess }
}
