import { Reaction } from '@api/messages/types/message-detail'
import { EmojiPopover } from '@components/emoji-popover'
import { useEmojiClickReaction } from '@hooks/use-emoji-click-reaction'
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BorderRadiusProps, HeightProps, PositionProps, SpaceProps } from 'styled-system'
import { HiddenLabel } from 'ui'
import { ActionsContainer, Action } from './styled'

export interface QuickAction {
  actionName: string
  actionIcon: JSX.Element
  onActionClick?: VoidFunction
  isDisabled?: boolean
  isReaction?: boolean
}

interface QuickActionsProps extends PositionProps, SpaceProps, BorderRadiusProps, HeightProps {
  actions: QuickAction[]
  isSender?: boolean
  messageId?: string
  setIsHoveringOverQuickActions: Dispatch<SetStateAction<boolean>>
  reactionCount?: number
  reactions?: Reaction[]
  clientMessageId?: string
}

export const QuickActions: FC<QuickActionsProps> = ({
  actions,
  messageId,
  reactionCount,
  setIsHoveringOverQuickActions,
  reactions,
  isSender,
  clientMessageId,
  ...stylesProps
}) => {
  const { t } = useTranslation('chat')
  const { onEmojiClick } = useEmojiClickReaction({
    t,
    messageId,
    reactions,
    reactionCount,
  })

  const crossAxis = useMemo(() => (isSender ? -10 : 108), [isSender])

  return (
    <ActionsContainer
      {...stylesProps}
      onMouseOver={() => setIsHoveringOverQuickActions(true)}
      onMouseOut={() => setIsHoveringOverQuickActions(false)}
    >
      {actions.map(({ actionIcon, actionName, onActionClick, isDisabled, isReaction }) => {
        if (isDisabled) return null

        if (!isReaction) {
          return (
            <Action key={actionName} onClick={onActionClick}>
              {actionIcon}
              <HiddenLabel>{actionName}</HiddenLabel>
            </Action>
          )
        }

        return (
          <EmojiPopover
            key={actionName}
            onEmojiClick={onEmojiClick}
            floatProps={{ offset: { crossAxis } }}
            py="1.2rem"
          >
            <Action as="span">
              {actionIcon}
              <HiddenLabel>{actionName}</HiddenLabel>
            </Action>
          </EmojiPopover>
        )
      })}
    </ActionsContainer>
  )
}
