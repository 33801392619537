import { MessageInput } from '@components/message-input'
import { GeneralMessageProps, MessageVariant } from '@components/message/index'
import { useMessageBulb } from '@hooks/use-message-bulb'
import { QuickActions } from '@modules/quick-actions'
import React from 'react'
import { Container, Text } from 'ui'
import { MessageBody, MessageText, Spacer } from './styled'

export const NotSentMessage: React.FC<GeneralMessageProps> = (props) => {
  const { messageId, text } = props
  const {
    onMouseLeave,
    onMouseEnter,
    messageActionsShow,
    setIsHoveringOverQuickActions,
    messageActions,
    spacerWidth,
    messageEditProps,
    shouldShowEditableBulb,
    getMessageFooter,
  } = useMessageBulb(props, MessageVariant.NOT_SENT)

  if (shouldShowEditableBulb) {
    return (
      <Container width="70%">
        <MessageInput {...messageEditProps} />
      </Container>
    )
  }

  return (
    <MessageBody
      isSender={false}
      variant={MessageVariant.NOT_SENT}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {messageActionsShow && (
        <QuickActions
          borderRadius="1.6rem"
          height="4rem"
          top="-3.2rem"
          right="0rem"
          padding="0.8rem 1.2rem"
          actions={messageActions}
          messageId={messageId}
          setIsHoveringOverQuickActions={setIsHoveringOverQuickActions}
        />
      )}
      <MessageText>
        <Text>
          {text} <Spacer width={spacerWidth} />
        </Text>
      </MessageText>
      <>{getMessageFooter({ showTimestamp: false })}</>
    </MessageBody>
  )
}
