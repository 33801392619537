import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Authenticator, cookieStorage } from './auth/oauth/auth-service'

export const getWorkspaceTokenFromStorage = () => {
  return cookieStorage.getItem(`oidc.user:${process.env.IDENTITY_SERVER_URL}:native-flow`)
}

export const getToken = () => {
  return sessionStorage.getItem(`workspace.token`)
}

export const onFulfilled = (response: AxiosResponse) => {
  return camelcaseKeys(response, { deep: true })
}

export const onRejected = (error?: any) => {
  if (error instanceof AxiosError && error.response?.status === 401) {
    Authenticator.userManager.signinSilent()
  }
  throw camelcaseKeys(error, { deep: true })
}

export const setWorkspaceAuthHeaders = (config: AxiosRequestConfig) => {
  const workspaceToken = getWorkspaceTokenFromStorage()
  if (workspaceToken && config?.headers) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(workspaceToken).access_token}`
    return config
  }
  return config
}

export const setAuthHeaders = (config: AxiosRequestConfig) => {
  if (getWorkspaceTokenFromStorage() && config?.headers) {
    config.headers['Authorization'] = `Bearer ${getToken()}`
    return config
  }
  return config
}

export const setWorkspaceAccessToken = (config: AxiosRequestConfig) => {
  if (getWorkspaceTokenFromStorage() && config?.headers) {
    config.headers['Authorization'] = `Bearer ${getToken()}`
    config.headers['X-DeviceId'] = 'web_client'
    return config
  }
  return config
}
