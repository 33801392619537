import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { AddChannelMembersModal } from '@modules/modals/add-channel-members'
import { getChatName } from '@utils/get-chat-name'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Button, Container, Heading, ImgContainer, Text, useModal } from 'ui'

export const EmptyChannelMembers: FC = () => {
  const { channelId } = useParams<{ channelId: string }>()
  const { data: chatDetails } = useGetChatDetails(channelId)
  const { openModal } = useModal()
  const { t } = useTranslation(['create-new', 'modal-add-channel-members'])

  if (!chatDetails) {
    return null
  }

  return (
    <Container textAlign="center">
      <ImgContainer mb="4.4rem">
        <img src="/img/onboarding-2.svg" alt="onboarding-2" width={549} height={292} />
      </ImgContainer>
      <Heading variant="h2" as="h2" fontSize="2.2rem" mb="0.8rem">
        {t('create-new:emptyChannelMembers.heading', {
          channelName: getChatName(chatDetails, t),
        })}
      </Heading>
      <Text mb="3rem">{t('create-new:emptyChannelMembers.addMembers')}</Text>
      <Button
        width="28rem"
        onClick={() =>
          openModal({
            content: (
              <AddChannelMembersModal channelName={chatDetails.name} channelId={chatDetails.id} />
            ),
            maxWidth: '62rem',
          })
        }
      >
        {t('create-new:emptyChannelMembers.addBtn')}
      </Button>
    </Container>
  )
}
