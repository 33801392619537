import { useGetMe } from '@api/account/hooks/use-get-me'
import { useJoinChannel } from '@api/chats/hooks/use-join-channel'
import { Channel } from '@api/chats/types/channel'
import { LockIcon, TextIcon } from '@components/icons'
import { LeaveChannelModal } from '@modules/modals/leave-channel'
import { PUBLIC_CHANNEL_TYPES, isGeneralChannelChatType } from '@utils/get-chat-type'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Avatar, Button, Container, Text, useModal } from 'ui'
import { ChannelDetails } from './details'

export const ChannelItem: FC<Channel> = ({
  id,
  avatar,
  name,
  membersCount,
  joined,
  color,
  type,
}) => {
  const { meData } = useGetMe()
  const [quickActionsShown, setQuickActionsShown] = useState(false)
  const { t } = useTranslation('channel-browser')
  const navigate = useNavigate()
  const { openModal } = useModal()
  const navigateToChannel = () => navigate(id)

  const { mutate: joinChannel, isLoading: isJoinChannelLoading } = useJoinChannel({
    conversationId: id,
    onSuccessCallback: navigateToChannel,
    onErrorCallback: () => toast.error(`${t('errors.somethingWentWrong')}`),
  })

  const onMouseEnter = useCallback(() => {
    setQuickActionsShown(true)
  }, [setQuickActionsShown])
  const onMouseLeave = useCallback(() => {
    setQuickActionsShown(false)
  }, [setQuickActionsShown])

  const leaveChannel = (myUserId: string) =>
    openModal({
      content: <LeaveChannelModal channelId={id} name={name} myUserId={myUserId} />,
    })

  return (
    <ChannelItemContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ChannelItemInner>
        <Avatar
          imgUrl={avatar || ''}
          bgColor={`#${color}`}
          icon={PUBLIC_CHANNEL_TYPES.includes(type) ? <TextIcon /> : <LockIcon />}
        />
        <Container ml="1.2rem">
          <Text>{isGeneralChannelChatType(type) ? t('generalChannelName') : name}</Text>
          <ChannelDetails membersAmount={membersCount} isMeActive={joined} />
        </Container>
      </ChannelItemInner>
      {quickActionsShown && (
        <Container display="flex" flexGap="1.2rem">
          <Button
            variant="secondary"
            size="sm"
            onClick={navigateToChannel}
            disabled={isJoinChannelLoading}
            minWidth="12rem"
          >
            {t('view')}
          </Button>
          {!isGeneralChannelChatType(type) &&
            (joined ? (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => (meData ? leaveChannel(meData.userId) : null)}
                minWidth="12rem"
                disabled={isJoinChannelLoading}
              >
                {t('leave')}
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={() => joinChannel(id)}
                minWidth="12rem"
                disabled={isJoinChannelLoading}
              >
                {t('join')}
              </Button>
            ))}
        </Container>
      )}
    </ChannelItemContainer>
  )
}

export const ChannelItemContainer = styled.div`
  display: flex;
  padding: 1.4rem 2rem;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`

const ChannelItemInner = styled(Container)`
  display: flex;
  flex: 1;
`
