import React, { SVGProps } from 'react'

export const TeammatesIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h14a1 1 0 0 0 1-1 1 1 0 0 0-1-1z" />
      <path d="M10 5c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm0 2c1.669 0 3 1.331 3 3s-1.331 3-3 3-3-1.331-3-3 1.331-3 3-3Z" />
      <mask>
        <path d="M16.035 13.864c.143.533.698.858 1.193.612a5 5 0 0 0-.98-9.318c-.534-.138-1.01.296-1.039.847-.029.552.408 1.008.92 1.215a3 3 0 0 1 .553 5.264c-.457.31-.79.846-.647 1.38Z" />
      </mask>
      <path d="M16.035 13.864c.143.533.698.858 1.193.612a5 5 0 0 0-.98-9.318c-.42-.108-.803.136-.962.513a.992.992 0 0 0-.077.334c-.029.552.408 1.008.92 1.215a3 3 0 0 1 .553 5.264c-.457.31-.79.846-.647 1.38z" />
    </svg>
  )
}
