import { CONNECTION_BANNER_HEIGHT } from '@common/constants'
import { useBannersContext } from '@contexts/banners-provider'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, SpinnerDarkAnimation, Text } from 'ui'

export const LostConnectionBanner: FC = () => {
  const { internetConnectionBannerShown } = useBannersContext()

  const { t } = useTranslation(['common'])

  if (!internetConnectionBannerShown) {
    return null
  }

  return (
    <BannerContainer>
      <Container display="flex" justifyContent="center" alignItems="center">
        <SpinnerDarkAnimation width={24} height={24} />
      </Container>
      <Text ml="1.25rem" lineHeight={2}>
        {t('errors.waitingNetwork')}
      </Text>
    </BannerContainer>
  )
}

const BannerContainer = styled(Container)`
  height: ${() => CONNECTION_BANNER_HEIGHT};
  display: flex;
  padding: 0.6rem;
  background-color: ${({ theme }) => theme.colors.primaryLighter};
  justify-content: center;
  align-items: center;
`
