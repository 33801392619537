import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CheckIcon, Text } from 'ui'

interface ChannelDetailsProps {
  membersAmount: number
  isMeActive: boolean
}

export const ChannelDetails: FC<ChannelDetailsProps> = ({ membersAmount, isMeActive }) => {
  const { t } = useTranslation('channel-browser')

  const getMembersCount = useMemo(() => {
    return `${membersAmount} ${membersAmount === 1 ? t('member') : t('members')}`
  }, [membersAmount, t])

  return (
    <ChannelDetailsContainer>
      {isMeActive && (
        <Indicator>
          <CheckIcon />
          <Text color="green" variant="smallTextRegular" ml="0.4rem">
            {t('joined')}
          </Text>
        </Indicator>
      )}
      <Text color="greySuperDark" variant="smallTextRegular">
        {getMembersCount}
      </Text>
    </ChannelDetailsContainer>
  )
}

const ChannelDetailsContainer = styled.div`
  display: flex;

  > :nth-child(2) {
    position: relative;
    padding-left: 1.8rem;
    /* Use the dot character in front on the second flexbox child */
    &::before {
      content: '';
      position: absolute;
      left: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.greySuperDark};
    }
  }
`

const Indicator = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.green};
`
