import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { useAppDispatch } from '@app/hooks'
import { useBannersContext } from '@contexts/banners-provider'
import { useSignalR } from '@hooks/use-signalr'
import { useWorkspace } from '@hooks/use-workspace'
import { getContainerHeight } from '@utils/get-container-height'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { markAsUnread } from '../../../features/chats/slice'
import { NEW_MESSAGE_HAS_BEEN_SENT } from '../../../signalr/events'
import { SignalRMessageResponse } from '../../../signalr/models'
import { SidebarContent } from './sidebar-content'
import { SidebarOnboarding } from './sidebar-onboarding'

export const Sidebar = () => {
  const { workspaceId, isPendingOnboarding } = useWorkspace()
  const { data: workspaceData } = useGetWorkspace({
    id: workspaceId,
  })
  const { meData, refetch: refetchMeData } = useGetMe(true)
  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()

  const { subscribeToEvent, unsubscribeFromEvent, connection } = useSignalR()

  const dispatch = useAppDispatch()

  useEffect(() => {
    subscribeToEvent(NEW_MESSAGE_HAS_BEEN_SENT, (message: SignalRMessageResponse) => {
      dispatch(markAsUnread({ chatId: message.chatId }))
    })
    return () => {
      unsubscribeFromEvent(NEW_MESSAGE_HAS_BEEN_SENT)
    }
  }, [connection])

  useEffect(() => {
    if (!isPendingOnboarding) {
      refetchMeData()
    }
  }, [isPendingOnboarding, refetchMeData])

  return (
    <SidebarContainer
      internetConnectionBannerShown={internetConnectionBannerShown}
      notificationsBannerShown={notificationsBannerShown}
    >
      {isPendingOnboarding || !meData?.isOnboarded ? (
        <SidebarOnboarding workspace={workspaceData} me={meData} />
      ) : (
        <SidebarContent />
      )}
    </SidebarContainer>
  )
}

export const SidebarContainer = styled.div<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  grid-area: sidebar;
  padding: 3rem 0 3.2rem;
  border-radius: 0 1.6rem 1.6rem 0;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
  background-color: ${({ theme }) => theme.colors.white};
  &::after {
    content: '';
    z-index: 3;
    position: absolute;
    background: linear-gradient(0deg, rgba(254, 254, 254, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    height: 5rem;
    pointer-events: none;
    width: 28rem;
    border-radius: 0 1.6rem 1.6rem 0;
  }
`
