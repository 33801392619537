import { usePatchMe } from '@api/account/hooks/use-patch-me'
import { usePostUserAvatar } from '@api/account/hooks/use-post-user-avatar'
import { usePatchWorkspace } from '@api/workspaces/hooks/use-patch-workspace'
import { usePostWorkspaceAvatar } from '@api/workspaces/hooks/use-post-workspace-avatar'
import { AvatarType } from '@modules/modals/edit-avatar/types'
import {
  canvasPreview,
  centerAspectCrop,
  useDebounceEffect,
} from '@modules/modals/edit-avatar/utils/crop-utils'
import { uploadUserAvatar } from '@modules/modals/edit-avatar/utils/user-blob'
import { uploadWorkspaceAvatar } from '@modules/modals/edit-avatar/utils/workspace-blob'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import ReactImageUploading, { ImageListType } from 'react-images-uploading'
import { toast } from 'react-toastify'
import { Button, Container, Divider, Heading, Text, useModal } from 'ui'
import { AvatarContainerHorizontal, AvatarContainerVertical, DragContainer } from './styled'

interface EditAvatarModalProps {
  avatarType: AvatarType
  workspaceId?: string
}

type ProhibitedFormat = { [formatName: string]: string }

const PROHIBITED_FORMATS: ProhibitedFormat = {
  'image/gif': 'image/gif',
}

export const EditAvatarModal: FC<EditAvatarModalProps> = ({ avatarType, workspaceId }) => {
  const { t: tCommon } = useTranslation(['common'])
  const { t: tEditAvatar } = useTranslation(['edit-avatar'])
  const { mutateAsync: mutateUserAvatar } = usePostUserAvatar()
  const { mutateAsync: mutateMe } = usePatchMe()
  const { mutateAsync: mutateWorkspaceAvatar } = usePostWorkspaceAvatar()
  const { mutate: mutateWorkspace } = usePatchWorkspace()
  const [isLoading, setIsLoading] = useState(false)

  const { closeModal } = useModal()

  const imgRef = useRef<HTMLImageElement>(null)
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  const [isImgVertical, setIsImgVertical] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [images, setImages] = useState<ImageListType>([])
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const canvas = useMemo(() => {
    return document.createElement('canvas')
  }, [])

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        canvasPreview(imgRef.current, canvas, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  const onSaveClick = () => {
    setIsLoading(true)
    if (!canvas) {
      toast.error(`${tCommon('changesNotSaved')}`)
      return
    }
    canvas.toBlob(
      async (blob) => {
        if (!blob) {
          throw Error
        }
        try {
          if (avatarType === AvatarType.User) {
            const { id, uri } = await mutateUserAvatar()
            if (!id || !uri) {
              throw Error
            }
            await uploadUserAvatar({
              blob,
              uri,
            })
            await mutateMe({ avatarId: id })
          }
          if (avatarType === AvatarType.Workspace && workspaceId) {
            const { id, uri } = await mutateWorkspaceAvatar(workspaceId)
            if (!id || !uri) {
              throw Error
            }
            await uploadWorkspaceAvatar({
              blob,
              uri,
            })
            await mutateWorkspace({ avatarId: id, id: workspaceId })
            setIsImgLoaded(false)
          }
        } catch (_) {
          toast.error(`${tCommon('changesNotSaved')}`)
          return
        } finally {
          setIsLoading(false)
          closeModal()
        }
      },
      'image/png',
      1
    )
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, 1))
    setIsImgVertical(height > width)
    setIsImgLoaded(true)
  }

  useEffect(() => {
    const { naturalHeight: height, naturalWidth: width } = imgRef?.current || {}
    // setCrop moved to useEffect from onImageLoad callback
    // setCrop must be called after proper AvatarContainer (vertical or horizontal) is defined
    // to avoid cropping of wrongly positioned image
    if (isImgLoaded && width && height) setCrop(centerAspectCrop(width, height, 1))
  }, [isImgLoaded, isImgVertical])

  const onChange = useCallback(
    (imageList: ImageListType) => {
      const selectedImage = imageList[0]?.file

      if (!selectedImage) {
        return
      }

      const fileType = selectedImage.type

      if (PROHIBITED_FORMATS[fileType]) {
        toast.error(`${tEditAvatar('prohibitedFormat')}`)
        return
      }

      setImages(imageList)
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(selectedImage)
    },
    [setImages]
  )

  const AvatarContainer = isImgVertical ? AvatarContainerVertical : AvatarContainerHorizontal

  return (
    <Container>
      <Heading as="h2" pb="2.8rem">
        {images.length ? tEditAvatar('cropAvatar') : tEditAvatar('uploadAvatar')}
      </Heading>
      <ReactImageUploading value={images} onChange={onChange} maxNumber={1} dataURLKey="dataURL">
        {({ dragProps, isDragging, onImageUpload }) => {
          return (
            <DragContainer images={images} {...dragProps}>
              {images.length === 0 && !isImgLoaded ? (
                <>
                  <Text fontSize="2.4rem">{tEditAvatar('dragPhoto')}</Text>
                  <Container my="1.5rem" width="7.6rem">
                    <Divider label={tCommon('or')} />
                  </Container>
                  <Button
                    onClick={onImageUpload}
                    variant="secondary"
                    py="1.2rem"
                    disabled={isDragging}
                  >
                    {tEditAvatar('uploadFromComputer')}
                  </Button>
                </>
              ) : (
                <AvatarContainer>
                  <ReactCrop
                    crop={crop}
                    onComplete={(c) => setCompletedCrop(c)}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    aspect={1}
                  >
                    <img
                      onLoad={onImageLoad}
                      alt={tEditAvatar('avatar')}
                      ref={imgRef}
                      src={imgSrc}
                    />
                  </ReactCrop>
                </AvatarContainer>
              )}
            </DragContainer>
          )
        }}
      </ReactImageUploading>
      {images.length > 0 && (
        <Container
          display="flex"
          mt="2.8rem"
          justifyContent="space-between"
          style={{ gap: '0.8rem' }}
        >
          <Button
            onClick={() => {
              closeModal()
            }}
            width="100%"
            variant="secondary"
            disabled={isLoading}
          >
            {tCommon('cancel')}
          </Button>
          <Button width="100%" onClick={onSaveClick} disabled={isLoading}>
            {tCommon('save')}
          </Button>
        </Container>
      )}
    </Container>
  )
}
