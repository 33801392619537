import { useGetSupportedLanguages } from '@api/account/hooks/use-get-supported-languages'
import { UserLanguage } from '@api/account/types/language'
import { SelectLanguageModal } from '@modules/modals/select-language'
import { useFormikContext } from 'formik'
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Button, Container, PlusIcon, useModal } from 'ui'
import { UserInitialValues, WsInitialValues } from '../data'

interface OnboardingPrimaryLanguageProps extends SpaceProps {
  moveToNextStep: VoidFunction
  setPrimaryLanguage: Dispatch<SetStateAction<UserLanguage | undefined>>
}

const popularLanguages: UserLanguage[] = [
  { code: 'en', name: 'English' },
  { code: 'zh-Hans', name: '简体字' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'ar', name: 'العربية' },
  { code: 'ru', name: 'Русский' },
  { code: 'pt', name: 'Português' },
]

export const OnboardingPrimaryLanguage: FC<OnboardingPrimaryLanguageProps> = ({
  moveToNextStep,
  setPrimaryLanguage,
  ...spacing
}) => {
  const { openModal, closeModal } = useModal()
  const { data: supportedLanguages } = useGetSupportedLanguages({ skip: false })
  const { values } = useFormikContext<WsInitialValues | UserInitialValues>()
  const { t } = useTranslation(['onboarding'])

  // Show language options array with browser language on the first place in case browser language is in supported languages
  const getLanguageOptions = useMemo(() => {
    const browserLangCode = window.navigator.language?.split('-')[0]
    const browserLang = supportedLanguages?.find((language) => language.code === browserLangCode)
    if (browserLang) {
      return [
        browserLang,
        ...popularLanguages.filter((language) => language.code !== browserLangCode),
      ].slice(0, 8)
    }
    return popularLanguages
  }, [supportedLanguages])

  const handleClick = (selectedLang: UserLanguage) => {
    const newPrimaryLanguage: UserLanguage = {
      name: selectedLang.name,
      code: selectedLang.code,
      isPrimary: true,
    }
    values.primaryLanguage = newPrimaryLanguage
    setPrimaryLanguage(newPrimaryLanguage)
    closeModal()
    moveToNextStep()
  }

  return (
    <Container {...spacing}>
      <LanguagesList flexGap="1rem">
        {getLanguageOptions.map((lang) => (
          <LanguageItem key={lang.code}>
            <Button variant="quaternary" width="100%" size="sm" onClick={() => handleClick(lang)}>
              {lang.name}
            </Button>
          </LanguageItem>
        ))}
        <Button
          variant="quaternary"
          minWidth="7rem"
          size="sm"
          hiddenLabel={t('choseAnotherLanguage')}
          icon={<PlusIcon />}
          onClick={() =>
            openModal({
              content: (
                <SelectLanguageModal
                  handleClick={handleClick}
                  supportedLanguages={supportedLanguages}
                />
              ),
            })
          }
        />
      </LanguagesList>
    </Container>
  )
}

const LanguagesList = styled(Container.withComponent('ul'))`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const LanguageItem = styled.li`
  flex: 0 1 auto;
`
