import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { useUpdateChat } from '@api/chats/hooks/use-update-chat'
import { useAppDispatch } from '@app/hooks'
import { ChevronLeftIcon } from '@components/icons'
import { useSidePage } from '@hooks/use-side-page'
import { useTabTitleWithCounter } from '@hooks/use-tab-title-with-counter'
import { About } from '@modules/about'
import { Files } from '@modules/files'
import { EditDetailsForm, EditValues } from '@modules/forms/edit-details'
import { channelNameValidationSchema } from '@modules/forms/edit-details/data'
import { Members } from '@modules/members'
import { SidepageHeader } from '@modules/side-page/header'
import { getFeatureFlag } from '@utils/flags-manager'
import { getChatName } from '@utils/get-chat-name'
import { isChat } from '@utils/get-chat-type'
import { getFullName } from '@utils/helpers'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { updateChat } from 'src/features/chats/slice'
import { Tab, Tabs, TabsVariant } from 'ui'

export enum EditType {
  channelName = 'channelName',
  channelDescription = 'channelDescription',
}

export const Details = () => {
  const { sidePageId } = useSidePage()
  const { data: chatDetails } = useGetChatDetails(sidePageId || undefined)
  const { meData } = useGetMe()
  const { t } = useTranslation('details')
  const dispatch = useAppDispatch()
  const { mutate: updateChannelInfo } = useUpdateChat({
    onErrorCallback: () => toast.error(`${t('toastMessages.changesNotSaved')}`),
    onSuccessCallback: (data) => {
      dispatch(updateChat({ chatId: data.id, chatDetails: { name: data.name } }))
      setEditType(null)
    },
  })

  const [editType, setEditType] = useState<EditType | null>(null)

  // Get active chat users
  const activeChatUsers = useMemo(
    () => chatDetails?.chatUsers.filter(({ leftChat }) => !leftChat),
    [chatDetails]
  )

  const membersTabTitle = useTabTitleWithCounter(activeChatUsers ?? [], t('members'))

  // Filter all chat users excluding ME
  const usersSendingToMe = useMemo(
    () => chatDetails?.chatUsers.filter(({ user }) => user.userId !== meData?.userId) || [],
    [chatDetails]
  )

  // Generate sidepage title
  const sidepageTitle = useMemo(() => {
    if (!chatDetails) {
      return null
    }

    if (isChat(chatDetails)) {
      if (activeChatUsers?.length === 2) {
        const { firstName, lastName } = usersSendingToMe[0].user
        return getFullName(firstName, lastName)
      }
      return usersSendingToMe.map(({ user }) => user.firstName).join(', ')
    } else {
      return getChatName(chatDetails, t)
    }
  }, [activeChatUsers, chatDetails, usersSendingToMe])

  const channelDetailsTitle = useMemo(() => {
    switch (editType) {
      case EditType.channelName:
        return t('renameChannel')
      case EditType.channelDescription:
        return t('editDescription')
      default:
        return sidepageTitle
    }
  }, [sidepageTitle, editType])

  if (!chatDetails) {
    return null
  }
  // TODO: Handle edit channel description, BE is not supporting update of description yet
  const onEditSubmit = (values: EditValues) => {
    if (!chatDetails || !editType) return

    if (editType === EditType.channelName) {
      updateChannelInfo({ chatId: chatDetails.id, requestBody: { name: values.channelName } })
    }
  }

  const commonEditProps = {
    onCancel: () => setEditType(null),
    onSubmit: onEditSubmit,
  }

  const editNameProps = {
    ...commonEditProps,
    fieldKey: EditType.channelName,
    label: t('channelName'),
    placeholder: t('enterName'),
    initialValue: chatDetails.name,
    validationSchema: channelNameValidationSchema,
  }

  const editDescriptionProps = {
    ...commonEditProps,
    fieldKey: EditType.channelDescription,
    maxCharacters: 100,
    label: t('description'),
    placeholder: t('enterDescription'),
    initialValue: chatDetails.description,
    disabled: true, // Temporary disable, BE is not supporting update of description yet
  }

  const tabs = [
    <Tab title={t('about')} key={t('about')}>
      <About setEditType={setEditType} />
    </Tab>,
    <Tab title={membersTabTitle} key={t('membersTabTitle')}>
      <Members />
    </Tab>,
  ]

  if (getFeatureFlag('showOnProd')) {
    tabs.push(
      <Tab title={t('files')} key={t('files')}>
        <Files />
      </Tab>
    )
  }

  return (
    <>
      {channelDetailsTitle && (
        <SidepageHeader
          title={channelDetailsTitle}
          preFix={
            editType && (
              <button onClick={() => setEditType(null)} aria-label={t('goBack')}>
                <ChevronLeftIcon />
              </button>
            )
          }
          mb="1.2rem"
        />
      )}

      {editType ? (
        <EditDetailsForm
          {...(editType === EditType.channelName ? editNameProps : editDescriptionProps)}
        />
      ) : (
        <Tabs variant={TabsVariant.UNDERLINE} listMarginBottom="1.6rem">
          {tabs}
        </Tabs>
      )}
    </>
  )
}
