import React, { createContext, Dispatch, SetStateAction, useState } from 'react'

export interface LeftSideBarProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface LeftSideBarProviderDefinition {
  selectedOption: string | null
  setSelectedOption: Dispatch<SetStateAction<string | null>>
}

const initData: LeftSideBarProviderDefinition = {
  selectedOption: null,
  setSelectedOption: () => {},
}

const LeftSideBarContext = createContext<LeftSideBarProviderDefinition>(initData)

const LeftSideBarProvider = ({ children }: LeftSideBarProviderProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  return (
    <LeftSideBarContext.Provider value={{ selectedOption, setSelectedOption }}>
      {children}
    </LeftSideBarContext.Provider>
  )
}

export { LeftSideBarContext, LeftSideBarProvider }
