import { protectedApiInstance } from '@api/instance'
import { onFulfilled, setAuthHeaders } from '@api/interceptors'
import { useWorkspaceToken } from '@api/workspaces/hooks/use-workspace-token'
import { useAuth } from '@hooks/use-auth'
import { AxiosError, AxiosInstance } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import React, { FC, createContext, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const ProtectedApiContext = createContext<AxiosInstance | null>(null)

interface ProtectedApiProviderProps {
  children: JSX.Element | JSX.Element[]
}

const RECONNECTION_ATTEMPTS_LIMIT = 3

export const ProtectedApiProvider: FC<ProtectedApiProviderProps> = ({ children }) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()

  const { login, refreshToken } = useAuth()
  const { refetch } = useWorkspaceToken({ id: workspaceId!, enabled: false })
  const instanceRef = useRef(protectedApiInstance)

  const prevNumberOfRefreshAttempts = useRef(0)
  const currentNumberOfRefreshAttempts = useRef(0)
  const failedAttemptsToReFetchTokens = useRef(0)

  const refreshTokensInProgress = (): boolean => {
    return prevNumberOfRefreshAttempts.current !== currentNumberOfRefreshAttempts.current
  }

  const handleReject = async (error: AxiosError) => {
    // TODO: Reimplement tokens handling for whole app
    // If request fails with 'unauthorized' reason and tokens are not refreshing at the moment
    // => will try to refresh tokens
    // Otherwise => do nothing (prevents simultaneous tons of requests to get tokens)
    // it shouldn't have influence on other simultaneous requests
    // so as after any request failed it will be called 2 more times with delay
    if (error.response?.status === 401 && !refreshTokensInProgress()) {
      currentNumberOfRefreshAttempts.current = prevNumberOfRefreshAttempts.current + 1

      await refreshToken()
      const response = await refetch()

      prevNumberOfRefreshAttempts.current = currentNumberOfRefreshAttempts.current

      if (response.status === 'error') {
        failedAttemptsToReFetchTokens.current++

        // Redirect user to workspace page when refresh attempts reaches the limit
        if (failedAttemptsToReFetchTokens.current === RECONNECTION_ATTEMPTS_LIMIT) {
          // It is impossible to logout user if token is invalid
          // Calling 'login' method system will redirect user to workspaces page
          login({})
        }
      } else {
        prevNumberOfRefreshAttempts.current = 0
        currentNumberOfRefreshAttempts.current = 0
        failedAttemptsToReFetchTokens.current = 0
      }
    }
  }

  useEffect(() => {
    instanceRef.current.interceptors.request.use(setAuthHeaders)
    instanceRef.current.interceptors.response.use(onFulfilled, async (error) => {
      handleReject(error)
      throw camelcaseKeys(error, { deep: true })
    })
  }, [])

  return (
    <ProtectedApiContext.Provider value={instanceRef.current}>
      {children}
    </ProtectedApiContext.Provider>
  )
}
