import { ConfirmationModal } from '@modules/modals/confirmation'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, useModal } from 'ui'

export interface RevokeInvitationModalProps {
  id: string
  email: string
}

export const RevokeInvitationModal: FC<RevokeInvitationModalProps> = ({ id, email }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-revoke-invitation')

  //Temporary
  const handleOnSubmit = () => {
    const alertString = `${email}, id: ${id}`
    alert(alertString)
    closeModal()
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      onSubmit={handleOnSubmit}
    >
      <Text mb="4.8rem">{t('confirmation', { email })}</Text>
    </ConfirmationModal>
  )
}
