import ReactSelect from 'react-select'
import styled from 'styled-components'
import { Container, Text } from 'ui'

export const MultiSelect = styled(ReactSelect)`
  .Select__multi {
    .Select-value {
      display: inline-flex;
      align-items: center;
      background-color: red;
    }
  }
  .Select__control {
    min-height: 4.8rem;
    width: 100%;
    border: 0.1rem solid ${({ theme }) => theme.colors.grey};
    border-radius: 0.8rem;
    background: ${({ theme }) => theme.colors.greyLight};
  }
  .Select__placeholder {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.greyDark};
  }
  .Select__menu {
    font-size: 20px;
    color: red;
    z-index: 2;
  }
  .Select__option {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
    &:last-child {
      border-bottom: 0;
    }
  }
  .Select__multi-value__label {
    display: flex;
  }
  .Select__multi-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3.2rem;
    padding: 0.4rem 0.4rem;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: 0.8rem;
  }
  .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
  .Select__value-container {
    display: flex;
  }
  .Select__single-value__label {
    display: flex;
  }
  .Select__single-value {
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 0.4rem 0.4rem;
    background-color: ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: 0.8rem;
  }
  .Select__single-value__remove {
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
  .Select__multi-value__remove {
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
  .Select__input-container {
    font-size: 1.6rem;
    font-weight: 400;
  }
`

export const PlaceHolderText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyDark};
`

export const OptionContainer = styled(Container)`
  display: flex;
`
// TODO: remove font styles
export const OptionText = styled(Text)`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.black};
`
