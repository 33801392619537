import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { Online } from '@api/chats/types/online'
import { getStatusVariant } from '@utils/get-status'
import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Avatar, AvatarVariant, Container, Text } from 'ui'

export interface ChipInputItemProps {
  value: string
  label: string
  avatarId?: string
  online?: Online
  color?: string
}

export const ChipInputItem: FC<ChipInputItemProps> = ({
  label,
  avatarId,
  online,
  color,
  value,
}) => {
  const theme = useTheme()

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: value,
    avatarId,
  })

  return (
    <>
      <Container>
        <Avatar
          variant={AvatarVariant.SQUARE}
          imgUrl={usersAvatarUrl}
          size="2.4rem"
          borderRadius="0.4rem"
          status={getStatusVariant(online)}
          label={label}
          bgColor={`#${color}`}
          isAvatarExists={enabled}
        />
      </Container>
      <Container display="flex" justifyContent="space-between" alignItems="center">
        <Text ml="0.8rem" color={theme.colors.black}>
          {label}
        </Text>
      </Container>
    </>
  )
}
