import React, { FC } from 'react'
import { InView } from 'react-intersection-observer'
import styled from 'styled-components'
import { PositionProps } from 'styled-system'
import { Container } from 'ui'

interface Props extends PositionProps {
  onChange: (c: boolean) => void
}

export const InViewTrigger: FC<Props> = ({ onChange, ...stylesProps }) => {
  return (
    <Trigger {...stylesProps}>
      {/*must contain any tag or text to trigger the function*/}
      <InView onChange={onChange}>
        <br />
      </InView>
    </Trigger>
  )
}

const Trigger = styled(Container)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
`
