import { Online } from '@api/chats/types/online'
import { TFunction } from 'next-i18next'
import { StatusVariant } from 'ui'

const getStatusVariant = (online?: Online) => {
  switch (online?.status) {
    case 1:
      return StatusVariant.ONLINE
    case 2:
      return StatusVariant.BUSY
    default:
      return StatusVariant.OFFLINE
  }
}

const getStatusHiddenLabel = (status: number, t: TFunction) => {
  switch (status) {
    case 2:
      return `${t('statusHiddenLabel')}: ${t('busy')}`
    case 1:
      return `${t('statusHiddenLabel')}: ${t('online')}`
    default:
      return `${t('statusHiddenLabel')}: ${t('offline')}`
  }
}

export { getStatusHiddenLabel, getStatusVariant }
