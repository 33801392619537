import { ChatUser } from '@api/chats/types/chat-user'

export const formatChatHeaderTitle = (senders: ChatUser[]) => {
  let result
  let sendersArray = []
  let characters = 0
  for (let i: number = 0; i < senders.length; i++) {
    const senderFirstName = senders[i].user.firstName
    /* If another first name fits incl. comma and space (+2) before the name, add it in the array */
    if (characters + (senderFirstName.length + 2) <= 25) {
      sendersArray.push(senderFirstName)
      characters = sendersArray.join(', ').length
      result = sendersArray.join(', ')
    } else {
      result = `${sendersArray.join(', ')} + ${senders.length - sendersArray.length} more`
    }
  }
  return result
}
