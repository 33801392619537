import { CancelablePromise, OpenAPIConfig } from '@api/account/generated'
import { ApiRequestOptions } from '@api/account/generated/core/ApiRequestOptions'
import { protectedApiInstance } from '@api/instance'
import { getQueryString } from '@api/utils'

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]))
      }
      return substring
    })

  const url = `${config.BASE}${path}`
  if (options.query) {
    return `${url}${getQueryString(options.query)} `
  }
  return url
}

export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> => {
  return new CancelablePromise((resolve, reject, onCancel) => {
    const url = `accounts${getUrl(config, options)}`

    protectedApiInstance
      .request({
        url,
        data: options.body,
        method: options.method,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
