import { LogoHeader } from '@components/logo-header'
import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'ui'

export const InitialLayout: FC = () => (
  <Container display="flex" flexDirection="column" height="100vh">
    <LogoHeader />
    <Container as="main" p="0 1.6rem 2.8rem" flex="1 1 auto">
      <Outlet />
    </Container>
  </Container>
)
