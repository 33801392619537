import styled from 'styled-components'
import { Container } from 'ui'

interface IconContainerProps {
  active?: boolean
}

export const PickerContainer = styled(Container)`
  display: flex;
  column-gap: 0.4rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  border-radius: 0.8rem;
  padding: 0.4rem;
`

export const IconContainer = styled('button')<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  background-color: ${({ active, theme }) => active && theme.colors.greyLight};
  border-radius: 0.6rem;

  & svg {
    fill: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.greyDark)};
  }
`
