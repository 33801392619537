import { store } from '@app/store'
import { theme } from '@common/theme'
import { ToastContainer } from '@components/toast-container'
import { AuthProvider } from '@contexts/auth-provider'
import { SidePageProvider } from '@contexts/side-page-provider'
import { SignalRProvider } from '@contexts/signalr-provider'
import { SkeletonProvider } from '@contexts/skeleton-provider'
import { ThemeProvider } from '@contexts/theme-provider'
import { TranslationsProvider } from '@contexts/translations-provider'
import { WorkspaceProvider } from '@contexts/workspace-provider'
import { init as initSentry, BrowserTracing, Replay } from '@sentry/react'
import '@styles/fonts.css'
import { GlobalStyles } from '@styles/global-styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import reportWebVitals from '@utils/report-web-vitals'
import React, { Suspense } from 'react'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ModalProvider } from 'ui'
import { App } from './app'
import './i18n'

initSentry({
  dsn: process.env.SENTRY_DSN_FLOW,
  integrations: [new BrowserTracing(), new Replay()],
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.APP_ENVIRONMENT,
})

const queryClient = new QueryClient()

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Suspense fallback={null}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <ToastContainer />
              <GlobalStyles />
              <AuthProvider>
                <WorkspaceProvider>
                  {/* this is added temporary, we should rethink the side bar logic and handle the translation provider properly */}
                  <TranslationsProvider>
                    <SidePageProvider>
                      {/*We can avoid language and user providers usage, but they are used inside Onboarding,
                 as Onbloarding is not finished yet, please review how your implementation, as you can't use account api here
                 and then remove them */}
                      {/*<UserProvider> */}
                      {/*  <LanguageProvider>*/}
                      <ModalProvider>
                        <SignalRProvider>
                          <SkeletonTheme
                            baseColor={theme.colors.greyLight}
                            enableAnimation={false}
                            borderRadius={8}
                          >
                            <SkeletonProvider>
                              <App />
                            </SkeletonProvider>
                          </SkeletonTheme>
                        </SignalRProvider>
                      </ModalProvider>
                      {/*</LanguageProvider>*/}
                      {/*</UserProvider>*/}
                    </SidePageProvider>
                  </TranslationsProvider>
                </WorkspaceProvider>
              </AuthProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </Router>
      </Suspense>
      {/* </PersistGate> */}
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
