import styled from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { Text } from 'ui'
import { ListItemTextProps } from '.'

export const ListItemText = styled(Text)<ListItemTextProps>`
  text-align: left;
  font-weight: ${({ hasUnreadMessage }) => (hasUnreadMessage ? 500 : 400)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 19.6rem;
  color: ${({ theme }) => theme.colors.black};
`

export const NewMessageIndicator = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 10rem;
  background-color: ${({ theme }) => theme.colors.primary};
`

export const Indicators = styled.span<SpaceProps>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  ${space}
`
