import { useGetWorkspaceInvitations } from '@api/workspaces/hooks/use-get-workspace-invitations'
import { useWorkspace } from '@hooks/use-workspace'
import { LoaderContainer } from '@modules/conversation-body/styled'
import { EmptyResults } from '@modules/empty-results'
import { InviteWsMembersModal } from '@modules/modals/invite-ws-members'
import { SettingsListItem } from '@pages/workspace'
import { sortInvitations } from '@utils/sort-invitations'
import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BasicSearch,
  Button,
  Container,
  DotsLoader,
  PlusIcon,
  ScrollContainer,
  Text,
  useModal,
} from 'ui'
import { InvitationItem } from './invitation-item'
import { Invitation } from './invitation-item/types'
import { Header } from './styled'

export const WorkspaceInvitations = () => {
  const [searchString, setSearchString] = useState<string>('')
  const { openModal } = useModal()
  const { t } = useTranslation('workspace')
  const { workspaceId } = useWorkspace()
  const { data, isLoading } = useGetWorkspaceInvitations({ id: workspaceId })
  const [invitations, setInvitations] = useState<Invitation[]>([])

  useEffect(() => {
    if (data && !isLoading) {
      setInvitations(data)
    }
  }, [data, isLoading])

  const filterInvitations = useMemo(() => {
    const sortedActiveInvitations = sortInvitations(
      invitations.filter((item) => {
        return item.email.toLowerCase().startsWith(searchString.toLowerCase())
      })
    )
    return sortedActiveInvitations
  }, [searchString, invitations])

  if (isLoading) {
    return (
      <LoaderContainer>
        <DotsLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container position="relative" height="100%">
      <Container position="absolute" top="-5.6rem" right="0">
        <Button
          type="button"
          size="sm"
          preFix={<PlusIcon />}
          minWidth="17.7rem"
          onClick={() =>
            openModal({
              content: <InviteWsMembersModal />,
              maxWidth: '62rem',
              closeOnClickOutside: false,
            })
          }
        >
          {t('inviteMembers')}
        </Button>
      </Container>
      <BasicSearch
        id="search_ws_invitation"
        name="search_ws_invitation"
        hiddenLabel={t('searchByEmail')}
        placeholder={t('searchByEmail')}
        setSearchString={setSearchString}
        searchString={searchString}
      />
      {filterInvitations.length > 0 ? (
        <>
          <Container pt="1.6rem">
            <Header>
              <Text variant="smallTextMedium" color="greyDark">
                {t('email')}
              </Text>
              <Text variant="smallTextMedium" color="greyDark">
                {t('role')}
              </Text>
              <Text variant="smallTextMedium" color="greyDark">
                {t('status')}
              </Text>
            </Header>
          </Container>
          <ScrollContainer hideScrollbar pb="8rem">
            {filterInvitations.map((invitation) => (
              <SettingsListItem key={invitation.id}>
                <InvitationItem invitation={invitation} />
              </SettingsListItem>
            ))}
          </ScrollContainer>
        </>
      ) : (
        <EmptyResults title={t('emptyInvTitle')} text={t('emptyInvSubtitle')} />
      )}
    </Container>
  )
}
