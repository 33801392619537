import { User } from '@api/account/types/user'
import { Skeleton } from '@modules/skeleton'
import { TeammateCard } from '@modules/teammate-card/tile-card'
import React, { FC } from 'react'
import { InViewTrigger } from 'src/components/in-view-trigger'
import styled from 'styled-components'
import { ScrollContainer } from 'ui'

interface TileLayoutProps {
  users: User[]
  handleSendClick: (userId: string) => void
  handleUserClick: (userId: string) => void
  isUsersFetching: boolean
  setIsTriggerInView: (c: boolean) => void
}

export const TileLayout: FC<TileLayoutProps> = ({
  users,
  handleSendClick,
  handleUserClick,
  isUsersFetching,
  setIsTriggerInView,
}) => (
  <TileScrollContainer hideScrollbar>
    <TileContainer>
      {users.map((teammate) => (
        <TeammateCard
          handleSendClick={handleSendClick}
          handleUserClick={handleUserClick}
          key={teammate.userId}
          {...teammate}
        />
      ))}
      {isUsersFetching && <Skeleton type="teammatesTile" length={10} />}
      <InViewTrigger onChange={setIsTriggerInView} bottom={0} position="absolute" />
    </TileContainer>
  </TileScrollContainer>
)

const TileScrollContainer = styled(ScrollContainer)`
  height: calc(100% - 13rem);
`

const TileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(16rem, 1fr)
  ); /* min 16rem is set to not wrap the text in the tile */
  gap: 1.2rem;
  justify-content: center;
  height: auto;
  padding-bottom: 3rem;
  position: relative;
`
