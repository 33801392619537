import styled, { css } from 'styled-components'
import { Container } from 'ui'
import { InvitationItemStylesProps, InvitationStatus } from './types'

export const StyledItem = styled(Container)<InvitationItemStylesProps>`
  ${(props) => {
    const expiredStyles = css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 4rem;
        background: rgba(255, 255, 255, 0.6);
      }
    `
    return css`
      position: relative;
      display: grid;
      grid-template-columns: 1.25fr 1fr 1fr 2.5rem;
      gap: 1rem;
      width: 100%;
      align-items: center;
      padding: 1.2rem 0;
      height: 6.8rem;
      ${props.status === InvitationStatus.EXPIRED && expiredStyles}
    `
  }}
`

export const Expiration = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-top: -0.1rem;
  }
`
