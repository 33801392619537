import { ComponentType } from 'react'
import { MaxWidthProps } from 'styled-system'

export enum ModalVariant {
  DEFAULT = 'default',
  FULL = 'full',
}

export interface CustomPanelProps extends MaxWidthProps {
  variant?: ModalVariant
}

export type ExtractProps<T> = T extends ComponentType<infer P> ? P : T

export type ExtendedPanelProps<T> = T extends ComponentType<infer P> ? P : T & CustomPanelProps

export interface ModalProps {
  maxWidth?: string
  children: any
  isOpen: boolean
  closeModal: () => void
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  closeOnClickOutside?: boolean
  variant?: ModalVariant
}
