import styled, { css } from 'styled-components'
import { Container } from 'ui'
import { MemberItemStylesProps } from '.'

export const StyledItem = styled(Container)<MemberItemStylesProps>`
  ${(props) => {
    const deactivatedStyles = css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 4rem;
        background: rgba(255, 255, 255, 0.6);
      }
    `

    return css`
      position: relative;
      display: grid;
      grid-template-columns: 1.25fr 1fr 1fr 2.5rem;
      gap: 1rem;
      width: 100%;
      align-items: center;
      padding: 1.4rem 0;
      ${props.isDeleted && deactivatedStyles}
    `
  }}
`
export const ActionsButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
`
