import { ImageListType } from 'react-images-uploading'
import styled from 'styled-components'
import { Container } from 'ui'

export const DragContainer = styled(Container)<{ images: ImageListType }>`
  width: 100%;
  min-height: 40rem;
  border-width: 2px;
  border-color: ${(props) => props.theme.colors.grey};
  border-radius: 1.6rem;
  border-style: ${(props) => (props.images.length ? 'none' : 'dashed')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const AvatarContainerHorizontal = styled(Container)`
  width: 100%;
  min-height: auto;
  border-radius: 1.6rem;
  overflow: hidden;

  img {
    height: auto;
    width: 100%;
  }
`

export const AvatarContainerVertical = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40rem;
  border-radius: 1.6rem;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.grey};

  img {
    height: 40rem;
    object-fit: contain;
  }
`
