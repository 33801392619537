import { ChipInputItem, ChipInputItemProps } from '@components/chip-input/chip-input-item'
import React, { FC } from 'react'
import {
  components,
  MultiValueRemoveProps,
  ValueContainerProps,
  OptionProps,
  MultiValueGenericProps,
  SingleValueProps,
} from 'react-select'
import { CloseIcon } from 'ui'
import { MultiSelect, OptionContainer, PlaceHolderText } from './styled'

interface ChipInputProps {
  options: ChipInputItemProps[]
  isMulti: boolean
  defaultValue: ChipInputItemProps | null
  setOptionsList: React.Dispatch<React.SetStateAction<any[]>> // TODO: research how to type React-select
}

export const ChipInput: FC<ChipInputProps> = ({
  options,
  isMulti,
  defaultValue,
  setOptionsList,
}) => {
  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...props}>
        <CloseIcon />
      </components.MultiValueRemove>
    )
  }

  const MultiValueLabel = (props: MultiValueGenericProps<ChipInputItemProps>) => {
    const { data } = props
    return (
      <components.MultiValueLabel {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          avatarId={data.avatar}
        />
      </components.MultiValueLabel>
    )
  }

  const SingleValue = (props: SingleValueProps) => {
    const { data } = props as { data: ChipInputItemProps }
    return (
      <components.SingleValue {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          //@ts-ignore
          avatarId={data.avatar?.id}
        />
      </components.SingleValue>
    )
  }

  const Option = (props: OptionProps) => {
    const { data } = props as { data: ChipInputItemProps }
    return (
      <components.Option {...props}>
        <OptionContainer>
          <ChipInputItem
            value={data.value}
            online={data.online}
            label={data.label}
            color={data.color}
            //@ts-ignore
            avatarId={data.avatar?.id}
          />
        </OptionContainer>
      </components.Option>
    )
  }

  const ValueContainer = (props: ValueContainerProps) => {
    const { children, getValue } = props
    const values = getValue()
    if (values.length > 0) {
      return (
        <components.ValueContainer {...props}>
          <PlaceHolderText>To: </PlaceHolderText>
          {children}
        </components.ValueContainer>
      )
    }

    return <components.ValueContainer {...props}>{children}</components.ValueContainer>
  }

  const handleOnChange = (options: any) => {
    if (options) {
      setOptionsList(options)
    }
  }

  return (
    <MultiSelect
      // setting key to default value to reset the component when the default value changes
      key={defaultValue ? `${defaultValue}-chip-input` : 'chip-input'}
      options={options}
      isMulti={isMulti}
      isClearable={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueRemove,
        MultiValueLabel,
        SingleValue,
        ValueContainer,
        Option,
      }}
      defaultValue={defaultValue ? defaultValue : []}
      onChange={handleOnChange}
      classNamePrefix="Select"
      placeholder="To name or surname:"
    />
  )
}
