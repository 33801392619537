import { chatsApi } from './instance'
import { Chat } from './types/chat'

interface GetChatDetailsProps {
  chatId?: string
}

export const getChatDetails = async ({ chatId }: GetChatDetailsProps) => {
  const { data } = await chatsApi.get<Chat>(`/${chatId}`)
  return data
}
