import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'
import React, { createContext, useState } from 'react'

export interface SignalRProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface SignalRProviderDefinition {
  connection: HubConnection | null
  startConnection: () => void
  stopConnection: () => void
  subscribeToEvent: (event: string, eventHandler: (...args: any[]) => void) => void
  unsubscribeFromEvent: (event: string) => void
  invokeMethod: (method: string, ...args: any[]) => void
}

const initData: SignalRProviderDefinition = {
  connection: null,
  startConnection: () => {},
  stopConnection: () => {},
  subscribeToEvent: () => {},
  unsubscribeFromEvent: () => {},
  invokeMethod: () => {},
}

const SignalRContext = createContext<SignalRProviderDefinition>(initData)

const SignalRProvider = ({ children }: SignalRProviderProps) => {
  const [connection, setConnection] = useState<HubConnection | null>(null)

  const startConnection = async () => {
    const connectionConfig = new HubConnectionBuilder()
      .withUrl(`${process.env.SIGNALR_FLOW_HUB_URL}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => sessionStorage.getItem('workspace.token') || '',
      })
      .withAutomaticReconnect()
      .build()

    if (!connection) {
      try {
        await connectionConfig.start()
        setConnection(connectionConfig)
      } catch (err) {
        // TODO: Handle error in sentry
        console.error(err)
      }
    } else if (connection && connection.state === HubConnectionState.Disconnected) {
      try {
        await connection.start()
        setConnection(connection)
      } catch (err) {
        // TODO: Handle error in sentry
        console.error(err)
      }
    }
  }

  const subscribeToEvent = (event: string, eventHandler: (...args: any[]) => void) => {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.on(event, eventHandler)
    }
  }

  const invokeMethod = (method: string, ...args: any[]) => {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.invoke(method, ...args)
    }
  }

  const unsubscribeFromEvent = (event: string) => {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.off(event)
    }
  }

  const stopConnection = () => {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.stop()
    }
  }

  return (
    <SignalRContext.Provider
      value={{
        connection,
        startConnection,
        stopConnection,
        subscribeToEvent,
        unsubscribeFromEvent,
        invokeMethod,
      }}
    >
      {children}
    </SignalRContext.Provider>
  )
}

export { SignalRContext, SignalRProvider }
