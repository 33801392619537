import { NetworkMode } from '@api/enums'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getMe } from '../get-me'
import { ME } from '../tags'

export const useGetMe = (skip?: boolean, networkMode?: NetworkMode) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery([ME], () => getMe(), {
    enabled: !skip,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: networkMode || NetworkMode.ONLINE,
  })

  const primaryLanguages: string[] = useMemo(() => {
    if (data && data.activeLanguages) {
      return (
        data.activeLanguages
          .filter((lang) => lang.isPrimary)
          .map((lang: { code: string }) => lang.code) || []
      )
    }
    return []
  }, [data])

  const userLanguages: string[] = useMemo(() => {
    if (data && data.activeLanguages) {
      return data.activeLanguages.map((lang: { code: string }) => lang.code) || []
    }
    return []
  }, [data])

  return { meData: data, isLoading, isError, isFetching, primaryLanguages, userLanguages, refetch }
}
