import { Status } from '../../../signalr/handlers/handle-user-connection-status-changed'
import { User } from '../types/user'

export const updateUserOnlineStatus = (
  cache: { users: User[] },
  userId: string,
  status: Status
) => {
  cache.users.find((user) => user.userId === userId)!.online.status = status ? 1 : 0
  return cache
}
