import { Chat } from '@api/chats/types/chat'
import { TFunction } from 'next-i18next'
import { isGeneralChannel } from './get-chat-type'

export const getChatName = (chat: Chat, t: TFunction) => {
  if (isGeneralChannel(chat) && t) {
    return t('generalChannelName')
  }
  return chat.name
}
