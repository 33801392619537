import { ControlProps, StylesConfig } from 'react-select'
import styled, { css } from 'styled-components'
import { Container } from 'ui'
import { MenuOptionProps } from '.'
import { OptionType } from '../types'

const getBorderColor = (props: ControlProps<OptionType, false>) =>
  props.isFocused ? props.theme.colors.primary : props.theme.colors.neutral40

export const SelectStyles: StylesConfig<OptionType, false> = {
  valueContainer: (base) => ({
    ...base,
    padding: '0.4rem 1rem',
  }),
  container: (base) => ({
    ...base,
    fontSize: '1.6rem',
  }),
  control: (base, props) => ({
    ...base,
    'alignItems': 'flex-start',
    'minHeight': '8.8rem',
    'maxHeight': '17rem',
    'boxShadow': 'none',
    'backgroundColor': props.isDisabled
      ? props.theme.colors.neutral30
      : props.theme.colors.neutral0,
    'borderRadius': '0.8rem',
    'overflowY': 'auto',
    'borderColor': getBorderColor(props),
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '&:hover': {
      borderColor: getBorderColor(props),
    },
  }),
  menu: (base) => ({
    ...base,
    padding: 0,
    borderRadius: '0.8rem',
    overflow: 'hidden',
    animation: 'fade-in 300ms ease-in-out',
  }),
  menuList: (base, props) => ({
    ...base,
    'borderColor': props.theme.colors.neutral40,
    '::-webkit-scrollbar': {
      width: '1.2rem',
      height: '1.2rem',
    },
    '::-webkit-scrollbar-thumb': {
      border: '0.4rem solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '2rem',
      backgroundColor: props.theme.colors.neutral40,
    },
  }),
  multiValue: (base, props) => ({
    ...base,
    backgroundColor: props.theme.colors.neutral10,
    borderRadius: '0.8rem',
    padding: '0.2rem 0.5rem',
    height: '3.2rem',
    transition: `width 300ms`,
  }),
  multiValueLabel: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueRemove: (base) => ({
    ...base,
    'paddingRight': 0,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    alignSelf: 'flex-start',
    display: 'flex',
    justifyContent: 'center',
    height: '4rem',
  }),
  option: (base, props) => ({
    ...base,
    'fontSize': '1.4rem',
    'color': '#373234',
    'backgroundColor': props.isFocused
      ? props.isDisabled
        ? props.theme.colors.neutral0
        : props.theme.colors.neutral10
      : props.theme.colors.neutral0,
    ':not(:last-of-type)': {
      borderBottom: `1px solid ${props.theme.colors.neutral20}`,
    },
    ':hover': {
      cursor: props.isDisabled ? 'default' : 'pointer',
      backgroundColor: props.isDisabled
        ? props.theme.colors.neutral0
        : props.theme.colors.neutral10,
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    'padding': '0.8rem',
    ':hover': {
      cursor: 'pointer',
    },
  }),
  loadingIndicator: (base) => ({
    ...base,
    'padding': '0.8rem',
    ':hover': {
      cursor: 'pointer',
    },
  }),
}

export const MenuOption = styled(Container)<MenuOptionProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => {
    const disabledStyles = css`
      position: relative;
      color: ${({ theme }) => theme.colors.greyDark};
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(255, 255, 255, 0.5);
      }
    `
    return css`
      ${props.isDisabled && disabledStyles};
      color: ${({ theme }) => theme.colors.black};
    `
  }};
`
