import { ConversationBodySkeleton } from '@modules/skeleton/skeleton-variants/conversation-body-skeleton'
import { TeammatesSkeletonList } from '@modules/skeleton/skeleton-variants/teammates-skeleton-list'
import { TeammatesSkeletonTile } from '@modules/skeleton/skeleton-variants/teammates-skeleton-tile'
import { WorkspaceMembersSkeleton } from '@modules/skeleton/skeleton-variants/workspace-members-skeleton'
import React, { FC, useMemo } from 'react'
import { ChannelSkeleton } from './skeleton-variants/channel-item-skeleton'

type SkeletonType = keyof typeof SKELETONS

interface Props {
  type: SkeletonType
  length?: number
}

export type SkeletonVariantProps = { arrayToMap: number[] }

const SKELETONS = {
  channel: ChannelSkeleton,
  conversationBody: ConversationBodySkeleton,
  teammatesTile: TeammatesSkeletonTile,
  teammatesList: TeammatesSkeletonList,
  workspaceMembers: WorkspaceMembersSkeleton,
}

function getSkeletonArray(length: number) {
  return Array.from({ length }).map((_, index) => index)
}

export const Skeleton: FC<Props> = ({ type, length }) => {
  const skeletonArray = useMemo(() => {
    if (!length) return []
    return getSkeletonArray(length)
  }, [length])

  const SkeletonItem = SKELETONS[type]

  return <SkeletonItem arrayToMap={skeletonArray} />
}
