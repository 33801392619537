import { AttachmentResponse } from '@api/chats/generated'
import { useGetAttachment } from '@api/chats/hooks/use-get-attachments'
import { useConversationId } from '@hooks/use-conversation-id'
import { FC, ReactElement } from 'react'

interface AttachmentFetcherProps extends AttachmentResponse {
  children: (options: AttachmentResponse & { src: string }) => ReactElement
}
export const AttachmentFetcher: FC<AttachmentFetcherProps> = ({ children, ...attachment }) => {
  const conversationId = useConversationId()

  const { data: src } = useGetAttachment(attachment.id!, conversationId)

  if (!src) {
    return null
  }

  return children({ src, ...attachment })
}
