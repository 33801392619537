import { SkeletonVariantProps } from '@modules/skeleton'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { Container } from 'ui'
import { SkeletonContainerList } from '../style'

export const WorkspaceMembersSkeleton: FC<SkeletonVariantProps> = ({ arrayToMap }) => {
  return (
    <SkeletonContainerList>
      {arrayToMap.map((key) => (
        <MembersContainer key={key}>
          <Container display="flex" alignItems="center">
            <Skeleton width="3.6rem" height="3.6rem" />
            <Container ml="1.2rem">
              <Skeleton width="18rem" height="1.2rem" />
            </Container>
          </Container>
          <Skeleton width="18rem" height="1.2rem" />
          <Skeleton width="18rem" height="1.2rem" />
        </MembersContainer>
      ))}
    </SkeletonContainerList>
  )
}

const MembersContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1.85fr 1.5fr 1fr 0.6fr;
  gap: 1rem;
  align-items: center;
  padding: 1.4rem 0;
  width: 100%;
`
