import { DateDivider } from '@components/date-divider'
import { GeneralMessageProps, Message, MessageVariant } from '@components/message'
import { MessageDate } from '@modules/conversation-body/types'
import { locale } from '@modules/conversation-body/utils'
import { formatRelative } from 'date-fns'
import React, { FC } from 'react'
import { Container } from 'ui'

interface MessageListWithDateDividerProps {
  list: (GeneralMessageProps | MessageDate)[]
}
export const MessageListWithDateDivider: FC<MessageListWithDateDividerProps> = ({ list }) => {
  return (
    <>
      {list
        .map((message: GeneralMessageProps | MessageDate, index) => {
          if (message.type === 'day' && message.date) {
            const date = new Date(message.date)
            const now = new Date()
            const formattedDate = formatRelative(date, now, { locale })
            // key should contain index to prevent react default behavior in case when date is the same after next fetch
            return <DateDivider key={index} date={formattedDate} m="0.8rem auto" />
          } else {
            const castedMessage = message as GeneralMessageProps
            const variant = castedMessage.isError
              ? MessageVariant.NOT_SENT
              : castedMessage?.isSending
              ? MessageVariant.IS_SENDING
              : castedMessage?.isDeleted
              ? MessageVariant.DELETED
              : MessageVariant.DEFAULT
            return (
              <Container key={castedMessage.clientMessageId} margin="0.8rem 0">
                <Message {...castedMessage} variant={variant} />
              </Container>
            )
          }
        })
        .reverse()}
    </>
  )
}
