import { AppDispatch } from '@app/store'
import { handleReaction } from '../../features/conversation/slice'

interface MessageReactionSignalR {
  messageId: string
  chatId: string
  reactionCode: string
  userId: string
  added: boolean
}

export const handleMessageReaction =
  (dispatch: AppDispatch) =>
  async ({
    added,
    chatId: incomingChatId,
    messageId: incomingMessageId,
    reactionCode,
    userId,
  }: MessageReactionSignalR) => {
    dispatch(
      handleReaction({
        messageId: incomingMessageId,
        chatId: incomingChatId,
        reactionCode,
        meId: userId,
        added,
      })
    )
  }
