import React from 'react'
import { SVGProps } from 'react'

export const MailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.229V16.5c0 .213.21.5.6.5h12.8c.39 0 .6-.287.6-.5V8.229l-6.419 4.585a1 1 0 0 1-1.162 0L5 8.229ZM17.28 7H6.72L12 10.771 17.28 7ZM5.6 5C4.23 5 3 6.063 3 7.5v9C3 17.937 4.23 19 5.6 19h12.8c1.37 0 2.6-1.063 2.6-2.5v-9C21 6.063 19.77 5 18.4 5H5.6Z"
      />
    </svg>
  )
}
