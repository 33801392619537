import { Chat } from '@api/chats/types/chat'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { parseChatUserLeft } from '@utils/redux-chats-handlers/parse-chat-user-left'
import { UpdateChat } from './models'

const chatsAdapter = createEntityAdapter<Chat>()

export const { selectAll: selectChats, selectById: selectChat } = chatsAdapter.getSelectors(
  (state: EntityState<Chat>) => state
)

const chatsSlice = createSlice({
  name: 'chats',
  initialState: chatsAdapter.getInitialState(),
  reducers: {
    setChat: chatsAdapter.setOne,
    setChats: chatsAdapter.setMany,
    markAsUnread: (state, action: PayloadAction<UpdateChat>) => {
      const { chatId } = action.payload
      const chat = selectChat(state, chatId)
      if (!chat) {
        return
      }
      chatsAdapter.updateOne(state, {
        id: chatId,
        changes: {
          unreadMessagesCount: chat.unreadMessagesCount + 1,
        },
      })
    },
    deleteChat: (state, action: PayloadAction<UpdateChat>) => {
      chatsAdapter.removeOne(state, action.payload.chatId)
    },
    markAsRead: (state, action: PayloadAction<UpdateChat>) => {
      const { chatId } = action.payload
      chatsAdapter.updateOne(state, {
        id: chatId,
        changes: {
          unreadMessagesCount: 0,
        },
      })
    },
    leaveChat: (state, action: PayloadAction<UpdateChat>) => {
      const { chatId, myUserId } = action.payload
      const chat = selectChat(state, chatId)
      if (!chat || !myUserId) return
      const updatedMyChatUser = parseChatUserLeft(chat, myUserId)

      if (updatedMyChatUser) {
        chatsAdapter.updateOne(state, {
          id: chatId,
          changes: {
            chatUsers: updatedMyChatUser,
          },
        })
      }
    },
    updateChat: (state, action: PayloadAction<UpdateChat>) => {
      const { chatId, chatDetails } = action.payload
      if (!chatDetails) {
        return
      }

      chatsAdapter.updateOne(state, {
        id: chatId,
        changes: chatDetails,
      })
    },
  },
})

export const { setChats, setChat, markAsUnread, markAsRead, updateChat, deleteChat, leaveChat } =
  chatsSlice.actions
export default chatsSlice.reducer
