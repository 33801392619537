import { Dialog } from '@headlessui/react'
import React, { FC } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { layout } from 'styled-system'
import { CloseIcon } from 'ui'
import { ExtendedPanelProps, ExtractProps, ModalProps, ModalVariant } from './types'

export const Modal: FC<ModalProps> = ({
  maxWidth,
  children,
  isOpen,
  closeModal,
  closeOnClickOutside,
  variant,
}) => {
  const theme = useTheme()

  if (variant === ModalVariant.FULL) {
    return (
      <StyledDialog open={isOpen} onClose={closeOnClickOutside ? closeModal : () => {}}>
        <Backdrop aria-hidden="true" />
        <FullScreenContainer>
          <CloseButton type="button" onClick={closeModal} aria-labelledby="Close">
            <CloseIcon fill={theme.colors.white} />
          </CloseButton>
          <StyledPanel variant={ModalVariant.FULL}>{children}</StyledPanel>
        </FullScreenContainer>
      </StyledDialog>
    )
  }

  return (
    <StyledDialog open={isOpen} onClose={closeOnClickOutside ? closeModal : () => {}}>
      <Backdrop aria-hidden="true" />
      <FullScreenContainer>
        <StyledPanel maxWidth={maxWidth} variant={ModalVariant.DEFAULT}>
          <CloseButton type="button" onClick={closeModal} aria-labelledby="Close">
            <CloseIcon />
          </CloseButton>
          {children}
        </StyledPanel>
      </FullScreenContainer>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)<ExtractProps<typeof Dialog>>`
  position: relative;
  z-index: 50;
`

const StyledPanel = styled(Dialog.Panel)<ExtendedPanelProps<ExtractProps<typeof Dialog.Panel>>>`
  width: 100%;
  margin: 0 auto;
  ${({ variant }) =>
    variant === ModalVariant.DEFAULT &&
    css`
      margin: 0 auto;
      position: relative;
      background: ${({ theme }) => theme.colors.white};
      padding: 3.6rem 2.4rem;
      border-radius: 1.6rem 1.6rem 1.6rem 0;
    `}
  ${layout}
`

const FullScreenContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
`

const CloseButton = styled.button`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  height: 2.4rem;
  width: 2.4rem;
  color: ${({ theme }) => theme.colors.greySuperDark};
  transition: color ${({ theme }) => theme.transitionTimes.short};
  outline: none;
  &:hover,
  &:focus-visible {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(55, 50, 52, 0.8);
`
