import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(new LanguageDetector(null, { order: ['querystring', 'cookie', 'localStorage'], caches: [] }))
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng: 'en',
    debug: false,
    ns: [
      'home',
      'chat',
      'login',
      'common',
      'not-found',
      'files',
      'user-detail',
      'workspace',
      'details',
      'modal-deactivate-member',
      'modal-delete-channel',
      'modal-delete-message',
      'modal-delete-ws',
      'modal-demote-member',
      'modal-reactions-limit',
      'modal-invite-ws-members',
      'modal-invite-channel-members',
      'modal-leave-channel',
      'modal-reactions-limit',
      'modal-revoke-account',
      'modal-revoke-invitation',
      'modal-sent-invitation',
      'modal-set-admin',
      'personal',
      'modal-create-channel',
      'translation-picker',
      'edit-avatar',
      'teammates',
      'sorting',
      'modal-update-email',
      'modal-email-updated',
      'members',
      'sidebar',
      'filters',
      'onboarding',
      'modal-leave-onboarding',
      'modal-select-language',
      'new-user',
      'reactions',
    ],
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {},
  })

export { i18n }
