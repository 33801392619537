import { getFeatureFlag } from '@utils/flags-manager'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, LanguageSelect } from 'ui'

export const LogoHeader = () => {
  const [interfaceLanguage, setInterfaceLanguage] = useState({ code: 'en', name: 'English' })
  const { t } = useTranslation('common')

  return (
    <LogoHeaderContainer as="header">
      <FlexContainer />
      <FlexContainer textAlign="center">
        <LogoContainer>
          <img src="/img/logo-native.svg" alt="Logo Native" width={106} height={20} />
        </LogoContainer>
      </FlexContainer>
      <FlexContainer textAlign="right">
        {getFeatureFlag('showOnProd') && (
          <LanguageSelect
            interfaceLanguage={interfaceLanguage}
            setInterfaceLanguage={setInterfaceLanguage}
            hiddenLabel={t('hiddenLabel.selectInterfaceLang')}
            hiddenLabelSelected={t('hiddenLabel.selected')}
            pr="2.4rem"
          />
        )}
      </FlexContainer>
    </LogoHeaderContainer>
  )
}

const LogoHeaderContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 2.4rem 1.6rem;
`

const FlexContainer = styled(Container)`
  flex: 1 1 0;
`

const LogoContainer = styled.div`
  & img {
    display: block;
    margin: 0 auto;
  }
`
