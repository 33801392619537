import { EmojiPopover } from '@components/emoji-popover'
import { setCursorPosition } from '@utils/set-cursor-position'
import { EmojiClickData } from 'emoji-picker-react'
import React, { useRef, useState, forwardRef, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import {
  Button,
  CheckmarkIcon,
  CloseIcon,
  Container,
  EmojiIcon,
  HiddenLabel,
  Text,
  useClickOutside,
} from 'ui'
import { MessageInputMode } from '.'
import {
  ExpandingInput,
  InputButton,
  ButtonWrapper,
  InputScrollContainer,
  InputWrapper,
} from './styled'

export interface EditGridProps {
  contentEditableInputText: string
  setContentEditableInputText: (newValue: string) => void
  cursorPosition: number
  handleOnChangeText: (target: HTMLDivElement) => void
  onClickSend: VoidFunction
  onClickClose?: VoidFunction
  isDisabled?: boolean
  hasReachedLimit?: boolean
  charsLeftText?: string
  onEmojiClick: ({ emoji }: EmojiClickData, event: MouseEvent, onClose: any) => void
  isEditLoading: boolean
}

export const EditGrid = forwardRef<HTMLDivElement | null, EditGridProps>(
  (
    {
      contentEditableInputText,
      setContentEditableInputText,
      cursorPosition,
      handleOnChangeText,
      onClickSend,
      onClickClose,
      isDisabled,
      hasReachedLimit,
      charsLeftText,
      onEmojiClick,
      isEditLoading,
    },
    ref
  ) => {
    const { t } = useTranslation(['chat'])
    const theme = useTheme()
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const inputRef = useRef<null | HTMLDivElement>(null)
    useClickOutside(inputRef, () => setIsFocused(false))

    useEffect(() => {
      const target = ref as MutableRefObject<HTMLDivElement>
      if (target.current) setCursorPosition(cursorPosition, target.current)
    }, [contentEditableInputText])

    useEffect(() => {
      const target = ref as MutableRefObject<HTMLDivElement>
      if (target.current) setCursorPosition(cursorPosition, target.current, true)
    }, [])

    return (
      <InputWrapper onFocus={() => setIsFocused(true)} ref={inputRef} isFocused={isFocused}>
        <InputScrollContainer messageInputMode={MessageInputMode.EDIT}>
          <ExpandingInput
            // https://www.jsdocs.io/package/@types/react#HTMLAttributes.contentEditable
            // @ts-ignore
            contentEditable="plaintext-only"
            placeholder={t('messageInput.placeholder')}
            onInput={(e) => {
              if (e.currentTarget.textContent) {
                setContentEditableInputText(e.currentTarget.textContent)
              }
              handleOnChangeText(e.currentTarget)
            }}
            ref={ref}
            suppressContentEditableWarning={true}
          >
            {contentEditableInputText}
          </ExpandingInput>
        </InputScrollContainer>
        <ButtonWrapper>
          <EmojiPopover
            onEmojiClick={onEmojiClick}
            floatProps={{ placement: 'top-start', offset: { crossAxis: -15 } }}
            pb="4.5rem"
          >
            <InputButton type="button" disabled={isEditLoading}>
              <HiddenLabel>{t('messageInput.addEmoji')}</HiddenLabel>
              <EmojiIcon />
            </InputButton>
          </EmojiPopover>
          <Container display="flex" alignItems="center" flexGap="2.4rem">
            {charsLeftText && (
              <Text
                color={hasReachedLimit ? theme.colors.red : theme.colors.greyDark}
                variant="smallTextRegular"
              >
                {charsLeftText}
              </Text>
            )}
            <InputButton type="button" onClick={onClickClose} disabled={isEditLoading}>
              <HiddenLabel>{t('messageInput.closeInput')}</HiddenLabel>
              <CloseIcon />
            </InputButton>
            <Button
              variant="primary"
              circle
              onClick={onClickSend}
              hiddenLabel={t('messageEdited')}
              disabled={isDisabled}
              icon={<CheckmarkIcon />}
            />
          </Container>
        </ButtonWrapper>
      </InputWrapper>
    )
  }
)

EditGrid.displayName = 'EditGrid'
