import { useLeaveChannel } from '@api/chats/hooks/use-leave-channel'
import { useAppDispatch } from '@app/hooks'
import { useSidePage } from '@hooks/use-side-page'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { leaveChat } from 'src/features/chats/slice'
import { Text, useModal } from 'ui'
import { ConfirmationModal } from '../confirmation'

interface LeaveChannelModalProps {
  channelId: string
  name: string
  myUserId: string
  navigateFn?: VoidFunction
}

export const LeaveChannelModal: FC<LeaveChannelModalProps> = ({
  channelId,
  myUserId,
  name,
  navigateFn,
}) => {
  const { closeModal } = useModal()
  const { setOpen } = useSidePage()
  const { t } = useTranslation('modal-leave-channel')
  const dispatch = useAppDispatch()

  const { mutate: leaveChannel, isLoading: isLeaveChannelLoading } = useLeaveChannel({
    onSuccess: (x, variables) => {
      dispatch(leaveChat({ chatId: variables.channelId, myUserId }))
      onSuccessCallback()
    },
    onError: () => toast.error(`${t('toastMessages.yourChangesWerentSaved')}`),
  })

  const onSuccessCallback = () => {
    toast.success(`${t('toastMessages.yourChangesWereSaved')}`)
    closeModal()
    setOpen(false)
    navigateFn?.()
  }

  const handleOnSubmit = () => {
    leaveChannel({ channelId })
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      isDisabled={isLeaveChannelLoading}
      onSubmit={handleOnSubmit}
    >
      <Text mb="4.8rem">{t('confirmation', { name })}</Text>
    </ConfirmationModal>
  )
}
