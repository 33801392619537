import React, { FC, createContext, useContext, ReactNode, useState, useEffect } from 'react'

interface BannersContextValues {
  internetConnectionBannerShown: boolean,
  notificationsBannerShown: boolean,
  setNotificationsBannerShown: any
}

const BannersContext = createContext<BannersContextValues>({
  internetConnectionBannerShown: true,
  notificationsBannerShown: true,
  setNotificationsBannerShown: () => {}
})

export const useBannersContext = () => useContext(BannersContext)

interface BannersContextProps {
  children: ReactNode
}

export const BannersProvider: FC<BannersContextProps> = ({ children }) => {
  const [internetConnectionBannerShown, setInternetConnectionBannerShown] = useState(false)
  const [notificationsBannerShown, setNotificationsBannerShown] = useState(Notification.permission === 'default')

  useEffect(() => {
    const networkHandler = () => {
      setInternetConnectionBannerShown(!navigator.onLine)
    }

    window.addEventListener('online', networkHandler)
    window.addEventListener('offline', networkHandler)
    return () => {
      window.removeEventListener('online', networkHandler)
      window.removeEventListener('offline', networkHandler)
    }
  }, [navigator.onLine])

  return <BannersContext.Provider value={{ internetConnectionBannerShown, notificationsBannerShown, setNotificationsBannerShown }}>{children}</BannersContext.Provider>
}
