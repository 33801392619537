import { generateAvatarRandomColor } from '@utils/helpers'
import styled from 'styled-components'

export const FilesList = styled.ul`
  margin: 0 2.4rem;
  padding-bottom: 5rem;
`

export const FilesItem = styled.li`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: -2.4rem;
    right: -2.4rem;
    top: -0.1rem;
    bottom: -0.1rem;
    background: transparent;
    transition: background ${(props) => props.theme.transitionTimes.short};
  }

  &:hover {
    & > a > svg {
      opacity: 1;
      fill: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.primary};
    }

    &::after {
      background: ${(props) => props.theme.colors.background};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.background};
  }
`

export const InnerContainer = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0.8rem 4rem 0.8rem 0;
  & > svg {
    position: absolute;
    opacity: 0;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    fill: ${(props) => props.theme.colors.transparent};
    transition: opacity ${(props) => props.theme.transitionTimes.short},
      fill ${(props) => props.theme.transitionTimes.short};
  }
`

// IMAGE
export const FileImagePreview = styled.div`
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 0.8rem;
  overflow: hidden;
  flex: 0 0 auto;
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.white};
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

// DEFAULT
export const FileGeneralPreview = styled(FileImagePreview)`
  background: ${generateAvatarRandomColor};
  &::after {
    content: url('/img/file.svg');
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

// AUDIO
export const FileAudioPreview = styled(FileGeneralPreview)`
  &::after {
    content: url('/img/audio.svg');
  }
`

// VIDEO
export const FileVideoPreview = styled(FileGeneralPreview)`
  background: ${(props) => props.theme.colors.background};
  &::after {
    content: url('/img/play.svg');
    background: linear-gradient(
      180deg,
      rgba(0, 40, 97, 0) 0%,
      rgba(0, 40, 97, 0) 0.01%,
      rgba(0, 40, 97, 0.7) 100%
    );
  }
`
export const VideoDuration = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  font-size: 1.2rem;
  padding: 0.4rem;
`
