import { Avatar } from '@api/account/types/avatar'
import { InviteWorkspaceUsersRequest, WorkspaceUserRole } from './generated'

export enum WorkspaceRoles {
  OWNER = 'Owner',
  MEMBER = 'Member',
  TEAMMATE = 'Teammate',
}

export interface Domain {
  name: string
}

export interface WorkspaceInvitation {
  id: string
  email: string
  expiresIn: string
  invitedAt: Date
  roles: WorkspaceUserRole[]
}

export interface WorkspaceUser {
  email: string
  roles: WorkspaceUserRole[]
  status: string
}

export interface Workspace {
  id: string
  name: string
  users: WorkspaceUser[]
  domains: Domain[]
  avatar: Avatar
  color: string
}

export interface WorkspaceToken {
  accessToken: string
  refreshToken: string
  expiresIn: number
}

export interface GetWorkspaceParams {
  id: string
}

export interface GetWorkspaceTokenParams {
  id: string
  enabled?: boolean
}

export enum MyWorkspaceStatus {
  INVITED = 'Invited',
  ALLOWED = 'Allowed',
  ACTIVATED = 'Activated',
  DEACTIVATED = 'Deactivated',
}

export interface MyWorkspace {
  avatar: Avatar
  id: string
  name: string
  color: string
  myStatus: MyWorkspaceStatus
  myRoles: string[]
}

export interface InviteWorkspaceUsersParams {
  workspaceId: string
  requestBody?: InviteWorkspaceUsersRequest
}
