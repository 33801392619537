import { useCreateMe } from '@api/account/hooks/use-create-me'
import { useGetMe } from '@api/account/hooks/use-get-me'
import { OnboardingType } from '@contexts/workspace-provider'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/routes'
import { Form, Formik } from 'formik'
import React, { FC, ReactNode, useEffect, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  UserInitialValues,
  WsInitialValues,
  userValidationSchema,
  wsValidationSchema,
} from './data'

interface OnboardingFormProps {
  bottomComponent: ReactNode | null
  onboardingType: OnboardingType
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const initialPrimaryLanguage = {
  isPrimary: true,
  name: 'English',
  code: 'en',
}

export const OnboardingForm: FC<OnboardingFormProps> = ({
  bottomComponent,
  onboardingType,
  setIsLoading,
}) => {
  const { workspaceId, removeOnboarding } = useWorkspace()
  const { mutateAsync: createMeAsync, isSuccess: isCreateMeSuccess } = useCreateMe()
  const { refetch: refetchMeData } = useGetMe(true)
  const navigate = useNavigate()
  const { t } = useTranslation('onboarding')
  const initialWsValues: WsInitialValues = {
    workspaceName: '',
    firstName: '',
    lastName: '',
    allowJoinByDomain: false,
    primaryLanguage: initialPrimaryLanguage,
  }

  const initialUserValues: UserInitialValues = {
    firstName: '',
    lastName: '',
    primaryLanguage: initialPrimaryLanguage,
  }

  const handleSubmit = async ({
    firstName,
    lastName,
    primaryLanguage: { code },
  }: UserInitialValues | WsInitialValues) => {
    setIsLoading(true)
    await createMeAsync({
      firstName,
      lastName,
      primaryLanguageCode: code,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isOnboarded: true,
    })
  }

  useEffect(() => {
    if (isCreateMeSuccess) {
      refetchMeData()
      navigate(`${routes.workspaces}/${workspaceId}/${routes.teammates}`)
      removeOnboarding()
    }
    setIsLoading(false)
  }, [isCreateMeSuccess, workspaceId, navigate, removeOnboarding, setIsLoading, refetchMeData])

  const initialValues = onboardingType === OnboardingType.WS ? initialWsValues : initialUserValues
  const validationSchema =
    onboardingType === OnboardingType.WS ? wsValidationSchema(t) : userValidationSchema(t)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form style={{ textAlign: 'center' }}>{bottomComponent}</Form>
    </Formik>
  )
}
