import { AvatarField } from '@components/skeleton-items/avatar-field'
import { Button } from '@components/skeleton-items/button'
import { InputField } from '@components/skeleton-items/input-field'
import React, { FC } from 'react'
import { Container } from 'ui'

export const PersonalGeneralSkeleton: FC = () => (
  <Container display="flex" flexDirection="column" flexGap="2.4rem">
    <AvatarField />
    <InputField />
    <InputField />
    <InputField />
    <InputField />
    <Button />
  </Container>
)
