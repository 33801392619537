import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Container } from 'ui'

export const ChevronContainer = styled(Container)`
  position: absolute;
  right: 1.2rem;
`

export const DropdownButton = styled(Container)<SpaceProps & { isOpen: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 4.8rem 0.8rem 1.2rem;
  border: ${(props) => `1px solid ${props.theme.colors.grey}`};
  border-radius: 0.8rem;
  width: 100%;
  ${space}
`

export const DropdownContainer = styled(Container)`
  position: relative;
  display: flex;
  min-height: 4rem;
  width: 100%;
  ${space}
`

export const DropdownContent = styled.ul`
  position: absolute;
  margin-top: 0.2rem;
  height: auto;
  padding: 0.4rem 0;
  text-wrap: none;
  z-index: 5;
  top: 100%;
  right: 0;
  width: min-content;
  min-width: 20rem;
  border-radius: 0.8rem;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.greyDark};
`

export const DropdownOption = styled(Container.withComponent('li'))`
  cursor: pointer;
  width: 100%;
  padding: 1rem 1.2rem;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.grey}`};
  :hover {
    background: ${({ theme }) => theme.colors.greyLight};
  }
  :last-child {
    border-bottom: none;
  }
`
