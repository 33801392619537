import { useGetSupportedLanguages } from '@api/account/hooks/use-get-supported-languages'
import { Chat } from '@api/chats/types/chat'
import { filterLanguageCodesBySupported } from '@utils/filter-language-codes-by-supported'
import { getLanguageNamesSeparatedByComma } from '@utils/get-language-names-separated-by-commas'
import { getUsersLanguageCodes } from '@utils/get-users-language-codes'
import { getArrayOfUniqueStrings } from '@utils/helpers'
import { useMemo } from 'react'

export const useFormattedChatLanguages = (chatDetails: Chat | undefined) => {
  const { data: supportedLanguages } = useGetSupportedLanguages({ skip: false })

  const languageCodes = useMemo(() => {
    if (!chatDetails) {
      return null
    }

    const codes = getUsersLanguageCodes(chatDetails.chatUsers)
    const uniqueCodes = getArrayOfUniqueStrings(codes)

    return uniqueCodes
  }, [chatDetails])

  const filteredLanguages =
    languageCodes &&
    supportedLanguages &&
    filterLanguageCodesBySupported(languageCodes, supportedLanguages)

  const languagesNamesSeparatedByCommas =
    filteredLanguages && getLanguageNamesSeparatedByComma(filteredLanguages)

  return languagesNamesSeparatedByCommas
}
