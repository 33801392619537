import { ConversationType } from '@utils/get-chat-type'
import { chatsApi } from './instance'
import { ConversationList } from './types/chat-list'

export interface GetChatListProps {
  pageNumber?: number
  pageParam?: number
  pageSize?: number
}

export const getChatList = async ({ pageParam = 1, pageSize = 20 }: GetChatListProps) => {
  const { data } = await chatsApi.get<ConversationList>(`/list`, {
    params: { pageNumber: pageParam, pageSize, type: ConversationType.CHAT },
  })
  return data
}
