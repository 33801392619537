import styled from 'styled-components'
import { Container } from 'ui'

export const Header = styled(Container)`
  display: grid;
  grid-template-columns: 1.25fr 1fr 1fr 2.5rem;
  gap: 2.4rem;
  align-items: center;
  height: 2.4rem;
`

export const LoaderContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
