import ArrowIcon from '@components/icons/arrow'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'ui'

export const TranslationsSkeleton: FC = () => (
  <>
    <Container display="flex" flexDirection="column" flexGap="1.2rem" pb="2.8rem">
      <Skeleton width={160} height={12} />
      <Skeleton width={288} height={90} style={{ borderRadius: 16, borderTopLeftRadius: 0 }} />
    </Container>
    <Container display="flex" flexDirection="column" flexGap="1.2rem">
      <Skeleton width="16rem" height="1.2rem" />
      <Skeleton width={288} height={90} style={{ borderRadius: 16, borderTopLeftRadius: 0 }} />
      <Skeleton
        width="28.8rem"
        height="9rem"
        style={{ borderRadius: 16, borderTopLeftRadius: 0 }}
      />
    </Container>
  </>
)

export const TranslationsHeaderSkeleton: FC = () => (
  <Container display="flex" alignItems="center" flexGap="0.8rem" p="1.6rem 0 2.8rem">
    <Skeleton width={124} height={40} borderRadius={40} />
    <ArrowIcon />
    <Skeleton width={124} height={40} borderRadius={40} />
  </Container>
)
