import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Text } from 'ui'
import { StyledButton } from './styled'

interface FreeTrialNoticeProps {
  freeTrialLeftDays: number
}

export const FreeTrialNotice: FC<FreeTrialNoticeProps> = ({ freeTrialLeftDays }) => {
  const { t } = useTranslation('sidebar')

  return (
    <Container p="0.8rem 1.2rem">
      <Text fontSize="1.2rem" fontWeight={500} lineHeight="1.6rem">
        {freeTrialLeftDays} {t('trialExpiryTitle')}
      </Text>
      <Text fontSize="1.2rem" fontWeight={400} lineHeight="1.6rem" mt="0.4rem">
        {t('trialExpirySubTitle')}
      </Text>
      <StyledButton plain mt="0.4rem" onClick={() => {}}>
        {t('trialExpiryButtonText')}
      </StyledButton>
    </Container>
  )
}
