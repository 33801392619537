import { useWorkspace } from '@hooks/use-workspace'
import { useFormikContext } from 'formik'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { Container, InputVariant, LimitTextField, SendIcon } from 'ui'
import { WsInitialValues } from '../data'
import { StyledContainer, SubmitButton } from '../styled'

interface OnboardingWsProps extends SpaceProps {
  moveToNextStep: VoidFunction
}

export const OnboardingWs: FC<OnboardingWsProps> = ({ moveToNextStep, ...spacing }) => {
  const { setWorkspaceName } = useWorkspace()
  const { values, errors, handleChange, handleBlur } = useFormikContext<WsInitialValues>()
  const { t } = useTranslation('onboarding')

  /**
Handler function to submit the form on pressing enter key
@param {KeyboardEvent} event - Keyboard event object
@returns {void}
*/
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !errors.workspaceName) {
      event.preventDefault()
      handleOnClick(values.workspaceName)
    }
  }

  /**
Handler function to update workspace name in the WorkspaceProvider and move to the next onboarding step
@returns {void}
*/
  const handleOnClick = (workspaceName: string) => {
    setWorkspaceName(workspaceName)
    moveToNextStep()
  }

  // Add keydown event listener to submit form on pressing enter key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [values])

  return (
    <Container {...spacing}>
      <StyledContainer>
        <LimitTextField
          id="workspaceName"
          name="workspaceName"
          autoFocus
          maxCharacters={100}
          variant={InputVariant.GHOST}
          placeholder={t('companyName')}
          hiddenLabel={t('companyName')}
          value={values.workspaceName}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.workspaceName}
          width="100%"
        />
        <SubmitButton
          variant="primary"
          hiddenLabel={t('submit')}
          circle
          disabled={!!errors.workspaceName}
          onClick={() => handleOnClick(values.workspaceName)}
          icon={<SendIcon />}
        />
      </StyledContainer>
    </Container>
  )
}
