import { UserLanguage } from '@api/account/types/language'
import { TFunction } from 'i18next'
import * as Yup from 'yup'

export interface WsInitialValues {
  workspaceName: string
  firstName: string
  lastName: string
  allowJoinByDomain: boolean
  primaryLanguage: UserLanguage
}

export interface UserInitialValues {
  firstName: string
  lastName: string
  primaryLanguage: UserLanguage
}

export const userValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required(t('errors.requiredField', { field: t('name') }))
      .matches(
        /^[-\p{L}]+$/u,
        t('errors.specialCharsNotAllowed', {
          field: t('name'),
        })
      ),
    lastName: Yup.string()
      .required(t('errors.requiredField', { field: t('surname') }))
      .matches(
        /^[-\p{L}]+$/u,
        t('errors.specialCharsNotAllowed', {
          field: t('surname'),
        })
      ),
    primaryLanguage: Yup.object().required(),
  })

export const wsValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    workspaceName: Yup.string()
      .required(t('errors.requiredField', { field: t('companyName') }))
      .min(2, t('errors.minTwoChars', { field: t('companyName').toLowerCase() })),
    allowJoinByDomain: Yup.boolean().required(),
    firstName: Yup.string()
      .required(t('errors.requiredField', { field: t('name') }))
      .matches(
        /^[-\p{L}]+$/u,
        t('errors.specialCharsNotAllowed', {
          field: t('name'),
        })
      ),
    lastName: Yup.string()
      .required(t('errors.requiredField', { field: t('surname') }))
      .matches(
        /^[-\p{L}]+$/u,
        t('errors.specialCharsNotAllowed', {
          field: t('surname'),
        })
      ),
    primaryLanguage: Yup.object().required(),
  })
