import { ConversationType } from '@utils/get-chat-type'
import qs from 'qs'
import { chatsApi } from './instance'
import { ConversationList } from './types/chat-list'

export interface GetChannelListProps {
  pageNumber?: number
  pageParam?: number
  pageSize?: number
}

export const getChannelList = async ({ pageParam = 1, pageSize = 20 }: GetChannelListProps) => {
  const { data } = await chatsApi.get<ConversationList>(`/list`, {
    params: {
      pageNumber: pageParam,
      pageSize,
      type: [
        ConversationType.PRIVATE_CHANNEL,
        ConversationType.PUBLIC_CHANNEL,
        ConversationType.GENERAL_CHANNEL,
      ],
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
  return data
}
