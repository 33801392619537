import { useGetMyWorkspaces } from '@api/workspaces/hooks/use-get-my-workspaces'
import { DotsLoader } from '@components/dots-loader'
import { useAuth } from '@hooks/use-auth'
import { routes } from '@routes/routes'
import { useEffect, useState } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Container } from 'ui'

const AuthCallBack = () => {
  const { t } = useTranslation(['login', 'common'])
  const [enable, setEnable] = useState(false)

  const auth = useAuth()
  const navigate = useNavigate()

  const { data, isSuccess, isError } = useGetMyWorkspaces({
    enabled: enable,
  })

  useEffect(() => {
    const login = async () => {
      await auth.loginCallback()
      setEnable(true)
    }
    login()
  }, [auth])

  useEffect(() => {
    if (isError) {
      toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`)
      navigate(routes.home)
      return
    }
    if (isSuccess) {
      if (data && data.length > 0) {
        navigate(routes.workspaces)
      } else {
        navigate(routes.newUser)
      }
    }
  }, [data, isSuccess, isError, navigate])

  return (
    <Container
      display="flex"
      justifyContent="center"
      alignContent="center"
      flexDirection="column"
      textAlign="center"
      height="100%"
    >
      <DotsLoader text={t('authCallBack')} />
    </Container>
  )
}

export { AuthCallBack }
