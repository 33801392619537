import { getTimeZones } from '@api/timezones/get-time-zones'
import { useQuery } from '@tanstack/react-query'

export const TIMEZONES = 'timezones'

export const useGetTimezones = () => {
  const { data, isError, isLoading, error } = useQuery([TIMEZONES], () => getTimeZones(), {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 120, //120 min
  })

  const timezones = data?.map((item) => ({ label: item.displayName, value: item.id }))

  return { timezones, isError, isLoading, error }
}
