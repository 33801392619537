import { workspaceApi } from '@api/workspaces/instance'
import { InviteUsersResponse, InviteWorkspaceUsersRequest } from './generated'

export const inviteWorkspaceUsers = async (
  workspaceId: string,
  requestBody?: InviteWorkspaceUsersRequest
) => {
  const { data } = await workspaceApi.post<InviteUsersResponse>(
    `workspaces/${workspaceId}/users`,
    requestBody
  )
  return data
}
