import { defaultImport } from 'default-import'
import React, { useState, FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import defaultStyled, { css } from 'styled-components'
import { useToastMessage } from '../../../hooks/use-toast-message'
import { Container } from '../../container'
import { Caption, HiddenLabel } from '../../typography'
import { GenericInput } from './generic-input'
import { InputLabel } from './labels'
import { ToastMessage } from './toast-message'
import { EyeButtonProps, TextFieldProps } from './types'

const styled = defaultImport(defaultStyled)

export const TextField: FC<TextFieldProps> = ({
  id,
  name,
  placeholder,
  label,
  hiddenLabel,
  maxLength,
  helperText,
  warning,
  postFix,
  disabled,
  type = 'text',
  value,
  iconHiddenLabel,
  toastMessage,
  copyToClipboard,
  toggleVisibility,
  autoFocus,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  variant,
  sizeVariant,
  description,
  tooltipText,
  ...spacing
}) => {
  const { isShown, show } = useToastMessage()
  const [isValueVisible, setIsValueVisible] = useState<boolean>(false)

  const renderPostFix = (
    isCopyToClipboard: boolean,
    toggleVisibility: boolean,
    hasPostFix: boolean
  ) => {
    if (isCopyToClipboard && hasPostFix) {
      return (
        <>
          <CopyToClipboard text={value || ''} onCopy={show}>
            <ClickableIcon>
              <>
                {postFix}
                <HiddenLabel>{iconHiddenLabel}</HiddenLabel>
              </>
            </ClickableIcon>
          </CopyToClipboard>
          <ToastMessage isVisible={isShown} message={toastMessage || 'Copied!'} />
        </>
      )
    }
    if (toggleVisibility && hasPostFix) {
      return (
        <EyeButton
          type="button"
          isValueVisible={isValueVisible}
          onClick={() => setIsValueVisible(!isValueVisible)}
        >
          {postFix}
        </EyeButton>
      )
    }
    if (!isCopyToClipboard && hasPostFix) {
      return (
        <GeneralIcon>
          {postFix}
          <HiddenLabel>{iconHiddenLabel}</HiddenLabel>
        </GeneralIcon>
      )
    }
    return null
  }

  const getInputType = (type: React.HTMLInputTypeAttribute) => {
    if (toggleVisibility && !isValueVisible) {
      return 'password'
    }
    return type
  }

  return (
    <Container display="flex" flexDirection="column" {...spacing}>
      {label && <InputLabel htmlFor={name} labelText={label} tooltipText={tooltipText} />}
      <Container position="relative">
        {hiddenLabel && <HiddenLabel>{hiddenLabel}</HiddenLabel>}
        <GenericInput
          id={id}
          name={name}
          contentEditable
          warning={warning}
          disabled={disabled}
          helperText={description || helperText}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          type={getInputType(type)}
          sizeVariant={sizeVariant}
          variant={variant}
          postFix={postFix}
          autoComplete="off"
          autoFocus={autoFocus}
        />
        {Boolean(helperText || description) && (
          <Container position="absolute" left="1.2rem" bottom="0.4rem">
            <HelperText isDescription={Boolean(description)}>
              {helperText ?? description}
            </HelperText>
          </Container>
        )}
        {renderPostFix(Boolean(copyToClipboard), Boolean(toggleVisibility), Boolean(postFix))}
      </Container>
    </Container>
  )
}

export const HelperText = styled(Caption)<{ isDescription?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 1.2rem;
  color: ${({ theme, isDescription }) =>
    isDescription ? theme.colors.greyDark : theme.colors.red};
`

const GeneralIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  display: flex;
  align-items: center;
`

const ClickableIcon = styled(GeneralIcon.withComponent('button'))`
  cursor: pointer;
  ${({ theme }) => {
    return css`
      color: ${theme.colors.primary};
      transition: color ${theme.transitionTimes.short};
      &:hover {
        color: ${theme.colors.primaryDark};
      }
    `
  }}
`

const EyeButton = styled(GeneralIcon.withComponent('button'))<EyeButtonProps>`
  ${({ theme, isValueVisible }) => {
    const { primary, grey, primaryDark, greyDark } = theme.colors
    return css`
      transition: color ${theme.transitionTimes.short};
      color: ${isValueVisible ? primary : grey};
      &:hover {
        color: ${isValueVisible ? primaryDark : greyDark};
      }
    `
  }}
`
