import { Reaction } from '@api/messages/types/message-detail'

export const mutateReaction = (reactionToMutate: Reaction, meId: string, added: boolean) => {
  const userReactionTime = { userId: meId, timestamp: new Date().toISOString() }

  if (added) {
    reactionToMutate.count++
    reactionToMutate.userReactionTimes.push(userReactionTime)
  } else {
    reactionToMutate.count--
    const mutatedReactionTimes = reactionToMutate.userReactionTimes.filter(
      (reaction) => reaction.userId !== meId
    )
    reactionToMutate.userReactionTimes = mutatedReactionTimes
  }
}
