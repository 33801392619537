import React from 'react'

export const FiltersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.5 2a2.001 2.001 0 0 0-2 2v2.586c0 .53.211 1.04.586 1.414L9.5 14.414V21a1 1 0 0 0 1.707.707l4-4A1 1 0 0 0 15.5 17v-2.586L21.914 8c.375-.375.586-.884.586-1.414V4a2.001 2.001 0 0 0-2-2Zm0 2h16v2.586L14.086 13a2.003 2.003 0 0 0-.586 1.414v2.172l-2 2v-4.172c0-.53-.211-1.04-.586-1.414L4.5 6.586Z" />
    </svg>
  )
}
