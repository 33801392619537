import { useAuth } from '@hooks/use-auth'
import { useWorkspace } from '@hooks/use-workspace'
import { DashboardOverview } from '@modules/dashboard-overview'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Heading, Text } from 'ui'

export const Dashboard: FC = () => {
  const { logout, user } = useAuth()
  const { removeWorkspace, removeOnboarding } = useWorkspace()
  const { t } = useTranslation('home')

  useEffect(() => {
    removeWorkspace()
    removeOnboarding()
  }, [])

  return (
    <Container height="100%" display="flex" flexDirection="column">
      <Container textAlign="center" width="50rem" m="4.8rem auto 0">
        <Heading variant="h0" mb="1.2rem">
          {t('welcome')}
        </Heading>
        <Text mb="4rem"> {t('intro')}</Text>
        <DashboardOverview />
      </Container>
      <Container textAlign="center" mt="auto" pt="2.2rem">
        <Text variant="textMedium">
          {t('loggedInAs')} {user?.profile.email}
        </Text>
        <Button variant="secondary" plain onClick={logout}>
          {t('change')}
        </Button>
      </Container>
    </Container>
  )
}
