import { useSkeleton } from '@hooks/use-skeleton'
import React, { ComponentType, FC, ReactElement, useEffect, useMemo } from 'react'

export interface WithSkeletonProps {
  componentName: string
}

export enum SkeletonComponentNames {
  DASHBOARD_1 = 'Dashboard1',
  INVITATION_VALID_1 = 'InvitationValid1',
  SETTINGS_PERSONAL_GENERAL_1 = 'SettingsPersonalGeneral1',
  SETTINGS_WORKSPACE_GENERAL_1 = 'SettingsWorkspaceGeneral1',
  SIDEBAR_CONTENT_1 = 'SidebarContent1',
  SIDEBAR_HEADER_1 = 'SidebarHeader1',
  TRANSLATION_PICKER_1 = 'TranslationPicker1',
  CONVERSATION_BODY_1 = 'ConversationBody1',
  CHAT_HEADER_1 = 'ChatHeader1',
  MESSAGE_INPUT_1 = 'MessageInput1',
  TRANSLATIONS_1 = 'Translations1',
  TRANSLATIONS_FOOTER_1 = 'TranslationsFooter1',
  TRANSLATIONS_HEADER_1 = 'TranslationsHeader1',
  CHANNEL_LIST_1 = 'ChannelList1',
}

export const withSkeleton = <T extends {}>(
  Component: ComponentType<T>,
  skeletonComponent: ReactElement
) => {
  const displayName = Component.name || Component.displayName || 'Component'

  type ComponentWithSkeletonProps = T & WithSkeletonProps

  const ComponentWithSkeleton: FC<ComponentWithSkeletonProps> = (props) => {
    const { skeletonStates, registerComponent, isSkeletonVisible } = useSkeleton()

    useEffect(() => registerComponent(props.componentName), [props.componentName])

    const isVisible = useMemo(() => isSkeletonVisible(props.componentName), [skeletonStates])

    return isVisible ? skeletonComponent : <Component {...props} />
  }

  // Create a clear name for React Dev Tools
  ComponentWithSkeleton.displayName = `withSkeleton(${displayName})`

  return ComponentWithSkeleton
}
