import { useMutation, useQueryClient } from '@tanstack/react-query'
import { joinChannel } from '../join-channel'
import { CHANNEL_LIST, CHAT_LIST } from '../query-keys'

interface UseJoinChannelInput {
  conversationId: string
  onSuccessCallback: CallableFunction
  onErrorCallback: CallableFunction
}

export const useJoinChannel = ({
  conversationId,
  onSuccessCallback,
  onErrorCallback,
}: UseJoinChannelInput) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (channelId: string) => joinChannel({ channelId }),
    onSuccess: () => {
      queryClient.invalidateQueries([CHANNEL_LIST])
      queryClient.invalidateQueries([CHAT_LIST, conversationId])

      onSuccessCallback?.()
    },
    onError: () => {
      onErrorCallback?.()
    },
  })
}
