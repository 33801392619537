import { PersonalProfile } from '@api/account/types/personal-profile'
import { Workspace } from '@api/workspaces/types'
import { NameWithState } from '@components/name-with-state'
import { OnboardingType } from '@contexts/workspace-provider'
import { useWorkspace } from '@hooks/use-workspace'
import { getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled, { useTheme } from 'styled-components'
import { Avatar, ChevronDownIcon, Container, ScrollContainer, StatusVariant, Text } from 'ui'
import { SidebarContentSkeleton } from '../sidebar-content/skeleton'

interface SidebarOnboardingProps {
  workspace?: Workspace
  me?: PersonalProfile
}

export const SidebarOnboarding: FC<SidebarOnboardingProps> = ({ workspace, me }) => {
  const { workspaceName, onboardingType } = useWorkspace()
  const theme = useTheme()
  const color = onboardingType === OnboardingType.USER ? `#${workspace?.color}` : theme.colors.blue
  const wsName = onboardingType === OnboardingType.USER ? workspace?.name : workspaceName

  return (
    <ScrollContainer hideScrollbar>
      <Header>
        <HeaderContainer flexGap="0.8rem">
          {wsName ? (
            <Avatar size="4.4rem" name={wsName} label={wsName} bgColor={color} fontSize="2.4rem" />
          ) : (
            <Skeleton height={44} width={44} />
          )}
          <Container flex={1}>
            {wsName ? (
              <Container display="flex" alignItems="center">
                <WorkspaceName mr="0.5rem">{wsName}</WorkspaceName>
                <Container as="span" mb="-0.2rem">
                  <ChevronDownIcon />
                </Container>
              </Container>
            ) : (
              <Container mb="0.3rem">
                <Skeleton height={12} />
              </Container>
            )}
            {me?.firstName && me?.lastName ? (
              <NameWithState
                isPopup={false}
                name={getFullName(me.firstName, me.lastName)}
                status={StatusVariant.ONLINE}
              />
            ) : (
              <Skeleton height={12} />
            )}
          </Container>
          <Skeleton height={36} width={36} borderRadius={12} />
        </HeaderContainer>
        <Skeleton height={40} width={250} />
      </Header>
      <SidebarContentSkeleton />
    </ScrollContainer>
  )
}

const WorkspaceName = styled(Text)`
  font-size: 1.8rem;
  font-weight: 600;
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const Header = styled.div`
  padding: 0 1.6rem;
`
const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
`
