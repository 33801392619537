import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SortingIconType = 'default' | 'up' | 'down'

type SortingIcons = {
  name: SortingIconType
  role: SortingIconType
  joiningDate: SortingIconType
}

export interface UserSortingSlice {
  sortBy: string
  sortDesc: boolean
  sortingIcons: SortingIcons
}

export enum SortingType {
  NAME = 'name',
  NAME_REVERSED = 'name-reversed',
  ROLE = 'role',
  ROLE_REVERSED = 'role-reversed',
  JOINED = 'joiningDate',
  JOINED_REVERSED = 'joiningDate-reversed',
}

const DEFAULT_ICONS: SortingIcons = {
  name: 'default',
  role: 'default',
  joiningDate: 'default',
}

const initialState: UserSortingSlice = {
  sortBy: SortingType.NAME,
  sortDesc: false,
  sortingIcons: {
    name: 'down',
    role: 'default',
    joiningDate: 'default',
  },
}

const userSortingSlice = createSlice({
  name: 'userSorting',
  initialState,
  reducers: {
    setSortingType: (state, action: PayloadAction<SortingType>) => {
      const sortingType = action.payload
      const sortName = sortingType.replace('-reversed', '')
      const includesReversed = sortingType.includes('reversed')
      state.sortingIcons = { ...DEFAULT_ICONS, [sortName]: includesReversed ? 'up' : 'down' }
      state.sortBy = sortName
      state.sortDesc = includesReversed
    },
  },
})

export const { setSortingType } = userSortingSlice.actions
export default userSortingSlice.reducer
