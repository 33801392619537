import styled, { css } from 'styled-components'
import { SpaceProps, space, width } from 'styled-system'
import { Container, Text } from 'ui'
import { MessageStylesProps, MessageVariant } from '.'
import { MessageFooterProps, MessageHeaderProps, SpacerProps } from './default-message'

const parseMessageStyles = (fromAnotherUser: boolean, variant: MessageVariant) => {
  switch (variant) {
    case MessageVariant.IS_SENDING:
      return css`
        background-color: ${({ theme }) => theme.colors.primaryLoading};
        ${MessageText} {
          color: ${({ theme }) => theme.colors.white};
        }
      `
    case MessageVariant.NOT_SENT:
      return css`
        background-color: ${({ theme }) => theme.colors.red};
        ${MessageText} {
          color: ${({ theme }) => theme.colors.white};
        }
        ${Timestamp} {
          color: ${({ theme }) => theme.colors.pinkLight};
        }
      `
    case MessageVariant.DELETED:
      return fromAnotherUser
        ? css`
            background-color: ${({ theme }) => theme.colors.white};
            ${MessageText} {
              color: ${({ theme }) => theme.colors.black};
            }
            ${DeleteIndicator} {
              color: ${({ theme }) => theme.colors.greyDark};
            }
            ${Timestamp} {
              color: ${({ theme }) => theme.colors.greySuperDark};
            }
          `
        : css`
            background-color: ${({ theme }) => theme.colors.primary};
            ${MessageText} {
              color: ${({ theme }) => theme.colors.white};
            }
            ${DeleteIndicator} {
              color: ${({ theme }) => theme.colors.primaryLighter};
            }
            ${Timestamp} {
              color: ${({ theme }) => theme.colors.primaryLighter};
            }
          `
    default:
    case MessageVariant.DEFAULT:
      return fromAnotherUser
        ? css`
            background-color: ${({ theme }) => theme.colors.white};
            ${MessageText} {
              color: ${({ theme }) => theme.colors.black};
            }
            ${Timestamp} {
              color: ${({ theme }) => theme.colors.greySuperDark};
            }
            ${IconsContainer} {
              color: ${({ theme }) => theme.colors.greyDark};
            }
          `
        : css`
            background-color: ${({ theme }) => theme.colors.primary};
            ${MessageText} {
              color: ${({ theme }) => theme.colors.white};
            }
            ${Timestamp} {
              color: ${({ theme }) => theme.colors.primaryLighter};
            }
            ${IconsContainer} {
              color: ${({ theme }) => theme.colors.primaryLighter};
            }
          `
  }
}

export const MessageContainer = styled(Container)`
  display: flex;
  //  below selector makes space between the direct children of the same kind that come one after another
  & > * + * {
    margin-left: 0.8rem;
  }
  ${space}
`

export const MessageBody = styled(Container)<MessageStylesProps>`
  position: relative;
  flex: ${({ hasAttachments }) => (hasAttachments ? 0 : '0 1 auto')};
  max-width: 70%;
  padding: 0.4rem;
  word-break: break-word;
  border-radius: ${({ isSender }) =>
    isSender ? '0 1.6rem 1.6rem 1.6rem' : '1.6rem 1.6rem 0 1.6rem'};
  ${({ isSender, variant }) => parseMessageStyles(isSender, variant)}
`

export const MessageHeader = styled(Container)<MessageHeaderProps>`
  ${({ shouldBeTranslated }) => {
    const translationStyles = css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `

    return css`
      padding: 0.4rem 0.8rem 0;
      ${shouldBeTranslated && translationStyles}
    `
  }}
`

export const MessageText = styled(Container)`
  padding: 0.4rem 0.8rem;
`

export const MessageFooter = styled(Container)<MessageFooterProps>`
  display: flex;
  align-items: center;
  float: right;
  padding-left: 0.8rem;
  margin: ${({ showReactions }) => (showReactions ? '-0.1rem 0 -0.4rem 0' : '-1.9rem 0.8rem 0 0')};
  position: relative;
  transition: margin ${({ theme }) => theme.transitionTimes.short};
`

export const Timestamp = styled(Text)``

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconsContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-right: 0.6rem;
`

// Deleted message
export const DeleteIndicator = styled.span`
  width: 2.4rem;
  height: 2.4rem;
  margin: -0.1rem 0.4rem 0 0;
`

export const TranslationButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.colors.greyDark};
  transition: ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

// Trick to keep the timestamp in correct place
export const Spacer = styled.span<SpacerProps>`
  display: inline-block;
  height: 1rem;
  ${width}
`

// Fake message
export const FakeMessageBody = styled(MessageBody)`
  padding: 1.6rem 1.6rem 1.4rem;
`
