import { UserLanguage } from '@api/account/types/language'
import React, { FC, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicSearch, Button, CheckmarkIcon, HiddenLabel, Heading, Text } from 'ui'
import {
  EmptyResults,
  LanguageButton,
  LanguageItem,
  LanguagesContainer,
  PrimaryIndicator,
} from './styled'

interface SelectLanguageModal {
  handleClick: (selectedLanguage: UserLanguage) => void
  supportedLanguages?: UserLanguage[]
}

export interface LanguageButtonProps {
  isPrimary?: boolean
}

export const SelectLanguageModal: FC<SelectLanguageModal> = ({
  handleClick,
  supportedLanguages,
}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [languages, setLanguages] = useState<UserLanguage[]>(supportedLanguages || [])
  const { t } = useTranslation(['modal-select-language', 'common'])

  // Filter languages by starting character
  const filteredLanguages = useMemo(() => {
    return languages?.filter((lang) => lang.name.toLowerCase().startsWith(searchString))
  }, [searchString, languages])

  // Toggle isPrimary value in the list if languages
  const toggleIsPrimary = (givenValue: string) => {
    const newArray = languages?.map((lang) => {
      if (lang.code === givenValue) {
        return { ...lang, isPrimary: true }
      }
      return { ...lang, isPrimary: false }
    })
    setLanguages(newArray || [])
  }

  const selectedLanguage = useMemo(
    () => filteredLanguages.filter((lang) => lang.isPrimary)[0],
    [languages]
  )

  // Check if there is any primary language
  const noPrimaryLanguage = useMemo(() => {
    return languages?.filter((lang) => lang.isPrimary).length === 0
  }, [languages])

  return (
    <>
      <Heading as="h2" mb="1.6rem">
        {t('choseLan')}
      </Heading>
      <BasicSearch
        id="select-lang"
        name="select-lang"
        hiddenLabel={t('search')}
        placeholder={t('search')}
        searchString={searchString}
        setSearchString={setSearchString}
        mb="1.2rem"
      />
      {filteredLanguages && filteredLanguages.length > 0 ? (
        <LanguagesContainer>
          <ul>
            {filteredLanguages?.map((lang) => (
              <LanguageItem key={lang.code}>
                <LanguageButton
                  type="button"
                  isPrimary={lang.isPrimary}
                  onClick={() => toggleIsPrimary(lang.code)}
                >
                  {lang.name}
                  {lang.isPrimary && (
                    <PrimaryIndicator>
                      <CheckmarkIcon />
                      <HiddenLabel>{t('lanSelected')}</HiddenLabel>
                    </PrimaryIndicator>
                  )}
                </LanguageButton>
              </LanguageItem>
            ))}
          </ul>
        </LanguagesContainer>
      ) : (
        <EmptyResults>
          <Text fontSize="2.2rem" fontWeight={600} mb="0.8rem">
            {t('nothingFound')}
          </Text>
          <Text>
            {t('emptyResults')} <br />
            {t('newSearch')}
          </Text>
        </EmptyResults>
      )}
      <Button
        width="100%"
        disabled={noPrimaryLanguage}
        onClick={() => handleClick(selectedLanguage)}
      >
        {t('save', { ns: 'common' })}
      </Button>
    </>
  )
}
