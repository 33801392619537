import React from 'react'
import { Container, HelpIcon, HelpTooltipProps, Tooltip } from 'ui'

export const HelpTooltip = ({ size, ...props }: Partial<HelpTooltipProps>) => (
  <Tooltip style={{ cursor: 'pointer' }} placement="top" {...props}>
    <Container as="span" color="greyDark">
      <HelpIcon
        width={size === 'sm' ? 16 : size === 'md' ? 22 : 24}
        height={size === 'sm' ? 16 : size === 'md' ? 22 : 24}
      />
    </Container>
  </Tooltip>
)
