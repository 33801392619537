import { messagesApi } from './instance'
import { Message } from './types/message'

export interface UpdateTranslationPreferenceInput {
  messageId: string
  languageCode: string
  provider: number
}

export const updateTranslationPreference = async ({
  messageId,
  languageCode,
  provider,
}: UpdateTranslationPreferenceInput) => {
  const { data } = await messagesApi.post<Message>(`${messageId}/translation-preference`, {
    languageCode,
    provider,
  })
  return data
}
