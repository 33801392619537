import { toast } from 'react-toastify'

interface CopyToClipBoardProps {
  text: string
  successMsg?: string
  errorMsg?: string
}

export const useCopyToClipboard = () => {
  const copyToClipboard = ({ text, successMsg, errorMsg }: CopyToClipBoardProps) => {
    try {
      navigator.clipboard.writeText(text.trim())
      successMsg && toast.success(successMsg)
    } catch (_) {
      errorMsg && toast.error(errorMsg)
    }
  }

  return { copyToClipboard }
}
