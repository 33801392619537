import { Actions } from '@api/account/toggle-mute'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

export const useToggleMute: () => [boolean, (userId: string) => void] = () => {
  const [isMuted, setIsMuted] = useState(false)

  const { mutate: toggleMute } = useMutation({
    mutationFn: (userId: string) =>
      toggleMute({ userId, action: isMuted ? Actions.UNMUTE : Actions.MUTE }),
    onSuccess: () => {
      setIsMuted((prev) => !prev)
    },
  })

  return [isMuted, (userId: string) => toggleMute(userId)]
}
