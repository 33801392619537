import React, { FC } from 'react'
import { Button, Container, ImgContainer, Heading, Text, useModal } from 'ui'

interface LimitModalProps {
  title: string
  text: string
  buttonLabel: string
}

export const LimitModal: FC<LimitModalProps> = ({ title, text, buttonLabel }) => {
  const { closeModal } = useModal()

  return (
    <Container width="40rem" textAlign="center">
      <ImgContainer maxWidth="26.1rem" m="0 auto 2rem">
        <img src="/img/wrong-keyboard.svg" alt="Sending" width={220} height={128} />
      </ImgContainer>
      <Heading as="h2" mb="0.8rem">
        {title}
      </Heading>
      <Text mb="4.5rem">{text}</Text>
      <Button type="submit" width="100%" onClick={closeModal}>
        {buttonLabel}
      </Button>
    </Container>
  )
}
