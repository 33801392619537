import { ConfirmationModal } from '@modules/modals/confirmation'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, useModal } from 'ui'

export interface DemoteMemberModalProps {
  id: string
  fullName?: string
  email?: string
}

export const DemoteMemberModal: FC<DemoteMemberModalProps> = ({ id, fullName, email }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-demote-member')

  //Temporary
  const handleOnSubmit = () => {
    const alertStringName = `${fullName}, id: ${id}`
    const alertStringEmail = `${email}, id: ${id}`
    if (fullName) {
      alert(alertStringName)
    }
    if (email) {
      alert(alertStringEmail)
    }
    closeModal()
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      onSubmit={handleOnSubmit}
    >
      <Text mb="4.8rem">
        {fullName && t('confirmationName', { fullName })}
        {email && t('confirmationEmail', { email })}
      </Text>
    </ConfirmationModal>
  )
}
