import { JsonPatchDocument, WorkspaceOperation } from '@api/workspaces/generated'
import { patchWorkspace, PatchWorkspacePath } from '@api/workspaces/patch-workspace'
import { WORKSPACE } from '@api/workspaces/tags'
import { OnSuccessCallback } from '@hooks/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { reduce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export interface MutationArgs {
  id: string
  name?: string
  avatarId?: string | null
}

interface UsePatchWorkspaceProps {
  onSuccessCallback?: OnSuccessCallback
}

export const usePatchWorkspace = ({ onSuccessCallback }: UsePatchWorkspaceProps = {}) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: MutationArgs) => {
      const { id, ...paramsWithoutId } = params
      const data = reduce(
        paramsWithoutId,
        (acc, value, key) => {
          return [
            ...acc,
            {
              op: 'replace',
              path: key as PatchWorkspacePath,
              value,
            },
          ]
        },
        [] as Array<WorkspaceOperation>
      )

      return patchWorkspace({ id, requestBody: data as JsonPatchDocument })
    },
    onMutate: async (newWorkspaceData) => {
      await queryClient.cancelQueries([WORKSPACE, newWorkspaceData.id])
      const previousWorkspaceData = queryClient.getQueryData([WORKSPACE, newWorkspaceData.id])
      queryClient.setQueryData([WORKSPACE, newWorkspaceData.id], newWorkspaceData)
      return { previousWorkspaceData }
    },
    onError: (_, v, context) => {
      toast.error(`${t('changesNotSaved')}.`)
      queryClient.setQueryData([WORKSPACE, v.id], context?.previousWorkspaceData)
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback(null, null)
      }
      toast.success(`${t('changesSaved')}.`)
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([WORKSPACE, variables.id])
    },
  })

  return { mutate, isLoading }
}
