import { OnboardingType } from '@contexts/workspace-provider'
import { useAuth } from '@hooks/use-auth'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/routes'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Heading, ImgContainer, Text } from 'ui'

export const NewUser: FC = () => {
  const { logout, user } = useAuth()
  const { setOnboardingType } = useWorkspace()
  const navigate = useNavigate()
  const { t } = useTranslation('new-user')

  const handleOnCreate = () => {
    setOnboardingType(OnboardingType.WS)
    navigate(`${routes.workspaces}/${routes.createNew}`)
  }

  return (
    <>
      <Container maxWidth="70rem" m="4.8rem auto 3.2rem" textAlign="center">
        <Heading variant="h0" mb="1.2rem">
          {t('newToNative')}
        </Heading>
        <Text maxWidth="43rem" m="0 auto">
          {t('newToNativeInfo')}&nbsp;
          <Text as="span" fontWeight={500}>
            {user?.profile.email}
          </Text>
          .
        </Text>
      </Container>
      <Container display="flex" flexGap="1.2rem" justifyContent="center" mb="8rem">
        <Button variant="secondary" width="24rem" onClick={handleOnCreate}>
          {t('createNewWs')}
        </Button>
        <Button variant="secondary" width="24rem" onClick={logout}>
          {t('tryDifferentEmail')}
        </Button>
      </Container>
      <ImgContainer textAlign="center" maxWidth="55rem" m="0 auto">
        <img src="/img/group.svg" alt={t('groupImgAlt')} width={550} height={304} />
      </ImgContainer>
    </>
  )
}
