import { useGetMe } from '@api/account/hooks/use-get-me'
import { useAppDispatch } from '@app/hooks'
import { useMutation } from '@tanstack/react-query'
import {
  resendMessage,
  sendMessage as sendMessageAction,
  setMessageError,
  setMessageSent,
} from '../../../features/conversation/slice'
import { sendMessage, SendMessageProps } from '../send-message'
import { Message } from '../types/message'
import { NetworkMode } from '@api/enums'

export const MESSAGE_MUTATION = 'message'

interface SendMessageCallbacks {
  onMutateCallback?: CallableFunction
  onErrorCallback?: CallableFunction
  onSuccessCallback?: CallableFunction
}

export const useSendMessage = ({
  onMutateCallback,
  onErrorCallback,
  onSuccessCallback,
}: SendMessageCallbacks = {}) => {
  const dispatch = useAppDispatch()
  const { meData } = useGetMe()
  return useMutation<Message, Error, SendMessageProps>(
    [MESSAGE_MUTATION],
    ({ chatId, clientMessageId, mediaIds, text }) =>
      sendMessage({ chatId, clientMessageId, mediaIds, text }),
    {
      networkMode: NetworkMode.OFFLINE_FIRST,
      onMutate: (args) => {
        const messageDetails = {
          clientMessageId: args.clientMessageId,
          chatId: args.chatId,
          isDeleted: false,
          createdBy: meData?.userId ?? '',
          createdAt: new Date(),
          originalMessage: args.text,
          sourceLanguageCode: '',
          translations: [],
          reactions: [],
          attachments: args.attachments,
        }
        if (args.isResending) {
          dispatch(resendMessage(messageDetails))
          return
        }
        dispatch(sendMessageAction(messageDetails))
        onMutateCallback?.()
      },
      onError: (error, args) => {
        dispatch(setMessageError(args))
        onErrorCallback?.()
      },
      onSuccess: (response, args) => {
        dispatch(setMessageSent({ ...response, ...args }))
        onSuccessCallback?.()
      },
    }
  )
}
