import { useGetMe } from '@api/account/hooks/use-get-me'
import { UserLanguage } from '@api/account/types/language'
import { FakeMessage, FakeMessageProps } from '@components/fake-message'
import { TypingIndicator } from '@components/typing-indicator'
import { OnboardingType } from '@contexts/workspace-provider'
import { useAutoScroll } from '@hooks/use-auto-scroll'
import { OnboardingPersonal } from '@modules/forms/onboarding/personal'
import { OnboardingPrimaryLanguage } from '@modules/forms/onboarding/primary-language'
import { LeaveOnboardingModal } from '@modules/modals/leave-onboarding'
import { getFullName } from '@utils/helpers'
import React, {
  useMemo,
  useRef,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, useModal } from 'ui'
import { getNativeMessages } from '../data'

interface OnboardingChatUserProps {
  setBottomComponent: Dispatch<SetStateAction<ReactNode>>
  height: string
}

const shortTimeout = 1500
const longTimeout = 1800

export const OnboardingChatUser: FC<OnboardingChatUserProps> = ({ setBottomComponent, height }) => {
  const { meData } = useGetMe(true)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [fakeMessages, setFakeMessages] = useState<FakeMessageProps[]>([])
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [primaryLanguage, setPrimaryLanguage] = useState<UserLanguage>()
  const { openModal } = useModal()
  const { t } = useTranslation(['onboarding'])

  const scrollAnchorRef = useRef<HTMLDivElement>(null)
  const mostRecentMessageRef = useRef<HTMLDivElement>(null)

  useAutoScroll(mostRecentMessageRef, [height, fakeMessages, isTyping])

  const moveToNextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const nativeMessages = useMemo(() => {
    return getNativeMessages(t, OnboardingType.USER)
  }, [])

  // Triger the Leave modal when clicking browser back button
  useEffect(() => {
    history.pushState(null, '', location.href)
    window.onpopstate = function () {
      openModal({ content: <LeaveOnboardingModal /> })
      history.go(1)
    }
    return () => {
      window.onpopstate = null
    }
  }, [openModal])

  useEffect(() => {
    scrollToBottom()
  }, [height])

  const scrollToBottom = () => {
    if (scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // Chat conversation
  useEffect(() => {
    switch (activeStep) {
      case 1:
        setFakeMessages([
          ...fakeMessages,
          {
            isSender: false,
            text: t('chat.myNameIs', {
              name: getFullName(meData?.firstName, meData?.lastName),
            }),
          },
        ])
        setBottomComponent(null)
        moveToNextStep()
        break
      case 2:
        setTimeout(() => {
          setFakeMessages([...fakeMessages, nativeMessages[2]])
        }, shortTimeout)
        setIsTyping(true)
        setTimeout(() => {
          setBottomComponent(
            <OnboardingPrimaryLanguage
              moveToNextStep={moveToNextStep}
              setPrimaryLanguage={setPrimaryLanguage}
            />
          )
          setFakeMessages([...fakeMessages, nativeMessages[2], nativeMessages[3]])
          setIsTyping(false)
        }, longTimeout)
        break
      case 3:
        setBottomComponent(null)
        setFakeMessages([
          ...fakeMessages,
          {
            isSender: false,
            text: primaryLanguage?.name,
          },
        ])
        moveToNextStep()
        break
      case 4:
        setIsTyping(true)
        setTimeout(() => {
          setIsTyping(false)
          setFakeMessages([...fakeMessages, nativeMessages[4]])
          setBottomComponent(
            <Button type="submit" width="18rem" onClick={moveToNextStep}>
              {t('getStarted')}
            </Button>
          )
        }, shortTimeout)
        break
      case 5:
        setBottomComponent(null)
        break
      default:
        setFakeMessages([nativeMessages[0], nativeMessages[1]])
        setBottomComponent(<OnboardingPersonal moveToNextStep={moveToNextStep} />)
        break
    }
  }, [activeStep])

  return (
    <>
      {fakeMessages.map((message, index) => (
        <FakeMessage
          key={message.text}
          isSender={message.isSender}
          text={message.text}
          avatarUrl={message.avatarUrl}
          hideAvatar={index > 0 && message.isSender === fakeMessages[index - 1].isSender}
        />
      ))}
      {isTyping && <TypingIndicator text={t('nativeTyping')} m="2rem 0 2rem 5rem" />}
      <div ref={scrollAnchorRef} />
    </>
  )
}
