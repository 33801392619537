import { accountApi } from '@api/account/instance'
import { ToggleMuteResponse } from '@api/account/types/user'
import { AxiosResponse } from 'axios'

export enum Actions {
  MUTE = 'mute',
  UNMUTE = 'unmute',
}

interface ToggleMuteParams {
  userId: string
  action: Actions
}

export const toggleMute: (
  params: ToggleMuteParams
) => Promise<AxiosResponse<ToggleMuteResponse>> = async ({ userId, action }) => {
  return await accountApi.post<ToggleMuteResponse>(`users/${userId}/${action}`)
}
