import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { LockIcon, TextIcon } from '@components/icons'
import { getChatName } from '@utils/get-chat-name'
import { ConversationType } from '@utils/get-chat-type'
import { getFullName } from '@utils/helpers'
import { format } from 'date-fns'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Avatar, Container, Heading, Text } from 'ui'

export const EmptyChannelMessages: FC = () => {
  const { channelId } = useParams()
  const { data: chatDetails } = useGetChatDetails(channelId)
  const { t } = useTranslation('create-new')

  const creatorFullName = useMemo(() => {
    const creator = chatDetails?.chatUsers.find(
      ({ user }) => user.userId === chatDetails.createdBy
    )?.user
    return getFullName(creator?.firstName, creator?.lastName)
  }, [chatDetails])

  const creationFortmattedDate = useMemo(() => {
    if (chatDetails?.createdAt) {
      return format(new Date(chatDetails.createdAt), 'MMMM d, yyyy').toString()
    }
    return ''
  }, [chatDetails])
  if (!chatDetails) {
    return null
  }
  return (
    <Container>
      <Container display="flex" alignItems="center" mb="1.7rem">
        <Avatar
          bgColor={`#${chatDetails.color}`}
          icon={
            chatDetails.conversationType === ConversationType.PRIVATE_CHANNEL ? (
              <LockIcon width={28} height={28} />
            ) : (
              <TextIcon width={28} height={28} />
            )
          }
        />
        <Heading variant="h2" as="h2" ml="1.2rem">
          {t('emptyChannelMsg.heading')} {getChatName(chatDetails, t)}
        </Heading>
      </Container>
      <Text>
        {t('emptyChannelMsg.info', {
          channelName: getChatName(chatDetails, t),
          creator: creatorFullName,
          date: creationFortmattedDate,
        })}{' '}
        {chatDetails.conversationType === ConversationType.PRIVATE_CHANNEL
          ? t('emptyChannelMsg.privateChannelText')
          : t('emptyChannelMsg.publicChannelText')}
      </Text>
    </Container>
  )
}
