import { useGetUsers } from '@api/account/hooks/use-get-users'
import { useAddUsersToChannel } from '@api/chats/hooks/use-add-users'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { DotsLoader } from '@components/dots-loader'
import { Form, Formik } from 'formik'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, useModal } from 'ui'
import { initialValues } from './data'
import { InviteToChannelSelect } from './invite-select'
import { InviteChannelMembersValues } from './types'

interface AddChannelMembersFormProps {
  channelId: string
}

export const AddChannelMembersForm: FC<AddChannelMembersFormProps> = ({ channelId }) => {
  const formRef = React.useRef<HTMLFormElement>(null)

  const { t } = useTranslation('modal-add-channel-members')
  const { users, hasNextPage, isUsersFetching, isFetchingNextPage, fetchNextPage } = useGetUsers()

  const { mutate, isLoading, isSuccess } = useAddUsersToChannel(channelId)
  const { data } = useGetChatDetails(channelId)
  const { closeModal } = useModal()

  const canFetch = hasNextPage && !isUsersFetching && !isFetchingNextPage

  useEffect(() => {
    if (canFetch) fetchNextPage()
  }, [canFetch])

  useEffect(() => {
    if (isSuccess) {
      closeModal()
      formRef.current?.reset()
    }
  }, [isSuccess])

  const usersToAdd = useMemo(() => {
    const channelUsers = data?.chatUsers ?? []
    const usersIds = channelUsers.map(({ user }) => user.userId ?? '')
    return users.map((user) => ({
      id: user.userId,
      value: {
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.lastName}`,
      },
      online: user.online,
      label: `${user.firstName} ${user.lastName}`,
      inChannel: usersIds.includes(user.userId),
      avatar: user.avatar ?? { mediaUri: '', mediaType: '', mediaSize: 0 },
      color: user.color ?? '',
    }))
  }, [data, users])

  const handleSubmit = (values: InviteChannelMembersValues) => {
    const userIds = values.users.map(({ id }) => id)
    mutate(userIds)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur>
      {({ setFieldValue, errors, values, isValid }) => (
        <Form ref={formRef}>
          <InviteToChannelSelect
            label={t('memberLabel')}
            loadOptions={usersToAdd}
            value={values.users}
            onChange={(val) => setFieldValue('users', val)}
            placeholder={t('memberPlaceholder')}
            mb="4.8rem"
            helperText={errors?.users as string}
            disabled={isLoading}
          />
          <Container display="flex" flexGap="1.2rem">
            <Button
              variant="secondary"
              width="100%"
              onClick={closeModal}
              disabled={isLoading} // isSubmitting is not valid with mutate function in onSubmit handler
            >
              {t('cancelButton')}
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={isLoading || !isValid}
              loading={isLoading}
              loadText={t('submitButton')}
              animationSize={24}
            >
              {t('submitButton')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
