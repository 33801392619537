import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { ChatUser } from '@api/chats/types/chat-user'
import { useCreateAttachment } from '@api/messages/hooks/use-create-attachment'
import { useGetMessageList } from '@api/messages/hooks/use-get-message-list'
import { useSendMessage } from '@api/messages/hooks/use-send-message'
import { useAppDispatch, useAppSelector } from '@app/hooks'
import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'
import { useConversationId } from '@hooks/use-conversation-id'
import { useSignalR } from '@hooks/use-signalr'
import { formatMessages } from '@utils/format-messages'
import { formatTypingUsers } from '@utils/format-typing-users'
import { DraftMessage, removeDraftMessage } from 'features/messages/draft-message-slice'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { selectConversationById, selectMessages } from '../features/conversation/slice'
import { USER_IS_TYPING } from '../signalr/events'
import { handleUserIsTyping } from '../signalr/handlers/handle-user-is-typing'

export const useConversation = () => {
  const conversationId = useConversationId()!
  const location = useLocation()

  const conversationMessaging = useAppSelector(({ conversation }) =>
    selectConversationById(conversation, conversationId)
  )

  const { imageList, onImageRemoveAll } = useAttachmentsUploadingContext()

  const { mutate: createAttachment, data: attachments } = useCreateAttachment()

  const mediaIds: string[] = attachments?.map(({ id }) => id) ?? []

  useEffect(() => {
    if (imageList.length > 0) {
      createAttachment({
        conversationId,
        files: imageList.map(({ file }) => file),
      })
    }
  }, [imageList])

  useEffect(() => {
    return () => onImageRemoveAll()
  }, [conversationId])

  const messages = useMemo(() => {
    if (conversationMessaging) {
      return selectMessages(conversationMessaging.messages)
    }
    return []
  }, [conversationMessaging])

  const { data: channelDetails, isLoading: isChatDetailsLoading } = useGetChatDetails(
    conversationId || ''
  )
  const [inputText, setInputText] = useState<string>('')
  const [contentEditableInputText, setContentEditableInputText] = useState<string>('')

  const messageListRef = useRef<HTMLDivElement | null>(null)

  const { mutate: sendText, isLoading: isMessageSending } = useSendMessage()
  const { meData } = useGetMe()
  const { subscribeToEvent, connection, unsubscribeFromEvent } = useSignalR()
  const {
    fetchNextPage: fetchNextPageMessageList,
    isLoading: isMessageListLoading,
    isFetchingNextPage: isMessageListFetchingNextPage,
  } = useGetMessageList({ chatId: conversationId })

  const sendInitialMessage = useMemo(
    () =>
      // we need debounce here cause useConversation initialized twice
      // TODO: find the reason why it happens, fix it and remove debounce
      debounce(
        () => {
          const id = location.state.id
          const text = location.state.text

          window.history.replaceState({}, '')

          sendText({
            chatId: id,
            clientMessageId: uuid(),
            mediaIds: [],
            text: text,
            attachments,
          })
          onImageRemoveAll()
        },
        1000,
        { leading: true, trailing: false }
      ),
    []
  )

  useEffect(() => {
    if (location.state?.key === 'refetchMessages' && !isMessageListLoading && location.state?.id) {
      // Send initial messages if proper location state passed (after new conversation creation and redirect)
      sendInitialMessage()
    }
  }, [location.state?.key, location.state?.id, isMessageListLoading])

  const usersArray = useMemo(() => channelDetails?.chatUsers || [], [channelDetails])

  const [typingUsers, setTypingUsers] = useState<ChatUser[]>([])
  const [typingChat, setTypingChat] = useState<string>('')

  const draftMessageForChat = useAppSelector((state) => {
    const { draftMessages } = state.draftMessages
    return draftMessages.find(
      (draftMessage: DraftMessage) => draftMessage.conversationId === conversationId
    )
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    // set text to have different value then '' and have possibility to clear input in next rendering cycle
    setContentEditableInputText(' ')
    // use timeout to wait next rendering cycle and re-render component with valid data
    setTimeout(() => setContentEditableInputText(draftMessageForChat?.messageText || ''), 0)
  }, [conversationId])

  // show draft messages in the message input if there are any
  useEffect(() => {
    setInputText(draftMessageForChat?.messageText || '')
  }, [draftMessageForChat, conversationId])

  // removing the typing users from the array for every 4 seconds - pop the array (oldest element gets removed first)
  useEffect(() => {
    const interval = setInterval(() => {
      if (typingUsers.length > 0) {
        setTypingUsers((prevData) => prevData.slice(1))
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [typingUsers])

  useEffect(() => {
    if (conversationId && connection && usersArray.length > 0) {
      subscribeToEvent(
        USER_IS_TYPING,
        handleUserIsTyping({
          usersArray,
          setTypeNewUsers: setTypingUsers,
          typeNewUsers: typingUsers,
          setTypingChat,
        })
      )
    }

    return () => {
      unsubscribeFromEvent(USER_IS_TYPING)
    }
  }, [conversationId, connection, usersArray, typingUsers, typingChat])

  const userMap = useMemo(() => {
    if (!Array.isArray(usersArray)) {
      return {}
    }
    return usersArray.reduce((acc: Record<string, ChatUser>, chatUser: ChatUser) => {
      acc[chatUser.user.userId] = chatUser
      return acc
    }, {})
  }, [usersArray])

  const formattedMessages = useMemo(
    () => formatMessages({ messages, users: userMap, meData }),
    [messages, userMap, meData]
  )

  const scrollDownHandle = () => {
    if (!messageListRef.current) return
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight
  }

  const onClickSend = () => {
    if (conversationId) {
      // set text to have different value then '' and have possibility to clear input in next rendering cycle
      setContentEditableInputText(inputText)
      sendText({
        chatId: conversationId,
        clientMessageId: uuid(),
        mediaIds,
        text: inputText,
        attachments: attachments ?? [],
      })
      setInputText('')
      onImageRemoveAll()
      // use timeout to wait next rendering cycle and re-render component with valid data
      setTimeout(() => setContentEditableInputText(''), 0)
      dispatch(removeDraftMessage({ conversationId }))
      //Need to review this approach, as in future we can have more complex cases for scrolling to specific messages, and it would be better to have some utility approach to cover different cases
      //TODO: https://nativechats.atlassian.net/jira/software/c/projects/NAT/boards/44?assignee=638a2f9e77acd224b341ed08&selectedIssue=NAT-1174
      scrollDownHandle()
    }
  }

  const typingUsersString = useMemo(() => {
    return formatTypingUsers(typingUsers.map(({ user }) => user.firstName))
  }, [typingUsers])

  return {
    conversationDetails: channelDetails,
    formattedMessages,
    isLoading: isMessageListLoading || isMessageListFetchingNextPage || isChatDetailsLoading,
    users: usersArray,
    messageInputProps: {
      inputText,
      contentEditableInputText,
      setContentEditableInputText,
      isDisabled: isMessageSending,
      setInputText,
      onClickSend,
    },
    typingUsers,
    typingUsersString,
    typingChat,
    fetchNextPageMessageList,
    typingTrackingData: {
      conversationId,
      connection,
      inputText,
    },
    messageListRef,
    scrollDownHandle,
  }
}
