import { getMessageById } from '@api/messages/get-message-by-id'
import { MessageDetail } from '@api/messages/types/message-detail'
import { AppDispatch } from '@app/store'
import { notificationsManager } from '@utils/notifications'
import { receiveMessage } from '../../features/conversation/slice'

export const handleNewMessage = (dispatch: AppDispatch) => async (message: MessageDetail) => {
  try {
    // After back end changes socket payload to be same DTO as response from getMessageById, we can get rid of this query
    const newMessage = await getMessageById({ id: message.messageId! }) //in socket payload this field is mandatory
    dispatch(receiveMessage(newMessage))
    notificationsManager.tryToSendNotification(message)
  } catch (error) {
    //todo: handle error in sentry
    console.error(error)
  }
}
