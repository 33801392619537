import { ConfirmationModal } from '@modules/modals/confirmation'
import { replaceStr } from '@utils/helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, useModal } from 'ui'

export interface SetAdminModalProps {
  id: string
  fullName?: string
  email?: string
}

export const SetAdminModal: FC<SetAdminModalProps> = ({ id, fullName, email }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-set-admin')

  //Temporary
  const handleOnSubmit = () => {
    const alertStringName = `${fullName}, id: ${id}`
    const alertStringEmail = `${email}, id: ${id}`
    if (fullName) {
      alert(alertStringName)
    }
    if (email) {
      alert(alertStringEmail)
    }
    closeModal()
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      onSubmit={handleOnSubmit}
    >
      <Text mb="4.8rem">
        {fullName && replaceStr(t('confirmationName'), '@fullName@', fullName)}
        {email && replaceStr(t('confirmationEmail'), '@email@', email)}
      </Text>
    </ConfirmationModal>
  )
}
