//this file should replace ./set-me-languages.ts after you clean your language types
import { accountApi } from '@api/account/instance'
import { User } from './types/user'

export interface LanguageParam {
  code: string
  isPrimary?: boolean
}

export const setPersonalLanguages = async (payload: LanguageParam[]) => {
  const { data } = await accountApi.post<User>('/me/language', { languages: payload })
  return data
}
