import { useGetMyWorkspaces } from '@api/workspaces/hooks/use-get-my-workspaces'
import { OnboardingType } from '@contexts/workspace-provider'
import { useSkeleton } from '@hooks/use-skeleton'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/routes'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import { useTheme } from 'styled-components'
import { Button, Container, PlusIcon } from 'ui'
import { DashboardWithSkeleton } from './dashboard'

export const DashboardOverview: FC = () => {
  const theme = useTheme()
  const { setOnboardingType, setIsPendingOnboarding } = useWorkspace()
  const { data, isLoading } = useGetMyWorkspaces({})
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  useEffect(() => {
    isLoading
      ? showSkeleton(SkeletonComponentNames.DASHBOARD_1)
      : hideSkeleton(SkeletonComponentNames.DASHBOARD_1)
  }, [isLoading, showSkeleton, hideSkeleton])

  const handleOnCreate = () => {
    setOnboardingType(OnboardingType.WS)
    setIsPendingOnboarding(true)
    navigate(`${routes.workspaces}/${routes.createNew}`)
  }

  return (
    <Container backgroundColor={theme.colors.white} p="2rem" borderRadius="1.6rem">
      <DashboardWithSkeleton data={data} componentName={SkeletonComponentNames.DASHBOARD_1} />
      <Button
        preFix={<PlusIcon />}
        variant="secondary"
        width="100%"
        mt="1.6rem"
        onClick={handleOnCreate}
      >
        {t('createWorkspace')}
      </Button>
    </Container>
  )
}
