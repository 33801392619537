import { SkeletonVariantProps } from '@modules/skeleton'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { Container } from 'ui'

export const TeammatesSkeletonTile: FC<SkeletonVariantProps> = ({ arrayToMap }) => {
  return (
    <>
      {arrayToMap.map((key) => (
        <Container key={key}>
          <AvatarContainer />
          <Skeleton width="14.6rem" height="1.2rem" />
          <br />
          <Skeleton width="11.6rem" height="1.2rem" />
          <br />
          <Skeleton width="11.6rem" height="1.2rem" />
        </Container>
      ))}
    </>
  )
}

export const AvatarContainer = styled.div`
  padding-top: 100%;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.greyLight};
`
