import { UserLanguage } from '@api/account/types/language'
import { ChipButton } from '@components/chip-button'
import ArrowIcon from '@components/icons/arrow'
import { useNativeTranslations } from '@hooks/use-translations'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { withSkeleton } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { ChevronDownIcon, Container } from 'ui'
import { TranslationsHeaderSkeleton } from './skeleton'

interface TranslationsHeaderProps {
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  isExpanded: boolean
  setShowOriginalMessage: Dispatch<SetStateAction<boolean>>
  showOriginalMessage: boolean
  availableLanguages: UserLanguage[]
  supportedLanguages: UserLanguage[]
}

export const TranslationsHeader: FC<TranslationsHeaderProps> = ({
  isExpanded,
  setIsExpanded,
  availableLanguages,
  supportedLanguages,
  setShowOriginalMessage,
  showOriginalMessage,
}) => {
  const { sourceLanguageCode, selectedLanguage } = useNativeTranslations()

  const getLanguageNameByCode = (code: string) => {
    const language = supportedLanguages.find((lang) => lang.code === code)
    return language?.translatedName || code
  }

  return (
    <TranslationsHeaderContainer>
      <ChipButton
        text={getLanguageNameByCode(sourceLanguageCode)}
        isSelected={showOriginalMessage}
        onClick={() => setShowOriginalMessage(true)}
        flex="1 1 10rem"
      />
      <Container>
        <ArrowIcon />
      </Container>
      <ChipButton
        key={selectedLanguage}
        text={getLanguageNameByCode(selectedLanguage)}
        isSelected={!showOriginalMessage}
        flex="1 1 10rem"
        onClick={() => setShowOriginalMessage(false)}
      />
      {availableLanguages.length > 0 && (
        <ExpandButton isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
          <ChevronDownIcon />
        </ExpandButton>
      )}
    </TranslationsHeaderContainer>
  )
}

export const TranslationsHeaderWithSkeleton = withSkeleton(
  TranslationsHeader,
  <TranslationsHeaderSkeleton />
)

export const TranslationsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin: 1.6rem 0 2.8rem;
`
const ExpandButton = styled.button<{ isExpanded: boolean }>`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.greySuperDark};
  background-color: ${({ theme }) => theme.colors.smoke};
  & svg {
    transition: transform 0.3s;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0)')};
  }
`
