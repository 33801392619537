import { CHANNEL_LIST } from '@api/chats/query-keys'
import { useAppDispatch, useAppSelector } from '@app/hooks'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ConversationType } from '@utils/get-chat-type'
import { flatMap } from 'lodash'
import { selectChats, setChats } from '../../../features/chats/slice'
import { getChannelList } from '../get-channel-list'
import { ConversationList } from '../types/chat-list'

export const useGetChannelList = (refetchIntervalValue?: number) => {
  const conversations = useAppSelector(({ chats }) => selectChats(chats))

  const dispatch = useAppDispatch()
  const { error, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery<ConversationList, Error>({
      staleTime: Infinity,
      queryKey: [CHANNEL_LIST],
      queryFn: getChannelList,
      onSuccess: (response) => {
        const channels = flatMap(response.pages, ({ chats }) => chats)
        dispatch(setChats(channels))
      },
      refetchOnWindowFocus: false,
      refetchInterval: refetchIntervalValue
        ? (data) =>
            (data?.pages ?? []).flatMap((page) => page?.chats ?? []).length > 0
              ? false
              : refetchIntervalValue
        : false,
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < lastPage.pages) {
          return lastPage.currentPage + 1
        }
        return false
      },
    })

  const channels = conversations.filter(({ conversationType, isDeleted }) => {
    return conversationType !== ConversationType.CHAT && !isDeleted
  })

  return {
    channels,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }
}
