import { createChat as createChatFn, CreateChatsProps } from '@api/chats/create-chat'
import { Chat } from '@api/chats/types/chat'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConversationType } from '@utils/get-chat-type'
import { CHAT_LIST } from '../query-keys'

interface UseCreateChatInput {
  onSuccessCallback?: CallableFunction
  onErrorCallback?: CallableFunction
}

export const useCreateChat = ({ onErrorCallback, onSuccessCallback }: UseCreateChatInput = {}) => {
  const queryClient = useQueryClient()
  const {
    data,
    isLoading: isCreateChatLoading,
    isError: isCreateChatError,
    isSuccess: isCreateChatSuccess,
    isIdle: isCreateChatIdle,
    mutateAsync: createChatAsync,
    mutate: createChat,
  } = useMutation<Chat, Error, CreateChatsProps>(
    [CHAT_LIST],
    ({ chatName, userIds }) =>
      createChatFn({ chatName, conversationType: ConversationType.CHAT, userIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CHAT_LIST])
        onSuccessCallback?.()
      },
      onError: () => {
        onErrorCallback?.()
      },
    }
  )

  return {
    data,
    createChat,
    createChatAsync,
    isCreateChatLoading,
    isCreateChatSuccess,
    isCreateChatError,
    isCreateChatIdle,
  }
}
