import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'ui'
import { InputLabel } from './input-label'

interface InputFieldProps {
  width?: number
}

const DEFAULT_WIDTH = 600

export const InputField = ({ width }: InputFieldProps) => {
  return (
    <Container display="flex" flexDirection="column">
      <InputLabel />
      <Container mt={12}>
        <Skeleton width={width ?? DEFAULT_WIDTH} height={48} />
      </Container>
    </Container>
  )
}
