import React from 'react'

export const CreateChatIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M17 2a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0V7h-2a1 1 0 1 1 0-2h2V3a1 1 0 0 1 1-1ZM9.5 7A4.5 4.5 0 0 0 5 11.5V17h9.5a4.5 4.5 0 0 0 4.5-4.5V12a1 1 0 1 1 2 0V12.5A6.5 6.5 0 0 1 14.5 19H3v-7.5A6.5 6.5 0 0 1 9.5 5H10a1 1 0 1 1 0 2H9.5Z" />
    </svg>
  )
}
