import React, { FC } from 'react'
import { SpaceProps } from 'styled-system'
import { DateDividerContainer, DateText } from './styled'

interface DateDiverProps extends SpaceProps {
  date: string
}

export const DateDivider: FC<DateDiverProps> = ({ date, ...spacing }) => {
  return (
    <DateDividerContainer {...spacing}>
      <DateText>{date}</DateText>
    </DateDividerContainer>
  )
}
