import { TilesIcon } from '@components/icons'
import React, { FC } from 'react'
import { ListIcon } from 'ui'
import { IconContainer, PickerContainer } from './styled'

interface IconPickerProps {
  onClick: (arg: boolean) => void
  isTile: boolean
}

export const LayoutPicker: FC<IconPickerProps> = ({ onClick, isTile }) => {
  return (
    <PickerContainer>
      <IconContainer
        type="button"
        active={isTile}
        onClick={() => {
          onClick(true)
        }}
      >
        <TilesIcon />
      </IconContainer>
      <IconContainer type="button" active={!isTile} onClick={() => onClick(false)}>
        <ListIcon />
      </IconContainer>
    </PickerContainer>
  )
}
