import { messagesApi } from './instance'
import { MessageDetailDto } from '@api/chats/generated'

export interface EditMessageParams {
  messageId?: string
  newText: string
}

export const editMessage = async ({ messageId, newText }: EditMessageParams) => {
  const { data } = await messagesApi.patch<MessageDetailDto>(`/${messageId}`, {
    newText,
  })

  return data
}
