import { Channel } from '@api/chats/types/channel'
import { Skeleton } from '@modules/skeleton'
import React, { FC } from 'react'
import { withSkeleton } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { ScrollContainer } from 'ui'
import { ChannelItem, ChannelItemContainer } from './channel-item'

interface ChannelList {
  channels: Channel[]
}

export const ChannelList: FC<ChannelList> = ({ channels }) => {
  return (
    <ChannelListContainer hideScrollbar mx="-2rem">
      {channels.map((channel) => (
        <ChannelItem key={channel.id} {...channel} />
      ))}
    </ChannelListContainer>
  )
}

const ChannelListContainer = styled(ScrollContainer)`
  & > ${ChannelItemContainer}:not(:last-of-type) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      height: 0.1rem;
      background: ${({ theme }) => theme.colors.greyLight};
    }
  }
`

export const ChannelListWithSkeleton = withSkeleton(
  ChannelList,
  <Skeleton length={10} type="channel" />
)
