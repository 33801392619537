import { messagesApi } from './instance'
import { Message } from './types/message'

export interface DeleteMessageInput {
  messageId: string
}

export const deleteMessage = async ({ messageId }: DeleteMessageInput) => {
  const { data } = await messagesApi.delete<Message>(`/${messageId}`)

  return data
}
