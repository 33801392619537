import { EmailUpdatedModal } from '@modules/modals/email-updated'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, TextField, useModal } from 'ui'
import { validationSchema } from './data'
import { UpdateEmailValues } from './types'

interface UpdateEmailFormProps {
  id: string
  email: string
  firstName: string
}

export const UpdateEmailForm: FC<UpdateEmailFormProps> = ({ email, firstName }) => {
  const { t } = useTranslation('modal-update-email')
  const { closeModal, openModal } = useModal()

  const initialValues: UpdateEmailValues = {
    email: email,
  }

  const handleSubmit = (values: UpdateEmailValues, actions: FormikHelpers<UpdateEmailValues>) => {
    const newEmail = values.email
    actions.setSubmitting(false)
    actions.resetForm()
    closeModal()
    openModal({ content: <EmailUpdatedModal firstName={firstName} newEmail={newEmail} /> })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
      validateOnBlur
    >
      {({ isSubmitting, values, errors, setFieldValue, isValid }) => (
        <Form>
          <TextField
            id="update-email"
            name="update-email"
            label={t('emailLabel')}
            type="email"
            placeholder="Example@gmail.com"
            onChange={(e) => setFieldValue('email', e.target.value)}
            value={values.email}
            warning={Boolean(errors?.email)}
            helperText={errors?.email}
            mb="4.8rem"
          />
          <Container display="flex" flexGap="1.2rem">
            <Button variant="secondary" width="100%" disabled={isSubmitting} onClick={closeModal}>
              {t('cancelButton')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              width="100%"
              disabled={isSubmitting || !isValid}
            >
              {t('submitButton')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
