import React, { FC } from 'react'
import {
  dotsAnimationData,
  loaderAnimationData,
  spinnerDarkAnimationData,
  spinnerLightAnimationData,
  typingAnimationData,
} from './animation-data'
import { LottieAnimation } from './lottie-animation'
import { AnimationProps } from './lottie-animation/types'

export const TypingAnimation: FC<AnimationProps> = (props) => {
  return <LottieAnimation animationData={typingAnimationData} {...props} />
}

export const LoaderAnimation: FC<AnimationProps> = (props) => {
  return <LottieAnimation animationData={loaderAnimationData} {...props} />
}

export const SpinnerDarkAnimation: FC<AnimationProps> = (props) => {
  return <LottieAnimation animationData={spinnerDarkAnimationData} {...props} />
}

export const SpinnerLightAnimation: FC<AnimationProps> = (props) => {
  return <LottieAnimation animationData={spinnerLightAnimationData} {...props} />
}

export const DotsAnimation: FC<AnimationProps> = (props) => {
  return <LottieAnimation animationData={dotsAnimationData} {...props} />
}
