export const generateReaction = (reactionCode: string, meId: string) => {
  const date = new Date().toISOString()
  return {
    reactionCode: reactionCode,
    count: 1,
    createdAt: date,
    userReactionTimes: [
      {
        userId: meId,
        timestamp: date,
      },
    ],
  }
}
