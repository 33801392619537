import { InviteChannelMembersValues, OptionType } from './types'

export const initialValues: InviteChannelMembersValues = {
  users: [],
}

// //Temporary static data
export const usersOptions: OptionType[] = [
  {
    id: '1',
    value: { firstName: 'John', lastName: 'Smith', fullName: 'John Smith' },
    label: 'John Smith',
    inChannel: false,
    avatar: {
      mediaUri: 'https://i.pravatar.cc/400?img=1',
    },
  },
  {
    id: '2',
    value: { firstName: 'Marc', lastName: 'Grant', fullName: 'Marc Grant' },
    label: 'Marc Grant',
    inChannel: false,
    avatar: {
      mediaUri: 'https://i.pravatar.cc/400?img=2',
    },
  },
  {
    id: '3',
    value: { firstName: 'Gene', lastName: 'Johnson', fullName: 'Gene Johnson' },
    label: 'Gene Johnson',
    inChannel: false,
    avatar: {
      mediaUri: 'https://i.pravatar.cc/400?img=3',
    },
  },
  {
    id: '4',
    value: { firstName: 'Marisa', lastName: 'Gorczany', fullName: 'Marisa Gorczany' },
    label: 'Marisa Gorczany',
    inChannel: false,
    avatar: {
      mediaUri: 'https://i.pravatar.cc/400?img=4',
    },
  },
  {
    id: '5',
    value: { firstName: 'Lisa', lastName: 'McNeal', fullName: 'Lisa McNeal' },
    label: 'Lisa McNeal',
    inChannel: true,
    avatar: {
      mediaUri: 'https://i.pravatar.cc/400?img=5',
    },
  },
  {
    id: '6',
    value: { firstName: 'Forest', lastName: 'Smith', fullName: 'Forest Smith' },
    label: 'Forrest Smith',
    inChannel: false,
    avatar: {
      mediaUri: 'https://i.pravatar.cc/400?img=6',
    },
  },
]
