import { getWorkspace } from '@api/workspaces/get-workspace'
import { WORKSPACE } from '@api/workspaces/tags'
import { GetWorkspaceParams } from '@api/workspaces/types'
import { useQuery } from '@tanstack/react-query'

export const useGetWorkspace = ({ id }: GetWorkspaceParams) => {
  const { data, isLoading, isError } = useQuery([WORKSPACE, id], () => getWorkspace({ id }), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
  return { data, isLoading, isError }
}
