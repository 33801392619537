import { useAppDispatch } from '@app/hooks'
import { useInfiniteQuery } from '@tanstack/react-query'
import { setMessagesToConversation } from '../../../features/conversation/slice'
import { getMessageList, GetMessageListProps, GetMessageListResponse } from '../get-message-list'

export const MESSAGE_LIST = 'messageList'

//JS does not have int 32 in its own lib, Number.MAX_SAFE_INTEGER is too big
const INT_32 = 2147483647

export const useGetMessageList = ({ chatId }: GetMessageListProps) => {
  const dispatch = useAppDispatch()
  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
  } = useInfiniteQuery<GetMessageListResponse, Error>({
    queryKey: [MESSAGE_LIST, chatId],
    queryFn: ({ pageParam = INT_32 }) => getMessageList({ chatId, pageNumber: pageParam }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!chatId,
    onSuccess: (res) => {
      const messages = res.pages.flatMap(({ messages }) => messages)
      dispatch(setMessagesToConversation({ chatId: chatId!, messages })) //marking chatId with ! because ts complains that chatId can be undefined, but in fact, if !chatId query will not be fired at all
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage > 1) return lastPage.currentPage - 1
      return
    },
  })
  const messages = (data?.pages || []).flatMap((page) => page.messages)

  return {
    messages,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  }
}
