import { OnboardingType } from '@contexts/workspace-provider'
import { TFunction } from 'next-i18next'

export const getNativeMessages = (t: TFunction, type: OnboardingType) => {
  const messages = [
    'chat.welcome',
    'chat.languageQ',
    'chat.tranlationInfo',
    'chat.langSettingsInfo',
  ]

  if (type === OnboardingType.USER) {
    messages.splice(1, 0, 'chat.nameQUser')
  } else if (type === OnboardingType.WS) {
    messages.splice(1, 0, 'chat.companyNameQ', 'chat.inviteQ', 'chat.nameQWs')
  }

  return messages.map((text) => ({
    text: t(text),
    avatarUrl: '/img/native-avatar.svg',
    isSender: true,
  }))
}
