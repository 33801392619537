import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Avatar, AvatarVariant, Container, Text } from 'ui'
import { MessageVariant } from '../message'
import { FakeMessageBody, MessageContainer } from '../message/styled'

export interface FakeMessageProps {
  isSender?: boolean
  avatarUrl?: string
  text?: string
  hideAvatar?: boolean
}

export const FakeMessage: FC<FakeMessageProps> = ({
  isSender = true,
  avatarUrl,
  text,
  hideAvatar,
}) => {
  const theme = useTheme()

  return (
    <MessageContainer justifyContent={isSender ? 'flex-start' : 'flex-end'}>
      {isSender && !hideAvatar && (
        <Container>
          <Avatar imgUrl={avatarUrl} name="Native" variant={AvatarVariant.SQUARE} size="3.6rem" />
        </Container>
      )}
      <FakeMessageBody isSender={isSender} variant={MessageVariant.DEFAULT} ml="4.4rem">
        <Text
          fontWeight={500}
          fontSize="1.8rem"
          color={isSender ? theme.colors.black : theme.colors.white}
        >
          {text}
        </Text>
      </FakeMessageBody>
    </MessageContainer>
  )
}
