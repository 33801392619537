import { PersonalProfile } from '@api/account/types/personal-profile'
import { User } from '@api/account/types/user'
import { Chat } from '@api/chats/types/chat'
import { LockIcon, TextIcon } from '@components/icons'
import React from 'react'
import { Avatar, AvatarsIcon, StatusVariant } from 'ui'
import { ConversationType } from './get-chat-type'
import { getRecipientOnlineStatus } from './utils'

interface GetIconProps extends Chat {
  label?: string
  size: string
  iconSize?: number
  showStatus?: boolean
  workspaceUsers?: User[]
  me?: PersonalProfile
  borderRadius?: string
  imgURL?: string
  isAvatarExists: boolean
}

export const getChatAvatar = ({
  conversationType,
  chatUsers,
  label,
  size,
  iconSize,
  color,
  workspaceUsers,
  borderRadius,
  me,
  imgURL,
  isAvatarExists,
}: GetIconProps) => {
  const usersCount = chatUsers?.length
  const getChatIconColor = () => {
    if (!usersCount) return color
    if (usersCount == 2) {
      const notMe = chatUsers?.find(({ user }) => user.userId !== me?.userId)
      return notMe?.user.color
    }
    if (usersCount == 1) {
      return chatUsers?.[0]?.user.color
    }
    return color
  }

  switch (conversationType) {
    case ConversationType.CHAT: {
      if (usersCount && usersCount > 2) {
        return (
          <Avatar
            bgColor={`#${color}`}
            borderRadius={borderRadius}
            size={size}
            icon={<AvatarsIcon width={iconSize} height={iconSize} />}
            isAvatarExists={isAvatarExists}
          />
        )
      }

      //TODO: we do not need to fetch users explicitly because we already have them in chatUsers with info about status NAT-1452
      const recipientOnlineStatus = getRecipientOnlineStatus(
        chatUsers,
        workspaceUsers || [],
        me || null
      )

      return (
        <Avatar
          imgUrl={imgURL}
          size={size}
          borderRadius={borderRadius}
          status={recipientOnlineStatus === 0 ? StatusVariant.OFFLINE : StatusVariant.ONLINE}
          label={label}
          bgColor={`#${getChatIconColor()}`}
          isAvatarExists={isAvatarExists}
        />
      )
    }
    case ConversationType.PRIVATE_CHANNEL: {
      return (
        <Avatar
          bgColor={`#${getChatIconColor()}`}
          borderRadius={borderRadius}
          size={size}
          icon={<LockIcon width={iconSize} height={iconSize} />}
          isAvatarExists={isAvatarExists}
        />
      )
    }
    default: {
      return (
        <Avatar
          bgColor={`#${getChatIconColor()}`}
          borderRadius={borderRadius}
          size={size}
          icon={<TextIcon width={iconSize} height={iconSize} />}
          isAvatarExists={isAvatarExists}
        />
      )
    }
  }
}
