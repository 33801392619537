import { ChatsService } from '@api/chats/generated'
import { useMutation } from '@tanstack/react-query'

interface UseReadConversationParams {
  onMutate: (chatId: string) => void
}

export const useReadConversation = ({ onMutate }: UseReadConversationParams) => {
  const { mutate } = useMutation({
    mutationFn: async (conversationId: string) => {
      await ChatsService.postV3ChatsRead(conversationId)
    },
    onMutate,
    retry: 3,
  })
  return { markAsRead: mutate }
}
