import React from 'react'
import { SVGProps } from 'react'

export const SortingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-3 1 23 21"
      {...props}
    >
      <path d="M4.889 11.006a1 1 0 0 0-.67.369 1 1 0 0 0 .156 1.406l5 4a1 1 0 0 0 1.25 0l5-4a1 1 0 0 0 .156-1.406 1 1 0 0 0-1.406-.156L10 14.719l-4.375-3.5a1 1 0 0 0-.736-.213ZM9.375 3.219l-5 4a1 1 0 0 0-.156 1.406 1 1 0 0 0 1.406.156L10 5.281l4.375 3.5a1 1 0 0 0 1.406-.156 1 1 0 0 0-.156-1.406l-5-4a1 1 0 0 0-1.25 0z" />
    </svg>
  )
}
