import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { FlexProps, SpaceProps, flexbox, space } from 'styled-system'
import { Text, Tooltip } from 'ui'

interface ChipButtonProps {
  text: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

interface ChipButtonStylesProps extends SpaceProps, FlexProps {
  isSelected?: boolean
}

type GeneralChipButtonProps = ChipButtonProps & ChipButtonStylesProps

export const ChipButton: FC<GeneralChipButtonProps> = ({ text, isSelected, ...stylesProps }) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (textRef?.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
    }
  }, [textRef])

  return (
    <Tooltip text={text} placement="bottom" disabled={!isTruncated}>
      <ChipContainer isSelected={isSelected} {...stylesProps}>
        <ChipText ref={textRef}>{text}</ChipText>
      </ChipContainer>
    </Tooltip>
  )
}

const ChipContainer = styled.button<ChipButtonStylesProps>`
  display: flex;
  align-items: center;
  border-radius: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : theme.colors.smoke};
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.white : theme.colors.black)};
  ${space}
  ${flexbox}
`

const ChipText = styled(Text.withComponent('span'))`
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
