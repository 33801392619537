import { getUsersAvatar } from '@api/account/get-users-avatar'
import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'

export const USERS_AVATAR = 'users_avatar'

interface UseGetUsersAvatar {
  userId?: string | null
  avatarId?: string
}

export const useGetUsersAvatar = ({ userId, avatarId }: UseGetUsersAvatar) => {
  const enabled = Boolean(avatarId && userId)
  const { data, isError, isLoading, error } = useQuery(
    [USERS_AVATAR, userId, avatarId],
    () => getUsersAvatar(userId!),
    {
      refetchOnWindowFocus: false,
      staleTime: AVATAR_STALE_TIME,
      enabled,
    }
  )

  const usersAvatarUrl = data?.url || undefined

  return { usersAvatarUrl, isError, isLoading, error, enabled }
}
