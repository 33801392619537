import styled from 'styled-components'
import { Container } from 'ui'

export const ItemContainer = styled(Container)`
  min-height: 7.6rem;
  padding: 1.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
`

export const ButtonsContainer = styled.div`
  margin-top: auto;
  & > *:not(:first-child) {
    margin-top: 1.6rem;
  }
`
