import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ImgContainer, ScrollContainer, Text } from 'ui'

export const LanguagesInfo: FC = () => {
  const { t } = useTranslation('personal')
  return (
    <ScrollContainer m="3rem 0 -2rem" pb="2.5rem" hideScrollbar>
      <ImgContainer mb="3rem" px="2rem">
        <img src="/img/info.svg" alt={t('info.imgAlt')} width={288} height={288} />
      </ImgContainer>
      <Text fontSize="1.8rem" fontWeight={500} mb="0.8rem">
        {t('info.title')}
      </Text>
      <Text color="greySuperDark" mb="0.8rem">
        {t('info.text1')}
      </Text>
      <Text color="greySuperDark" mb="0.8rem">
        {t('info.text2')}
      </Text>
      <Text color="greySuperDark">{t('info.text3')}</Text>
    </ScrollContainer>
  )
}
