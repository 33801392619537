import { UpdateEmailForm } from '@modules/forms/update-email'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Heading } from 'ui'

export interface UpdateEmailModalProps {
  id: string
  email: string
  firstName: string
}

export const UpdateEmailModal: FC<UpdateEmailModalProps> = ({ id, email, firstName }) => {
  const { t } = useTranslation('modal-update-email')

  return (
    <Container width="40rem">
      <Heading as="h2" mb="2.8rem">
        {firstName && t('title', { firstName })}
      </Heading>
      <UpdateEmailForm id={id} email={email} firstName={firstName} />
    </Container>
  )
}
