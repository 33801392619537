import { workspaceApi } from '@api/workspaces/instance'
import { WorkspaceInvitation } from '@api/workspaces/types'

export interface GetWorkspaceInvitationsParams {
  id: string
}

export interface GetWorkspaceInvitationsResponse {
  items: WorkspaceInvitation[]
  count: number
}

export const getWorkspaceInvitations = async ({ id }: GetWorkspaceInvitationsParams) => {
  const { data } = await workspaceApi.get<GetWorkspaceInvitationsResponse>(
    `workspaces/${id}/invitations`
  )
  return data.items
}
