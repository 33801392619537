import { LayoutPicker } from '@components/layout-picker'
import { useFilters } from '@contexts/filters-provider'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { SortTeammates } from '@modules/sort-teammates'
import { getFeatureFlag } from '@utils/flags-manager'
import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { BasicSearch, Button, Container, FiltersIcon } from 'ui'

interface TeammatesControlsProps extends SpaceProps {
  isTileLayout: boolean
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  setIsTileLayout: (isTile: boolean) => void
}

function countTrueFields(obj: { [key: string]: { [key: string]: boolean } } | boolean) {
  return Object.values(obj).reduce((acc, value) => {
    if (typeof value === 'object') {
      acc += countTrueFields(value)
    } else if (value === true) {
      acc += 1
    }
    return acc
  }, 0)
}

export const TeammatesControls: FC<TeammatesControlsProps> = ({
  isTileLayout,
  setIsTileLayout,
  searchValue,
  setSearchValue,
  ...spacing
}) => {
  const { t } = useTranslation(['common'])
  const { setType, setOpen } = useSidePage()
  const { filters } = useFilters()
  const filterCounts = useMemo(() => countTrueFields(filters), [filters])

  const openFilters = useCallback(() => {
    setType(SidePageTypes.TEAMMATES_FILTERS)
    setOpen(true)
  }, [setOpen, setType])

  return (
    <Container {...spacing}>
      <BasicSearch
        searchString={searchValue}
        setSearchString={setSearchValue}
        id="searchUser"
        name="searchUser"
        placeholder={t('searchByName')}
        mb="1.6rem"
        hiddenLabel="search teammate by name"
      />
      <Container
        display="flex"
        justifyContent={
          getFeatureFlag('showOnProd') ? "space-between" : "right"
        }>
        {
          getFeatureFlag('showOnProd')
            && (
              <Button
                variant="secondary"
                size="sm"
                minWidth="17rem"
                preFix={<FiltersIcon />}
                onClick={openFilters}
              >
                {t('filters')} {filterCounts !== 0 && `(${filterCounts})`}
              </Button>
            )
        }
        <Container display="flex" flexGap="1.2rem">
          <SortTeammates />
          <LayoutPicker onClick={setIsTileLayout} isTile={isTileLayout} />
        </Container>
      </Container>
    </Container>
  )
}
