import React from 'react'
import styled from 'styled-components'
import { Container, StatusBadge, StatusVariant, Text } from 'ui'

export interface NameWithStateProps {
  name: string
  status: StatusVariant
  isPopup: boolean
}

export const NameWithState: React.FC<NameWithStateProps> = (props) => {
  const { name, status, isPopup } = props

  return (
    <Container display="flex" alignItems="center" width="100%" overflow="hidden">
      <UserName isPopup={isPopup} name={name} status={status}>
        {name}
      </UserName>
      <Container as="span">
        <StatusBadge status={status} sizeLg={true} />
      </Container>
    </Container>
  )
}

const UserName = styled(Text)<NameWithStateProps>`
  font-size: ${(props) => (props.isPopup ? '1.6rem' : '1.3rem')};
  color: ${(props) => (props.isPopup ? props.theme.colors.black : props.theme.colors.greyDark)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
