import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { User } from '@api/account/types/user'
import { getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import { Avatar, AvatarVariant } from 'ui'

interface UserPreviewAvatarProps {
  user: User
}

export const UserPreviewAvatar: FC<UserPreviewAvatarProps> = ({ user }) => {
  const { userId, color, firstName, avatar, lastName } = user
  const fullName = getFullName(firstName, lastName)

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: userId,
    //@ts-ignore
    avatarId: avatar?.id,
  })

  return (
    <Avatar
      imgUrl={usersAvatarUrl}
      key={userId}
      bgColor={`#${color}`}
      name={fullName}
      label={firstName}
      variant={AvatarVariant.SQUARE}
      size="6rem"
      fontSize="3.6rem"
      isAvatarExists={enabled}
    />
  )
}
