import { Actions } from '@api/account/toggle-mute'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { CHAT_LIST } from '@api/chats/query-keys'
import { toggleMuteChat } from '@api/chats/toggle-mute-chat'
import { Chat } from '@api/chats/types/chat'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

//type cast for function is required because usemutation returns a handler with types incompatible with all our handlers
//this hook uses optimistic updates approach from https://react-query-v3.tanstack.com/guides/optimistic-updates
export const useToggleMute: () => VoidFunction = () => {
  const queryClient = useQueryClient()

  const { chatId, channelId } = useParams()

  const { data } = useGetChatDetails(chatId ?? channelId)

  const { mutate: toggleMute } = useMutation({
    mutationKey: [CHAT_LIST, chatId ?? channelId],
    mutationFn: () =>
      toggleMuteChat({
        chatId: chatId ?? channelId,
        action: data?.isMuted ? Actions.UNMUTE : Actions.MUTE,
      }),

    onMutate: async () => {
      await queryClient.cancelQueries([CHAT_LIST, chatId ?? channelId])

      const previousChat = queryClient.getQueryData<Chat>([CHAT_LIST, chatId ?? channelId])

      queryClient.setQueryData([CHAT_LIST, chatId ?? channelId], {
        ...previousChat,
        isMuted: !previousChat?.isMuted,
      })

      return { previousChat }
    },
    // first two arguments named by convention from react-query docs
    onError: (err, variables, context) => {
      queryClient.setQueryData([CHAT_LIST, chatId ?? channelId], context?.previousChat)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([CHAT_LIST, chatId ?? channelId])
    },
  })

  return toggleMute
}
