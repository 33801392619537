import { WorkspaceUserRequest, WorkspaceUserRole } from '@api/workspaces/generated'
import { useInviteWorkspaceUsers } from '@api/workspaces/hooks/use-invite-workspace-users'
import { useWorkspace } from '@hooks/use-workspace'
import { DemoteMemberModal } from '@modules/modals/demote-member'
import { RevokeInvitationModal } from '@modules/modals/revoke-invitation'
import { SentInvitationModal } from '@modules/modals/sent-invitation'
import { SetAdminModal } from '@modules/modals/set-admin'
import { getFeatureFlag } from '@utils/flags-manager'
import { capitalizeFirstLetter } from '@utils/helpers'
import { formatDuration } from 'date-fns'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Text, TimeIcon } from 'ui'
import {
  BlockIcon,
  Container,
  Popper,
  PopperActionVariant,
  SendIcon,
  UserIcon,
  theme,
  useModal,
} from 'ui'
import { Expiration, StyledItem } from './styled'
import { Invitation, InvitationStatus } from './types'

export interface InvitationItemProps {
  invitation: Invitation
}

export const InvitationItem = ({ invitation }: InvitationItemProps) => {
  const { id, email, expiresIn, roles } = invitation
  const { t } = useTranslation('workspace')
  const { workspaceId } = useWorkspace()
  const { openModal } = useModal()
  const { mutate: resendInvitation } = useInviteWorkspaceUsers()

  const role = roles[0]

  const status = useMemo(() => {
    if (expiresIn.slice(0, 1) === '-') {
      return InvitationStatus.EXPIRED
    }
    return InvitationStatus.PENDING
  }, [expiresIn])

  const getRoleStr = (role: WorkspaceUserRole[]) => {
    switch (role[0]) {
      case WorkspaceUserRole.OWNER:
      case WorkspaceUserRole.ADMIN:
        return t('admin')
      case WorkspaceUserRole.TEAMMATE:
        return t('member')
      default:
        return null
    }
  }

  const getExpiration = () => {
    const [daysAndHours, minutes] = expiresIn.split(':')
    const [days, hours] = daysAndHours.split('.').map(Number)
    if (days < 0) {
      return t('expired')
    }
    const day = formatDuration(
      {
        days,
        hours,
        minutes: Number(minutes),
      },
      { format: ['days'] }
    )
    if (hours > 1) {
      return `${t('expiresIn')} ${day
        .replace('about', '')
        .replace('days', t('days'))
        .replace('day', t('day'))}`
    } else {
      return `${t('expiresIn')} ${t('lessThanHour')}`
    }
  }

  //TODO: update with different roles when available, for MVP there is only Admin role
  const handleOnResendInvitation = async (email: string, workspaceId: string) => {
    const userData: WorkspaceUserRequest[] = [
      {
        email,
        roles: [WorkspaceUserRole.ADMIN],
      },
    ]
    if (!userData) return
    try {
      await resendInvitation({
        workspaceId,
        requestBody: {
          sendEmail: true,
          users: userData,
        },
      })
      toast.success(`${t('toastMessages.invitationSent', { email })}`)
    } catch (_) {
      toast.error(`${t('toastMessages.somethingWentWrong')}`)
    }
  }

  const getInvitationActions = useMemo(() => {
    if (status === InvitationStatus.EXPIRED) {
      return [
        {
          name: t('resendInv'),
          onClick: () => handleOnResendInvitation(email, workspaceId),
          icon: <SendIcon />,
        },
      ]
    }
    if (status === InvitationStatus.PENDING && role === WorkspaceUserRole.ADMIN) {
      const actions: {
        name: string
        onClick: () => void
        icon: JSX.Element
        variant?: PopperActionVariant.DEFAULT | PopperActionVariant.WARNING
      }[] = [
        {
          name: t('demoteToMember'),
          onClick: () => openModal({ content: <DemoteMemberModal id={id} email={email} /> }),
          icon: <UserIcon />,
        },
        {
          name: t('resendInv'),
          onClick: () => handleOnResendInvitation(email, workspaceId),
          icon: <SendIcon />,
        },
      ]

      if (getFeatureFlag('showOnProd')) {
        actions.push({
          name: t('revokeInv'),
          onClick: () => openModal({ content: <RevokeInvitationModal id={id} email={email} /> }),
          icon: <BlockIcon />,
          variant: PopperActionVariant.WARNING,
        })
      }

      return actions
    }
    if (status === InvitationStatus.PENDING && role === WorkspaceUserRole.TEAMMATE) {
      return getFeatureFlag('showOnProd')
        ? [
            {
              name: t('setAdmin'),
              onClick: () => openModal({ content: <SetAdminModal id={id} email={email} /> }),
              icon: <UserIcon />,
            },
            {
              name: t('resendInv'),
              onClick: () =>
                openModal({ content: <SentInvitationModal />, closeOnClickOutside: true }),
              icon: <SendIcon />,
            },
            {
              name: t('revokeInv'),
              onClick: () =>
                openModal({
                  content: <RevokeInvitationModal id={id} email={email} />,
                }),
              icon: <BlockIcon />,
              variant: PopperActionVariant.WARNING,
            },
          ]
        : [
            {
              name: t('resendInv'),
              onClick: () => handleOnResendInvitation(email, workspaceId),
              icon: <SendIcon />,
            },
          ]
    }
    return []
  }, [status, role, id, email, t, openModal])

  return (
    <StyledItem status={status}>
      <Container>
        <Text>{email}</Text>
      </Container>
      <Container>
        <Text>{getRoleStr(roles)}</Text>
      </Container>
      <Container>
        <Text>
          {capitalizeFirstLetter(status)}
          <br />
          {status !== InvitationStatus.EXPIRED && (
            <Expiration>
              <TimeIcon fill={theme.colors.greyDark} width={16} height={16} />
              <Text variant="smallTextRegular" color="greySuperDark" ml="0.8rem">
                {getExpiration()}
              </Text>
            </Expiration>
          )}
        </Text>
      </Container>
      <Container>
        <Popper actions={getInvitationActions} hiddenLabel={t('invitationMenu')} />
      </Container>
    </StyledItem>
  )
}
