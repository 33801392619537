import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, ImgContainer, Heading, Text, useModal } from 'ui'

interface EmailUpdatedModalProps {
  firstName: string
  newEmail: string
}

export const EmailUpdatedModal: FC<EmailUpdatedModalProps> = ({ firstName, newEmail }) => {
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-email-updated')

  return (
    <Container textAlign="center">
      <ImgContainer maxWidth="21.2rem" m="0 auto 2rem">
        <img src="/img/update.svg" alt="Update" width={212} height={114} />
      </ImgContainer>
      <Heading as="h2" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text mb="3.4rem">
        {firstName && t('confirmation', { firstName })} {newEmail}
      </Text>
      <Button type="submit" width="100%" onClick={closeModal}>
        {t('submitButton')}
      </Button>
    </Container>
  )
}
