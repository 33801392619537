import { workspaceApiInstance } from '@api/instance'
import type { AxiosRequestConfig } from 'axios'

function get<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return workspaceApiInstance.get<ResponseType>(url, config)
}

function post<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return workspaceApiInstance.post<ResponseType>(url, data, config)
}

function patch<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return workspaceApiInstance.patch(url, data, config)
}
function put<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return workspaceApiInstance.put<ResponseType>(url, data, config)
}
function del<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return workspaceApiInstance.delete(url, config)
}

export const workspaceApi = {
  get,
  post,
  patch,
  put,
  delete: del,
}
