import { useAppDispatch } from '@app/hooks'
import { useMutation } from '@tanstack/react-query'
import { updateMessageTranslation } from 'src/features/conversation/slice'
import { MessageDetail } from '../types/message-detail'
import {
  updateTranslationLanguage,
  UpdateTranslationLanguageInput,
} from '../update-translation-language'

export type ExtendedUpdateTranslationLanguageInput = UpdateTranslationLanguageInput & {
  clientMessageId: string
  chatId: string
}

export const useUpdateTranslationLanguage = () => {
  const dispatch = useAppDispatch()

  return useMutation<MessageDetail, Error, ExtendedUpdateTranslationLanguageInput>(
    ({ messageId, languageCode }) => updateTranslationLanguage({ messageId, languageCode }),
    {
      onSuccess: ({ clientMessageId, messageId, chatId, translations }) => {
        dispatch(
          updateMessageTranslation({
            clientMessageId,
            messageId: messageId || '',
            chatId,
            translations,
          })
        )
      },
    }
  )
}
