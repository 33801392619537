import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { useGetWorkspaceAvatar } from '@api/workspaces/hooks/use-get-workspace-avatar'
import { useWorkspace } from '@hooks/use-workspace'
import { EditAvatar } from '@modules/edit-avatar'
import WorkspaceSettingsForm from '@modules/forms/workspace-settings'
import { AvatarType } from '@modules/modals/edit-avatar/types'
import React, { FC } from 'react'
import { Container } from 'ui'

const Info: FC = () => {
  const { workspaceId } = useWorkspace()
  const { data } = useGetWorkspace({ id: workspaceId })

  const { workspaceAvatarUrl, enabled } = useGetWorkspaceAvatar({
    workspaceId,
    //@ts-ignore
    avatarId: data?.avatar?.id,
  })

  return (
    <Container>
      <EditAvatar
        mb="2.4rem"
        imgURL={workspaceAvatarUrl}
        name={data?.name}
        label={data?.name}
        avatarBgColor={data?.color}
        workspaceId={workspaceId}
        avatarType={AvatarType.Workspace}
        isAvatarExists={enabled}
      />
      <WorkspaceSettingsForm />
    </Container>
  )
}

export default Info
