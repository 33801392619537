import { chatsApi } from './instance'
import { Chat } from './types/chat'

interface AddUsersToChannelProps {
  chatId: string
  userIds: string[]
}

export const addUsersToChannel = async ({ chatId, userIds }: AddUsersToChannelProps) => {
  const { data } = await chatsApi.post<Chat>(`/${chatId}/add-users`, { userIds })
  return data
}
