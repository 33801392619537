import { messagesApi } from './instance'
import { MessageDetail } from './types/message-detail'

export interface GetMessageByIdProps {
  id: string
}

export const getMessageById = async ({ id }: GetMessageByIdProps) => {
  const { data } = await messagesApi.get<MessageDetail>(`/${id}`)
  return data
}
