import CSS from 'csstype'
import { CustomColorProps } from 'src/utils/types'
import { FlexProps, SpaceProps } from 'styled-system'

export enum StatusVariant {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BUSY = 'busy',
}

export enum AvatarVariant {
  CIRCLE = 'circle',
  SQUARE = 'square',
}

export interface AvatarProps {
  label?: string
  name?: string
  bgColor?: string
  imgUrl?: string
  size?: CSS.Property.Width
  fontSize?: CSS.Property.FontSize
  borderRadius?: CSS.Property.BorderRadius
  iconSize?: number
  flag?: string
  isDeleted?: boolean
  firstName?: string
  lastName?: string
  icon?: JSX.Element
  isAvatarExists?: boolean 
}

export interface AvatarStatusBadgeProps {
  status?: StatusVariant | undefined
  position?: string
}
interface AvatarVariantProps extends CustomColorProps {
  variant?: AvatarVariant
  borderRadius?: string
}

export type AvatarImageProps = AvatarStatusBadgeProps & AvatarVariantProps

export type GeneralAvatarProps = AvatarProps & AvatarImageProps & SpaceProps & FlexProps

export interface ImagePlaceholderProps {
  label?: string
  firstName?: string
  fontSize?: CSS.Property.FontSize
}
