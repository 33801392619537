import { USERS } from '@api/account/hooks/use-get-users'
import { updateUserOnlineStatus } from '@api/account/signal-r-handler-helpers/update-user-online-status'
import { User } from '@api/account/types/user'
import { QueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'

interface UserCacheData {
  users: User[]
}

export type Status = 1 | 0
type ActivityType = Status
type Online = {
  status: Status
  activityType: ActivityType
  lastOnline: Date
}

interface UserConnectionStatus {
  userId: string
  online: Online
}

export const handleConnectionStatusChanged =
  (queryClient: QueryClient) => (signal: UserConnectionStatus) => {
    if (signal) {
      try {
        queryClient.setQueryData([USERS], (oldData: UserCacheData | undefined | null) => {
          if (!oldData) return null

          const newData = cloneDeep(oldData)
          return updateUserOnlineStatus(newData, signal.userId, signal.online.status)
        })
      } catch (error) {
        // todo: add error sentry
        console.error(error)
      }
    }
  }
