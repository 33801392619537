import { Workspace } from '@api/workspaces/types'
import { getFullName } from '@utils/helpers'
import React from 'react'
import { Avatar, Container } from 'ui'
import { LogoWrapper } from './styled'

export interface AvatarWithLogoProps {
  avatarUrl?: string
  workspace: Workspace
  color?: string
  firstName: string
  lastName: string
}

export const AvatarWithLogo: React.FC<AvatarWithLogoProps> = ({
  avatarUrl,
  workspace,
  color,
  firstName,
  lastName,
}) => {
  const fullName = getFullName(firstName, lastName)
  return (
    <Container position="relative">
      <Avatar
        bgColor={`#${color}`}
        label={firstName}
        imgUrl={avatarUrl}
        name={fullName}
        size="4.4rem"
        fontSize="2rem"
      />
      {workspace.avatar && (
        <LogoWrapper>
          <img src={workspace.avatar.mediaUri} alt={workspace.name} />
        </LogoWrapper>
      )}
    </Container>
  )
}
