import { messagesAdditionalUrl } from '@api/create-base-url'
import { protectedApiInstance } from '@api/instance'
import type { AxiosRequestConfig } from 'axios'

function get<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return protectedApiInstance.get<ResponseType>(`${messagesAdditionalUrl}/${url}`, config)
}

function post<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.post<ResponseType>(`${messagesAdditionalUrl}/${url}`, data, config)
}

function patch<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.patch<ResponseType>(`${messagesAdditionalUrl}/${url}`, data, config)
}

function put<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.put<ResponseType>(`${messagesAdditionalUrl}/${url}`, data, config)
}
function del<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return protectedApiInstance.delete<ResponseType>(`${messagesAdditionalUrl}/${url}`, config)
}
export const messagesApi = {
  get,
  post,
  patch,
  put,
  delete: del,
}
