import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'ui'
import { InputLabel } from './input-label'

export const AvatarField = () => {
  return (
    <Container display="flex" alignItems="center">
      <Skeleton width={80} height={80} />
      <Container ml={16}>
        <InputLabel />
      </Container>
    </Container>
  )
}
