import { Form, Formik } from 'formik'
import { TFunction } from 'i18next'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, LimitTextField } from 'ui'

export type EditValues<T extends string = string> = {
  [key in T]: string
}

export interface EditDetailsFormProps {
  onCancel: VoidFunction
  onSubmit: (values: EditValues) => void
  fieldKey: string
  initialValue: string
  validationSchema?: (t: TFunction, fieldKey: string) => any
  maxCharacters?: number
  placeholder?: string
  label?: string
  disabled?: boolean
}

export const EditDetailsForm: FC<EditDetailsFormProps> = ({
  onCancel,
  onSubmit,
  fieldKey,
  maxCharacters = 50,
  validationSchema,
  placeholder,
  label,
  initialValue,
  disabled,
}) => {
  const { t } = useTranslation('details')

  const initialValues: EditValues = {
    [fieldKey]: initialValue,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema?.(t, fieldKey)}
    >
      {({ values, errors, handleChange, isValid, isSubmitting }) => (
        <Form>
          <LimitTextField
            value={values[fieldKey]}
            onChange={handleChange}
            maxCharacters={maxCharacters}
            id={fieldKey}
            name={fieldKey}
            label={label}
            placeholder={placeholder}
            mb="2rem"
            warning={!isValid}
            helperText={errors ? errors[fieldKey] : ''}
          />
          <Container display="flex" flexGap="1.2rem">
            <Button
              onClick={onCancel}
              variant="secondary"
              disabled={isSubmitting}
              size="sm"
              width="100%"
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              size="sm"
              disabled={!isValid || isSubmitting || disabled}
              width="100%"
            >
              {t('save')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
