import { UpdateChatInfoRequest } from './generated'
import { chatsApi } from './instance'
import { Chat } from './types/chat'

export interface UpdateChatProps {
  chatId: string
  requestBody: UpdateChatInfoRequest
}

export const updateChat = async ({ chatId, requestBody }: UpdateChatProps) => {
  const { data } = await chatsApi.patch<Chat>(`/${chatId}/update-info`, requestBody)
  return data
}
