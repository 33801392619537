import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetSupportedLanguages } from '@api/account/hooks/use-get-supported-languages'
import { useReportTranslation } from '@api/messages/hooks/use-report-translation'
import { useUpdateTranslationLanguage } from '@api/messages/hooks/use-update-translation-language'
import { useUpdateTranslationPreference } from '@api/messages/hooks/use-update-translation-preference'
import { ChipButton } from '@components/chip-button'
import { TranslationText } from '@components/translation-text'
import { useSkeleton } from '@hooks/use-skeleton'
import { useNativeTranslations } from '@hooks/use-translations'
import { EmptyResults } from '@modules/empty-results'
import { SidepageHeader } from '@modules/side-page/header'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { Caption, Container, Divider, HelpTooltip, ScrollContainer } from 'ui'
import { TranslationsWithSkeleton } from './translations'
import { TranslationsFooterWithSkeleton } from './translations-footer'
import { TranslationsHeaderWithSkeleton } from './translations-header'

export const TranslationPickerContent: FC = () => {
  const { t } = useTranslation(['translation-picker'])
  const { clientMessageId, setSelectedLanguage, selectedLanguage, message } =
    useNativeTranslations()

  const { data: supportedLanguages, isLoading: isSupportedLanguagesLoading } =
    useGetSupportedLanguages({ skip: false })
  const { meData, primaryLanguages, isLoading: isMeDataLoading } = useGetMe()
  const [showOriginalMessage, setShowOriginalMessage] = useState<boolean>(false)
  const { mutate: updateTranslation, isLoading: isUpdateTranslationLoading } =
    useUpdateTranslationLanguage()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const { mutate: updateTranslationPreference } = useUpdateTranslationPreference()
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const { mutate: reportTranslation } = useReportTranslation()

  const isTranslationsPickerLoading = isUpdateTranslationLoading
  const isTranslationsHeaderLoading = isMeDataLoading || isSupportedLanguagesLoading

  const selectedTranslation = useMemo(() => {
    const foundTranslation = message?.translations.find(
      (translation) => translation.languageCode === selectedLanguage
    )

    return foundTranslation || null
  }, [message, selectedLanguage])

  const shouldUpdateTranslations = (langCode: string) =>
    !message?.translations.some((translation) => langCode === translation.languageCode)

  // Select language of translations
  const handleOnSelectLanguage = (langCode: string) => {
    setSelectedLanguage(langCode)
    setIsExpanded(false)
    if (message?.messageId && shouldUpdateTranslations(langCode)) {
      updateTranslation({
        clientMessageId,
        messageId: message.messageId,
        languageCode: langCode,
        chatId: message.chatId,
      })
    }
  }

  // Select translation text preference
  const handleOnSelectTranslation = (provider: number) => {
    if (selectedLanguage && message?.messageId) {
      updateTranslationPreference({
        clientMessageId,
        messageId: message.messageId,
        provider: provider,
        languageCode: selectedLanguage,
        chatId: message.chatId,
      })
    }
  }

  // Report incorrect translation
  const handleOnReportIncorrectTranslation = () => {
    if (selectedLanguage && message?.messageId) {
      reportTranslation({
        clientMessageId,
        messageId: message.messageId,
        languageCode: selectedLanguage,
        chatId: message.chatId,
      })
    }
  }

  useEffect(() => {
    if (!message || !primaryLanguages) {
      return
    }
    // Always show main translations by default when opening the translation picker
    setSelectedLanguage(primaryLanguages[0])
  }, [])

  useEffect(() => {
    if (isTranslationsPickerLoading) {
      showSkeleton(SkeletonComponentNames.TRANSLATIONS_1)
      showSkeleton(SkeletonComponentNames.TRANSLATIONS_FOOTER_1)
    } else {
      hideSkeleton(SkeletonComponentNames.TRANSLATIONS_1)
      hideSkeleton(SkeletonComponentNames.TRANSLATIONS_FOOTER_1)
    }
  }, [hideSkeleton, isTranslationsPickerLoading, showSkeleton])

  useEffect(() => {
    if (isTranslationsHeaderLoading) {
      showSkeleton(SkeletonComponentNames.TRANSLATIONS_HEADER_1)
    } else {
      hideSkeleton(SkeletonComponentNames.TRANSLATIONS_HEADER_1)
    }
  }, [hideSkeleton, isSupportedLanguagesLoading, showSkeleton])

  const availableLanguages = useMemo(
    () => meData?.activeLanguages.filter((lang) => lang.code !== selectedLanguage) || [],
    [meData, selectedLanguage]
  )

  return (
    <>
      <SidepageHeader title={t('translation')} />
      {message?.translations.length === 0 ? (
        <Container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="calc(100% - 10rem)"
        >
          <EmptyResults
            title={t('emptyTranslations.title')}
            text={t('emptyTranslations.subtitle')}
          />
        </Container>
      ) : (
        <>
          <TranslationsHeaderWithSkeleton
            availableLanguages={availableLanguages}
            setIsExpanded={setIsExpanded}
            isExpanded={isExpanded}
            setShowOriginalMessage={setShowOriginalMessage}
            showOriginalMessage={showOriginalMessage}
            supportedLanguages={supportedLanguages || []}
            componentName={SkeletonComponentNames.TRANSLATIONS_HEADER_1}
          />
          <ScrollContainer hideScrollbar height="calc(100% - 9.5rem)">
            {showOriginalMessage && (
              <TranslationHeading mb="1.2rem">{t('originalMessageTitle')}</TranslationHeading>
            )}

            {showOriginalMessage && message ? (
              <TranslationText text={message?.originalMessage} />
            ) : (
              <Container
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <div>
                  {availableLanguages.length > 0 && isExpanded && (
                    <>
                      <Container display="flex" alignItems="center" mb="1.2rem">
                        <TranslationHeading mr="0.4rem">{t('availableLangs')}</TranslationHeading>
                        <HelpTooltip size="sm" text={t('availableLangsInfo')} placement="bottom" />
                      </Container>
                      <LanguagesContainer>
                        {availableLanguages.map((lang) => (
                          <ChipButton
                            key={lang.code}
                            text={lang.translatedName || ''}
                            isSelected={false}
                            onClick={() => handleOnSelectLanguage(lang.code)}
                          />
                        ))}
                      </LanguagesContainer>
                      <Divider my="2.4rem" />
                    </>
                  )}
                  <TranslationsWithSkeleton
                    translationTexts={selectedTranslation?.translations || []}
                    onClick={handleOnSelectTranslation}
                    componentName={SkeletonComponentNames.TRANSLATIONS_1}
                  />
                </div>
                <Container py="2rem">
                  <TranslationsFooterWithSkeleton
                    onClick={handleOnReportIncorrectTranslation}
                    isIncorrect={selectedTranslation?.hasBeenReportedAsIncorrect || false}
                    componentName={SkeletonComponentNames.TRANSLATIONS_FOOTER_1}
                  />
                </Container>
              </Container>
            )}
          </ScrollContainer>
        </>
      )}
    </>
  )
}

export const TranslationHeading = styled(Caption)`
  color: ${({ theme }) => theme.colors.greySuperDark};
  text-transform: uppercase;
`

const LanguagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 0.8rem;
`
