import { Notifications } from '@modules/notifications'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'ui'

export const PersonalPreferences: FC = () => {
  const { t } = useTranslation('personal')
  return (
    <Container maxWidth="50rem">
      <Notifications title={t('notificationsTitle')} />
    </Container>
  )
}
