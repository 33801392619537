import { DotsLoader } from '@components/dots-loader'
import { ProtectedApiProvider } from '@contexts/protected-api'
import { useAuth } from '@hooks/use-auth'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Container } from 'ui'

const ProtectedRoutes = () => {
  const { user, login } = useAuth()
  const { t } = useTranslation('common')

  useEffect(() => {
    if (user === undefined) {
      // Redirect to the login page
      login({})
    }
  }, [user])

  return user === undefined ? (
    <Container
      textAlign="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <DotsLoader text={t('loading')} />
    </Container>
  ) : (
    <ProtectedApiProvider>
      <Outlet />
    </ProtectedApiProvider>
  )
}

export { ProtectedRoutes }
