import { theme } from '@common/theme'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { LanguageSettingsForm } from '@modules/forms/language-settings'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Text, InfoIcon } from 'ui'

export const PersonalLanguages: FC = () => {
  const { t } = useTranslation('personal')
  const { setOpen, setType, setBgColor } = useSidePage()

  const handleClick = () => {
    setBgColor(theme.colors.greyLighter)
    setType(SidePageTypes.INFO)
    setOpen(true)
  }

  return (
    <Container position="relative">
      <Container position="absolute" top="-5rem" right="0">
        <Button plain variant="fainted" onClick={handleClick} preFix={<InfoIcon />}>
          {t('help')}
        </Button>
      </Container>
      <Text as="h2" fontSize="1.8rem" mb="0.8rem" fontWeight={500}>
        {t('languagesTitle')}
      </Text>
      <Text color={theme.colors.greyDark} mb="2.4rem">
        {t('languagesSubtitle')}
      </Text>
      <Container maxWidth="60rem">
        <LanguageSettingsForm />
      </Container>
    </Container>
  )
}
