import { Translation } from '@api/chats/types/translation'

export const markIncorrectTranslation = (languageCode: string, translations: Translation[]) => {
  return translations.map((translation) => {
    if (translation.languageCode === languageCode) {
      const updatedProviderTranslations = translation.translations.map((providerTranslation) => ({
        ...providerTranslation,
        isPreferred: false,
      }))

      return {
        ...translation,
        translations: updatedProviderTranslations,
        hasBeenReportedAsIncorrect: true,
      }
    }
    return translation
  })
}
