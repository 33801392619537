import jwt_decode from 'jwt-decode'
import React, { createContext, Dispatch, SetStateAction, useState, useEffect } from 'react'

export interface WorkspaceProviderProps {
  children: JSX.Element | JSX.Element[]
}

export enum OnboardingType {
  USER = 'user',
  WS = 'workspace',
}

export interface WorkspaceProviderDefinition {
  workspaceId: string
  setWorkspaceId: Dispatch<SetStateAction<string>>
  workspaceName: string
  setWorkspaceName: Dispatch<SetStateAction<string>>
  allowJoinByDomain: boolean
  setAllowJoinByDomain: Dispatch<SetStateAction<boolean>>
  onboardingType?: OnboardingType
  setOnboardingType: Dispatch<SetStateAction<OnboardingType | undefined>>
  isPendingOnboarding: boolean
  setIsPendingOnboarding: Dispatch<SetStateAction<boolean>>
  removeWorkspace: VoidFunction
  removeOnboarding: VoidFunction
}

const initData: WorkspaceProviderDefinition = {
  workspaceId: '',
  setWorkspaceId: () => {},
  workspaceName: '',
  setWorkspaceName: () => {},
  allowJoinByDomain: false,
  setAllowJoinByDomain: () => {},
  onboardingType: OnboardingType.WS,
  setOnboardingType: () => {},
  removeWorkspace: () => {},
  removeOnboarding: () => {},
  isPendingOnboarding: false,
  setIsPendingOnboarding: () => {},
}

const WorkspaceContext = createContext<WorkspaceProviderDefinition>(initData)

const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const [workspaceId, setWorkspaceId] = useState('')
  const [workspaceName, setWorkspaceName] = useState('')
  const [allowJoinByDomain, setAllowJoinByDomain] = useState(false)
  const [onboardingType, setOnboardingType] = useState<OnboardingType>()
  const [isPendingOnboarding, setIsPendingOnboarding] = useState<boolean>(false)

  useEffect(() => {
    const workspaceTokenFromStorage = sessionStorage.getItem('workspace.token')
    const onboardingTypeFromStorage = localStorage.getItem('onboardingType')

    if (workspaceTokenFromStorage) {
      const decodedWorkspaceToken: any = jwt_decode(workspaceTokenFromStorage)
      setWorkspaceId(decodedWorkspaceToken.act.workspace_id)
    }

    if (onboardingTypeFromStorage) {
      setOnboardingType(onboardingTypeFromStorage as OnboardingType)
    }
  }, [])

  useEffect(() => {
    if (onboardingType) localStorage.setItem('onboardingType', onboardingType)
  }, [onboardingType])

  const removeWorkspace = () => {
    setWorkspaceId('')
    setWorkspaceName('')
    sessionStorage.removeItem('workspace.token')
  }

  const removeOnboarding = () => {
    localStorage.removeItem('onboardingType')
    setIsPendingOnboarding(false)
  }

  return (
    <WorkspaceContext.Provider
      value={{
        workspaceId,
        setWorkspaceId,
        workspaceName,
        setWorkspaceName,
        allowJoinByDomain,
        setAllowJoinByDomain,
        onboardingType,
        setOnboardingType,
        removeWorkspace,
        removeOnboarding,
        isPendingOnboarding,
        setIsPendingOnboarding,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}

export { WorkspaceContext, WorkspaceProvider }
