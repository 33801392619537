import { MessageContainer } from '@components/message/styled'
import styled from 'styled-components'
import { Container, ScrollContainer } from 'ui'

export const ChatBodyContainer = styled(ScrollContainer)`
  display: flex;
  flex-direction: column-reverse;
  flex-basis: 100%;
  height: 100%;
  & > ${MessageContainer} {
    margin: 0.4rem 0;
  }
  & > ${MessageContainer}:first-of-type {
    margin-bottom: 0;
  }
`

export const LoaderContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
