import { getFeatureFlag } from '@utils/flags-manager'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonLink, Container, Heading, OpenIcon, Text } from 'ui'

const DeleteWorkspace: React.FC = () => {
  const { t } = useTranslation(['workspace'])
  return (
    <Container>
      <Heading variant="h3" as="h2" mb="1.2rem">
        {t('deleteWs')}
      </Heading>
      {
        getFeatureFlag('showOnProd')
         ? (
          <>
            <Text mb="2.4rem">{t('deleteMessage')}</Text>
            <Button type="reset" variant="warning" minWidth="16rem">
              {t('delete')}
            </Button>
          </>
         )
         : (
          <>
            <Text>{t('deleteMessage')}</Text>
            <Text mb="2.4rem">Contact customer support to delete a workspace.</Text>
            <Container>
              <ButtonLink
                href="mailto: support@native.tech"
                plain
                variant="secondary"
                preFix={<OpenIcon />}
              >
                Contact support
              </ButtonLink>
            </Container>
          </>
         )
      }
    </Container>
  )
}

export default DeleteWorkspace
