import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface DraftMessage {
  conversationId: string
  messageText: string
}

export interface DraftMessagesState {
  draftMessages: DraftMessage[]
}

const initialState: DraftMessagesState = {
  draftMessages: [],
}

export const draftMessagesSlice = createSlice({
  name: 'draftMessages',
  initialState,
  reducers: {
    addDraftMessage: (state, action: PayloadAction<DraftMessage>) => {
      const existingDraftMessageIndex = state.draftMessages.findIndex(
        (df) => df.conversationId === action.payload.conversationId
      )

      if (existingDraftMessageIndex !== -1) {
        // Update the existing draft message with a new array
        const updatedDraftMessages = [...state.draftMessages]
        updatedDraftMessages[existingDraftMessageIndex] = {
          ...updatedDraftMessages[existingDraftMessageIndex],
          messageText: action.payload.messageText,
        }

        return {
          ...state,
          draftMessages: updatedDraftMessages,
        }
      } else {
        return {
          ...state,
          draftMessages: [...state.draftMessages, action.payload],
        }
      }
    },
    removeDraftMessage: (state, action: PayloadAction<{ conversationId: string }>) => {
      const conversationIdToRemove = action.payload.conversationId
      const updatedDraftMessages = state.draftMessages.filter(
        (df) => df.conversationId !== conversationIdToRemove
      )
      return {
        ...state,
        draftMessages: updatedDraftMessages,
      }
    },
  },
})

export const { addDraftMessage, removeDraftMessage } = draftMessagesSlice.actions

export default draftMessagesSlice.reducer
