import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { createMe } from '../create-me'
import { CreateProfile } from '../generated'

interface useCreateMeProps {
  disableToast?: boolean
}

export const useCreateMe = ({ disableToast = false }: useCreateMeProps = {}) => {
  const { t } = useTranslation('onboarding')

  const { mutate, mutateAsync, isLoading, isError, error, isSuccess } = useMutation({
    mutationFn: (requestBody: CreateProfile) => {
      return createMe(requestBody)
    },
    onMutate: async () => {
      // You can implement any necessary actions here before mutation
    },
    onError: () => {
      if (!disableToast) {
        toast.error(`${t('errors.somethingWentWrong')}. ${t('errors.tryAgainLater')}`)
      }
    },
    onSuccess: () => {
      if (!disableToast) {
        toast.success(`${t('success.onboarded')}.`)
      }
    },
    onSettled: () => {
      // You can implement any necessary actions here after mutation
    },
  })

  return { mutate, mutateAsync, isLoading, isError, error, isSuccess }
}
