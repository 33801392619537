import { patchMe, PatchMePath } from '@api/account/patch-me'
import { ME } from '@api/account/tags'
import { PatchMeArgs } from '@api/account/types/patch-me-args'
import { CHAT_LIST } from '@api/chats/query-keys'
import { WORKSPACE } from '@api/workspaces/tags'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { invalidateManyQueries } from '@utils/invalidate-many-queries'
import { reduce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Operation } from '../generated'

interface usePatchMeProps {
  disableToast?: boolean
  onSuccessCallback?: VoidFunction
}

const KeysToInvalidate = [ME, CHAT_LIST]

export const usePatchMe = ({ disableToast = false, onSuccessCallback }: usePatchMeProps = {}) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (personalValues: PatchMeArgs) => {
      const data = reduce(
        personalValues,
        (acc, value, key) => {
          if (key === PatchMePath.Email || (!value && key !== PatchMePath.AvatarId)) return acc
          return [
            ...acc,
            {
              op: 'replace',
              path: key as PatchMePath,
              value,
            },
          ]
        },
        [] as Operation[]
      )
      return patchMe(data)
    },
    onMutate: async (newMeData) => {
      await queryClient.cancelQueries([ME])
      const previousMeData = queryClient.getQueryData([ME])
      queryClient.setQueryData([ME, WORKSPACE], newMeData)
      return { previousMeData }
    },
    onError: (e, v, context) => {
      if (!disableToast) {
        toast.error(`${t('changesNotSaved')}.`)
      }
      queryClient.setQueryData([ME, WORKSPACE], context?.previousMeData)
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback()
      }
      if (!disableToast) {
        toast.success(`${t('changesSaved')}.`)
      }
    },
    onSettled: () => {
      invalidateManyQueries(queryClient, KeysToInvalidate)
    },
  })
}
