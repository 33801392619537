import { messagesApi } from './instance'
import { Message } from './types/message'
import { CreateAttachmentResponse } from '@api/chats/generated'

export interface SendMessageProps {
  chatId: string
  clientMessageId: string
  mediaIds: string[]
  text: string
  attachments?: CreateAttachmentResponse[]
  isResending?: boolean
}

export const sendMessage = async ({
  chatId,
  clientMessageId,
  mediaIds,
  text,
}: SendMessageProps) => {
  const { data } = await messagesApi.post<Message>(`/`, {
    chatId,
    clientMessageId,
    mediaIds,
    text,
  })

  return data
}
