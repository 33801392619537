import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsers } from '@api/account/hooks/use-get-users'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { Chat } from '@api/chats/types/chat'
import { NetworkMode } from '@api/enums'
import { useChatName } from '@hooks/use-chat-name'
import { getChatAvatar } from '@utils/get-chat-avatar'
import { isChat } from '@utils/get-chat-type'
import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Container, EditIcon, Tooltip } from 'ui'
import { Indicators, ListItemText, NewMessageIndicator } from './styled'

interface ChatListItemProps {
  item: Chat
  showStatus?: boolean
}

export interface ListItemTextProps {
  hasUnreadMessage?: boolean
  textRef?: RefObject<HTMLParagraphElement>
}

export interface ListProps {
  open: boolean
}

export const ChatListItem: FC<ChatListItemProps> = ({
  item: { unreadMessagesCount, id },
  item,
}) => {
  const [chatUserId, setChatUserId] = useState<string>('')
  const [avatarId, setAvatarId] = useState<string>()
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)
  const { meData } = useGetMe()
  const { users } = useGetUsers({ networkMode: NetworkMode.OFFLINE_FIRST })
  const theme = useTheme()
  const { t } = useTranslation('sidebar')

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: chatUserId,
    avatarId,
  })

  const hasUnreadMessage = useMemo(() => unreadMessagesCount > 0, [unreadMessagesCount])

  /* TODO: Currently the EditIcon is not displayed when a chat has a draft message. Add this functionality so the EditIcon will be displayed when there will be some draft messages ont he specific chat.*/
  const hasDraftMessage = false

  const name = useChatName(item, t)

  useEffect(() => {
    if (isChat(item)) {
      if (item.chatUsers.length === 1) {
        setChatUserId(item.chatUsers[0].user.userId)
        //@ts-ignore
        setAvatarId(item.chatUsers[0].user.avatar?.id)
      }

      if (item.chatUsers.length === 2) {
        const uniqUser = item.chatUsers.find((chatUser) => chatUser.user.userId !== meData?.userId)
        setChatUserId(uniqUser?.user.userId || '')
        //@ts-ignore
        setAvatarId(uniqUser?.user.avatar?.id)
      }
    }
  }, [item, setChatUserId, meData?.userId]) //be doesn't have avatars for chats yet

  useEffect(() => {
    textRef?.current && setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
  }, [textRef])

  return (
    <>
      <Container id={id} mr="0.8rem">
        {getChatAvatar({
          ...item,
          avatar: item.avatar,
          label: name?.slice(0, 1),
          size: '2.4rem',
          borderRadius: '0.4rem',
          iconSize: 24,
          id: item.id,
          workspaceUsers: users,
          me: meData,
          imgURL: usersAvatarUrl,
          isAvatarExists: enabled,
        })}
      </Container>
      <Tooltip text={name} placement="right" disabled={!isTruncated}>
        <ListItemText id={id} hasUnreadMessage={hasUnreadMessage} ref={textRef}>
          {name}
        </ListItemText>
      </Tooltip>
      {(hasDraftMessage || hasUnreadMessage) && (
        <Indicators ml="1.5rem">
          {hasDraftMessage && (
            <EditIcon
              fill={theme.colors.greyDark}
              width={16}
              height={16}
              aria-label={t('draftMessage')}
            />
          )}
          {hasUnreadMessage && <NewMessageIndicator aria-label={t('unreadMessage')} />}
        </Indicators>
      )}
    </>
  )
}
