import { RefObject } from 'react'

const getCaretCharacterOffsetWithin = (elementRef: RefObject<HTMLDivElement>) => {
  let caretOffset = 0

  if (elementRef.current) {
    const element = elementRef.current // Access the underlying DOM element
    const doc = element.ownerDocument
    const win = doc.defaultView 
    const sel = win?.getSelection()

    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0)
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      caretOffset = preCaretRange.toString().length
    }
  }
  return caretOffset
}

export const getCursorPosition = (elementRef: RefObject<HTMLDivElement>) => {
  return getCaretCharacterOffsetWithin(elementRef)
}
