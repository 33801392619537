import { SendButton } from '@components/message-input/styled'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding-right: 5.4rem;
  position: relative;
  border-radius: 1.6rem 1.6rem 0rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`

export const SubmitButton = styled(SendButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
`
