import { OnboardingType } from '@contexts/workspace-provider'
import { useAuth } from '@hooks/use-auth'
import { useWorkspace } from '@hooks/use-workspace'
import { InvitationInvalid } from '@modules/invitation-invalid'
import { routes } from '@routes/routes'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Text } from 'ui'

export const InvalidInvitation: FC = () => {
  const { logout, user, login } = useAuth()
  const { setOnboardingType } = useWorkspace()
  const navigate = useNavigate()
  const { t } = useTranslation('invitation')

  useEffect(() => {
    const redirectUrl = new URL(window.location.href)
    !user && login({ redirectUrl: redirectUrl.pathname })
    return
  }, [user, login])

  const handleOnCreateWs = () => {
    setOnboardingType(OnboardingType.WS)
    navigate(`${routes.workspaces}/${routes.createNew}`)
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      <InvitationInvalid width="100%" maxWidth="70rem" mt="4.8rem" onCreateWs={handleOnCreateWs} />
      <Container textAlign="center" mt="auto" pt="2.2rem">
        {user && (
          <Text mb="0.8rem">
            {t('loggedInAs')} {user?.profile.email}
          </Text>
        )}
        <Button plain variant="secondary" onClick={logout}>
          {t('change')}
        </Button>
      </Container>
    </Container>
  )
}
