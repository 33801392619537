import { ConversationType } from '@utils/get-chat-type'
import { chatsApi } from './instance'
import { Chat } from './types/chat'

export interface CreateChatsProps {
  chatName?: string
  userIds?: string[]
  conversationType?: ConversationType
  description?: string
}

export const createChat = async ({
  chatName,
  userIds,
  conversationType,
  description,
}: CreateChatsProps) => {
  const { data } = await chatsApi.post<Chat>(`/`, {
    name: chatName,
    userIds,
    conversationType,
    description,
  })

  return data
}
