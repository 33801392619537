import { ConversationType } from '@utils/get-chat-type'
import { TFunction } from 'next-i18next'
import * as Yup from 'yup'
import { CreateChannelValues } from '.'

export const initialValues: CreateChannelValues = {
  name: '',
  desc: '',
  channelType: ConversationType.PUBLIC_CHANNEL,
}

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t('errors.emptyField')).max(50, t('errors.maxChars')),
    desc: Yup.string(),
    channelType: Yup.string(),
  })
