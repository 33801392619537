import { useBannersContext } from '@contexts/banners-provider'
import { useSidePage } from '@hooks/use-side-page'
import { TeammatesFilters } from '@modules/filters/teammates-filters'
import { Details } from '@modules/side-page/details'
import { LanguagesInfo } from '@modules/side-page/languages-info'
import { TranslationPicker } from '@modules/side-page/translation-picker'
import { User } from '@modules/side-page/user'
import CSS from 'csstype'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LayoutProps, SpaceProps } from 'styled-system'
import { CloseIcon } from 'ui'
import { CloseButton, SidePageContainer, StyledSidePage } from './styled'

export interface SidePageProps {
  bgColor?: CSS.Property.Color | null
}

export interface StyledSidePageProps {
  bgColor: CSS.Property.Color | null
}
export type GeneralSidePageProps = SidePageProps & SpaceProps & LayoutProps

export enum SidePageTypes {
  DETAILS = 'details',
  USER = 'user',
  INFO = 'info',
  TRANSLATION = 'translation',
  TEAMMATES_FILTERS = 'filters',
}

export const SidePage: FC<GeneralSidePageProps> = () => {
  const { open, type, setOpen, bgColor, setBgColor, sidePageId } = useSidePage()
  const { t } = useTranslation(['common'])
  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()

  const handleClose = () => {
    setOpen(false)
    setBgColor(null)
  }

  // Remove bgColor when the sidepage is still open and only the content changes
  useEffect(() => {
    setBgColor(null)
  }, [sidePageId])

  const getSidePageContent = () => {
    switch (type) {
      case SidePageTypes.DETAILS:
        return <Details />
      case SidePageTypes.USER:
        return <User />
      case SidePageTypes.INFO:
        return <LanguagesInfo />
      case SidePageTypes.TRANSLATION:
        return <TranslationPicker />
      case SidePageTypes.TEAMMATES_FILTERS:
        return <TeammatesFilters />
      default:
        return null
    }
  }

  if (open) {
    return (
      <SidePageContainer
        p="2rem 2rem 2rem 0"
        notificationsBannerShown={notificationsBannerShown}
        internetConnectionBannerShown={internetConnectionBannerShown}
      >
        <StyledSidePage bgColor={bgColor}>
          <CloseButton type="button" hiddenLabel={t('close')} onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          {getSidePageContent()}
        </StyledSidePage>
      </SidePageContainer>
    )
  }
  return null
}
