import { AddChannelMembersForm } from '@modules/forms/add-channel-members'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'ui'

interface AddChannelMembersModalProps {
  channelId: string
  channelName: string
}

export const AddChannelMembersModal: FC<AddChannelMembersModalProps> = ({
  channelName,
  channelId,
}) => {
  const { t } = useTranslation('modal-add-channel-members')
  return (
    <>
      <Heading as="h2" mb="2.8rem">
        {channelName && t('title', { channelName })}
      </Heading>
      <AddChannelMembersForm channelId={channelId} />
    </>
  )
}
