import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container, Text } from 'ui'

export const DashboardSkeleton: FC = () => (
  <>
    <Text textAlign="left" mb="1.5rem">
      <Skeleton width={200} height={20} />
    </Text>
    <Container display="flex" alignItems="center" flexGap="1.2rem">
      <Skeleton width={60} height={60} />
      <Skeleton width={248} height={12} />
      <Skeleton width={128} height={48} />
    </Container>
  </>
)
