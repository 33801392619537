import { routes } from '@routes/routes'
import { UserManager, User, WebStorageStateStore } from 'oidc-client-ts'
import { authConfig, OauthConfiguration } from './oauth-configuration'
import { CookieStorage } from 'cookie-storage'

const expirationTime = new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 1000) //gives us 30 days to expire token

export const cookieStorage = new CookieStorage({
  sameSite: 'Strict',
  secure: true,
  expires: expirationTime,
})

class Authenticator {
  static userManager: UserManager

  constructor(config: OauthConfiguration) {
    const settings = {
      authority: config.authority,
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      scope: config.scope,
      post_logout_redirect_uri: config.postLogoutRedirectUrl,
      response_type: 'code',
      automaticSilentRenew: true,
      defaultLanguage: 'en-US',
      userStore: new WebStorageStateStore({
        store: cookieStorage,
      }),
    }
    Authenticator.userManager = new UserManager(settings)
  }

  static globalInstance() {
    if (!Authenticator.userManager) {
      return new Authenticator(authConfig)
    }
    return Authenticator.userManager
  }

  public getUser(): Promise<User | null> {
    return Authenticator.userManager.getUser()
  }

  public login(redirectUrl?: string): Promise<void> {
    if (redirectUrl) {
      return Authenticator.userManager.signinRedirect({
        state: redirectUrl,
      })
    }
    return Authenticator.userManager.signinRedirect()
  }

  public loginCallback(): Promise<User> {
    return Authenticator.userManager.signinRedirectCallback()
  }

  public refreshToken(): Promise<User | null> {
    return Authenticator.userManager.signinSilent({ redirect_uri: routes.home })
  }

  public logout(): Promise<void> {
    return Authenticator.userManager.signoutRedirect()
  }
}

export { Authenticator }
