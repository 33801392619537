import styled from 'styled-components'
import { ScrollContainer, Text } from 'ui'
import { LanguageButtonProps } from '.'

export const LanguagesContainer = styled(ScrollContainer)`
  height: 20rem;
  margin: 0 -1.6rem 1.6rem 0;
`

export const LanguageItem = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.colors.greyLight};
  }
`

export const PrimaryIndicator = styled.span`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 2.4rem;
  height: 2.4rem;
`

export const LanguageButton = styled.button<LanguageButtonProps>`
  position: relative;
  width: 100%;
  padding: 0.8rem 1rem;
  text-align: left;
  min-height: 4rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${({ theme, isPrimary }) => (isPrimary ? theme.colors.primary : theme.colors.black)};
  transition: color ${({ theme }) => theme.transitionTimes.short},
    background-color ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

export const EmptyResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.6rem;
  height: 20rem;
  ${Text} {
    text-align: center;
  }
`
